import { Grid, Typography, Box, Button } from '@mui/material';
import AnimationVisibilityTracker from './AnimationVisibilityTracker';
import CenteredGrid from './CenteredGrid';
import styles from './styles/ourServices';
import SectionTag from './SectionTag';
import Img from './Img';

const ServiceItem = ({ src, title, text, linkText, link, isNew }) => (
  <Grid item xs={12} md={10} sx={{ minHeight: 170 }}>
    <Grid container spacing={10}>
      <Grid item sx={styles.servicesItemSvg}>
        <AnimationVisibilityTracker animationIn="bounceIn" animationOut="bounceOut">
          <Img src={src} sx={styles.servicesIcons} alt={title} />
        </AnimationVisibilityTracker>
      </Grid>
      <Grid item xs sx={isNew ? styles.servicesItemText : styles.servicesItemTextSec}>
        {isNew && (
          <Box component="span" sx={styles.newChip}>
            New
          </Box>
        )}
        <Box sx={styles.serviceItemInner}>
          <Typography variant="h4SemiBold" sx={styles.subTitle}>
            {title}
          </Typography>
          <Typography variant="subtitle3SemiBold" sx={styles.regularTextServices}>
            {text}
          </Typography>
          <Box sx={styles.servicesLinkContainer}>
            <Box
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={link}
              sx={styles.regularTextServicesLink}
            >
              {linkText}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  </Grid>
);

const splitInHalf = (arr = []) => {
  const middle = Math.ceil(arr.length / 2);
  return [arr.slice(0, middle), arr.slice(middle)];
};

const defaultButtonProps = {
  text: 'BUILD A PACK TODAY!',
  link: '/dashboard/product-onboarding'
};

const OurServices = ({
  services,
  sectionTag = <SectionTag tag="OUR SERVICES" left={false} />,
  title,
  textAboveButton,
  buttonProps = {}
}) => {
  const [firstSection, secondSection] = splitInHalf(services);

  const derivedButtonProps = { ...defaultButtonProps, ...buttonProps };

  return (
    <Grid container justifyContent="center" sx={styles.container}>
      <CenteredGrid sx={(styles.center, styles.sectionContainerOS)}>
        {sectionTag}
        <Typography variant="display2SemiBold" sx={styles.title}>
          {title}
        </Typography>
        <Grid container justifyContent="center" spacing={8}>
          <Grid item lg={6} md={6} sm={12}>
            <Grid container spacing={4}>
              {firstSection.map(serviceItem => (
                <ServiceItem
                  src={serviceItem.src}
                  title={serviceItem.title}
                  text={serviceItem.text}
                  linkText={serviceItem.linkText}
                  link={serviceItem.link}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <Grid container justifyContent="flex-end" spacing={4}>
              {secondSection.map(serviceItem => (
                <ServiceItem
                  src={serviceItem.src}
                  title={serviceItem.title}
                  text={serviceItem.text}
                  linkText={serviceItem.linkText}
                  link={serviceItem.link}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CenteredGrid>
      <Grid
        container
        alignContent="center"
        direction="column"
        justifyContent="center"
        item
        sx={styles.servicesButtonContainer}
      >
        {textAboveButton && (
          <Grid item>
            <Typography sx={styles.textAboveButtonOS}>{textAboveButton}</Typography>
          </Grid>
        )}
        <Grid item style={{ alignSelf: 'center' }}>
          <Button
            href={derivedButtonProps.link}
            target="_blank"
            variant="contained"
            sx={[styles.mainButton, derivedButtonProps.sx]}
          >
            {derivedButtonProps.text}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OurServices;
