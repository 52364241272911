import { Box } from '@mui/material';
import { Redirect, useParams } from 'react-router-dom';
import seoTags from '../../utils/seoTags';
import Helmet from '../shared/Helmet';
import Blog from './Blog';
import BuildLoyalty from './BuildLoyalty';
import CarePackages from './CarePackages';
import CustomPacks from './CustomPacks';
import EmployeeSwagPack from './EmployeeSwagPack';
import HolidaySwag from './HolidaySwag';
import HomeOffice from './HomeOffice';
import NewHire from './NewHire';
import RemoteEmployee from './RemoteEmployee';
import RipplingIntegration from './RipplingIntegration';
import SimpleSwagPacks from './SimpleSwagPacks';
import SwagManagment from './SwagManagment';
import SwagMadeSimpleEventManagers from './SwagMadeSimpleEventManagers';
import SwagPacksMadeSimple from './SwagPacksMadeSimple';
import VirtualConferenceSwag from './VirtualConferenceSwag';

const landingPages = {
  blog: { Component: Blog, tags: seoTags.blog },
  'build-loyalty-with-swag-packs': { Component: BuildLoyalty, tags: seoTags.buildLoyalty },
  'care-packages': { Component: CarePackages, tags: seoTags.carePackages },
  'custom-packs': { Component: CustomPacks, tags: seoTags.customPacks },
  'employee-swag-pack': { Component: EmployeeSwagPack, tags: seoTags.employeeSwagPack },
  holidayswag: { Component: HolidaySwag, tags: seoTags.holidaySEMPage },
  'integrate-swagup-with-rippling': { Component: RipplingIntegration, tags: seoTags.rippling },
  'new-hire-swag-packs': { Component: NewHire, tags: seoTags.newHire },
  'send-swag-from-your-home-office': { Component: HomeOffice, tags: seoTags.homeOffice },
  'simple-swag-packs': { Component: SimpleSwagPacks, tags: seoTags.swagPacksMadeSimple },
  'stay-connected-with-remote-employees-through-swag': { Component: RemoteEmployee, tags: seoTags.remoteSwag },
  'swag-management-platform': { Component: SwagManagment, tags: seoTags.swagManagment },
  'swag-made-simple-for-event-managers': {
    Component: SwagMadeSimpleEventManagers,
    tags: seoTags.swagMadeSimpleEventManagers
  },
  'swag-packs-made-simple': { Component: SwagPacksMadeSimple, tags: seoTags.swagPacksMadeSimple },
  'virtual-conference-swag': { Component: VirtualConferenceSwag, tags: seoTags.virtualSwag }
};

const styles = {
  container: theme => ({
    paddingTop: 31.75,
    [theme.breakpoints.down('md')]: { paddingTop: 14, paddingBottom: 12 }
  })
};

const Landing = () => {
  let { slug } = useParams();
  const { Component: LandingPage, tags } = landingPages[slug] ?? {};

  if (!LandingPage) return <Redirect to="/404" />;

  return (
    <Box component="main" sx={styles.container}>
      <Helmet tags={tags} />
      <LandingPage />
    </Box>
  );
};

export default Landing;
