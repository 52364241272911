import * as React from 'react';
import { Button, Grid, Fab, FormControl, Box, Typography, Link } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import platformApiClient from '../../api/platformApiClient';
import styles from './styles/subscribe';
import { getSchema, emailRequired } from '../../common/commonValidations';
import { TextField } from '../shared/reactHookFormFields';
import Img from './Img';
import CenteredGrid from './CenteredGrid';

const SocialIcon = ({ href, src, alt, paddingLeft }) => (
  <Grid item xs={3} sx={{ paddingLeft }}>
    <Link href={href} target="_blank" rel="noopener noreferrer">
      <Img src={src} alt={alt} />
    </Link>
  </Grid>
);

const Socials = () => {
  const basePath = 'imgs/subscribe/';

  return (
    <Grid item md={6} sm={5} xs={12}>
      <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
        <Grid item>
          <Box sx={styles.socialMedia}>
            <Typography component="p">Follow along on our journey!</Typography>
            <Grid container alignItems="flex-end">
              <SocialIcon href="https://www.instagram.com/swagup" src={`${basePath}instagram.svg`} alt="Instagram" />
              <SocialIcon href="https://twitter.com/swagup" src={`${basePath}twitter.svg`} alt="Twitter" />
              <SocialIcon
                href="https://www.facebook.com/weareswagup"
                src={`${basePath}facebook.svg`}
                alt="Facebook"
                paddingLeft={2}
              />
              <SocialIcon
                href="https://www.linkedin.com/company/swagup"
                src={`${basePath}linkedin.svg`}
                alt="LinkedIn"
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ResponseForm = ({ title, subtitle, ButtonComponent, text }) => (
  <Box>
    <Typography variant="display2Bold" sx={styles.title}>
      {title}
    </Typography>
    <Typography variant="subtitle2SemiBold" sx={styles.regularText}>
      {subtitle} <br /> {text} {ButtonComponent}
    </Typography>
  </Box>
);

const FormAfterSend = ({ serverError, onTryAgain }) => (
  <Box>
    {serverError ? (
      <ResponseForm
        title="Something went wrong"
        subtitle="There was a problem with your subscription."
        text="Please try"
        ButtonComponent={
          <Button type="submit" onClick={onTryAgain} sx={styles.againButton}>
            again
          </Button>
        }
      />
    ) : (
      <ResponseForm
        title="Thank you for signing up!"
        subtitle="We’ll be delivering content to you shortly."
        text="Welcome aboard!"
      />
    )}
  </Box>
);

const Form = ({ onSubmit, register, errors, FormComponent, fullWidth }) => {
  return (
    <Box component="form" onSubmit={onSubmit} sx={{ width: fullWidth ? '100%' : null }}>
      {FormComponent ? (
        <FormComponent register={register} errors={errors} />
      ) : (
        <Grid>
          <Typography variant="display2Bold" sx={styles.subscribeTitle}>
            Be the first to know
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <TextField
                  sx={styles.subscribeInput}
                  placeholder="Your email"
                  {...register('email')}
                  error={errors?.email?.message}
                  variant="standard"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Box sx={styles.subscribeActions}>
                <Fab type="submit" sx={styles.subscribeButton}>
                  Subscribe
                </Fab>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const resolver = yupResolver(getSchema([], { email: emailRequired }));

const Subscribe = ({ FormComponent, socials = true, fullWidth = false }) => {
  const { register, handleSubmit, formState } = useForm({
    resolver,
    defaultValues: { email: '' },
    reValidateMode: 'onChange',
    mode: 'all'
  });
  const { errors } = formState;

  const [sent, setSent] = React.useState(false);
  const [serverError, setServerError] = React.useState('');

  const handleSubmitRequest = (newSent, newError) => {
    setSent(newSent);
    setServerError(newError);
  };

  const handleTryAgain = () => handleSubmitRequest(false, false);

  const onSubmit = formData => {
    const { email } = formData;
    platformApiClient
      .subscribe({ email })
      .then(result => {
        const isErrorResponse = result.result !== 'ok' || result.data.status >= 400;
        handleSubmitRequest(true, isErrorResponse);
      })
      .catch(() => {
        handleSubmitRequest(true, true);
      });
  };

  return (
    <CenteredGrid sx={{ maxWidth: fullWidth ? '100%' : null }}>
      <Box sx={styles.sectionContainer}>
        <Grid container spacing={6} justifyContent="center" sx={{ marginLeft: -4 }}>
          <Grid
            container
            item
            md={6}
            sm={7}
            xs={12}
            sx={[
              styles.subscribeContainer,
              { maxWidth: fullWidth ? '100% !important' : null, flexBasis: fullWidth ? '100% !important' : null }
            ]}
          >
            {sent ? (
              <FormAfterSend onTryAgain={handleTryAgain} serverError={serverError} />
            ) : (
              <Form
                onSubmit={handleSubmit(onSubmit)}
                register={register}
                errors={errors}
                FormComponent={FormComponent}
                fullWidth={fullWidth}
              />
            )}
          </Grid>
          {socials && <Socials />}
        </Grid>
      </Box>
    </CenteredGrid>
  );
};

export default Subscribe;
