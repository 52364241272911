import { Grid, Typography } from '@mui/material';
import { imageSrcSet } from '../../../utils';
import Img from '../../shared/Img';
import CenteredGrid from '../../shared/CenteredGrid';
import SectionTag from '../../shared/SectionTag';
import styles from './styles/newBuildYourBrand';

const Section = ({ response, sm }) => (
  <Grid item xs={12} sm={sm || 4} sx={[styles.boxBYB, sm === 3 && styles.boxSM3]}>
    <Grid container sx={{ margin: 0 }}>
      {response.img && (
        <Grid item xs={12} sx={[styles.servicesItemSvg, { paddingBottom: 0 }]}>
          <Img sx={styles.imgRocketBYB} src={response.img.src} alt={response.img.alt} />
        </Grid>
      )}
      <Grid item xs sx={styles.containerSectionSubTitleBYB}>
        <Typography variant="inherit" sx={styles.sectionSubTitle}>
          {response.subTitle}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);

const NewBuildYourBrand = ({ responses, title, maingImg, hideTag }) => (
  <Grid container justifyContent="center" sx={styles.containerBuildYB}>
    <CenteredGrid sx={styles.center}>
      {!hideTag && <SectionTag tag="BUILD YOUR BRAND" />}
      <Typography variant="inherit" sx={[styles.titleBYB, { whiteSpace: 'pre-wrap' }]}>
        {title || 'What’s a Swag Pack?'}
      </Typography>
      <Grid container justifyContent="center" sx={[styles.containerAllBYB, styles.wrapperBYBImg]}>
        <Img
          src={maingImg || '/imgs/landingPagesSSR/screen-shot-pm.jpg'}
          srcSet={imageSrcSet(maingImg || '/imgs/landingPagesSSR/screen-shot-pm.jpg')}
          alt="group"
          sx={styles.bYBImg}
        />
        <Grid item sx={styles.containerBoxBYB}>
          <Grid container>
            {responses.map(r => (
              <Section key={r.subTitle} response={r} sm={12 / responses.length} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </CenteredGrid>
  </Grid>
);

export default NewBuildYourBrand;
