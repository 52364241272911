const styles = {
  center: theme => ({
    [theme.breakpoints.down('md')]: {
      width: 1024,
      padding: '0px 24px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }),
  loadMoreButton: {
    width: 145,
    height: 45,
    borderRadius: 5,
    backgroundColor: 'gb(250, 250, 255)',
    color: '#3577d4',
    border: 0,
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontWeight: 600,
    outline: 'none',
    marginTop: 6,
    marginBottom: 6,
    cursor: 'pointer'
  },
  refreshIcon: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    fontWeight: 600,
    marginRight: 2,
    marginTop: 1
  },
  RelatedPosts: theme => ({
    paddingTop: 14,
    paddingBottom: 25.5,
    marginTop: 14,
    marginBottom: 14,
    backgroundColor: '#e9f0fa',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 8,
      paddingBottom: 14
    }
  }),
  SubTitle: {
    fontFamily: 'Gilroy-Medium',
    fontSize: '16px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#9846dd',
    textTransform: 'uppercase',
    paddingTop: 7.5,
    paddingLeft: 5,
    '&::before': {
      backgroundColor: '#9846dd',
      content: '""',
      display: 'inline-block',
      height: '1px',
      position: 'relative',
      verticalAlign: 'middle',
      width: '7%',
      right: '1em'
    }
  },
  title: theme => ({
    textAlign: 'center',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 48,
    fontWeight: 600,
    lineHeight: 1.29,
    color: '#0f2440',
    paddingBottom: 18,
    marginBottom: 0,
    paddingTop: 7.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      paddingBottom: 6,
      paddingTop: 0
    }
  }),
  loadMoreSection: {
    paddingTop: 14,
    paddingBottom: 0,
    width: '100%'
  }
};

export default styles;
