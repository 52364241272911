import { Grid } from '@mui/material';
import styles from '../shared/styles/backgroundAdorments';
import BaseBackgroundCircle from '../shared/BackgroundCircle';
import BackgroundFigureCompany from './BackgroundFigureCompany';

const BackgroundCircleCompany = ({ invisible, ...props }) => (
  <BaseBackgroundCircle {...props} opacity={invisible ? 0.05 : 0.15} />
);

const BackgroundAdornments = () => {
  return (
    <Grid container justifyContent="center" sx={styles.ovalContainer}>
      <BackgroundCircleCompany size={402} color="#9846dd" top={600} right={-72} />
      <BackgroundCircleCompany size={456} color="#003399" top={700} left={-364} outlined />
      <BackgroundCircleCompany size={256} color="#003399" top={724} right={182} invisible />
      <BackgroundCircleCompany size={72} color="#003399" top={1024} right={-72} />
      <BackgroundCircleCompany size={356} color="#9846dd" top={1700} left={56} outlined />
      <BackgroundCircleCompany size={156} color="#003399" top={1724} left={332} invisible />
      <BackgroundCircleCompany size={72} color="#003399" top={3124} left={-72} />
      <BackgroundCircleCompany size={356} color="#003399" top={3424} right={-392} outlined />
      <BackgroundCircleCompany size={72} color="#9846dd" top={4124} right={156} />
      <BackgroundCircleCompany size={224} color="#003399" top={4092} right={192} invisible />
      <BackgroundCircleCompany size={124} color="#003399" bottom={4700} right={40} invisible />
      <BackgroundCircleCompany size={342} color="#9846dd" bottom={4500} right={-216} outlined invisible />
      <BackgroundCircleCompany size={72} color="#003399" bottom={5300} left={56} />
      <BackgroundCircleCompany size={242} color="#003399" bottom={4200} outlined invisible />
      <BackgroundCircleCompany size={224} color="#9846dd" bottom={4100} left={-100} invisible />
      <BackgroundCircleCompany size={72} color="#9846dd" bottom={3500} right={156} />
      <BackgroundCircleCompany size={142} color="#003399" bottom={2800} invisible />
      <BackgroundCircleCompany size={324} color="#9846dd" bottom={2600} left={-232} outlined />
      <BackgroundCircleCompany size={342} color="#9846dd" bottom={2100} right={-216} invisible />
      <BackgroundCircleCompany size={124} color="#003399" bottom={1500} right={56} invisible />
      <BackgroundCircleCompany size={192} color="#003399" bottom={1600} invisible />
      <BackgroundCircleCompany size={72} color="#9846dd" bottom={950} left={32} />
      <BackgroundCircleCompany size={342} color="#9846dd" bottom={560} right={-116} invisible />
      <BackgroundCircleCompany size={542} color="#003399" bottom={-100} right={-192} invisible outlined />
      <BackgroundCircleCompany size={92} color="#9846dd" bottom={200} right={280} />
      <BackgroundFigureCompany width={264} height={198} bottom={4000} right={575} borderBottomRightRadius />
      <BackgroundFigureCompany width={306} height={216} bottom={3500} left={490} borderBottomRightRadius />
      <BackgroundFigureCompany width={268} height={192} bottom={2950} right={680} borderTopLeftRadius />
      <BackgroundFigureCompany width={200} height={224} bottom={2450} left={640} borderTopLeftRadius />
      <BackgroundFigureCompany width={306} height={247} bottom={1800} right={400} borderBottomLeftRadius />
      <BackgroundFigureCompany width={306} height={247} bottom={1250} left={600} borderBottomRightRadius />
      <BackgroundFigureCompany width={306} height={165} bottom={730} right={380} borderBottomRightRadius />
      {/* Mobile */}
      <BackgroundCircleCompany size={120} color="#9846dd" top={212} left={350} mobile />
      <BackgroundCircleCompany size={56} color="#003399" top={472} right={370} mobile />
      <BackgroundCircleCompany size={256} color="#003399" top={2522} left={270} mobile outlined />
      <BackgroundCircleCompany size={120} color="#9846dd" top={3822} right={350} mobile />
      <BackgroundCircleCompany size={180} color="#9846dd" top={7922} left={350} mobile />
      <BackgroundCircleCompany size={124} color="#003399" top={8822} right={350} mobile />
      <BackgroundCircleCompany size={180} color="#9846dd" top={9922} right={350} mobile />
      <BackgroundCircleCompany size={356} color="#003399" top={10122} left={270} mobile outlined />
      <BackgroundCircleCompany size={124} color="#9846dd" top={10622} right={350} mobile />
      <BackgroundCircleCompany size={180} color="#003399" top={10722} right={380} mobile invisible />
    </Grid>
  );
};

export default BackgroundAdornments;
