import { useState } from 'react';
import { Grid, Tabs, Tab, Typography, Box, useTheme } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import styles from './styles/gallery';
import CenteredGrid from '../shared/CenteredGrid';
import GalleryDisplay from './GalleryDisplay';

const TabPanel = ({ children, value, index }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
  >
    <Box p={3}>{children}</Box>
  </Typography>
);

const GalleryTabs = ({ mediaGallery, categories }) => {
  const [value, setValue] = useState(0);
  const [filteredImages, setFilteredImages] = useState(mediaGallery);
  const theme = useTheme();
  const conventionalCategories = ['All Items', ...categories];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFilteredImages(
      newValue
        ? mediaGallery.filter(image => image.acf.categories?.includes(conventionalCategories[newValue]))
        : mediaGallery
    );
  };
  return (
    <Grid container justifyContent="center" sx={styles.galleryContainer}>
      <CenteredGrid>
        <Tabs
          value={value}
          sx={[styles.flexContainer]}
          TabIndicatorProps={{
            sx: styles.indicator
          }}
          onChange={handleChange}
        >
          {['All Items', ...categories].map((category, index) => (
            <Tab key={category} sx={index === value ? styles.tab : styles.tabOff} label={category} />
          ))}
        </Tabs>
      </CenteredGrid>
      <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} style={{ width: '100vw' }}>
        {conventionalCategories.map((category, index) => (
          <TabPanel key={category} value={value} index={index} dir={theme.direction}>
            {value === index && (
              <CenteredGrid container justifyContent="center" sx={styles.centerTabs}>
                <Box sx={styles.tabsWrapper}>
                  <GalleryDisplay mediaGallery={filteredImages} />
                </Box>
              </CenteredGrid>
            )}
          </TabPanel>
        ))}
      </SwipeableViews>
    </Grid>
  );
};

export default GalleryTabs;
