import { useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import { Modal } from './Modal';
import ContactForm from './ContactForm';

const styles = {
  container: { padding: '0 72px 56px' },
  title: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    color: '#0f2440',
    marginBottom: 5,
    textAlign: 'center'
  },
  subtitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    maxWidth: 456,
    color: '#0f2440',
    marginBottom: 16,
    opacity: 0.54,
    textAlign: 'center'
  }
};

const ThanksView = ({ onClose }) => {
  return (
    <Box sx={styles.container}>
      <Typography component="h3" variant="inherit" sx={styles.title}>
        Message Received!
      </Typography>
      <Typography variant="inherit" sx={styles.subtitle}>
        Your message has made it through to mission control. We’ll be in touch soon. To the moon!
      </Typography>
      <Grid container justifyContent="center">
        <Button variant="primary" size="small" onClick={onClose} style={{ width: 322 }}>
          Back to Page
        </Button>
      </Grid>
    </Box>
  );
};

const ContactDialog = ({ open, onClose, isPartnership, defaultSubject }) => {
  const [showContactForm, setShowContactForm] = useState(open);
  const title = showContactForm ? 'Send us a message' : '';

  return (
    <Modal title={title} open={open} onClose={onClose}>
      {showContactForm ? (
        <ContactForm
          onlyPartnershipSubjects={isPartnership}
          defaultSubject={defaultSubject}
          onSubmitSuccess={() => setShowContactForm(false)}
        />
      ) : (
        <ThanksView onClose={onClose} />
      )}
    </Modal>
  );
};

export default ContactDialog;
