const styles = {
  containerSamplePackVCS: theme => ({
    py: 40,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      paddingBottom: 10.5
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('1030')]: {
      width: 960
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  sectionContainerSPVCS: theme => ({
    marginTop: 0,
    paddingLeft: 3,
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      px: 4.5
    }
  }),
  subSectionContainerSPVCS: theme => ({
    paddingLeft: 20.75,
    borderRadius: 6.25,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0
    },
    [theme.breakpoints.down('1030')]: {
      paddingLeft: 0
    },
    [theme.breakpoints.down('sm')]: {
      background: 'none',
      paddingLeft: 0,
      borderTopRightRadius: '25px !important',
      backgroundSize: ' cover !important'
    }
  }),
  contentNewGT: {
    marginTop: 0
  },
  containerTitlesSP: theme => ({
    paddingTop: 15,
    paddingRight: 4,
    [theme.breakpoints.down('md')]: {
      paddingRight: 4.5,
      paddingLeft: 7
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  }),
  promotionTitleSP: theme => ({
    textAlign: 'left',
    fontSize: 56,
    marginTop: 7,
    fontFamily: 'Gilroy-Bold',
    color: '#0f2440',
    lineHeight: 1.29,
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('1030')]: {
      lineHeight: 1,
      marginBottom: 6
    },
    [theme.breakpoints.down('lg')]: { fontSize: 56 },
    [theme.breakpoints.down('md')]: {
      paddingBottom: 2.5,
      paddingTop: 1.25,
      fontSize: 24,
      marginBottom: 2.5
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 2.5,
      paddingTop: 4,
      textAlign: 'center',
      marginLeft: 0,
      marginBottom: 0
    }
  }),
  textSamplePackVCS: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 24,
    color: '#8D9299',
    lineHeight: 1.5,
    marginTop: 9,
    paddingRight: 20,
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      paddingRight: 0
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      textAlign: 'center',
      marginLeft: 0,
      marginTop: 0,
      marginBottom: 2.5,
      padding: '0px 20px'
    }
  }),
  containerSPVCS: theme => ({
    borderTopRightRadius: 100,
    paddingLeft: 7,
    paddingRight: 4,
    paddingTop: 5,
    marginTop: 2.5,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginTop: 5,
      marginLeft: 0,
      paddingLeft: 5,
      paddingRight: 3.5,
      paddingTop: 6.25
    }
  }),
  subContainerSP: theme => ({
    boxShadow: '0 30px 100px 0 rgba(212, 217, 226, 0.5)',
    backgroundColor: '#ffffff',
    borderRadius: 5,
    borderTopRightRadius: 100,
    height: '94%',
    [theme.breakpoints.down('md')]: {
      boxShadow: '0 30px 100px 0 #ffffff'
    }
  }),
  wrapperSPImg: theme => ({
    position: 'relative',
    top: -133,
    paddingLeft: 4,
    paddingRight: 4,
    [theme.breakpoints.down('md')]: {
      borderBottomRightRadius: 15,
      borderTopLeftRadius: 15,
      padding: '0px 20px 0px 20px',
      top: -45
    }
  }),
  sPImg: {
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  },
  containerButtonSPVCS: theme => ({
    position: 'relative',
    top: -90,
    [theme.breakpoints.down('md')]: {
      top: -13
    }
  }),
  buttonSPVCS: theme => ({
    py: 4,
    width: 294,
    fontSize: 18,
    borderRadius: 9,
    backgroundColor: '#9846dd',
    [theme.breakpoints.down('md')]: {
      py: 3,
      width: 190,
      fontSize: 12
    }
  }),
  textIntCust: theme => ({
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    opacity: 0.56,
    textAlign: 'center',
    color: '#0f2440',
    marginTop: 8,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginBottom: 2.5,
      marginTop: 3
    }
  })
};

export default styles;
