const styles = {
  containerRequestPack: theme => ({
    paddingBottom: 25,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 10.5
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('1030')]: {
      width: 960
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }),
  sectionContainerCU: theme => ({
    paddingLeft: 15.5,
    paddingRight: 0,
    paddingTop: 16.75,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 6.25,
      paddingLeft: 0
    }
  }),
  contentNewGT: {
    marginTop: 0
  },
  sectionTagContainerCU: theme => ({
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 11.25,
      justifyContent: 'left'
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingLeft: 0,
      paddingTop: 0
    }
  }),
  sectionTagContainerOS: theme => ({
    paddingTop: 15.25,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'left',
      paddingTop: 0,
      paddingLeft: 11.25
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingTop: 0,
      paddingLeft: 0
    }
  }),
  sectionTagLineMobil: theme => ({
    height: 0,
    border: 'solid 2px #9846dd',
    marginRight: 4,
    width: 64,
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      border: 'solid 0.5px #9846dd',
      marginLeft: 4,
      width: 42,
      display: 'block'
    }
  }),
  sectionTagLine: theme => ({
    height: 0,
    border: 'solid 1px #9846dd',
    marginRight: 4,
    width: 64,
    [theme.breakpoints.down('sm')]: {
      border: 'solid 0.5px #9846dd',
      marginLeft: 4,
      width: 42
    }
  }),
  sectionTag: theme => ({
    color: '#3577d4',
    fontSize: 16,
    textTransform: 'uppercase',
    textAlign: 'left',
    fontFamily: 'Gilroy-SemiBold',
    lineHeight: 1.5,
    fontWeight: 600,
    marginTop: 7.25,
    marginBottom: 7.25,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
      margin: 0
    }
  }),
  promotionTitleCU: theme => ({
    textAlign: 'left',
    fontSize: 56,
    marginTop: 0,
    marginBottom: 4.5,
    fontFamily: 'Gilroy-SemiBold',
    color: '#0f2440',
    fontWeight: 500,
    lineHeight: 1.29,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 2.5,
      paddingTop: 4,
      textAlign: 'left',
      fontSize: 24,
      marginBottom: 0,
      marginLeft: 11.25,
      lineHeight: 1.5
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 2.5,
      paddingTop: 4,
      textAlign: 'center',
      fontSize: 20,
      marginBottom: 0,
      marginLeft: 0,
      lineHeight: 1.5
    }
  }),
  containerPhone: theme => ({
    marginTop: 16.25,
    [theme.breakpoints.down('sm')]: {
      marginTop: 3.75
    }
  }),
  textPhone: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    fontWeight: 500,
    color: '#434c5f',
    opacity: 0.54,
    textDecoration: 'none',
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
      marginLeft: 11.25
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      marginLeft: 5
    }
  }),
  textPhoneNumber: theme => ({
    alignItems: 'center',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    fontWeight: 'bold',
    color: '#434d5c',
    textDecoration: 'none',
    marginTop: 5,
    '& a': {
      color: '#434d5c'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 2.5,
      fontSize: 14,
      marginLeft: 11.25,
      marginBottom: 7.5
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 2.5,
      fontSize: 14,
      marginLeft: 5
    }
  }),
  imageContainerPhone: theme => ({
    height: 25,
    width: 'auto',
    marginRight: 4.525,
    position: 'relative',
    top: 5,
    [theme.breakpoints.down('sm')]: {
      marginRight: 1.5,
      height: 20
    }
  }),
  ContainerForm: theme => ({
    display: 'flex',
    boxShadow: '0 20px 80px 0 rgba(27, 28, 31, 0.03)',
    backgroundColor: '#ffffff',
    borderBottomRightRadius: 100,
    padding: 13.75,
    [theme.breakpoints.down('sm')]: {
      marginTop: 5,
      fontSize: 14,
      marginLeft: 0,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 6.25,
      maxWidth: '100%'
    }
  }),
  thankYouContainer: theme => ({
    margin: 'auto',
    padding: '56px 72px',
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '24px 42px',
      paddingTop: 0
    }
  }),
  thankYouText: theme => ({
    fontFamily: 'Gilroy',
    fontSize: 24,
    color: '#0f2440',
    marginBottom: 5,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      marginBottom: 3
    }
  }),
  thankYouSubText: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    maxWidth: 456,
    color: '#434c5f',
    marginBottom: 16.25,
    opacity: 0.54,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      marginBottom: 6
    }
  })
};

export default styles;
