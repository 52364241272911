import { useMemo } from 'react';
import FeaturedPost from './FeaturedPost';
import RecentlyPublished from './RecentlyPublished';
import StayInLoop from '../shared/StayInLoopSection';
import Box from '@mui/material/Box';
import _ from 'lodash';
import styles from './styles/BlogHome';

const BlogHome = ({ posts, categories, paddingTop }) => {
  const featuredPost = useMemo(() => posts?.find(p => p.headliner === 'Yes'), [posts]);
  const combinedCategories = useMemo(() => {
    if (posts && categories) {
      return _.uniq([...categories, ...posts?.map(p => p.category)]);
    }
  }, [categories, posts]);
  if (!posts || !combinedCategories) {
    return <div style={{ height: '100vh' }}></div>;
  }

  return (
    <Box component="main" sx={[styles.container, paddingTop && { paddingTop: paddingTop }]}>
      <FeaturedPost post={featuredPost} />
      <RecentlyPublished posts={posts} categories={combinedCategories} featuredPost={featuredPost} />
      <StayInLoop />
    </Box>
  );
};

export default BlogHome;
