import { platformAxiosInstance } from './platformApiClient';
import log from '../common/logger';
import { okAndLog, errorAndLog } from '../utils';
import apiPaths from '../api/apiPaths';
import appConfig from '../appConfig';

export const fetchNotificationServer = async () => {
  log.debug('fetchNotificationServer Action - Entering');
  try {
    const apiCall = await platformAxiosInstance.get(`${apiPaths.notifications}?ordering=-modified_on`);
    if (apiCall.status === 200) return okAndLog('fetchNotificationServer', apiCall.status, apiCall.data.results);

    return errorAndLog(
      `fetchNotificationServer ${appConfig.data.REACT_APP_API_ENDPOINT}`,
      apiCall.status,
      apiCall.data
    );
  } catch (e) {
    return errorAndLog(`fetchNotificationServer try ${appConfig.data.REACT_APP_API_ENDPOINT} ${e}`, e.status, e.data);
  }
};
