import { Grid, Button, FormControl, Typography } from '@mui/material';
import { TextField } from './reactHookFormFields';
import Subscribe from './Subscribe';
import styles from './styles/stayInLoop';
import { imageSrcSet } from '../../helpers/utils';
import Img from './Img';

const Subscriber = ({ register, errors }) => (
  <>
    <Typography variant="h1SemiBold" sx={styles.title}>
      Stay in the loop
    </Typography>
    <Typography variant="body1SemiBold" sx={styles.subTitle}>
      Be the first to learn about the latest swag and culture building tips
    </Typography>
    <Grid container style={{ paddingTop: 56 }}>
      <Grid item xs={12} sm={9} md={7}>
        <FormControl fullWidth>
          <TextField
            placeholder="Email address"
            {...register('email')}
            error={errors?.email?.message}
            sx={styles.emailInput}
          />
        </FormControl>
      </Grid>
      <Grid container item xs={12} sm={3} md={4} sx={styles.buttonContainer}>
        <Button variant="contained" type="submit" sx={styles.subscribeButton}>
          Subscribe
        </Button>
      </Grid>
    </Grid>
  </>
);

const StayInLoop = () => (
  <Grid container justifyContent="center" sx={styles.container}>
    <Grid container sx={styles.center}>
      <Grid container justifyContent="center" item md={5} sx={styles.imageContainer}>
        <Img
          src="/imgs/blog/subscribe-email.png"
          srcSet={imageSrcSet('/imgs/blog/subscribe-email.png')}
          sx={styles.image}
          alt="Stay in the loop"
        />
      </Grid>
      <Grid item xs={12} md={7} sx={styles.textContainer}>
        <Subscribe FormComponent={Subscriber} socials={false} fullWidth />
      </Grid>
    </Grid>
  </Grid>
);

export default StayInLoop;
