import { Grid, Box, Typography } from '@mui/material';
import { Animated } from 'react-animated-css';
import styles from './styles/logos';

const bottomImagesList = [
  { src: '/imgs/public/google.svg', alt: 'Google', margin: '0 0 -5px 0' },
  { src: '/imgs/public/walmart.svg', alt: 'Walmart', margin: '0 0 -5px 30px' },
  { src: '/imgs/public/cocacola.svg', alt: 'CocaCola', margin: '0 0 -4px 30px' },
  { src: '/imgs/public/duolingo_logo.svg', alt: 'Duolingo', margin: '2px 0 0 30px', width: '103px' },
  { src: '/imgs/public/gemini.svg', alt: 'Gemini', margin: '0 0 0 30px', padding: '4px 0 0 0' },
  { src: '/imgs/public/facebook.svg', alt: 'Facebook', margin: '0 0 -1px 30px' }
];

const ShowBottomImages = ({ list }) => (
  <Grid container sx={styles.bottomImagesContainer}>
    {list.map(({ src, alt, margin, padding, width }) => (
      <Grid item key={alt} style={{ margin, padding, width }}>
        <img src={src} alt={alt} style={width && { height: '100%', width: '100%' }} />
      </Grid>
    ))}
  </Grid>
);

const Logos = ({ isBeondeck }) => {
  if (isBeondeck) {
    bottomImagesList[3] = { src: '/static/imgs/public/amazon.svg', alt: 'Amazon', margin: '8px 0 0 30px' };
  }

  return (
    <Box sx={styles.container}>
      <Animated animationIn="fadeIn" animationInDelay={2000}>
        <Typography variant="inherit" sx={styles.trustedBy}>
          Trusted by
        </Typography>
        <ShowBottomImages list={bottomImagesList} />
      </Animated>
    </Box>
  );
};

export default Logos;
