import ContactUs from '../../shared/ContactUs';
import OurServices from '../../shared//OurServices';
import BackgroundLandingPages from '../shared/BackgroundAdornments';
import BuildYourBrand from '../shared/NewBuildYourBrand';
import LandingSection from '../shared/LandingSection';
import SamplePack from '../shared/NewSamplePack';

const services = [
  {
    src: '/imgs/landingPagesSSR/design.svg',
    title: 'Design',
    text: 'Why bog down your design team with swag design? We can handle that for you. Our team will work with you to understand your branding and recommend items that fit your company culture'
  },
  {
    src: '/imgs/landingPagesSSR/warehouse-storage.svg',
    title: 'Storage',
    text: 'No need to store swag in your car or office. We provide inventory tracking and storage.'
  },
  {
    src: '/imgs/landingPagesSSR/assemble.svg',
    title: 'Assemble',
    text: 'Assembling items in your office is hard, especially if you are trying to surprise your co-workers/employees. Our team will ship your packs pre-assembled for you'
  },
  {
    src: '/imgs/landingPagesSSR/ship.svg',
    title: 'Ship',
    text: 'Having employees working remote or in different offices is a logistical headache. Our team has expertise shipping worldwide to ensure your swag makes it to your employees desk!'
  }
];

const promotionTitle = ' We do the work - You take the credit!';

const buttonLabel = 'GET STARTED TODAY!';

const responses = [
  {
    img: { src: '/imgs/landingPagesSSR/culture.svg', alt: 'culture' },
    subTitle:
      'High end swag assembled together that your new hires and employees will love! Select from brands they will love like Patagonia, S’well, and more!'
  },
  {
    img: { src: '/imgs/landingPagesSSR/quality-items.svg', alt: 'quality-items' },
    subTitle:
      'Contains items such as notebooks and apparel your employees will appreciate. Give them a fresh start with swag will use!'
  },
  {
    img: { src: '/imgs/landingPagesSSR/client-employee-bond.svg', alt: 'client-employee-bond' },
    subTitle:
      'These packs are great for new hire on-boarding, employee appreciation, work anniversary, or simply just saying thank you!'
  }
];

const sectionData = {
  h1Style: { whiteSpace: 'pre-wrap' },
  title: 'Employee \nSwag Pack',
  subTitle: 'Let´s on board them with Swag!',
  buttonLabel: 'Let’s get started!'
};

const EmployeeswagPackHome = () => (
  <>
    <BackgroundLandingPages />
    <LandingSection sectionData={sectionData} />
    <BuildYourBrand responses={responses} title="What’s an Employee Swag Pack?" />
    <SamplePack />
    <OurServices services={services} promotionTitle={promotionTitle} buttonProps={{ text: buttonLabel }} />
    <ContactUs namePage="Employee Swag Pack" />
  </>
);

export default EmployeeswagPackHome;
