import axios from 'axios';
import { parseHtmlEntities } from '../components/Blog/blogUtilFunctions';

let instance = axios.create();

export const createWordpressApiInstances = apiEndpoint => {
  instance = axios.create({
    baseURL: `${apiEndpoint}/wp-json/wp/v2`
  });
};

const processFAQs = rawFAQs =>
  rawFAQs.map(({ id, title, content: { rendered } }) => ({
    id,
    title: parseHtmlEntities(title.rendered),
    content: rendered
  }));

const parseTeam = team =>
  team.map(({ id, slug, title, content: { rendered }, acf }) => ({
    id,
    slug,
    title: title.rendered,
    rendered,
    first_name: acf.first_name,
    last_name: acf.last_name,
    job_title: acf.job_title,
    hierarchy: acf.hierarchy,
    image: acf.image
  }));

const getAllEntities = async (call, previousEntities = [], page = 1) => {
  const entities = await instance
    .get(call.endpoint, { params: { ...call.params, per_page: call.params.per_page || 100, page } })
    .then(async res => {
      const totalPages = res.headers['x-wp-totalpages'];
      const currentData = [...previousEntities, ...res.data];
      if (totalPages && page >= parseInt(totalPages, 10)) return currentData;
      const rslt = await getAllEntities(call, currentData, page + 1);
      return rslt;
    });
  return entities;
};

const getCategories = id => instance.get(`/categories/${id}`).then(({ data }) => data.description.split('\r\n'));

const wordpressApiClient = {
  fetchAllFAQs: () =>
    getAllEntities({
      endpoint: '/posts',
      params: {
        'filter[meta_key]': 'type',
        'filter[meta_value]': 'FAQ',
        _fields: 'id,title,content'
      }
    }).then(processFAQs),
  fetchAllTeamMembers: () =>
    getAllEntities({
      endpoint: '/posts',
      params: {
        'filter[meta_key]': 'type',
        'filter[meta_value]': 'Team Member',
        'filter[orderby]': 'menu_order',
        'filter[posts_per_page]': 100
      }
    }).then(parseTeam),
  fetchAllPosts: async () => {
    const allPostsData = await getAllEntities({
      endpoint: '/posts',
      params: {
        'filter[meta_key]': 'type',
        'filter[meta_value]': 'Blog Article',
        'filter[orderby]': 'priority',
        _fields: 'id,slug,title,date,content,acf'
      }
    });

    return allPostsData.map(
      ({
        id,
        slug,
        title,
        date,
        content: { rendered },
        acf: {
          description,
          summary,
          category,
          readtime,
          image,
          headliner,
          featuredimage,
          cardimage,
          last_modified: lastModified
        }
      }) => ({
        id,
        slug,
        title: parseHtmlEntities(title.rendered),
        date,
        modified: lastModified || date,
        rendered,
        category,
        description: parseHtmlEntities(description),
        summary: parseHtmlEntities(summary),
        readtime,
        image,
        headliner,
        featuredImage: featuredimage,
        cardImage: cardimage
      })
    );
  },
  fetchBlogCategories: async () => getCategories(7),
  fetchGalleryCategories: async () => getCategories(9),
  getPostBySlug: async slug => await instance.get(`/posts/?slug=${slug}`).then(res => res.data),
  getPostComments: async id => await instance.get(`/comments?post=${id}`).then(res => res.data),
  getRelatedPosts: async (id, category) => {
    return await instance
      .get(`/posts`, {
        params: {
          'filter[meta_query][0][key]': 'type',
          'filter[meta_query][0][value]': 'Blog Article',
          'filter[meta_query][1][key]': 'category',
          'filter[meta_query][1][value]': category,
          exclude: id,
          _fields: 'id,slug,title,date,content,acf',
          per_page: 9
        }
      })
      .then(res => res.data);
  },
  getGalleryImages: async () => {
    const mediaGallery = await getAllEntities({
      endpoint: '/media',
      params: {
        parent: 6303,
        _fields: 'id,source_url,media_details,acf,description,caption,alt_text,title',
        per_page: 100,
        'filter[meta_key]': 'priority',
        'filter[orderby]': 'priority',
        'filter[order]': 'DESC'
      }
    });
    return mediaGallery;
  }
};

export default wordpressApiClient;
