import { Divider, Grid, Typography } from '@mui/material';
import styles from './styles/newSectionTag';

const SectionTag = ({
  tag,
  color = '#9846dd',
  hr1Style,
  hr2Style,
  sectionContainerClass,
  sectionLineClass,
  sectionClass,
  sectionTagClass
}) => {
  return (
    <Grid container sx={styles[sectionContainerClass] || styles.sectionTagContainerOS} alignItems="center">
      <Grid item sx={hr1Style}>
        <Divider sx={[styles[sectionLineClass] || styles.sectionTagLineMobil, { background: color }]} />
      </Grid>
      <Grid item>
        <Typography component="h6" sx={[styles[sectionClass] || styles.sectionTag, { color: color }]}>
          {tag}
        </Typography>
      </Grid>
      <Grid item sx={hr2Style}>
        <Divider sx={[styles[sectionTagClass] || styles.sectionTagLine, { background: color, marginLeft: 1 }]} />
      </Grid>
    </Grid>
  );
};

export default SectionTag;
