import Helmet from '../shared/Helmet';
import tags from '../../utils/seoTags';
import { getDemoLink } from '../../utils';
import Subscribe from '../shared/Subscribe';
import OurServices from '../shared/OurServices';
import BackgroundLight from './BackgroundLight';
import LandingView from './LandingView';
import { Box } from '@mui/material';
import styles from './styles/home';
import SwagForRemote from './SwagForRemote';
import PromotionHome from './PromotionHome';
import BuildYourBrand from './BuildYourBrand';
import SamplePack from './SamplePack';
import YourNewGoTo from './YourNewGoTo';
import Testimonials from '../shared/Testimonials';
import LetsGetStarted from './LetsGetStarted';
import Recents from './Recents';
import PartnerWithUs from './PartnerWithUs';
import FloatingCallUs from './FloatingCallUs';
import SeasonPromotion from './SeasonPromotion';
import SectionTag from './SectionTag';

const basePath = `${process.env.PUBLIC_URL}/imgs/homeLight/`;

const services = [
  {
    src: `${basePath}we-ll-handle-design.svg`,
    title: "We'll handle design",
    text: 'Design handled from start to finish for free.'
  },
  {
    src: `${basePath}inventory-management.svg`,
    title: 'Inventory management',
    text: 'Take back your office. We’ll hold onto your swag and alert you when you’re low.'
  },
  {
    src: `${basePath}swag-management-dashboard.svg`,
    title: 'Swag management dashboard',
    text: 'One centralized platform to streamline all your swag needs.',
    linkText: 'Schedule a demo',
    link: getDemoLink()
  },
  {
    src: `${basePath}global-fulfillment-covered.svg`,
    title: 'Global fulfillment covered',
    text: "If it's on a map, odds are we've shipped swag there."
  },
  {
    src: `${basePath}swag-shops.svg`,
    title: 'Swag shops',
    text: 'Use the SwagUp Shopify app to create and manage your online swag shop.'
  },
  {
    src: `${basePath}api-integrations.svg`,
    title: 'API & integrations',
    text: 'Integrate swag packs into your existing processes in minutes.',
    linkText: 'View docs',
    link: 'https://api.swagup.com/docs'
  }
];

const buttonProps = {
  text: 'GET IN TOUCH',
  link: 'https://swagup.chilipiper.com/router/product-demo-router?utm_source=homepage&utm_medium=dashboard_tour_homepage&utm_campaign=dashboard_tour_homepage&utm_content=dashboard_tour_homepage',
  sx: { backgroundColor: '#9846dd' }
};

const HomePage = ({ packs }) => {
  return (
    <>
      <Helmet tags={tags.home} />
      <BackgroundLight />
      {/* <Home testimonials={testimonials} packs={packs} /> */}
      <Box component="main" sx={styles.container}>
        <LandingView />
        <SeasonPromotion />
        <SwagForRemote />
        <PromotionHome />
        <BuildYourBrand />
        <SamplePack />
        <OurServices
          sectionTag={<SectionTag tag="OUR SERVICES" />}
          services={services}
          title="So much more than swag"
          buttonProps={buttonProps}
        />
        <YourNewGoTo />
        <Testimonials title={['Our clients love us to the  ', <br key="br" />, 'moon and back']} />
        <LetsGetStarted packs={packs} />
        <Recents />
        <PartnerWithUs />
        <FloatingCallUs />
        <Subscribe />
      </Box>
    </>
  );
};

export default HomePage;
