import { useState } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import ContactDialog from '../../../src/components/shared/ContactDialog';
import CenteredGrid from '../../../src/components/shared/CenteredGrid';
import styles from './styles/index';

const AskAQuestion = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => setOpenModal(false);

  return (
    <Grid container justifyContent="center">
      <CenteredGrid item sx={styles.center}>
        <Grid container justifyContent="center">
          <Grid item>
            <Box sx={styles.faqCenter}>
              <Box sx={styles.aaqBox}>
                <Grid container alignItems="stretch">
                  <Grid item xs>
                    <Typography variant="inherit" sx={styles.aaqBoxText}>
                      Can’t find what you’re looking for?
                    </Typography>
                    <Typography variant="inherit" sx={styles.aaqBoxSubText}>
                      You can post your question. Our Swag Agent will answer your question
                    </Typography>
                    <Grid container justifyContent="center">
                      <Grid item sm={12} xs="auto">
                        <Button variant="outlined" sx={styles.aaqBoxButton} onClick={() => setOpenModal(true)}>
                          Ask question
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm="auto" xs={12}>
                    <Grid container justifyContent="center" style={{ height: '100%' }}>
                      <Grid item>
                        <Box sx={styles.aaqImgContainer}>
                          <img
                            src="/imgs/faq/swagup-agent.png"
                            srcSet="/imgs/faq/swagup-agent.png 1x, /imgs/faq/swagup-agent@2x.png 2x, /imgs/faq/swagup-agent@3x.png 3x"
                            alt="SwagUp Agent"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CenteredGrid>
      <ContactDialog key={openModal} open={openModal} onClose={handleCloseModal} />
    </Grid>
  );
};

export default AskAQuestion;
