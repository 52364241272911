const styles = {
  promotionHomeCenter: theme => ({
    [theme.breakpoints.down('md')]: {
      padding: '0px 24px !important'
    }
  }),
  promotionHomeWrapperHAH: theme => ({
    marginTop: 3,
    paddingLeft: 5.5,
    paddingRight: 5.5,
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  }),
  headerSwagManagement: theme => ({
    padding: '20px 20px 0px 62px !important',
    [theme.breakpoints.down('md')]: {
      padding: '20px 10px 0px 45px!important'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px !important'
    }
  }),
  containerTextEventGeek: theme => ({
    padding: '0',
    marginTop: 15,
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      marginTop: 1.25,
      padding: 0
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 1.25,
      padding: 0,
      justifyContent: 'center',
      alignSelf: 'center'
    }
  }),
  logoChip: theme => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ebf1fb',
    borderRadius: 6,
    paddingBottom: 3,
    paddingTop: 3,
    paddingLeft: 4.5,
    paddingRight: 4.5,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 1.5,
      paddingTop: 1.5,
      paddingLeft: 2.25,
      paddingRight: 2.25
    }
  }),
  promotionTextHEH: theme => ({
    fontFamily: 'Gilroy-Bold',
    fontSize: 72,
    color: '#0f2440',
    marginTop: 4.5,
    lineHeight: 1.17,
    marginBottom: 2.5,
    [theme.breakpoints.down('1030')]: {
      fontSize: 50,
      marginTop: 2.5,
      marginBottom: 2.5
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px !important',
      marginTop: 2.5,
      marginBottom: 2.5,
      textAlign: 'center'
    }
  }),
  regularText: theme => ({
    color: '#0f2440',
    opacity: 0.54,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    textAlign: 'center',
    [theme.breakpoints.down('1030')]: {
      fontSize: '20px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px !important'
    }
  }),
  homePromoMiddleHAH: theme => ({
    lineHeight: 1.8,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: 3,
      textAlign: 'left'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 3,
      textAlign: 'center'
    }
  }),
  landingHereButtonContainer: theme => ({
    paddingTop: 10,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      paddingBottom: 0,
      textAlign: 'center'
    }
  }),
  landingHereButtonContainerFix: theme => ({
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }),
  buttonLandingSectionEG: theme => ({
    width: 256,
    height: 'unset',
    py: 5,
    fontSize: 20,
    lineHeight: 1.75,
    borderRadius: 9,
    [theme.breakpoints.down('md')]: {
      width: 125,
      fontSize: 12,
      py: 3,
      px: 0
    }
  }),
  containerImgHeroEventGeek: theme => ({
    backgroundColor: 'transparent !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingTop: 0,
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      marginTop: 2.5
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginLeft: '0px !important',
      paddingLeft: '20px !important',
      paddingRight: '14px !important',
      paddingTop: '5px !important'
    }
  }),
  eGImg: theme => ({
    objectFit: 'cover',
    width: 'auto',
    height: '94%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%'
    }
  }),
  containerDiscount: theme => ({
    width: 324,
    height: 98,
    boxShadow: '0 32px 85px 0 rgba(27, 28, 31, 0.05)',
    backgroundColor: '#fff',
    alignItems: 'center',
    paddingLeft: 5.25,
    position: 'relative',
    top: -200,
    left: 145,
    [theme.breakpoints.down('1030')]: {
      width: 270,
      height: 90,
      top: -180,
      left: 120
    },
    [theme.breakpoints.down('md')]: {
      top: -85,
      left: 93,
      width: 200,
      height: 50,
      paddingLeft: 1.25
    }
  }),
  imageDiscount: theme => ({
    height: 56,
    [theme.breakpoints.down('1030')]: {
      height: 52
    },
    [theme.breakpoints.down('md')]: {
      height: 30
    }
  }),
  exclusive: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#185ffe',
    marginTop: '10px !important',
    [theme.breakpoints.down('1030')]: {
      fontSize: 10
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 8
    }
  }),
  discount: theme => ({
    fontFamily: 'Gilroy-Semibold',
    fontSize: 16,
    marginBottom: '12px !important',
    color: '#0f2440',
    [theme.breakpoints.down('1030')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 9
    }
  })
};

export default styles;
