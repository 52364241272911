const styles = {
  center: theme => ({
    margin: 'auto',
    width: 960,
    [theme.breakpoints.only('md')]: {
      padding: '0 24px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 56px',
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px'
    }
  }),
  twoColumnCenter: theme => ({
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1280,
      paddingLeft: '0 !important',
      minWidth: 1280
    },
    [theme.breakpoints.down('xl')]: {
      maxWidth: 1120,
      paddingLeft: '0 !important',
      minWidth: 1120
    }
  }),
  articleContent: theme => ({
    paddingTop: 10.5,
    paddingBottom: 6.5,
    marginBottom: 14,
    borderBottom: 'solid 1px #ebeef2',
    [theme.breakpoints.down('md')]: {
      padding: '0px 65px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px'
    }
  }),
  Article: theme => ({
    paddingBottom: 14,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 18
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 4
    }
  }),
  TextContainer: {
    textAlign: 'justify',
    padding: '20px 80px'
  },
  Title: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.33,
    color: '#0f2440'
  },
  RegularText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.88,
    color: '#0f2440',
    paddingTop: 2.5,
    paddingBottom: 6
  },
  BigImgContainer: {
    maxWidth: 980,
    '& img': {
      width: '100%',
      borderBottomRightRadius: 100,
      borderTopLeftRadius: 100
    }
  },
  NormalImgContainer: {
    width: 448,
    '& img': {
      width: '100%',
      height: 552,
      borderBottomRightRadius: 100,
      borderTopLeftRadius: 100
    },
    padding: '60px 20px'
  },
  TitleText: {
    lineHeight: 0.75,
    textTransformation: 'uppercase',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 9,
    color: '#8d9299',
    textAlign: 'center'
  },
  SocialMedia: theme => ({
    float: 'right',
    [theme.breakpoints.down('md')]: {
      paddingTop: 6,
      paddingBottom: 6,
      float: 'left',
      width: '100%'
    }
  }),
  imgSocials: {
    width: 56,
    height: 56
  }
};

export default styles;
