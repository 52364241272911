import { useState } from 'react';
import { Grid, Typography, useMediaQuery, Box, Link as MuiLink } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { thisYear } from '../../utils';
import CenteredGrid from '../shared/CenteredGrid';
import ContactDialog from '../shared/ContactDialog';
import styles from './styles/index';
import { SwagUpLogo, Smartphone } from './icons';

const media = [
  { href: 'https://www.facebook.com/weareswagup', alt: 'Facebook', src: '/imgs/public/facebook-logo.svg' },
  { href: 'https://www.linkedin.com/company/swagup', alt: 'LinkedIn', src: '/imgs/public/linkedin-logo.svg' },
  { href: 'https://www.instagram.com/swagup', alt: 'Instagram', src: '/imgs/public/instagram.svg' }
];

const Signature = () => (
  <>
    © {thisYear()} by SwagUp Inc. <span>Proudly based in NJ</span>
  </>
);

const FooterBar = () => {
  const isBelowSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <>
      <CenteredGrid container justifyContent={isBelowSm ? 'center' : 'space-between'}>
        {!isBelowSm && (
          <Grid item>
            <Typography variant="body1Medium" sx={[styles.footerText, { color: '#434c5f' }]}>
              <Signature />
            </Typography>
          </Grid>
        )}
        <Grid item container sx={{ width: 'auto' }}>
          <MuiLink
            href="tel:6464940520"
            underline="none"
            typography="body1Medium"
            sx={[styles.contactLink, styles.footerText]}
          >
            <div style={{ width: 16, height: 28, marginRight: 16 }}>
              <Smartphone />
            </div>
            646-494-0520
          </MuiLink>
        </Grid>
      </CenteredGrid>
      {isBelowSm && (
        <Grid item container alignItems="center" justifyContent="center" sx={{ backgroundColor: '#fff' }}>
          <Typography variant="body1Medium" sx={styles.footerText}>
            <Signature />
          </Typography>
        </Grid>
      )}
    </>
  );
};

const Link = ({ children, to, absolute = false, ...props }) =>
  absolute ? (
    <a href={to} target="_blank" rel="noopenener noreferrer" {...props}>
      {children}
    </a>
  ) : (
    <RouterLink to={to} {...props}>
      {children}
    </RouterLink>
  );

const NavigationSection = ({ itemTitle, links }) => {
  return (
    <Grid item xs={6} sm={6} sx={styles.topBarSection}>
      <Typography variant="h6" sx={styles.sectionTitle}>
        {itemTitle}
      </Typography>
      {links.map(link =>
        link.action ? (
          <Button
            key={link.name}
            variant="text"
            onClick={link.action}
            sx={[
              styles.text,
              {
                display: 'block',
                padding: 0,
                color: '#0f2440',
                fontWeight: 300,
                fontDecoration: 'none',
                fontSize: 16,
                textDecoration: 'none',
                marginBottom: 5,
                lineHeight: 1,
                height: 20
              }
            ]}
          >
            {link.name}
          </Button>
        ) : (
          <Link
            key={link.name}
            absolute={link.absolute}
            to={link.path}
            style={{
              display: 'block',
              padding: 0,
              color: '#0f2440',
              fontWeight: 300,
              fontSize: 16,
              textDecoration: 'none',
              marginBottom: 20,
              lineHeight: 1,
              height: 20
            }}
          >
            {link.name}
          </Link>
        )
      )}
    </Grid>
  );
};

const socialsPaths = ['/services', '/blog'];

const InfoFooter = () => {
  const [open, setOpen] = useState(false);
  const [isPartnership, setIsPartnership] = useState(false);

  const location = useLocation();
  const socials = socialsPaths.some(path => location.pathname.includes(path));

  const handleClose = () => {
    setOpen(false);
    setIsPartnership(false);
  };

  return (
    <Box style={styles.rootContainer}>
      <CenteredGrid container>
        <Grid container sx={styles.infoSection} direction="column" item sm="auto" xs={12}>
          <Box sx={styles.logoContainer}>
            <Box sx={styles.logo}>
              <SwagUpLogo />
            </Box>
            <Typography sx={styles.text}>© {thisYear()} SwagUp, Inc</Typography>
          </Box>
          {socials && (
            <Grid item container justifyContent="space-between" sx={{ maxWidth: '120px !important' }}>
              {media.map(link => (
                <MuiLink underline="none" key={link.alt} href={link.href} sx={styles.mobileSocials}>
                  <img alt={link.alt} src={link.src} />
                </MuiLink>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid item sm md xs={12}>
          <Grid container>
            <Grid item md={6} xs={12}>
              <Grid container>
                <NavigationSection
                  itemTitle="ORDER"
                  links={[
                    { name: 'Build a pack', path: '/dashboard/custom-swag-packs/catalog' },
                    { name: 'Preset packs', path: '/dashboard/preset-packs' },
                    { name: 'Bulk swag', path: '/dashboard/bulk/catalog' }
                  ]}
                />
                <NavigationSection
                  itemTitle="EXPLORE"
                  links={[
                    { name: 'Services', path: '/services' },
                    { name: 'Pricing', path: '/pricing' },
                    { name: 'FAQ', path: '/faq' }
                  ]}
                />
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container>
                <NavigationSection
                  itemTitle="CONNECT"
                  links={[
                    { name: 'Help', path: 'https://help.swagup.com', absolute: true },
                    {
                      name: 'Contact us',
                      action: () => setOpen(true)
                    },
                    {
                      name: 'Partnerships',
                      action: () => {
                        setOpen(true);
                        setIsPartnership(true);
                      }
                    },
                    { name: 'Careers', path: 'https://jobs.swagup.com/', absolute: true }
                  ]}
                />
                <NavigationSection
                  itemTitle="LEGAL"
                  links={[
                    { name: 'Privacy', path: '/privacy-policy' },
                    { name: 'Terms of Use', path: '/terms-of-use' },
                    { name: 'Return/Refund Policy', path: '/return-refund-policy' }
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CenteredGrid>
      <Box component="footer" sx={styles.footer} id="footer">
        <FooterBar />
      </Box>
      {open && <ContactDialog open={open} onClose={handleClose} isPartnership={isPartnership} />}
    </Box>
  );
};

export default InfoFooter;
