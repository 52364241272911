const styles = {
  container: theme => ({
    padding: '64px 0 100px 0',
    textAlign: 'center',
    color: '#ffffff',
    borderRadius: '75px 5px 75px 5px',
    backgroundColor: '#9846dd',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      px: 6
    },
    [theme.breakpoints.down('sm')]: {
      width: '99%'
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }),
  title: theme => ({
    fontFamily: 'Gilroy-Bold',
    fontSize: '3.5rem',
    lineHeight: 1.14,
    letterSpacing: 'normal',
    my: 5,
    [theme.breakpoints.down('md')]: {
      fontSize: '2.75rem'
    }
  }),
  subText: {
    opacity: 0.8,
    fontFamily: 'Gilroy-Bold',
    fontSize: '1.25rem',
    lineHeight: 1.6
  },
  emailForm: {
    marginTop: 12.5,
    minHeight: 100
  },
  input: {
    color: '#ffffff',
    fontSize: '1.25rem',
    minWidth: 280,
    maxWidth: 400,
    width: '100%',
    '&:before': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '1px solid rgba(255, 255, 255, 1)'
    },
    '&:after': { borderBottom: '2px solid white' },
    '&.Mui-error:after': { borderBottomColor: 'white' }
  },
  subscribeButtonContainer: theme => ({
    paddingLeft: 7.5,
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingTop: 5
    }
  }),
  subscribeButton: {
    padding: '20px 40px',
    borderRadius: 8,
    backgroundColor: '#ffffff !important',
    border: 'none',
    cursor: 'pointer',
    color: '#9846dd !important'
  },
  subscribeContainer: {
    justifyContent: 'center'
  }
};

export default styles;
