import { Grid, Box, Typography } from '@mui/material';
import CenteredGrid from '../shared/CenteredGrid';
import styles from './styles/gallery';
import { imageSrcSet } from '../../utils';
import Img from '../shared/Img';

const stepCards = [
  {
    title: 'Select Swag',
    excerpt:
      "Select the swag items you want. Don't see what you like? Don't worry we can do just about anything. You select , we help design.",
    number: '1',
    image: '/imgs/gallery/select-swag.png'
  },
  {
    title: 'Store & Pack',
    excerpt:
      'Store your swag with us, including 3rd party items. All swag gets preassembled to your liking and inventory is tracked on your dashboard.',
    number: '2',
    image: '/imgs/gallery/store-pack.png'
  },
  {
    title: 'Ship Swag',
    excerpt:
      'Select shipments on demand through your SwagUp dashboard.  Even schedule shipments for new hires or conferences in advance! ',
    number: '3',
    image: '/imgs/gallery/ship-swag.png'
  }
];

const StepCard = ({ title, excerpt, number, image }) => (
  <Grid item sx={styles.stepCardItem}>
    <Box sx={styles.stepCard}>
      <Grid container justifyContent="center">
        <Box sx={styles.stepCardImageContainer}>
          <Img src={image} alt={title} srcSet={imageSrcSet(image)} sx={styles.stepCardImage} />
        </Box>
        <Grid container justifyContent="center">
          <Grid item>
            <Grid container justifyContent="center" alignItems="center" sx={styles.stepCardNumber}>
              {number}
            </Grid>
          </Grid>
        </Grid>
        <Box sx={styles.stepCardContent}>
          <Typography variant="h4SemiBold" sx={styles.stepCardTitle}>
            {title}
          </Typography>
          <Typography variant="body1Medium" sx={styles.stepCardExcerpt}>
            {excerpt}
          </Typography>
        </Box>
      </Grid>
    </Box>
  </Grid>
);

const HowSwagsWorks = () => (
  <Grid container justifyContent="center" sx={styles.howSwagsWorks}>
    <CenteredGrid>
      <Typography variant="h3SemiBold" sx={styles.galleryTitleHowSwagsWorks}>
        How does SwagUp work?
      </Typography>
      <Typography variant="body1Medium" sx={styles.galleryExcerptHowSwagsWorks}>
        Let us do the heavy lifting
      </Typography>
      <Grid container justifyContent="space-between" alignItems="center" spacing={10} sx={styles.cardContainer}>
        {stepCards.map(c => (
          <StepCard key={c.number} title={c.title} excerpt={c.excerpt} number={c.number} image={c.image} />
        ))}
      </Grid>
    </CenteredGrid>
  </Grid>
);

export default HowSwagsWorks;
