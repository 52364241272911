const cardText = {
  fontFamily: 'Gilroy-SemiBold',
  color: '#ffffff',
  margin: 0
};

const infoText = {
  fontFamily: 'Gilroy-SemiBold',
  color: '#0f2440'
};

const styles = {
  sectionContainer: theme => ({
    marginTop: 45,
    marginBottom: 45,
    backgroundColor: '#f7f9fc',
    borderTopRightRadius: 25,
    borderBottomLeftRadius: 25,
    padding: '105px 245px 0 245px',
    [theme.breakpoints.down('md')]: {
      padding: '105px 145px 0 145px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 20px 0 20px'
    }
  }),
  sectionSubTitle: {
    width: '100%',
    textAlign: 'center',
    margin: 0,
    marginTop: 3,
    fontFamily: 'Gilroy-Medium',
    lineHeight: 1.17,
    color: '#8d9299'
  },
  infoSection: theme => ({
    paddingTop: 30,
    maxWidth: 945,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
      paddingTop: 15,
      paddingLeft: 12.5,
      paddingRight: 12.5
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 5,
      paddingLeft: 2.5,
      paddingRight: 2.5
    }
  }),
  infoColumn: theme => ({
    maxWidth: '231px !important',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset !important',
      display: 'flex',
      alignItems: 'center'
    }
  }),
  infoTextContainer: theme => ({
    [theme.breakpoints.down('md')]: {
      paddingLeft: 7.5
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 2.5
    }
  }),
  infoHeading: theme => ({
    marginLeft: 3,
    fontSize: 24,
    lineHeight: 1.33,
    ...infoText,
    [theme.breakpoints.down('md')]: {
      marginTop: 2.5
    }
  }),
  infoText: {
    opacity: 0.54,
    marginLeft: 3,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.63,
    ...infoText
  },
  infoImage: theme => ({
    [theme.breakpoints.down('md')]: {
      width: 160
    },
    [theme.breakpoints.down('sm')]: {
      width: 100
    }
  }),
  blueCard: theme => ({
    position: 'relative',
    top: 100,
    backgroundColor: '#3577d4',
    borderRadius: 5,
    borderTopRightRadius: 25,
    padding: '62px 81px',
    [theme.breakpoints.down('md')]: {
      padding: '32px 51px',
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 8px',
      borderTopRightRadius: 5,
      top: 40
    }
  }),
  cardTitle: theme => ({
    fontSize: 36,
    fontWeight: 600,
    ...cardText,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 500,
      fontSize: 20
    }
  }),
  cardText: theme => ({
    fontSize: 16,
    ...cardText,
    marginTop: 5,
    color: '#ebf1fb',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  }),
  button: theme => ({
    padding: '22px 52px',
    borderRadius: 9,
    textDecoration: 'none',
    backgroundColor: '#ffffff',
    fontFamily: 'Gilroy-Bold',
    fontSize: 20,
    fontWeight: 500,
    color: '#3577d4',
    [theme.breakpoints.down('md')]: {
      padding: '12px 22px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      padding: '6px 6px'
    }
  })
};

export default styles;
