import { Box, styled, Typography } from '@mui/material';

const Line = styled('hr')``;

const getStyles = color => ({
  sectionTag: theme => ({
    color,
    fontSize: 16,
    textTransform: 'uppercase',
    textAlign: 'left',
    fontFamily: 'Gilroy-SemiBold',
    lineHeight: 1.5,
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
      margin: 0
    }
  }),
  container: theme => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: 3.75
    }
  }),
  line: theme => ({
    height: 0,
    border: `solid 1px ${color}`,
    width: 64,
    margin: 0,
    [theme.breakpoints.down('md')]: {
      border: `solid 0.5px ${color}`,
      width: 42
    }
  })
});

const SectionTag = ({ left = true, right = true, centered = false, tag, color = '#9846dd' }) => {
  const styles = getStyles(color);
  return (
    <Box sx={[styles.container, centered && { justifyContent: 'center' }]}>
      {left && <Line sx={[styles.line, { marginRight: 4 }]} />}
      <Typography variant="inherit" component="h6" sx={styles.sectionTag}>
        {tag}
      </Typography>
      {right && <Line sx={[styles.line, { marginLeft: 4 }]} />}
    </Box>
  );
};

export default SectionTag;
