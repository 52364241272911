import { Box, Grid, Typography } from '@mui/material';
import { CANNOT_GUARANTEE_TURNAROUND_TIME } from '../../../utils/constants';
import CenteredGrid from '../../shared/CenteredGrid';
import styles from './styles/faqs';

const questions = [
  {
    question: 'What’s the turnaround time for packs?',
    answer: CANNOT_GUARANTEE_TURNAROUND_TIME
  },
  {
    question: 'What’s the minimum order?',
    answer: 'Typically 50 packs, however there are some items we can go lower (25 units)'
  },
  {
    question: 'Do you ship globally?',
    answer: 'Yes, we’ve shipped to over 100 countries'
  },
  {
    question: 'Do you offer warehousing and fulfillment?',
    answer: 'Yes, for a flat $4 per pack, one time fee'
  },
  {
    question: 'Do you do items outside the ones shown in the pack builder?',
    answer: 'Yes, our pack builder is meant to be curated but we have hundreds of more options'
  },
  {
    question: 'Can we send you our existing swag to include?',
    answer: 'Yes'
  },
  {
    question: 'Do you have an API?',
    answer:
      'Yes, we have an Open API as well as a Zapier and Shopify App. We are building out more integrations as well.'
  }
];

const FAQs = () => (
  <CenteredGrid container flexDirection="column" alignItems="center" sx={styles.container}>
    <Typography variant="inherit" component="h2" sx={styles.title}>
      Swag Pack FAQs
    </Typography>
    <Box sx={styles.questions}>
      {questions.map((question, index) => (
        <Grid item xs={12} style={{ marginBottom: index < questions.length - 1 ? 47.8 : 70.8 }} key={question.question}>
          <Typography variant="inherit" sx={styles.questionText}>
            {question.question}
          </Typography>
          <Typography variant="inherit" component="span" sx={styles.questionText}>
            A:{' '}
          </Typography>
          <Typography variant="inherit" component="span" sx={styles.answerText}>
            {question.answer}
          </Typography>
          {index < questions.length - 1 && <Box component="hr" sx={styles.line} />}
        </Grid>
      ))}
    </Box>
  </CenteredGrid>
);

export default FAQs;
