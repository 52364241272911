import { Box, Grid } from '@mui/material';
import { imageSrcSet } from '../../../utils';
import AnimatedImg from '../../shared/AnimatedImg';
import styles from './styles/landingView';

const defaultImages = [
  '/imgs/landingPagesSSR/socks-2-x.png',
  '/imgs/landingPagesSSR/waterbottle-2-x.png',
  '/imgs/landingPagesSSR/pen-2-x.png',
  '/imgs/landingPagesSSR/tote.png',
  '/imgs/landingPagesSSR/adapter-2-x.png',
  '/imgs/landingPagesSSR/box-2-x.png',
  '/imgs/landingPagesSSR/bag-2-x.png',
  '/imgs/landingPagesSSR/coffee-cup-2-x.png',
  '/imgs/landingPagesSSR/notebook-2-x.png'
];

const LandingView = ({ images = defaultImages }) => (
  <Grid container justifyContent="center">
    <Grid item sx={[styles.centerLV, { position: 'relative', right: -8 }]}>
      <Grid container sx={styles.landingFrame}>
        <div style={{ position: 'relative', width: '100%' }}>
          <div style={{ position: 'relative', top: 0, width: '100%' }}>
            <Grid container>
              <Grid item>
                <Box sx={styles.socksImg}>
                  <AnimatedImg
                    src={images[0]}
                    srcSet={imageSrcSet(images[0])}
                    animation="fadeInLeft"
                    animationInDelay={2200}
                  />
                </Box>
              </Grid>

              <Grid item align="left">
                <Box sx={styles.waterbottleImg}>
                  <AnimatedImg
                    src={images[1]}
                    srcSet={imageSrcSet(images[1])}
                    animation="fadeInUp"
                    animationInDelay={1500}
                  />
                </Box>
              </Grid>
              <Grid item align="right">
                <Box sx={styles.contentPenImg}>
                  <Box sx={styles.penImg}>
                    <AnimatedImg
                      src={images[2]}
                      srcSet={imageSrcSet(images[2])}
                      animation="fadeInUp"
                      animationInDelay={1700}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid container>
                <Grid item>
                  <Box sx={styles.toteImgContainer}>
                    <AnimatedImg
                      src={images[3]}
                      srcSet={imageSrcSet(images[3])}
                      animation="fadeInLeft"
                      animationInDelay={2700}
                    />
                  </Box>
                </Grid>
                <Grid item xs sx={styles.treeContainer}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12}>
                      <Grid item>
                        <Box align="left" sx={styles.adapterImg}>
                          <AnimatedImg
                            src={images[4]}
                            srcSet={imageSrcSet(images[4])}
                            animation="fadeInRight"
                            animationInDelay={3600}
                          />
                        </Box>
                      </Grid>
                      <Grid item align="center">
                        <Box sx={styles.boxImgContainer}>
                          <AnimatedImg
                            src={images[5]}
                            srcSet={imageSrcSet(images[5])}
                            animation="fadeInUp"
                            animationInDelay={1000}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Box sx={styles.bagImgContainer}>
                        <AnimatedImg
                          src={images[6]}
                          srcSet={imageSrcSet(images[6])}
                          animation="fadeInUp"
                          animationInDelay={3000}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <Box sx={styles.cupImgContainer} style={{ display: 'table', height: '100%' }}>
                            <Box sx={styles.cupImg}>
                              <AnimatedImg
                                src={images[7]}
                                srcSet={imageSrcSet(images[7])}
                                animation="fadeInUp"
                                animationInDelay={3300}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <dix align="center">
                    <Box sx={styles.notebookImg}>
                      <AnimatedImg
                        src={images[8]}
                        srcSet={imageSrcSet(images[8])}
                        animation="fadeInRight"
                        animationInDelay={3800}
                      />
                    </Box>
                  </dix>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </Grid>
  </Grid>
);

export default LandingView;
