import { useState } from 'react';
import { Tooltip } from '@swagup-com/react-ds-components';
import { ClickAwayListener } from '@mui/material';

const ClickableTooltip = ({ title, children, disabled }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => setOpen(false);

  const handleTooltipOpen = () => setOpen(true);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        disableTouchListener
        interactive
        open={open && !disabled}
        onClose={handleTooltipClose}
        arrow
        title={title}
      >
        <div role="presentation" onMouseEnter={handleTooltipOpen} onClick={handleTooltipOpen}>
          {children}
        </div>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default ClickableTooltip;
