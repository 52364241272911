const styles = {
  center: theme => ({
    [theme.breakpoints.only('lg')]: {
      width: 1024,
      padding: '0 24px'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '0 42px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  }),
  centerTabs: theme => ({
    [theme.breakpoints.only('lg')]: {
      width: 1024,
      padding: '0 24px'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.only('sm')]: {
      padding: '0px 16px'
    }
  }),
  tabsWrapper: theme => ({
    paddingTop: 10.5,
    paddingBottom: 8,
    maxWidth: '100vw',
    [theme.breakpoints.down('md')]: {
      padding: 6
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px 0px'
    }
  }),
  loadMoreButton: {
    width: 145,
    height: 45,
    borderRadius: 5,
    backgroundColor: '#ebf1fb',
    color: '#3577d4',
    border: 0,
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontWeight: 600,
    outline: 'none',
    marginBottom: 6,
    cursor: 'pointer'
  },
  refreshIcon: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    fontWeight: 600,
    marginRight: 2,
    marginTop: 1
  },
  Title: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    // fontWeight: 400,
    lineHeight: 'normal',
    color: '#0f2440',
    marginBottom: 8,
    marginTop: 42.5,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: 29,
      fontSize: 30,
      marginBottom: 4
    }
  }),
  Title48: theme => ({
    marginTop: 0,
    fontSize: 48,
    [theme.breakpoints.down('md')]: { marginTop: 0 }
  }),
  searchInput: theme => ({
    height: 56,
    borderRadius: 8,
    border: 'solid 1px #d3dbe5',
    position: 'absolute',
    width: '100%',
    bottom: -52,
    outline: 'none',
    padding: '22px 32px',
    color: '#8d9299',
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 0.75,
    zIndex: 999,
    [theme.breakpoints.between('sm', 'md')]: {
      top: -56,
      bottom: 0
    },
    [theme.breakpoints.down('md')]: {
      top: -36,
      height: 36,
      padding: '12px 24px'
    }
  }),
  searchInputContainerInner: theme => ({
    position: 'relative',
    width: 322,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  CardContainer: {
    padding: '40px 40px 40px 0px'
  },
  indicator: {
    backgroundColor: 'transparent'
  },
  Tab: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    marginTop: 4.5,
    textAlign: 'center',
    color: '#3577d4 !important',
    border: '1px solid #3577d4',
    height: 42,
    borderRadius: 5,
    marginRight: 5,
    // fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 1,
      paddingRight: 1,
      minWidth: 64,
      fontSize: 12,
      marginRight: '6px !important'
    }
  }),
  flexContainer: {
    justifyContent: 'center'
  },
  TabOff: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    marginTop: 4.5,
    textAlign: 'center',
    color: '#8d9299 !important',
    border: '1px solid #8d9299',
    height: 42,
    borderRadius: 5,
    // fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 1,
      paddingRight: 1,
      minWidth: 48,
      fontSize: 12,
      marginRight: '6px !important'
    }
  }),
  loadMoreSection: {
    paddingTop: 0,
    paddingBottom: 18
  }
};

export default styles;
