const styles = {
  container: theme => ({
    paddingBottom: 0,
    paddingTop: 12,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
      paddingTop: 14
    }
  }),
  center: theme => ({
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      paddingLeft: '0 !important',
      minWidth: '1280px'
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1120px',
      paddingLeft: '0 !important',
      minWidth: '1120px'
    }
  })
};

export default styles;
