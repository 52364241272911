export const createMenuItemProps = (title, description, path, absolute, imageSrc, onClick) => ({
  title,
  description,
  path,
  absolute,
  imageSrc,
  onClick
});

export const packsMenuItemList = [
  createMenuItemProps(
    'Build A Pack',
    'Create your own custom pack',
    '/dashboard/custom-swag-packs/catalog',
    false,
    '/imgs/public/build-pack.svg'
  ),
  createMenuItemProps(
    'Preset Packs',
    'Select one of our preset packs',
    '/dashboard/preset-packs/',
    false,
    '/imgs/public/preset-pack.svg'
  )
];

export const productMenuItemList = [
  ...packsMenuItemList,
  createMenuItemProps(
    'Bulk Swag',
    'Get products in bulk',
    '/dashboard/bulk/catalog',
    false,
    '/imgs/public/bulk-swag.svg'
  )
];

export const companyMenuItemList = [
  createMenuItemProps('About Us', 'Get to know SwagUp', '/company', true, '/imgs/public/about-us.svg'),
  createMenuItemProps('Blog', 'Read SwagUp related articles', '/blog', true, '/imgs/public/blog.svg'),
  createMenuItemProps('FAQ', 'Frequently Asked Questions', '/faq', true, '/imgs/public/faq.svg')
];

export const platformMenuItemList = [
  createMenuItemProps('Services', 'The SwagUp process', '/services', true, '/imgs/public/services.svg'),
  createMenuItemProps(
    'Redeem Pages',
    'Automate swag fulfillment',
    '/blog/product-launch-redeem-pages',
    true,
    '/imgs/public/redeemPages.svg'
  )
];

export const accountMenuItemList = [
  createMenuItemProps('Profile', '', '/account/profile', false, '/imgs/public/profile.svg'),
  createMenuItemProps('Company', '', '/account/company', false, '/imgs/public/company.svg'),
  createMenuItemProps('Billing', '', '/billing/accounts', false, '/imgs/public/billing.svg')
];
