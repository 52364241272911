const boxButton = theme => ({
  width: 218,
  height: 72,
  borderRadius: 9,
  fontSize: 20,
  letterSpacing: 0,
  color: '#3577D4 !important',
  border: '2px solid #3577D4 !important',
  [theme.breakpoints.between('sm', 'md')]: {
    marginBottom: 6
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 16,
    width: 192,
    height: 56,
    marginBottom: 4
  }
});

const styles = {
  // common
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: 1024,
      padding: '0 24px'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  faqCenter: theme => ({
    maxWidth: 980,
    paddingLeft: '0 !important',
    minWidth: 980,
    [theme.breakpoints.down('xl')]: {
      maxWidth: 980,
      paddingLeft: '0 !important',
      minWidth: 980
    },
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: 768,
      minWidth: 768
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      padding: '0px 24px !important',
      minWidth: '100%'
    }
  }),

  // header
  headerSection: theme => ({
    backgroundImage:
      'url(imgs/faq/faq-background@3x.png), linear-gradient(0deg, rgb(235, 241, 251), rgb(235, 241, 251))',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    marginBottom: 30,
    [theme.breakpoints.down('md')]: {
      marginBottom: 16
    }
  }),
  title: theme => ({
    fontFamily: 'Gilroy-Bold',
    fontSize: 64,
    color: '#0f2440',
    textAlign: 'center',
    marginTop: 26,
    marginBottom: 7,
    [theme.breakpoints.down('md')]: {
      fontSize: 48,
      marginTop: 10.5,
      marginBottom: 3
    }
  }),
  subTitle: theme => ({
    fontFamily: 'Gilroy',
    fontSize: 24,
    fontWeight: 100,
    textAlign: 'center',
    opacity: 0.54,
    color: '#434d5c',
    margin: 'auto',
    marginTop: 0,
    maxWidth: 680,
    lineHeight: 1.67,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      opacity: 0.8
    }
  }),
  headerBox: theme => ({
    background: '#3577d4',
    width: '100%',
    padding: '26px 77px',
    borderRadius: 6,
    marginTop: 18,
    marginBottom: -33,
    [theme.breakpoints.down('md')]: {
      padding: '16px 36px',
      marginTop: 8,
      marginBottom: -23
    }
  }),
  headerBoxText: theme => ({
    margin: '24px 0',
    fontFamily: 'Gilroy',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.5,
    color: '#ffffff',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      textAlign: 'center'
    }
  }),
  headerBoxButton: theme => boxButton(theme),

  // ask a question
  aaqBox: theme => ({
    background: '#3577d4',
    width: '100%',
    padding: '60px 64px 0px 84px',
    marginTop: 27,
    borderRadius: 6,
    borderTopRightRadius: 120,
    marginBottom: 9,
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: 14
    },
    [theme.breakpoints.down('md')]: {
      padding: '36px 24px 16px 24px',
      marginTop: 6,
      marginBottom: 6,
      borderTopRightRadius: 60
    }
  }),
  aaqBoxText: theme => ({
    fontFamily: 'Gilroy',
    fontSize: 36,
    fontWeight: 600,
    lineHeight: 1.5,
    color: '#ffffff',
    margin: 0,
    marginBottom: 5,
    [theme.breakpoints.down('md')]: {
      fontSize: 24
    }
  }),
  aaqBoxSubText: theme => ({
    fontFamily: 'Gilroy',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
    color: '#ffffff',
    marginBottom: 8,
    maxWidth: 310,
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    }
  }),
  aaqBoxButton: theme => ({
    marginBottom: 16,
    ...boxButton(theme)
  }),
  aaqImgContainer: theme => ({
    width: 464,
    height: '100%',
    position: 'relative',
    [theme.breakpoints.between('sm', 'md')]: {
      width: 316
    },
    [theme.breakpoints.down('md')]: {
      width: 192
    },
    [theme.breakpoints.down('sm')]: {
      width: 124
    },
    '& img': {
      width: '100%',
      height: 464,
      position: 'absolute',
      bottom: 0,
      [theme.breakpoints.between('sm', 'md')]: {
        height: 316
      },
      [theme.breakpoints.down('md')]: {
        height: 192
      },
      [theme.breakpoints.down('sm')]: {
        height: 124,
        position: 'relative'
      }
    }
  }),

  // faq list
  faqListSection: {
    paddingTop: 26
  },
  searchInputContainer: theme => ({
    paddingBottom: 6,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 5
    }
  }),
  subscribeInput: theme => ({
    border: '1px solid #d3dbe5',
    borderRadius: 180,
    padding: '20px 30px',
    fontSize: 16,
    fontFamily: 'Gilroy-Medium',
    '& input::placeholder': {
      color: '#8d9299',
      fontSize: '16px !important',
      height: 32,
      opacity: 1,
      border: '0px !important'
    },
    [theme.breakpoints.down('md')]: {
      padding: '12px 24px'
    }
  }),
  listContainer: theme => ({
    paddingBottom: 14,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 6
    }
  }),
  listContainerOnly: theme => ({
    paddingBottom: 27,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 14
    }
  }),
  faqItem: theme => ({
    borderBottom: '1px solid #ebeef2',
    paddingTop: 12.5,
    paddingBottom: 12,
    [theme.breakpoints.down('md')]: {
      paddingTop: 6,
      paddingBottom: 5
    }
  }),
  faqItemTitle: theme => ({
    fontFamily: 'Gilroy-Bold',
    fontSize: 36,
    fontWeight: 'bold',
    color: '#0f2440',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 24
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    }
  }),
  faqItemContent: theme => ({
    paddingTop: 6,
    '& p': {
      fontFamily: 'Gilroy',
      fontSize: 24,
      color: '#8d9299',
      lineHeight: 1.75,
      margin: '0 0 1.5em !important'
    },
    [theme.breakpoints.down('md')]: {
      '& p': {
        fontSize: 18,
        lineHeight: 1.5
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& p': {
        fontSize: 16,
        lineHeight: 1.5
      }
    }
  })
};

export default styles;
