import { Dialog, DialogContent, DialogTitle, IconButton, Typography, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { scrollBar } from '../../common/styles';

const styles = {
  title: {
    fontFamily: 'Gilroy-SemiBold',
    padding: 6,
    minHeight: 78,
    textAlign: 'center',
    fontSize: 24
  },
  subtitle: {
    lineHeight: '22px',
    marginTop: 2.5,
    color: '#8D9299',
    fontSize: 16
  },
  content: {
    ...scrollBar,
    paddingBottom: 6
  },
  closeButton: theme => ({
    position: 'absolute',
    right: 15,
    top: 15,
    [theme.breakpoints.down('sm')]: {
      right: 2,
      top: 17
    }
  })
};

export const Modal = ({ title, subtitle, actions, children, onClose, innerClasses, ...props }) => (
  <Dialog onClose={onClose} maxWidth={false} {...props} classes={innerClasses}>
    <IconButton aria-label="close" sx={styles.closeButton} onClick={onClose}>
      <CloseIcon />
    </IconButton>
    <DialogTitle sx={styles.title}>
      {title || ''}
      {subtitle && (
        <Typography align="center" sx={styles.subtitle}>
          {subtitle}
        </Typography>
      )}
    </DialogTitle>
    <DialogContent sx={styles.content}>{children}</DialogContent>
    {actions && <DialogActions>{actions}</DialogActions>}
  </Dialog>
);
