import { Box, Switch, Typography } from '@mui/material';

const styles = {
  root: {
    width: 160,
    height: 48,
    padding: 0,
    overflow: 'visible'
  },
  switchBase: {
    color: '#ffffff',
    padding: 0,
    '&.Mui-checked': {
      color: '#ffffff',
      transform: 'translateX(80px)'
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'transparent'
    }
  },
  thumb: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
    height: 48,
    borderRadius: 6,
    backgroundColor: '#9846dd',
    boxShadow: '0 8px 20px 0 rgba(152, 70, 221, 0.24)'
  },
  track: {
    borderRadius: 6,
    border: 'solid 1px #ebeef2',
    backgroundColor: 'transparent',
    opacity: 1
  },
  switchContainer: {
    position: 'relative'
  },
  overlayContainer: {
    width: 160,
    height: 48,
    position: 'absolute',
    display: 'flex'
  },
  column: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  font: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 0.75
  },
  whiteColor: {
    color: '#ffffff'
  },
  grayColor: {
    color: '#8d9299'
  }
};

const Thumb = ({ text, color }) => (
  <Box
    sx={[
      styles.thumb,
      styles.font,
      {
        backgroundColor: color,
        boxShadow: `0 8px 20px 0 ${color === '#9846dd' ? 'rgba(152, 70, 221, 0.24)' : 'rgba(53, 119, 212, 0.24)'}`
      }
    ]}
  >
    {text}
  </Box>
);

const ProductTypeSwitch = ({ color, checked, onChange }) => {
  return (
    <Box sx={styles.switchContainer}>
      <Box sx={styles.overlayContainer}>
        <Typography sx={[styles.column, styles.font, styles.grayColor]}>Pack</Typography>
        <Typography sx={[styles.column, styles.font, styles.grayColor]}>Bulk</Typography>
      </Box>
      <Switch
        icon={<Thumb color={color} text="Pack" />}
        checkedIcon={<Thumb color={color} text="Bulk" />}
        sx={[
          styles.root,
          {
            '& .MuiSwitch-switchBase': styles.switchBase,
            '& .MuiSwitch-track': styles.track,
            '& .Mui-checked': styles.checked
          }
        ]}
        checked={checked}
        onChange={onChange}
      />
    </Box>
  );
};

export default ProductTypeSwitch;
