const styles = {
  marginFS: theme => ({
    marginTop: 34,
    marginBottom: 0,
    [theme.breakpoints.down('lg')]: { minHeight: 'auto' },
    [theme.breakpoints.down('md')]: { marginTop: 22.5 },
    [theme.breakpoints.down('sm')]: { marginTop: 0, paddingLeft: 0, paddingRight: 0 }
  }),
  sectionTag: theme => ({
    padding: '10px 14px',
    borderRadius: 4.5,
    backgroundColor: '#fbf7ff',
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    textAlign: 'center',
    color: '#9846dd',
    [theme.breakpoints.down('md')]: { marginLeft: 7, alignSelf: 'center', textAlign: 'center' },
    [theme.breakpoints.down('sm')]: { marginLeft: 0 }
  }),
  containerImg: theme => ({
    display: 'flex',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: { display: 'none' }
  }),
  img: theme => ({
    marginTop: 30.75,
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: { width: '100%', height: '100%' }
  }),
  logosSection: theme => ({
    marginTop: 18,
    marginBottom: 47,
    [theme.breakpoints.down('md')]: { marginBottom: 17.5, padding: '0px 20px' }
  }),
  stylesOurServices: theme => ({
    width: 'auto !important',
    '& > div:first-child': { alignItems: 'flex-start', textAlign: 'left' },
    [theme.breakpoints.down('md')]: { '& > div:first-child': { alignItems: 'center', textAlign: 'center' } }
  }),
  ourServicesTag: {
    fontSize: 14,
    fontFamily: 'Gilroy-Medium',
    height: 37
  },
  marginHQS: theme => ({
    marginTop: 30.5,
    [theme.breakpoints.up('lg')]: {
      '& h4,p': { marginLeft: 29.75 },
      '& h4': { fontSize: 36 },
      '& p': { fontSize: 20 }
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 15,
      '& h4,p': { marginLeft: 12.5 }
    },
    [theme.breakpoints.down('md')]: { marginBottom: 10 },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 22.5,
      '& .containerButton': {
        marginLeft: 0,
        '& > div': {
          marginLeft: 0
        }
      },
      '& a': { marginRight: 0 },
      '& h4,p': { marginLeft: 0, marginRight: 0, alignSelf: 'center', textAlign: 'center', padding: '0 15px' }
    }
  })
};

export default styles;
