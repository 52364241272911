const title = {
  fontFamily: 'Gilroy-SemiBold',
  fontSize: 20,
  color: '#0b1829',
  margin: 0
};

const styles = {
  root: theme => ({
    padding: '124px 0px 32px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '56px 0px 12px 0px'
    }
  }),
  sampleSectionContainer: theme => ({
    backgroundColor: '#fbf7ff',
    padding: '42px 112px',
    borderRadius: 5,
    [theme.breakpoints.down('lg')]: {
      padding: '32px 64px',
      paddingRight: 6
    },
    [theme.breakpoints.down('md')]: {
      padding: '24px 24px'
    }
  }),
  sampleImageContainer: theme => ({
    width: 532,
    height: 480,
    [theme.breakpoints.down('lg')]: {
      width: 444,
      height: 312
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 16,
      width: 266,
      height: 240
    }
  }),
  sampleImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  sampleTitle: {
    ...title,
    fontSize: 48,
    fontWeight: 'bold',
    lineHeight: 1.17,
    marginBottom: 8,
    marginTop: 6,
    maxWidth: 322
  },
  sampleText: {
    ...title,
    fontSize: 16,
    color: '#787b80',
    marginBottom: 14
  },
  samplePackTag: {
    padding: '12px 14px',
    width: 124,
    borderRadius: 4.5,
    lineHeight: 1,
    backgroundColor: '#ffffff',
    color: '#9846dd',
    fontSize: 14,
    fontWeight: 500
  }
};

export default styles;
