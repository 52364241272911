import { useMemo } from 'react';
import { useQuery } from 'react-query';
import wordpressApiClient from '../../../api/wordpressApiClient';
import RecentlyPublished from '../../Blog/RecentlyPublished';
import LandingSubscribe from './LandingSubscribe';

const BlogHome = () => {
  const { data: posts } = useQuery('posts', wordpressApiClient.fetchAllPosts);

  const post = useMemo(() => posts?.find(p => p.headliner === 'Yes'), [posts]);

  return (
    <>
      <LandingSubscribe />
      {posts && <RecentlyPublished posts={posts} categories={[]} featuredPost={post} subscriptionOnLanding />}
    </>
  );
};

export default BlogHome;
