import { Box, Button, Grid, Typography } from '@mui/material';
import { imageSrcSet } from '../../utils';
import gtm from '../../utils/gtm';
import SectionTag from './SectionTag';
import CenteredGrid from '../shared/CenteredGrid';
import getStyles from './styles/samplePack';
import Img from './Img';

const SamplePack = ({
  title = 'Grab a sample pack',
  subtitle = 'It’s pretty simple! In just a few clicks you can sync your SwagUp products to Shopify app.',
  buttonText = 'Get started',
  sectionTag,
  titleStyle = { marginBottom: 0 },
  subtitleStyle,
  hideBackground,
  removePaddingLeft,
  modifyFontSize
}) => {
  const styles = getStyles({ hideBackground, removePaddingLeft, modifyFontSize });
  return (
    <CenteredGrid sx={[styles.containerSamplePack, styles.center]}>
      <Grid container sx={styles.subSectionContainer}>
        <Grid container direction="column" alignItems="flex-start" item sm={7} xs={12} sx={styles.containerTitlesSP}>
          {sectionTag ? (
            <Grid item>{sectionTag}</Grid>
          ) : (
            <Box sx={{ my: 7 }}>
              <SectionTag tag="Sample Pack" left={false} />
            </Box>
          )}
          <Typography component="h2" variant="inherit" sx={[styles.promotionTitleSP, titleStyle]}>
            {title}
          </Typography>
          <Grid item>
            <Typography varaint="inherit" sx={[styles.textSamplePack, subtitleStyle]}>
              {subtitle}
            </Typography>
          </Grid>
          <Grid item container justifyContent="flex-start">
            <Button
              variant="contained"
              sx={styles.buttonR}
              href="/dashboard/onboarding"
              onClick={gtm.onClickRequestSampleCTA}
            >
              {buttonText}
            </Button>
          </Grid>
        </Grid>
        <Grid item sm={5} xs={12} sx={styles.wrapperRImg}>
          <Img
            src="/imgs/landingPagesSSR/pack0050.png"
            srcSet={imageSrcSet('/imgs/landingPagesSSR/pack0050.png')}
            alt="box"
            sx={styles.sPRImg}
          />
        </Grid>
      </Grid>
    </CenteredGrid>
  );
};

export default SamplePack;
