import { Grid } from '@mui/material';
import Img from '../../shared/Img';

const BottomImages = ({ sx, list, xs = true }) =>
  list.map(
    ({
      src,
      alt,
      margin: marginBottom = 0,
      noPadding,
      specialClass,
      forcedMargin: marginLeft = 0,
      align = 'center'
    }) => {
      const paddingLeft = (!noPadding && !marginLeft && 16) || 0;
      return (
        <Grid key={alt} item xs={xs} align={align} sx={{ marginBottom, paddingLeft, marginLeft }}>
          <Img src={src} sx={specialClass || sx} alt={alt} />
        </Grid>
      );
    }
  );

export default BottomImages;
