import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { sleep } from '../../utils';
import styles from './styles/home';

const makeCancelable = promise => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    }
  };
};

const verticalTextSliderStyles = {
  container: theme => ({
    padding: '0px 15px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 7px'
    }
  }),
  text: {
    display: 'inherit',
    color: '#3577d4'
  },
  textOut: {
    WebkitAnimationName: 'translationOut',
    WebkitAnimationDuration: '2s',
    animationName: 'translationOut',
    animationDuration: '2s',
    transitionTimingFunction: 'ease-out'
  },
  textIn: {
    WebkitAnimationName: 'translationIn',
    WebkitAnimationDuration: '1s',
    animationName: 'translationIn',
    animationDuration: '1s'
  }
};

class VerticalTextSlider extends Component {
  state = {
    indexIn: 0,
    indexOut: -1
  };

  stop = false;

  cancelablePromise = null;

  componentDidMount() {
    this.doSlide();
  }

  componentDidUpdate() {
    if (!this.stop) this.doSlide();
  }

  componentWillUnmount() {
    this.cancelablePromise.cancel();
  }

  slideIndex = (prevState, currentProps) =>
    currentProps.options.length - 1 > prevState.indexIn ? prevState.indexIn + 1 : 0;

  doSlide = () => {
    this.stop = true;
    const cancelableSleep = makeCancelable(sleep(2000));
    this.cancelablePromise = cancelableSleep;
    cancelableSleep.promise.then(() => {
      this.stop = false;
      this.setState((prevState, currentProps) => {
        return {
          indexIn: prevState.indexOut > -1 ? this.slideIndex(prevState, currentProps) : prevState.indexIn,
          indexOut: prevState.indexOut > -1 ? -1 : prevState.indexIn
        };
      });
    });
  };

  render() {
    const { options, firstText, secondText } = this.props;
    const { indexIn, indexOut } = this.state;

    const textIn = options[indexIn];
    const textOut = options[indexOut];
    return (
      <Typography variant="display1Bold" sx={styles.mainText}>
        <Grid container>
          <Grid item> {firstText}</Grid>
          <Grid sx={verticalTextSliderStyles.container} item>
            <Box
              component="span"
              sx={[verticalTextSliderStyles.text, verticalTextSliderStyles[textOut ? 'textOut' : 'textIn']]}
            >{` ${textOut || textIn} `}</Box>
          </Grid>
          <Grid item> {secondText}</Grid>
        </Grid>
      </Typography>
    );
  }
}

export default VerticalTextSlider;
