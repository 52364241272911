import { Box } from '@mui/material';

const getStyles = absolute => ({
  loading: {
    position: absolute ? 'absolute' : 'fixed',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: '3',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& > img': { width: '100px' }
  }
});

const Loader = ({ absolute }) => {
  const styles = getStyles(absolute);

  return (
    <Box sx={styles.loading}>
      <img src="/imgs/public/loader.png" alt="Loader" />
    </Box>
  );
};

export default Loader;
