import { Grid, Link, Typography } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import Helmet from '../shared/Helmet';
import CenteredGrid from '../shared/CenteredGrid';
import { imageSrcSet } from '../../utils';
import seoTags from '../../utils/seoTags';
import Img from '../shared/Img';
import BackgroundCircle from '../shared/BackgroundCircle';

const styles = {
  container: theme => ({
    paddingBottom: 27.5,
    maxWidth: '100vw',
    minHeight: 'calc(100vh - 122px)',
    [theme.breakpoints.down('md')]: { minHeight: 'calc(100vh - 86px)' }
  }),
  notFoundImage: theme => ({
    width: '100%',
    maxWidth: 525,
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: { width: '75%' }
  }),
  shadow: {
    marginTop: -2.5,
    width: 270,
    height: 7,
    opacity: 0.5,
    filter: 'blur(25px)',
    backgroundColor: '#000000'
  },
  headerText: theme => ({
    textAlign: 'center',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 36,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0f2440',
    margin: 0,
    [theme.breakpoints.down('sm')]: { fontSize: 20 }
  }),
  needHelpText: theme => ({
    textAlign: 'center',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#868a8f',
    margin: 0,
    [theme.breakpoints.down('sm')]: { fontSize: 12 }
  }),
  email: {
    fontWeight: 600,
    color: '#3577d4'
  },
  backgroundCircle: theme => ({
    position: 'absolute',
    [theme.breakpoints.down('md')]: { display: 'none' }
  })
};

const BackgroundAdornments = () => (
  <Grid
    container
    sx={{
      zIndex: -1,
      position: 'absolute',
      width: '100%',
      overflow: 'hidden',
      top: 0,
      bottom: 0
    }}
  >
    <BackgroundCircle size={330} color="#9846dd" top={-223} left={110} opacity={0.25} />
    <BackgroundCircle size={570} color="#134283" top={-240} right={-200} opacity={0.03} outlined />
    <BackgroundCircle size={66} color="#134283" top={440} right={-15} opacity={0.03} />
    <BackgroundCircle size={170} color="#9846dd" top={495} right={196} opacity={0.03} />
    <BackgroundCircle size={36} color="#134283" top={650} left={97} opacity={0.03} />
    <BackgroundCircle size={170} color="#134283" top={480} left={-120} opacity={0.04} />
  </Grid>
);

const NotFoundPage = () => {
  return (
    <>
      <Helmet tags={seoTags.notFoundPage} />
      <BackgroundAdornments />
      <CenteredGrid container direction="column" alignItems="center" justifyContent="center" sx={styles.container}>
        <Img srcSet={imageSrcSet('/imgs/notFoundPage/404.png')} alt="Error 404" sx={styles.notFoundImage} />
        <Grid item sx={styles.shadow} />

        <Grid item sx={{ marginTop: 12.5 }}>
          <Typography sx={styles.headerText}>Woah! You&apos;ve ventured into uncharted territory</Typography>
        </Grid>
        <Grid item container justifyContent="center" sx={{ marginTop: 4.25 }}>
          <Typography sx={styles.needHelpText}>
            If you need help email&nbsp;
            <Link href="mailto:team@swagup.com?subject=I got a 404 message & need help!">team@swagup.com</Link>
          </Typography>
        </Grid>
        <Grid item sx={{ marginTop: 10.25 }}>
          <Button variant="primary" href="/" size="medium">
            Back to Home
          </Button>
        </Grid>
      </CenteredGrid>
    </>
  );
};

export default NotFoundPage;
