import Cookie from 'js-cookie';
import log from '../common/logger';

const lowerWithoutSpace = (text, divider = '-') => text.toLowerCase().replace(/ /g, divider);

const imageSrcSet = (imagePath, imageName, extension) => {
  const imgName = imageName
    ? imagePath + lowerWithoutSpace(imageName)
    : imagePath.substring(0, imagePath.lastIndexOf('/')) +
      lowerWithoutSpace(imagePath.substring(imagePath.lastIndexOf('/'), imagePath.lastIndexOf('.')));

  const extFromPath = imagePath.lastIndexOf('.') > 0 ? imagePath.substring(imagePath.lastIndexOf('.') + 1) : undefined;
  const extFromName =
    imageName && imageName.lastIndexOf('.') > 0 ? imageName.substring(imageName.lastIndexOf('.') + 1) : undefined;
  const ext = extension || extFromName || extFromPath || 'png';
  return `${imgName}.${ext} 1x, ${imgName}@2x.${ext} 2x, ${imgName}@3x.${ext} 3x`; // images can also be jpg: TODO
};

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const trackingTemplate = () => {
  const template = getTrackingTemplate();
  return template ? `?${template}` : '';
};

const getTrackingTemplate = (cookieName = 'swagup') => {
  const cookieValue = Cookie.get(cookieName);
  const trackingTemplate = cookieValue && new URLSearchParams(JSON.parse(cookieValue)).toString();
  return trackingTemplate;
};

const getDemoLink = () => `https://swagup.chilipiper.com/router/product-demo-router${trackingTemplate()}`;

const getFieldFromCookie = (field, name = 'swagup') => {
  const cookieValue = Cookie.get(name);

  try {
    const data = cookieValue && JSON.parse(cookieValue)[field];
    log.debug('cookie', name, 'found with a value of', data, 'for the field', field);
    return data;
  } catch (e) {
    log.error('Error', e, 'when trying to parse cookie', name);
  }

  return '';
};

const okAndLog = (actionName, okStatus, data) => {
  log.debug(`${actionName} Action - Exiting`);
  return { result: 'ok', status: okStatus, data };
};

const errorAndLog = (actionName, errorStatus, data) => {
  log.debug(`${actionName} Action - error: ${errorStatus}, data:`, data);
  return { result: 'error', status: errorStatus, data };
};

const notificationBuilder =
  Container =>
  (variant = '') =>
    <Container variant={variant} />;

const joinFields = (fields, separator) => fields.filter(Boolean).join(separator);

const thisYear = () => new Date().getFullYear();

const oneSecond = 1000;
const fiveMinutes = 5 * 60 * oneSecond;
const fifteenMinutes = 15 * 60 * oneSecond;
const oneHour = 4 * fifteenMinutes;

const mergeDataWithCookie = (data, cookieName = 'swagup') => {
  const cookieValue = Cookie.get(cookieName);
  try {
    const mergedData = {
      ...data,
      ...(cookieValue && JSON.parse(cookieValue))
    };
    log.debug(`merged data with cookie ${cookieName}:`, JSON.stringify(mergedData));
    return mergedData;
  } catch {
    return data;
  }
};

const mergeCampaignIdsWithData = data => {
  const msclkid = localStorage.getItem('_uetmsclkid');
  const gacid = Cookie.get('_ga');

  return mergeDataWithCookie({
    ...data,
    msclkid,
    gacid
  });
};

const conversionDefaults = {
  standardOrderAmount: 4500,
  standardOrderClosingPercent: 50 / 100,
  stardardOrderMargin: 33 / 100,
  samplePackClosingPercent: 10 / 100,
  contactFormClosingPercent: 5 / 100,
  getConversionValue() {
    return this.standardOrderAmount * this.stardardOrderMargin;
  },
  getcontactFormConversionValue() {
    return (this.getConversionValue() * this.contactFormClosingPercent).toFixed(2);
  },
  getRequestSampleConversionValue() {
    return (this.getConversionValue() * this.samplePackClosingPercent).toFixed(2);
  }
};

const getFirstLastName = fullName => {
  const [first, ...last] = fullName.split(' ').filter(e => e);
  return [first, last.join(' ')];
};

export {
  imageSrcSet,
  sleep,
  getDemoLink,
  getFieldFromCookie,
  okAndLog,
  errorAndLog,
  notificationBuilder,
  joinFields,
  fiveMinutes,
  fifteenMinutes,
  oneHour,
  thisYear,
  mergeDataWithCookie,
  mergeCampaignIdsWithData,
  conversionDefaults,
  getFirstLastName
};
