import Box from '@mui/material/Box';
import styles from './styles/backgroundAdorments';

const BackgroundCircle = ({ color, size, outlined, top, bottom, left, right, sx, mobile, opacity }) => {
  const style = {
    backgroundColor: color || '#000033',
    width: size || 0,
    height: size || 0,
    borderRadius: size ? size / 2 : 0
  };

  if (outlined) {
    style.border = `32px ${color || '#000033'} solid`;
    style.backgroundColor = 'transparent';
  }

  if (top) style.top = top;
  if (bottom) style.bottom = bottom;
  if (left) style.left = left;
  if (right) style.right = right;
  if (opacity) style.opacity = opacity;

  const derivedStyles = [sx || style].flat();

  return <Box sx={[mobile ? styles.ovalPrimaryMobile : styles.ovalPrimary, ...derivedStyles]} />;
};

export default BackgroundCircle;
