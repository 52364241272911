import { Grid, Typography, Box, TableCell } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import styles from './styles/gallery';
import { imageSrcSet } from '../../utils';
import Img from '../shared/Img';

const ImageInfoContainer = ({ image: { title, caption } }) => (
  <div className="image-info-container">
    <Grid container direction="column" sx={styles.imageCaptionWrapper}>
      <Grid item sm />
      <Grid item xs sx={styles.imageCaptionContainer}>
        <Typography variant="h5SemiBold" sx={styles.imageTitle}>
          {title.rendered}
        </Typography>
        <Box sx={styles.imageCaption} dangerouslySetInnerHTML={{ __html: caption.rendered }} />
      </Grid>
    </Grid>
  </div>
);

const Cell = ({ image }) => (
  <TableCell sx={styles.wideCell}>
    <Box sx={styles.cellContent}>
      <Box sx={styles.cellImageContainer}>
        <Img
          id={image.id}
          alt={image.alt || image.title?.rendered}
          src={image.source_url}
          srcSet={image.responsive.srcSet}
          sizes={image.responsive.sizes}
          sx={styles.cellImage}
        />
        {image.title?.rendered && image.caption?.rendered && <ImageInfoContainer image={image} />}
      </Box>
    </Box>
  </TableCell>
);

const WideCell = ({ image }) => (
  <TableCell colSpan="2" sx={styles.wideCell}>
    <Box sx={styles.cellContent}>
      <Box sx={styles.cellImageContainer}>
        <Img
          id={image.id}
          alt={image.alt}
          src={image.source_url}
          srcSet={image.responsive.srcSet}
          sizes={image.responsive.sizes}
          sx={styles.cellImage}
        />
        {image.title?.rendered && image.caption?.rendered && <ImageInfoContainer image={image} />}
      </Box>
    </Box>
  </TableCell>
);

const LongCell = ({ image }) => (
  <TableCell rowSpan="2" sx={styles.wideCell}>
    <Box sx={styles.cellContentLong}>
      <Box sx={styles.cellImageContainer}>
        <Img
          id={image.id}
          alt={image.alt}
          src={image.source_url}
          srcSet={image.responsive.srcSet}
          sizes={image.responsive.sizes}
          sx={styles.cellImage}
        />
        {image.title?.rendered && image.caption?.rendered && <ImageInfoContainer image={image} />}
      </Box>
    </Box>
  </TableCell>
);

const ExcerptCell = () => (
  <TableCell sx={styles.wideCell}>
    <Box sx={styles.cellContent}>
      <Box sx={styles.cellExcerptContainer}>
        <Typography variant="body1Medium" sx={styles.excerpt}>
          “Our company-wide slack channels flooded with thank yous, and people were posting pictures of their kids just
          saying, it made my day, it made my week. [It] warmed my heart to see. [It] reinforced the positive culture and
          reinforced that we are always thinking about employees and how to adapt given different circumstances.”
        </Typography>
        <Grid container sx={styles.excerptBottom}>
          <Grid item xs>
            <Typography variant="body1Medium" sx={styles.excerptName}>
              Kyrene Wang
            </Typography>
            <Typography variant="body1Medium" sx={styles.excerptTitle}>
              People Operations Specialist at Clari
            </Typography>
            <Img
              src="/imgs/gallery/clari-logo.png"
              srcSet={imageSrcSet('/imgs/gallery/clari-logo.png')}
              alt="blue-package"
              sx={styles.clariLogo}
            />
          </Grid>
          <Grid item>
            <Button href="/blog/keep-calm-and-clari-on" sx={styles.caseStudyButton}>
              Read the Case Study
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </TableCell>
);

const EmptyCell = () => (
  <TableCell sx={styles.wideCell}>
    <Box sx={styles.cellContent}>
      <Box sx={styles.cellImageContainer} />
    </Box>
  </TableCell>
);

export { Cell, WideCell, LongCell, ExcerptCell, EmptyCell };
