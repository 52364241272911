import { Grid, Typography } from '@mui/material';
import styles from './styles/company';
import CenteredGrid from '../../../src/components/shared/CenteredGrid';
import Img from '../shared/Img';

const OurMission = () => (
  <Grid container justifyContent="center">
    <CenteredGrid sx={styles.center}>
      <Grid item>
        <Typography component="h2" sx={styles.ourMissionTitle}>
          Our Mission
        </Typography>
        <Typography component="p" sx={styles.ourMissionSubTitle}>
          Eliminate all of the friction in the swag buying and distribution process
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          <Img
            src="/imgs/company/swagup-team.png"
            srcSet="/imgs/company/swagup-team.png 1x, /imgs/company/swagup-team@2x.png 2x,
                  /imgs/company/swagup-team@3x.png 3x"
            alt="Our Mission - SwagUp Team"
            sx={styles.imageWASwagUp}
          />
        </Grid>
      </Grid>
    </CenteredGrid>
  </Grid>
);

export default OurMission;
