import ContactUs from '../../shared/ContactUs';
import OurServices from '../../shared/OurServices';
import BackgroundLandingPages from '../shared/BackgroundAdornments';
import LandingSection from '../shared/LandingSection';
import LandingView from '../shared/LandingView';
import BuildYourBrand from '../shared/NewBuildYourBrand';

const services = [
  {
    src: '/imgs/landingPagesSSR/design.svg',
    title: 'Design',
    text: 'We understand you have been assigned to order swag for your next tradeshow. Leave the artsy stuff to us. We will design the swag for you'
  },
  {
    src: '/imgs/landingPagesSSR/automate.svg',
    title: 'Automate',
    text: 'Collecting data on employees or clients can be hard. We can integrate with any CRM/HR system or simply upload a CSV of contacts you want to distribute swag'
  },
  {
    src: '/imgs/landingPagesSSR/assemble.svg',
    title: 'Assemble',
    text: 'We understand assembling swag is not part of your job description! Leave the assembly line to us! We have the packing covered'
  },
  {
    src: '/imgs/landingPagesSSR/ship.svg',
    title: 'Distribute',
    text: 'Manage, track inventory and shipments for your swag on our SwagUp Platform. One centralized platform making swag experiences for remote workers simple'
  }
];

const title = 'We do the work - You take the credit!';

const buttonLabel = 'Let’s get started!';

const bottomImagesList = [
  { src: '/imgs/landingPagesSSR/atlassian.svg', alt: 'Atlassian', margin: 0.75, forcedMargin: 5.5 },
  { src: '/imgs/landingPagesSSR/notion.svg', alt: 'Notion', margin: 0, noPadding: true, forcedMargin: 10.5 },
  { src: '/imgs/landingPagesSSR/breeze.svg', alt: 'Breeze', noPadding: true, forcedMargin: 10.5 },
  { src: '/imgs/landingPagesSSR/smartbug.svg', alt: 'SmartBug', margin: 0, forcedMargin: 10.5 },
  { src: '/imgs/landingPagesSSR/nasdaq.svg', alt: 'Nasdaq', margin: 0, forcedMargin: 10.5 },
  { src: '/imgs/landingPagesSSR/boomi.svg', alt: 'Boomi', forcedMargin: 10.5 }
];

const sectionData = {
  title: 'Send Swag from Your Home Office!',
  h1Style: { fontSize: 64 },
  subTitle: 'Create, Automate and Distribute Swag\nwithout the hassle!',
  subTitleClass: 'homePromoMiddleVCS',
  buttonLabel: 'Let’s get started!',
  landingView: <LandingView />,
  imagesTitle: 'SwagUp Client’s Sending Swag to Remote Teams',
  containerClass: 'bottomImagesContainerVCS',
  bottomImagesList
};

const answers = [
  {
    subTitle:
      'We assemble high-quality swag with your brand. Select from top brands like Patagonia, Corkcicle, and more!'
  },
  {
    subTitle:
      'Let us get your brand out there whether its to employees or clients. Swag can include items like a notebook, socks, and stickers!'
  },
  { subTitle: 'Promoting your brand and values is important. What better way than providing custom swag!' }
];

const HomeOffice = () => (
  <>
    <BackgroundLandingPages />
    <LandingSection sectionData={sectionData} />
    <BuildYourBrand
      responses={answers}
      title={`What’s Swag Look like for\nRemote Workers?`}
      maingImg="/imgs/landingPagesSSR/remote-work.png"
      hideTag
    />
    <OurServices services={services} title={title} buttonProps={{ text: buttonLabel }} />
    <ContactUs namePage="Send Swag from Your Home Office!" />
  </>
);

export default HomeOffice;
