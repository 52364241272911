import * as React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import { Animated } from 'react-animated-css';
import Phone from '@mui/icons-material/Phone';
import { imageSrcSet } from '../../utils';
import CenteredGrid from '../shared/CenteredGrid';
import AnimatedImg from '../shared/AnimatedImg';
import styles from './styles/home';
import VerticalTextSlider from './VerticalTextSlider';
import LogosSection from './LogosSection';

const options = ['build', 'design', 'pack', 'store', 'ship', 'automate'];

const WelcomeText = () => (
  <Grid container justifyContent="center">
    <Grid item sx={styles.titleContainer}>
      <VerticalTextSlider firstText="We " secondText=" swag packs!" options={options} />
    </Grid>
    <Grid item xs={12} align="center">
      <Typography variant="h4SemiBold" sx={styles.descriptionText}>
        We&apos;ve mastered Swag packs, so you don&apos;t have to.
      </Typography>
    </Grid>
    <Grid item>
      <Button
        href="/dashboard/product-onboarding"
        variant="primary"
        sx={[
          styles.mainLandingButton,
          styles.responsiveButton,
          { width: 265, backgroundColor: '#9846dd', marginBottom: 0 }
        ]}
      >
        GET STARTED
      </Button>
      <Typography variant="subtitle3SemiBold" sx={styles.orText}>
        or
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Box component="a" href="tel:6464940520" sx={styles.callUsText}>
            <Phone sx={styles.callUsPhone} /> Call Us
          </Box>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const logos = ['Google', 'Walmart', 'Crunchbase', 'Standford', 'Duolingo', 'Square', 'Facebook', 'Atlassian'];

const getAnimatedImages = darkMode => {
  const basePath = process.env.PUBLIC_URL + '/imgs/homeLight/';

  const images = darkMode
    ? {
        socks: 'socks.png',
        waterbottle: 'waterbottle.png',
        notebook: 'holiday-notebook.png',
        tape: 'holiday-tape.png',
        tshirt: 'holiday-tshirt.png',
        box: 'box.png',
        bag: 'bag.png',
        coffeeCup: 'coffee-cup.png',
        tote: 'tote.png'
      }
    : {
        socks: 'socks-2-x.png',
        waterbottle: 'waterbottle-2-x.png',
        notebook: 'notebook-2-x.png',
        tape: 'tape-2-x.png',
        tshirt: 'shirt-2-x.png',
        box: 'box-2-x.png',
        bag: 'bag-2-x.png',
        coffeeCup: 'coffee-cup-2-x.png',
        tote: 'tote-2-x.png',
        pen: 'pen-2-x.png'
      };

  Object.entries(images).forEach(([k, v]) => {
    images[k] = basePath + v;
  });
  images.adapter = process.env.PUBLIC_URL + '/imgs/homeLight/adapter-2-x.png';

  return images;
};

const LandingView = () => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    setIsVisible(true);
  }, []);

  const animatedImages = getAnimatedImages();

  return (
    <Grid container justifyContent="center">
      <CenteredGrid item sx={styles.center}>
        <Grid container sx={styles.landingFrame}>
          <div style={{ position: 'relative', width: '100%' }}>
            {isVisible && (
              <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <Grid container>
                  <Grid item sx={[styles.noMobile, { paddingTop: 14, paddingRight: 20, marginLeft: -8 }]}>
                    <div style={{ width: 220 }}>
                      <AnimatedImg
                        srcSet={imageSrcSet(animatedImages.socks)}
                        animation="fadeInLeft"
                        animationInDelay={2200}
                        isVisible={isVisible}
                        altText="swag socks"
                      />
                    </div>
                  </Grid>

                  <Grid item align="left">
                    <Box sx={styles.waterbottleImg}>
                      <AnimatedImg
                        srcSet={imageSrcSet(animatedImages.waterbottle)}
                        animation="fadeInUp"
                        animationInDelay={1500}
                        isVisible={isVisible}
                        altText="swag water bottle"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs />
                  <Grid item align="right">
                    <Box sx={[styles.notebookImg, { display: 'table', height: '100%' }]}>
                      <div style={{ display: 'table-cell', verticalAlign: 'bottom' }}>
                        <AnimatedImg
                          srcSet={imageSrcSet(animatedImages.notebook)}
                          animation="fadeInUp"
                          animationInDelay={1700}
                          isVisible={isVisible}
                          altText="swag notebook"
                        />
                      </div>
                    </Box>
                  </Grid>
                  <Grid item sx={styles.noMobile} align="right">
                    <div style={{ width: 320, marginRight: -80, marginLeft: 42, marginTop: 56, paddingLeft: 56 }}>
                      <AnimatedImg
                        srcSet={imageSrcSet(animatedImages.tape)}
                        animation="fadeInRight"
                        animationInDelay={2000}
                        isVisible={isVisible}
                        altText="scotch tape"
                      />
                    </div>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Box sx={styles.shirtImgContainer}>
                        <AnimatedImg
                          srcSet={imageSrcSet(animatedImages.tshirt)}
                          animation="fadeInLeft"
                          animationInDelay={2700}
                          isVisible={isVisible}
                          altText="swag t-shirt"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs sx={styles.treeContainer}>
                      <Grid container justifyContent="center">
                        <Grid item xs={12} align="center">
                          <Box sx={styles.boxImgContainer}>
                            <AnimatedImg
                              srcSet={imageSrcSet(animatedImages.box)}
                              animation="fadeInUp"
                              animationInDelay={1000}
                              isVisible={isVisible}
                              altText="swag pack box"
                            />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box sx={styles.bagImgContainer}>
                            <AnimatedImg
                              srcSet={imageSrcSet(animatedImages.bag)}
                              animation="fadeInUp"
                              animationInDelay={3000}
                              isVisible={isVisible}
                              altText="swag bag"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container justifyContent="flex-end">
                            <Grid item>
                              <Box sx={[styles.cupImgContainer, { display: 'table', height: '100%' }]}>
                                <div style={{ display: 'table-cell', verticalAlign: 'bottom' }}>
                                  <AnimatedImg
                                    srcSet={imageSrcSet(animatedImages.coffeeCup)}
                                    animation="fadeInUp"
                                    animationInDelay={3300}
                                    isVisible={isVisible}
                                    altText="swag coffee cup"
                                  />
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="flex-end">
                        <Grid item xs sx={{ paddingTop: undefined }}>
                          <Box sx={styles.toteImgContainer} align="right">
                            <AnimatedImg
                              srcSet={imageSrcSet(animatedImages.tote)}
                              animation="fadeInRight"
                              animationInDelay={2500}
                              isVisible={isVisible}
                              altText="swag tote"
                            />
                          </Box>
                        </Grid>
                        <Grid item sx={styles.noMobile}>
                          <div align="center" style={{ marginTop: -384, width: 100 }}>
                            <AnimatedImg
                              srcSet={imageSrcSet(animatedImages.adapter)}
                              animation="fadeInRight"
                              animationInDelay={3600}
                              isVisible={isVisible}
                            />
                          </div>
                          <div align="center">
                            <div style={{ width: 34, marginTop: 116 }}>
                              <AnimatedImg
                                srcSet={imageSrcSet(animatedImages.pen)}
                                animation="fadeInRight"
                                animationInDelay={3800}
                                isVisible={isVisible}
                                altText="swag pen"
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={styles.landingBottomFrame}>
                      <Animated animationIn="fadeIn" animationInDelay={4200}>
                        <LogosSection
                          justifyContent="center"
                          logos={logos}
                          logosSpacing={6}
                          titleStyles={{ color: '#0f2440', fontWeight: 600 }}
                        />
                      </Animated>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <WelcomeText />
            </div>
          </div>
        </Grid>
      </CenteredGrid>
    </Grid>
  );
};

export default LandingView;
