import { Grid } from '@mui/material';
import AboutUs from '../../shared/AboutUs';
import ContactUs from '../../shared/ContactUs';
import ProductSection from '../../shared/ProductSection';
import SamplePack from '../../shared/SamplePack';
import SectionTag from '../../shared/SectionTag';
import Testimonials from '../../shared/Testimonials';
import BackgroundAdornments from '../shared/BackgroundAdornments';
import Benefits from '../shared/Benefits';
import OurServices from '../shared/OurServices';
import LandingSection from './LandingSection';
import HowDoesWork from './HowDoesWork';

const configurationData = {
  tag: 'Section Tag',
  SectionImages: [{}, {}, {}]
};

const benefits = {
  src: '/imgs/landingPagesSSR/benefits-rippling.png',
  facilities: [{ facility: 'Dashboard', src: '/imgs/landingPagesSSR/dashboard-event-geek.svg' }],
  hideIntegrations: true
};

const ourServicesData = {
  tag: 'SERVICES',
  title: 'Our Services',
  services: [
    {
      img: '/imgs/landingPagesSSR/custom-v-2.svg',
      alt: 'custom',
      title: `Quick Installation - \n One click & done!`,
      text: 'Add the SwagUp app to your Rippling account in just one click! You are one step closer to having an automated swag for new hires.'
    },
    {
      img: '/imgs/landingPagesSSR/swags.svg',
      alt: 'rocket',
      title: `Select your Swag - \n We handle the Rest!`,
      text: 'Create your account and build out your pack. We work with you on finding the best swag that fits your brand & budget.'
    },
    {
      img: '/imgs/landingPagesSSR/moon.svg',
      alt: 'moon',
      title: `Create, automate, distribute - We’ve got it covered!`,
      text: 'Automatically ship to each new hire. We can even store all of your swag, including 3rd party items. All packs are shipped pre-assembled.'
    }
  ]
};

const subtitle =
  'Not sure where to start? No worries, our team curated the most \npopular packs. Check out our SwagUp Preset packs!';

const RipplingIntegration = () => (
  <>
    <BackgroundAdornments />
    <LandingSection />
    <OurServices {...ourServicesData} />
    <HowDoesWork configurationData={configurationData} />
    <SamplePack subtitle="Get a sense of what your branded swag pack can look like with our SwagUp Sample pack!" />
    <ProductSection subtitle={subtitle} />
    <Benefits benefits={benefits} />
    <AboutUs />
    <Grid container flexDirection="column" alignItems="center">
      <SectionTag tag="TESTIMONIALS" />
      <br />
      <Testimonials title={`Our clients love us to the\nmoon and back`} />
    </Grid>
    <ContactUs namePage="Integrate SwagUp with Rippling" />
  </>
);

export default RipplingIntegration;
