import { Box, Grid } from '@mui/material';
import LogosSection from '../../shared/LogosSection';
import FeatureCardItem from '../../shared/FeatureCardItem';
import FeaturesSection from '../../shared/FeaturesSection';
import MainSection, { LeftComponent } from '../../shared/MainSection';
import ContactSection from '../../shared/ContactSection';
import HighQualitySwag from '../../shared/HighQualitySwag';
import Tag from '../../shared/Tag';
import SamplePack from '../../shared/SamplePack';

const styles = {
  container: theme => ({
    paddingTop: 31.75,
    [theme.breakpoints.down('md')]: { paddingTop: 14, paddingBottom: 12 }
  }),
  marginFS: theme => ({
    marginTop: 34,
    marginBottom: 0,
    [theme.breakpoints.down('lg')]: { minHeight: 'auto' },
    [theme.breakpoints.down('md')]: { marginTop: 22.5 },
    [theme.breakpoints.down('sm')]: { marginTop: 0, paddingLeft: 0, paddingRight: 0 }
  }),
  marginHQS: theme => ({
    marginTop: 30.5,
    [theme.breakpoints.up('lg')]: {
      '& h4,p': { marginLeft: 29.75 },
      '& h4': { fontSize: 36 },
      '& p': { fontSize: 20 }
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 15,
      '& h4,p': { marginLeft: 12.5 }
    },
    [theme.breakpoints.down('md')]: { marginTop: 15, marginBottom: 10 },
    [theme.breakpoints.down('sm')]: {
      '& .MuiButton-root': {
        marginLeft: 0,
        '& > div': {
          marginLeft: 0
        }
      },
      '& a': { marginRight: 0 },
      '& h4,p': { marginLeft: 0, marginRight: 0, alignSelf: 'center', textAlign: 'center', padding: '0 15px' }
    }
  }),
  sectionTag: theme => ({
    height: 37,
    borderRadius: 4.5,
    backgroundColor: '#fbf7ff',
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    color: '#9846dd',
    [theme.breakpoints.down('md')]: { marginLeft: 7.25 },
    [theme.breakpoints.down('sm')]: { marginLeft: 0 }
  }),
  containerImg: theme => ({
    display: 'flex',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: { display: 'none' }
  }),
  img: theme => ({
    marginTop: 30.75,
    objectFit: 'cover',
    maxWidth: '100%',
    maxHeight: '100%',
    [theme.breakpoints.down('md')]: { width: '100%', height: '100%' }
  }),
  logosSection: theme => ({
    marginTop: 18,
    marginBottom: 47.75,
    [theme.breakpoints.down('md')]: { marginBottom: 17.5, padding: '0px 20px' }
  }),
  logosOurAssociates: theme => ({
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.down('md')]: { marginTop: 17.5, marginBottom: 17.5 }
  }),
  stylesOurServices: theme => ({
    [theme.breakpoints.down('md')]: { '& > div:first-child': { alignSelf: 'center', textAlign: 'center' } }
  })
};

const titleMainSection = 'Swag Packs Made \nSimple!';
const titleSamplePack = 'Grab a Free \nsample today!';
const subTitleMainSection = 'Create, Automate, & Distribute Swag without \nthe hassle or crazy platform fees.';
const titleHQS = 'Creating High Quality Swag \nWithout the Hassle';
const logos = ['Google', 'Walmart', 'Crunchbase', 'Amazon', 'Standford', 'Duolingo', 'Square', 'Facebook', 'Atlassian'];
const logosOurAssociates = ['Patagonia', 'Swell', 'Realthread', 'Stickermule', 'Sockclub', 'Moo'];
const features = [
  {
    image: '/imgs/landingPagesSSR/hiw-create.png',
    title: 'Create',
    description: 'Get mockups in 24 hours after selecting from our curated swag catalog filled with brands you love!'
  },
  {
    image: '/imgs/landingPagesSSR/hiw-automate.png',
    title: 'Automate',
    description: 'Automate the swag process by using our redeem pages, Zapier, or request a custom integration.'
  },
  {
    image: '/imgs/landingPagesSSR/hiw-distribute.png',
    title: 'Distribute',
    description: 'Easy to use platform to track shipments and manage inventory \nall in one centralized location.'
  }
];
const featuresOurServices = [
  {
    image: '/imgs/landingPagesSSR/dedicated-team.png',
    title: 'Dedicated Team',
    description: 'Have a dedicated team to help you find the best options that fit your budget and use case.'
  },
  {
    image: '/imgs/landingPagesSSR/assemble.png',
    title: 'Assemble',
    description: 'Gain your time back by not having to assemble swag. We handle assembly at no additional cost.'
  },
  {
    image: '/imgs/landingPagesSSR/dashboard-management.png',
    title: 'Dashboard Management',
    description: 'Eliminate the manual labor of creating spreadsheets to manage swag and tracking.'
  },
  {
    image: '/imgs/landingPagesSSR/inventory-management.png',
    title: 'Inventory Management',
    description: 'Manage inventory and distribution on one centralized platform without paying for monthly fees.'
  },
  {
    image: '/imgs/landingPagesSSR/collaborate.png',
    title: 'Collaborate',
    description: 'Collaborate on swag projects across multiple teams with admin and user permissions.'
  },
  {
    image: '/imgs/landingPagesSSR/integration2.png',
    title: 'Integration',
    description: 'Integrate SwagUp Platform to automate internal processes to track cost and reporting.'
  }
];

const renderFeature =
  imageSize =>
  ({ image, title, description }) =>
    (
      <FeatureCardItem
        padding={50}
        image={image}
        imageSize={imageSize}
        title={title}
        description={description}
        animateOnHover
      />
    );

const SimpleSwagPacks = () => {
  return (
    <Box component="main" sx={styles.container}>
      <MainSection
        leftComponent={
          <LeftComponent
            alignContent="flex-start"
            title={titleMainSection}
            subtitle={subTitleMainSection}
            buttonLabel="Book a Tour!"
            lg={7}
            href="https://swagup.chilipiper.com/router/product-demo-router?utm_source=SEM&utm_medium=landing%20page&utm_campaign=Dashboard%20Demo&utm_term=demo"
            centerElementsOnXS
          />
        }
        variant="primary"
        backgroundImage="/imgs/landingPagesSSR/form-bg-lined.svg"
        img="/imgs/landingPagesSSR/pack-builder.png"
      />
      <LogosSection
        justifyContent="center"
        logos={logos}
        logosSpacing={5}
        titleStyles={{ color: '#0B1829' }}
        sx={styles.logosSection}
      />
      <FeaturesSection
        alignItems="center"
        renderTag={<Tag sx={styles.sectionTag} label="Our Services" />}
        title="We offer more than just Swag"
        subtitle="We solve the problems that come with it. Here’s how we make swag simple"
        features={featuresOurServices}
        featuresSpacing={5}
        renderFeature={renderFeature(96)}
        sx={styles.stylesOurServices}
      />
      <SamplePack
        title={titleSamplePack}
        subtitle="Want to see a sample? We got you covered!"
        buttonText="Request a Sample Pack"
        titleStyle={{ fontSize: 48, marginBottom: 3, marginTop: 5 }}
        subtitleStyle={{ fontSize: 16, color: '#787b80' }}
        sectionTag={<Tag sx={styles.sectionTag} label="Sample Pack" />}
        hideBackground
        removePaddingLeft
        modifyFontSize
      />
      <FeaturesSection
        alignItems="center"
        title="How it Works?"
        features={features}
        featuresSpacing={5}
        renderFeature={renderFeature(118)}
      />
      <Grid sx={styles.marginHQS}>
        <HighQualitySwag title={titleHQS} centerButton />
      </Grid>
      <LogosSection
        justifyContent="center"
        title="We partner with the best"
        logos={logosOurAssociates}
        logosSpacing={5}
        animated
        titleStyles={{ marginBottom: 10 }}
        sx={[styles.logosSection, styles.logosOurAssociates]}
      />
      <ContactSection />
    </Box>
  );
};

export default SimpleSwagPacks;
