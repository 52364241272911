import { Box } from '@mui/system';
import { Grid, Link, Typography, Button } from '@mui/material';
import CenteredGrid from '../../../src/components/shared/CenteredGrid';
import styles from './styles/index';

const FAQHeader = () => (
  <Grid container justifyContent="center" sx={styles.headerSection}>
    <CenteredGrid item sx={styles.center}>
      <Typography component="h1" variant="inherit" sx={styles.title}>
        Frequently Asked Questions
      </Typography>
      <Typography component="h2" variant="inherit" sx={styles.subTitle}>
        Don&apos;t worry, you&apos;re not alone! Here are some of the top questions we get asked all of the time.
      </Typography>

      <Grid item container justifyContent="center">
        <Box sx={styles.faqCenter}>
          <Box sx={styles.headerBox}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs>
                <Typography variant="inherit" sx={styles.headerBoxText}>
                  Head to our help desk for a more detailed breakdown of the SwagUp Platform
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  component={Link}
                  sx={styles.headerBoxButton}
                  target="_blank"
                  href="https://help.swagup.com"
                >
                  Visit Help Desk
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </CenteredGrid>
  </Grid>
);

export default FAQHeader;
