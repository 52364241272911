const styles = {
  container: theme => ({
    paddingTop: 6,
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0
    }
  })
};

export default styles;
