import { Grid, Box, Typography } from '@mui/material';
import styles from './styles/RelatedPosts';
import PostCard from './PostCard';
import CenteredGrid from '../shared/CenteredGrid';

const RelatedPosts = ({ relatedPosts, category }) => {
  return (
    <CenteredGrid container justifyContent="center" sx={styles.center} component="section">
      <Box sx={styles.sectionContainer}>
        <Typography component="p" sx={styles.title}>
          Related blog posts
        </Typography>
        <Grid container alignItems="stretch">
          {relatedPosts.map(
            (rp, index) =>
              index < 9 && (
                <Grid key={rp.id} item xs={12} sm={6} md={4} sx={{ marginBottom: 8 }}>
                  <PostCard post={rp} category={rp.category || category} />
                </Grid>
              )
          )}
        </Grid>
      </Box>
    </CenteredGrid>
  );
};

export default RelatedPosts;
