const titles = {
  margin: 0,
  fontFamily: 'Gilroy-SemiBold',
  fontSize: 24,
  fontWeight: 500,
  lineHeight: 1.33,
  textAlign: 'center',
  color: '#0f2440'
};

const styles = {
  sectionContainer: theme => ({
    paddingTop: 45,
    paddingLeft: 47.5,
    paddingRight: 47.5,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 25,
      paddingRight: 25
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }),
  mainArea: {
    maxWidth: 1060,
    width: '100%'
  },
  sectionTitle: theme => ({
    margin: 0,
    marginTop: 6,
    maxWidth: 500,
    lineHeight: 1.17,
    [theme.breakpoints.down('md')]: {
      marginBottom: 6
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset'
    }
  }),
  tableContainer: theme => ({
    marginTop: 8.5,
    borderRadius: 6,
    border: 'solid 1px #d3dbe5',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('md')]: {
      marginTop: 3.5
    }
  }),
  FreeText: theme => ({
    ...titles,
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  }),
  ChargeText: theme => ({
    ...titles,
    [theme.breakpoints.down('lg')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  }),
  tableBodyContainer: {
    borderBottom: 'solid 1px #e9f0fa',
    '&:last-child': {
      borderBottom: 'transparent'
    }
  },
  mainRowImageSection: {
    alignContent: 'space-evenly'
  },
  mainRowTitle: theme => ({
    margin: 0,
    marginLeft: 5,
    marginTop: 1,
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 0.75,
    color: '#0f2440',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 1.5,
      fontSize: 16
    }
  }),
  row: {
    borderBottom: 'solid 1px #e9f0fa',
    '&:last-child': {
      borderBottom: 'transparent'
    }
  },
  rowColumn: {
    borderLeft: 'solid 1px #e9f0fa'
  },
  rowText: theme => ({
    margin: '30px 0px 30px 30px',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 0.75,
    color: '#0f2440',
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1,
      fontSize: 14,
      margin: '10px 0px 4px 10px'
    }
  }),
  tooltip: theme => ({
    margin: 0,
    maxWidth: 500,
    padding: '32px 36px',
    boxShadow: '0 12px 56px 0 rgba(27, 28, 31, 0.05)',
    border: 'solid 1px #ebeef2',
    backgroundColor: '#ffffff',
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: 12,
    color: '#8d9299',
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200
    }
  }),
  tooltipTitle: theme => ({
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 0.75,
    color: '#0f2440',
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1
    }
  }),
  tooltipFreeText: theme => ({
    margin: 0,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    lineHeight: 0.75,
    color: '#3577d4',
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1
    }
  })
};

export default styles;
