import { Grid, Typography, Box, Button } from '@mui/material';
import { Animated } from 'react-animated-css';
import gtm from '../../../utils/gtm';
import CenteredGrid from '../../shared/CenteredGrid';
import BottomImages from '../shared/BottomImages';
import LandingView from './LandingView';
import styles from './styles/landingSection';

const bottomImagesList = [
  { src: '/imgs/public/google.svg', alt: 'Google', margin: -1.25, noPadding: true },
  { src: '/imgs/public/walmart.svg', alt: 'Walmart', margin: -1.25, noPadding: true, forcedMargin: 0.75 },
  { src: '/imgs/public/crunchbase.svg', alt: 'Crunchbase', margin: 0.25, forcedMargin: 6 },
  { src: '/imgs/public/cocacola.svg', alt: 'CocaCola', margin: -1, forcedMargin: 2.5 },
  { src: '/imgs/public/duolingo_logo.svg', alt: 'Duolingo', margin: -1.75, forcedMargin: -0.5 },
  { src: '/imgs/public/gemini.svg', alt: 'Gemini', margin: -0.5, forcedMargin: 0.75 },
  { src: '/imgs/public/facebook.svg', alt: 'Facebook', margin: -0.25, forcedMargin: 2.75 },
  { src: '/imgs/public/atlassian.svg', alt: 'Atlassian', margin: 0.75, forcedMargin: 5.5 }
];

const ShowBottomImages = ({ onMobile, list, containerClass }) => (
  <Box sx={onMobile ? styles.onMobile : styles.noMobile}>
    <Grid
      container
      alignItems={onMobile ? 'center' : 'flex-end'}
      sx={styles[containerClass] || styles.bottomImagesContainer}
    >
      <BottomImages sx={styles.imgStandardServing} list={list} />
    </Grid>
  </Box>
);

const LandingSection = ({ sectionData = {} }) => (
  <Grid container justifyContent="center" sx={styles.landingAreHereContainer}>
    <CenteredGrid sx={[styles.center, styles.promotionHomeCenter]}>
      <Grid container justifyContent="center" sx={styles.promotionHomeWrapperHAH}>
        <Grid item sm={6} xs={12} sx={styles.headerSW}>
          <Grid container justifyContent="flex-start" direction="column">
            <Typography variant="inherit" sx={[styles.promotionTextHEH, sectionData.h1Style]}>
              {sectionData.title || 'Build Loyalty with Swag Packs!'}
            </Typography>
            <Typography
              variant="inherit"
              sx={[
                styles.regularText,
                styles[sectionData.subTitleClass] || styles.homePromoMiddleHAH,
                { whiteSpace: 'pre-wrap', my: 6 }
              ]}
            >
              {sectionData.subTitle || 'We make Swag Packs Simple!'}
            </Typography>
            <Box sx={styles.landingHereButtonContainer}>
              <Grid container direction="row" sx={styles.landingHereButtonContainerFix}>
                <Grid item>
                  <Button
                    href="/dashboard/product-onboarding"
                    variant="contained"
                    sx={[styles.buttonLandingSection, styles[sectionData.buttonClass]]}
                    onClick={gtm.onClickLandingCTA}
                  >
                    {sectionData.buttonLabel || 'Let’s get started!'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} sx={[styles.hero, { padding: 0 }]}>
          <Grid container justifyContent="center">
            <Grid item>{sectionData.landingView || <LandingView />}</Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={styles.landingBottomFrame}>
          <Animated animationIn="fadeIn" animationInDelay={4200}>
            <Typography variant="inherit" sx={[styles.servingUp, styles[sectionData.imagesTitleClass]]}>
              {sectionData.imagesTitle || 'Serving up fresh swag for'}
            </Typography>
            <ShowBottomImages
              onMobile={false}
              list={sectionData.bottomImagesList || bottomImagesList}
              containerClass={sectionData.containerClass}
            />
          </Animated>
        </Grid>
      </Grid>
    </CenteredGrid>
  </Grid>
);

export default LandingSection;
