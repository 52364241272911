import { Grid, Fab, Card, FormControl, Typography } from '@mui/material';
import { TextField } from '../shared/reactHookFormFields';
import Subscribe from '../shared/Subscribe';
import styles from './styles/PostCard';
import { imageSrcSet } from '../../utils';
import Img from '../shared/Img';

const SubscribeInner = ({ register, errors }) => (
  <>
    <FormControl fullWidth sx={{ minHeight: 96 }}>
      <TextField
        sx={styles.subscribeInput}
        placeholder="Email address"
        {...register('email')}
        error={errors?.email?.message}
        InputProps={{
          sx: styles.input,
          autoComplete: 'off'
        }}
      />
    </FormControl>
    <Grid container justifyContent="center">
      <Fab type="submit" sx={styles.subscribeButton}>
        Subscribe
      </Fab>
    </Grid>
  </>
);

const SubscribeCard = () => (
  <Card sx={[styles.card, styles.cardSubscribe]}>
    <Grid container justifyContent="center" sx={styles.subscribeCard}>
      <Img
        sx={styles.mediaSubscribe}
        src="/imgs/blog/subscribe-email.png"
        srcSet={imageSrcSet('/imgs/blog/subscribe-email.png')}
        title="subscribe email"
        alt="subscribe email"
      />
    </Grid>
    <div>
      <Typography variant="h4SemiBold" sx={[styles.postTitle, { textAlign: 'center' }]}>
        Subscribe to our newsletter
      </Typography>
      <Typography variant="body1SemiBold" sx={[styles.postSummary, { textAlign: 'center', marginBottom: 10.5 }]}>
        Be the first to learn about the latest swag and culture building tips
      </Typography>
      <Subscribe
        FormComponent={SubscribeInner}
        socials={false}
        fullWidth
        // classes={{ sectionContainer: classes.sectionContainer, subscribeContainer: classes.subscribeContainer }}
      />
    </div>
  </Card>
);

export default SubscribeCard;
