import GTM from 'react-gtm-module';
import { conversionDefaults } from './index';

const getLandingPage = () => window.location.pathname.split('/').pop();

const gtm = {
  submitCatalogForm(order) {
    GTM.dataLayer({
      dataLayer: {
        event: 'submitForm',
        orderId: order.id,
        riskLevel: order.risk_level,
        formType: order.form_type,
        packBudget: order.pack_budget,
        packQuantity: order.pack_quantity,
        work_email: order.work_email,
        inhands_date: order.inhands_date,
        orderTotal: (
          order.pack_quantity *
          parseFloat(order.pack_budget) *
          conversionDefaults.stardardOrderMargin *
          conversionDefaults.standardOrderClosingPercent
        ).toFixed(2)
      }
    });
  },

  submitContactForm(formName) {
    GTM.dataLayer({
      dataLayer: {
        event: 'submitContactForm',
        formName,
        value: conversionDefaults.getcontactFormConversionValue()
      }
    });
  },

  submitSamplePackForm() {
    GTM.dataLayer({
      dataLayer: {
        event: 'submitSamplePackForm',
        value: conversionDefaults.getRequestSampleConversionValue()
      }
    });
  },

  onClickStartWithPresetPack(name) {
    GTM.dataLayer({
      dataLayer: {
        event: 'onClickStartWithPresetPack',
        name
      }
    });
  },

  onClickLandingCTA() {
    GTM.dataLayer({
      dataLayer: {
        event: 'clickLandingCTA',
        landingPage: getLandingPage()
      }
    });
  },

  onClickRequestSampleCTA() {
    GTM.dataLayer({
      dataLayer: {
        event: 'clickRequestSampleCTA',
        landingPage: getLandingPage()
      }
    });
  },

  onClick(text) {
    GTM.dataLayer({
      dataLayer: {
        event: 'Click',
        text
      }
    });
  },

  onClickGenerateAIMockupsWithLogo(companyName, domain, packTemplate, designId) {
    GTM.dataLayer({
      dataLayer: {
        event: 'generateAIMockupsWithLogo',
        companyName,
        domain,
        packTemplate,
        designId
      }
    });
  },

  submitAIMockupOrderRequest(
    companyName,
    domain,
    packTemplate,
    designId,
    collageId,
    collageVariantNumber,
    userProvidedLogo
  ) {
    GTM.dataLayer({
      dataLayer: {
        event: 'submitAIMockupOrderRequest',
        companyName,
        domain,
        packTemplate,
        designId,
        collageId,
        collageVariantNumber,
        userProvidedLogo
      }
    });
  },

  onClickSeasonPromotionCTA() {
    GTM.dataLayer({
      dataLayer: {
        event: 'clickSeasonPromotionCTA',
        landingPage: getLandingPage()
      }
    });
  }
};

export default gtm;
