import { Grid, Typography } from '@mui/material';
import { imageSrcSet } from '../../../helpers/utils';
import CenteredGrid from '../../shared/CenteredGrid';
import ContactSection from '../../shared/ContactSection';
import FeatureCardItem from '../../shared/FeatureCardItem';
import FeaturesSection from '../../shared/FeaturesSection';
import HighQualitySwag from '../../shared/HighQualitySwag';
import Img from '../../shared/Img';
import LogosSection from '../../shared/LogosSection';
import MainSection, { LeftComponent } from '../../shared/MainSection';
import SamplePack from '../../shared/SamplePack';
import Tag from '../../shared/Tag';
import styles from './styles';

const titleMainSection = 'Swag Management\nfor the Modern\nWorkplace';
const titleSP = 'Grab a Free\nsample today!';
const logos = ['Google', 'Walmart', 'Crunchbase', 'Amazon', 'Standford', 'Duolingo', 'Square', 'Facebook', 'Atlassian'];
const features = [
  {
    image: '/imgs/landingPagesSSR/hiw-create.png',
    title: 'Create',
    description: 'Get mockups in 24 hours after selecting from our curated swag catalog filled with brands you love!'
  },
  {
    image: '/imgs/landingPagesSSR/hiw-automate.png',
    title: 'Automate',
    description: 'Automate the swag process by using our redeem pages, Zapier, or request a custom integration.'
  },
  {
    image: '/imgs/landingPagesSSR/hiw-distribute.png',
    title: 'Distribute',
    description: 'Easy to use platform to track shipments and manage inventory all in one centralized location.'
  }
];
const featuresOurServices = [
  {
    image: '/imgs/landingPagesSSR/dedicated-team.png',
    title: 'Dedicated Team',
    description: 'Have a dedicated team to help you find the best options that fit your budget and use case.'
  },
  {
    image: '/imgs/landingPagesSSR/assemble.png',
    title: 'Assemble',
    description: 'Gain your time back by not having to assemble swag. We handle assembly at no additional cost.'
  },
  {
    image: '/imgs/landingPagesSSR/dashboard-management.png',
    title: 'Dashboard Management',
    description: 'Eliminate the manual labor of creating spreadsheets to manage swag and tracking.'
  },
  {
    image: '/imgs/landingPagesSSR/inventory-management.png',
    title: 'Inventory Management',
    description: 'Manage inventory and distribution on one centralized platform without paying for monthly fees.'
  },
  {
    image: '/imgs/landingPagesSSR/collaborate.png',
    title: 'Collaborate',
    description: 'Collaborate across teams and invite multiple users to the SwagUp Platform.'
  },
  {
    image: '/imgs/landingPagesSSR/integration.png',
    title: 'Integration',
    description: 'Integrate SwagUp Platform to automate internal processes to track cost and reporting.'
  }
];

const renderFeature = ({ image, title, description }) => (
  <FeatureCardItem padding={50} imageSize={118} image={image} title={title} description={description} animateOnHover />
);
const renderFeatureOurServices = ({ image, title, description }) => (
  <FeatureCardItem
    padding={0}
    imageSize={64}
    image={image}
    title={title}
    description={description}
    animateOnHover
    small
  />
);
const SwagManagmentHome = () => {
  return (
    <>
      <MainSection
        leftComponent={
          <LeftComponent
            alignContent="flex-start"
            title={titleMainSection}
            subtitle="Create, Automate, and Distribute Swag"
            buttonLabel="Let’s get started!"
            lg={7}
            variant="secondary"
            centerElementsOnXS
          />
        }
        changeBackgroundOnXS
        backgroundImage="/imgs/landingPagesSSR/mask-group@2x.png"
      />
      <LogosSection
        justifyContent="center"
        title="Serving up fresh swag for"
        logos={logos}
        logosSpacing={5}
        animated
        titleStyles={{ marginBottom: 5, color: '#0b1829' }}
        sx={styles.logosSection}
      />
      <FeaturesSection
        alignItems="center"
        title="How it Works?"
        features={features}
        featuresSpacing={5}
        renderFeature={renderFeature}
      />
      <CenteredGrid>
        <Grid container justifyContent="center" spacing={10} sx={styles.marginFS}>
          <Grid item md={8} sm={12} alignContent="center">
            <FeaturesSection
              alignItems="flex-start"
              renderTag={<Tag sx={styles.ourServicesTag} label="Our Services" color="secondary" />}
              title="We offer more than just Swag"
              subtitle="We solve the problems that come with it. Here’s how we can help!"
              features={featuresOurServices}
              featuresSpacing={5}
              renderFeature={renderFeatureOurServices}
              sx={styles.stylesOurServices}
            />
          </Grid>
          <Grid
            item
            md={4}
            sm={12}
            style={{ padding: 0, height: '100%', position: 'relative', left: 'calc(50vw - 50.5%)' }}
          >
            <Grid container style={{ height: '100%' }} justifyContent="center">
              <Grid item sx={styles.containerImg}>
                <Img
                  src="/imgs/landingPagesSSR/dashboard.png"
                  srcSet={imageSrcSet('/imgs/landingPagesSSR/dashboard.png')}
                  alt="Img"
                  sx={styles.img}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CenteredGrid>
      <Grid sx={styles.marginHQS}>
        <HighQualitySwag centerButton />
      </Grid>
      <SamplePack
        title={titleSP}
        subtitle="Want to see a sample? We got you covered!"
        buttonText="Request a Sample Pack"
        titleStyle={{ fontSize: 48, marginBottom: 3 }}
        subtitleStyle={{ fontSize: 16, color: '#787b80' }}
        sectionTag={() => (
          <Typography variant="inherit" sx={styles.sectionTag}>
            Sample Pack
          </Typography>
        )}
        hideBackground
        removePaddingLeft
        modifyFontSize
      />
      <CenteredGrid>
        <ContactSection />
      </CenteredGrid>
    </>
  );
};

export default SwagManagmentHome;
