import { useMemo, useState } from 'react';
import { Box, Grid, FormControl, Input, InputAdornment, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import styles from './styles/index';
import LoadMoreSection from '../shared/LoadMoreSection';

const FAQItem = ({ faq }) => {
  const [expanded, setExpanded] = useState(false);
  const ExpandCollapseIcon = expanded ? Remove : Add;
  return (
    <Box sx={styles.faqItem}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ cursor: 'pointer' }}
        onClick={() => setExpanded(!expanded)}
      >
        <Grid item xs>
          <Typography
            variant="inherit"
            sx={styles.faqItemTitle}
            style={{ paddingRight: 24 }}
            dangerouslySetInnerHTML={{ __html: faq.title }}
          />
        </Grid>
        <Grid item>
          <ExpandCollapseIcon sx={styles.faqItemTitle} />
        </Grid>
      </Grid>
      {expanded && <Box sx={styles.faqItemContent} dangerouslySetInnerHTML={{ __html: faq.content }} />}
    </Box>
  );
};

const FAQList = ({ faqs = [] }) => {
  const [showCount, setShowCount] = useState(10);
  const [search, setSearch] = useState('');

  const filteredFAQs = useMemo(
    () => faqs.filter(f => f.title.toUpperCase().includes(search) || f.content.toUpperCase().includes(search)),
    [faqs, search]
  );

  return (
    <Grid container justifyContent="center" sx={styles.faqListSection}>
      <Grid item sx={styles.faqCenter}>
        <Box sx={styles.searchInputContainer}>
          <FormControl fullWidth>
            <Input
              id="input-with-icon-adornment"
              fullWidth
              placeholder="Search any topic, question here"
              sx={styles.subscribeInput}
              disableUnderline
              onChange={event => setSearch(event.target.value.toUpperCase())}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon style={{ color: '#aaaaaa' }} />
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <Box sx={faqs.length > showCount ? styles.listContainer : styles.listContainerOnly}>
          {filteredFAQs.slice(0, showCount).map(faq => (
            <FAQItem key={faq.id} faq={faq} />
          ))}
          {filteredFAQs.length > showCount && (
            <Grid item container justifyContent="center">
              <LoadMoreSection onLoadMore={() => setShowCount(showCount + 6)} />
            </Grid>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default FAQList;
