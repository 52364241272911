import { Box, Grid, Paper, Typography } from '@mui/material';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import CenteredGrid from '../../shared/CenteredGrid';
import styles from './styles/process';
import { useContext } from 'react';

const processes = [
  {
    imgPath: '/imgs/landingPagesSSR/custom-pack/processes/Icon_Custom_Pack_Process_1.svg',
    title: 'Pick your swag',
    text: 'Use the pack builder to pick from our curated selection of quality items',
    styles: {
      boxShadow: '0 25px 50px 0 rgba(26, 58, 98, 0.25)',
      backgroundColor: '#9846dd',
      color: '#ffffff'
    }
  },
  {
    imgPath: '/imgs/landingPagesSSR/custom-pack/processes/Icon_Custom_Pack_Process_2.svg',
    title: 'Upload your assets',
    text: 'Send over your logo files for our design team to work with'
  },
  {
    imgPath: '/imgs/landingPagesSSR/custom-pack/processes/Icon_Custom_Pack_Process_3.svg',
    title: 'Mockups within hours',
    text: 'Our design team will create custom mockups to review within 24 hours'
  },
  {
    imgPath: '/imgs/landingPagesSSR/custom-pack/processes/Icon_Custom_Pack_Process_4.svg',
    title: 'Place Order',
    text: 'Once you’re happy with how the pack looks, complete your order and we’ll get started'
  }
];

const Process = ({ process, withLine, number }) => (
  <Box sx={styles.processWrapper}>
    <Paper sx={[styles.paper, process.styles]}>
      <Typography variant="inherit" sx={styles.processNumber}>
        {number}
      </Typography>
      <Grid container sx={styles.paperMedia}>
        <Grid item xs={12}>
          <img alt="delivery" src={process.imgPath} />
        </Grid>
      </Grid>
      <Grid container sx={styles.paperContent}>
        <Grid item xs={12}>
          <h2>{process.title}</h2>
          <p>{process.text}</p>
        </Grid>
      </Grid>
    </Paper>
    {withLine && <Box sx={styles.line}>&nbsp;</Box>}
  </Box>
);

const LeftArrow = () => {
  const { getPrevItem, scrollToItem } = useContext(VisibilityContext);

  const clickHandler = () => {
    const prevItem = getPrevItem();
    scrollToItem(prevItem?.entry?.target, 'smooth', 'start');
  };

  return <KeyboardArrowLeftRounded onClick={clickHandler} sx={[styles.scrollArrow, { left: 10 }]} />;
};

const RightArrow = () => {
  const { getNextItem, scrollToItem } = useContext(VisibilityContext);

  const clickHandler = () => {
    const nextItem = getNextItem();
    scrollToItem(nextItem?.entry?.target, 'smooth', 'end');
  };

  return <KeyboardArrowRightRounded onClick={clickHandler} sx={[styles.scrollArrow, { right: 10 }]} />;
};

const Processes = () => (
  <Grid container justifyContent="center" sx={styles.container}>
    <CenteredGrid container justifyContent="center" sx={styles.titleContainer}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="inherit" component="h2" sx={styles.title}>
          The Swag Pack Process
        </Typography>
        <Typography variant="inherit" sx={styles.subtitle}>
          Let us do the heavy lifting
        </Typography>
      </Grid>
    </CenteredGrid>
    <Box sx={styles.processesContainer}>
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {processes.map((process, i) => (
          <Process
            key={process.title}
            itemId={process.title}
            process={process}
            number={i + 1}
            withLine={i !== processes.length - 1}
          />
        ))}
      </ScrollMenu>
    </Box>
  </Grid>
);

export default Processes;
