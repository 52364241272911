const styles = {
  card: theme => ({
    cursor: 'pointer',
    borderRadius: 1,
    height: '100%',
    boxShadow: 'none',
    padding: '0px 18px',
    backgroundColor: 'transparent',
    width: '95%',
    '&&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 32px 85px 0 rgba(27, 28, 31, 0.05)'
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 320
    }
  }),
  cardSubscribe: {
    padding: 1,
    margin: '0px 18px',
    justifyItems: 'center',
    border: 'solid 1px #ebeef2',
    height: 'auto',
    paddingBottom: 3.5
  },
  media: theme => ({
    height: 236,
    borderRadius: 1,
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      height: 212
    }
  }),
  mediaSubscribe: theme => ({
    height: 142,
    borderRadius: 1,
    objectFit: 'cover',
    marginTop: 1,
    [theme.breakpoints.down('md')]: {
      height: 92
    }
  }),
  subscribeButton: theme => ({
    width: '100%',
    height: 64,
    borderRadius: 9,
    backgroundColor: '#3577d4',
    color: '#FFFFFF',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.8,
    textTransform: 'none',
    boxShadow: 'none',
    '&&:hover': {
      backgroundColor: '#3577d4'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      height: 56
    }
  }),
  postSummary: {
    opacity: 1,
    lineHeight: 1.75,
    color: '#434d5c'
  },
  timeReading: {
    opacity: 1,
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.43,
    textAlign: 'right',
    color: '#8d9299'
  },
  postTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 23,
    fontWeight: 600,
    lineHeight: 1.5,
    color: '#0f2440',
    marginTop: 5,
    marginBottom: 5
  },
  subscribeInput: {
    maxWidth: 494,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.33,
    color: '#0f2440',
    backgroundColor: 'transparent',
    '& input': {
      fontSize: 20,
      fontFamily: 'Gilroy-SemiBold',
      fontWeight: 100,
      backgroundColor: 'transparent',
      '&::placeholder': {
        color: '#8d9299',
        fontFamily: 'Gilroy-SemiBold',
        fontWeight: 100,
        fontSize: 16,
        opacity: 1
      }
    }
  },
  subscribeCard: {
    background: '#ebf1fb',
    borderRadius: 1,
    overflowY: 'hidden',
    height: 142
  },
  sectionContainer: {
    width: '100%',
    padding: 5
  },
  subscribeContainer: {
    padding: '0 !important'
  },
  input: {
    backgroundColor: 'transparent',
    fontFamily: 'Gilroy-SemiBold'
  }
};

export default styles;
