import { Grid, Avatar, Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import styles from './styles/ArticleInformation';

const Author = ({ author, post }) => (
  <Box sx={styles.AboutAuthor}>
    <Grid container alignItems="center">
      <Grid item xs>
        <Grid container alignItems="center">
          <Grid item>
            <Box sx={styles.AvatarContainer}>
              <Avatar alt={`${author.fullname}`} src={author.avatar} sx={styles.BigAvatar} />
            </Box>
          </Grid>
          <Grid item xs sx={styles.authorData}>
            <Box sx={styles.AuthorName}>{`${author.fullname}`}</Box>
            <Box sx={styles.AuthorTitle}>{author.title}</Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography component="p" sx={styles.datePublished}>
          {post.lastModified
            ? `Last updated: ${post.lastModified}`
            : `Published: ${dayjs(post.date).format('MMMM DD, YYYY')}`}
        </Typography>
      </Grid>
    </Grid>
  </Box>
);

const ArticleInformation = ({ post }) => (
  <Box sx={styles.ArticleInformation}>
    <Author author={post.author} post={post} />
  </Box>
);

export default ArticleInformation;
