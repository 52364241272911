import { Grid, Button, FormControl, Typography } from '@mui/material';
import { TextField } from '../../shared/reactHookFormFields';
import SectionTag from '../../shared/SectionTag';
import Subscribe from '../../shared/Subscribe';
import styles from './styles/signUpNewsLetter';

const SignUpNewsLetterForm = ({ register, errors }) => (
  <Grid container justifyContent="center" sx={styles.container}>
    <Grid container justifyContent="center">
      <SectionTag tag="NEWSLETTER" color="#ffffff" />
    </Grid>
    <Grid item xs={12}>
      <Typography variant="inherit" component="h2" sx={styles.title}>
        Sign up for the newsletter!
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="inherit" sx={styles.subText}>
        In the tumultuous business of cutting-in and attending to a whale
      </Typography>
    </Grid>
    <Grid container sx={styles.emailForm}>
      <Grid item md={7} xs={12} container justifyContent="flex-end" sx={styles.center}>
        <FormControl style={{ width: '400px' }}>
          <TextField
            placeholder="Your email"
            variant="standard"
            {...register('email')}
            error={errors?.email?.message}
            InputProps={{
              sx: styles.input,
              autoComplete: 'off'
            }}
            FormHelperTextProps={{
              sx: { '&.Mui-error': { color: '#ffffff' } }
            }}
          />
        </FormControl>
      </Grid>
      <Grid item md={5} xs={12} container sx={[styles.center, styles.subscribeButtonContainer]}>
        <Button type="submit" sx={styles.subscribeButton}>
          Subscribe
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

const SignUpNewsLetter = () => (
  <Grid container justifyContent="center" style={{ margin: '90px 0 180px 0' }}>
    <Subscribe
      FormComponent={SignUpNewsLetterForm}
      fullWidth
      socials={false}
      classes={{ subscribeContainer: styles.subscribeContainer }}
    />
  </Grid>
);

export default SignUpNewsLetter;
