import { Grid } from '@mui/material';
import CenteredGrid from './CenteredGrid';
import FeatureHeader from './FeatureHeader';

const styles = {
  cardsContainer: theme => ({
    marginTop: 7.5,
    [theme.breakpoints.down('sm')]: { fontSize: 10 }
  })
};

const FeaturesSection = ({ alignItems, title, subtitle, featuresSpacing, renderFeature, renderTag, features, sx }) => {
  return (
    <CenteredGrid sx={sx} container direction="column" alignItems={alignItems}>
      <FeatureHeader title={title} subtitle={subtitle} tags={renderTag} />
      <Grid item container justifyContent="space-around" spacing={featuresSpacing} sx={styles.cardsContainer}>
        {features.map(feature => renderFeature(feature))}
      </Grid>
    </CenteredGrid>
  );
};

export default FeaturesSection;
