import { Animated } from 'react-animated-css';
import Img from './Img';

const defaultStyles = { maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' };

const AnimatedImg = ({
  sx,
  src,
  srcSet,
  animation,
  animationInDuration = 500,
  animationInDelay = 0,
  isVisible,
  altText = 'sample'
}) => (
  <Animated
    animationIn={animation}
    animationInDuration={animationInDuration}
    animationInDelay={animationInDelay}
    isVisible={isVisible}
  >
    <Img src={src} sx={sx || defaultStyles} srcSet={srcSet} alt={altText} />
  </Animated>
);

export default AnimatedImg;
