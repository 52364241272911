import styles from './styles/pricingHome';
import LandingSection from './LandingSection';
import SwagPricesSection from './SwagPricesSection';
import ShippingPriceCalculatorSection from './ShippingPriceCalculatorSection';
import FulfillmentSection from './FulfillmentSection';
import SwagUpWorkSection from './SwagUpWorkSection';
import ContactUs from '../shared/ContactUs';
import StayInLoop from '../shared/StayInLoopSection';
import PricingModelSection from './PricingModelSection';
import { Box } from '@mui/material';

const PricingHome = () => (
  <Box component="main" sx={styles.container}>
    <LandingSection />
    <SwagPricesSection />
    <FulfillmentSection />
    <ShippingPriceCalculatorSection />
    <PricingModelSection />
    <SwagUpWorkSection />
    <ContactUs namePage="Pricing" mailTo="team@swagup.com" />
    <StayInLoop />
  </Box>
);

export default PricingHome;
