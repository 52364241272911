import { Box, Button, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { imageSrcSet } from '../../utils';
import CenteredGrid from '../shared/CenteredGrid';
import Img from '../shared/Img';
import styles from './styles/landingSection';
import Logos from './Logos';

const DiscountCard = ({ discount, discountText }) => (
  <Grid container sx={styles.containerDiscount}>
    <Grid item sx={styles.imageDiscount}>
      <img src="/imgs/landingPagesSSR/discount.png" alt="discount" height="100%" />
    </Grid>
    <Grid item container xs direction="column">
      <Typography variant="inherit" sx={[styles.exclusive, { margin: 0 }]}>
        Exclusive
      </Typography>
      <Typography variant="inherit" sx={[styles.discount, { margin: 0 }]}>
        <span style={{ color: '#185ffe' }}>{+discount}% off</span> {discountText}
      </Typography>
    </Grid>
  </Grid>
);

const LandingSection = ({ page }) => {
  return (
    <CenteredGrid container justifyContent="center" sx={styles.promotionHomeCenter}>
      <Grid container justifyContent="center" spacing={2} className={styles.promotionHomeWrapperHAH}>
        <Grid item sm={6} xs={12} sx={styles.headerSwagManagement}>
          <Grid container direction="column">
            <Grid item xs={12} container justifyContent="center" alignItems="center" sx={styles.containerTextEventGeek}>
              <Box sx={styles.logoChip}>
                {page.logo && (
                  <>
                    <img src={page.logo} alt="Partner Logo" style={{ height: 24 }} />
                    <CloseIcon style={{ color: '#1d1d1d', fontSize: '1rem', margin: '0px 4px' }} />
                  </>
                )}
                <img src="/imgs/landingPagesSSR/blue.svg" alt="SwagUp Logo" style={{ maxHeight: 24, maxWidth: 160 }} />
              </Box>
            </Grid>
            <Typography component="h1" variant="inherit" sx={styles.promotionTextHEH}>
              Swag <br /> Management <br /> Made Simple
            </Typography>
            <Typography variant="inherit" sx={[styles.regularText, styles.homePromoMiddleHAH, { my: 6 }]}>
              Create, Automate and Distribute <br /> High Quality Swag
            </Typography>
            <Box sx={styles.landingHereButtonContainer}>
              <Grid container sx={styles.landingHereButtonContainerFix}>
                <Grid item>
                  <Button
                    variant="contained"
                    href="/dashboard/custom-swag-packs/catalog"
                    sx={styles.buttonLandingSectionEG}
                  >
                    Let’s get started!
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid item sm={5} xs={12} sx={[styles.containerImgHeroEventGeek, { padding: 0 }]}>
          <Grid container justifyContent="center">
            <Img
              src="/imgs/landingPagesSSR/pack-hero@3x.png"
              srcSet={imageSrcSet('/imgs/landingPagesSSR/pack-hero.png')}
              alt="pack"
              sx={styles.eGImg}
            />
            <DiscountCard discount={page.discount} discountText={page.discount_text} />
          </Grid>
        </Grid>
        <Logos />
      </Grid>
    </CenteredGrid>
  );
};

export default LandingSection;
