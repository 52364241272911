import { scrollBar } from '../../../common/styles';

const styles = {
  rootContainer: {
    position: 'relative',
    zIndex: 2,
    fontFamily: 'Gilroy'
  },
  mobileSocials: {
    width: 24,
    height: 24
  },
  centerOnMobile: theme => ({
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      textAlign: 'center'
    }
  }),
  contactLink: {
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 400
  },
  footer: {
    backgroundColor: '#f3f3f3',
    marginTop: 6
  },
  footerText: theme => ({
    color: '#0f2440',
    paddingTop: 5.5,
    paddingBottom: 5.5,
    [theme.breakpoints.down('md')]: {
      fontSize: 11,
      textAlign: 'center'
    }
  }),
  infoSection: theme => ({
    margin: '0 auto',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      paddingBottom: 5
    }
  }),
  text: theme => ({
    fontFamily: 'Gilroy',
    fontSize: 16,
    padding: 0,
    color: '#0f2440',
    textDecoration: 'none',
    marginBottom: 5,
    height: 20,
    [theme.breakpoints.down('md')]: {
      marginBottom: 4
    }
  }),
  logo: theme => ({
    height: 36,
    width: 170,
    marginBottom: 5,
    marginLeft: -2,
    color: '#0f2440',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  }),
  logoContainer: theme => ({
    textAlign: 'left',
    paddingRight: 20.5,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      paddingRight: 0
    }
  }),
  topBarSection: theme => ({
    paddingTop: 2,
    paddingLeft: 10,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 6
    }
  }),
  sectionTitle: theme => ({
    fontFamily: 'Gilroy-Bold',
    color: '#0f2440',
    fontSize: 20,
    marginBottom: 6,
    marginTop: 0.5,
    fontWeight: 600,
    lineHeight: '28.6px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      marginBottom: 4
    }
  }),
  dialogContent: { ...scrollBar }
};
export default styles;
