import { Grid } from '@mui/material';
import BackgroundCircle from '../shared/BackgroundCircle';
import styles from '../shared/styles/backgroundAdorments';

const BackgroundLight = () => {
  return (
    <Grid container justifyContent="center" sx={styles.ovalContainer}>
      <BackgroundCircle size={450} color="#134283" top={-100} right={-150} outlined />
      <BackgroundCircle size={66} color="#134283" top={514} right={44} />
      <BackgroundCircle size={170} color="#003399" top={631} left={-130} opacity={0.08} />
      <BackgroundCircle size={107} color="#9846dd" top={974} right={281} />
      <BackgroundCircle size={350} color="#134283" top={2065} left={-190} opacity={0.08} outlined />
      <BackgroundCircle size={170} color="#134283" top={2280} right={-85} />
      <BackgroundCircle size={66} color="#134283" top={3074} right={-10} opacity={0.12} />
      <BackgroundCircle size={402} color="#9846dd" top={3795} right={-300} opacity={0.08} />
      <BackgroundCircle size={256} color="#3577d4" top={4210} left={-50} />
      <BackgroundCircle size={131} color="#3577d4" top={4401} left={-60} opacity={0.08} />
      <BackgroundCircle size={360} color="#9846dd" top={5555} right={-260} outlined />
      <BackgroundCircle size={104} color="#134283" top={5895} left={332} />
      <BackgroundCircle size={360} color="#9846dd" top={6062} left={-210} outlined />
      <BackgroundCircle size={447} color="#134283" top={6030} right={-380} />
      <BackgroundCircle size={113} color="#9846dd" top={8437} right={33} />
      <BackgroundCircle size={306} color="#9846dd" top={8457} right={-150} outlined />
      <BackgroundCircle size={102} color="#9846dd" top={8657} left={144} />
      <BackgroundCircle size={170} color="#9846dd" top={9000} left={-40} />
      <BackgroundCircle size={122} color="#3577d4" top={10628} left={-50} opacity={0.15} />
      <BackgroundCircle size={306} color="#9846dd" top={10700} left={-160} opacity={0.15} outlined />
      <BackgroundCircle size={108} color="#3577d4" top={10911} right={196} />
      {/* Mobile */}
      <BackgroundCircle size={120} color="#9846dd" top={-90} left={430} mobile />
      <BackgroundCircle size={40} color="#003399" top={-20} left={520} mobile />
      <BackgroundCircle
        sx={[styles.ovalBlueEmptyTop, { height: 160, width: 160, top: 610, right: 330, border: 'solid 16px #134283' }]}
        mobile
      />
      <BackgroundCircle size={40} color="#9846dd" top={450} right={450} mobile />
      <BackgroundCircle size={40} color="#3577d4" top={510} left={390} mobile />
      <BackgroundCircle size={40} color="#9846dd" top={1250} left={400} mobile />
      <BackgroundCircle size={40} color="#3577d4" top={3610} right={390} mobile />
      <BackgroundCircle
        outlined
        mobile
        sx={{ height: 140, width: 140, top: 3280, left: 330, border: 'solid 16px #003399' }}
      />
      <BackgroundCircle
        outlined
        mobile
        sx={{ height: 140, width: 140, top: 6430, left: 330, border: 'solid 16px #9846dd' }}
      />
      <BackgroundCircle size={60} color="#3577d4" top={6430} left={430} mobile />
      <BackgroundCircle size={60} color="#3577d4" top={8160} right={490} mobile />
    </Grid>
  );
};

export default BackgroundLight;
