const getStyles = headerRow => ({
  container: theme => ({
    margin: 'auto',
    maxWidth: 1500,
    paddingTop: 45,
    paddingLeft: 47.5,
    paddingRight: 47.5,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 25,
      paddingRight: 25
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 25,
      paddingLeft: 15,
      paddingRight: 15
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }),
  horizontalLine: {
    margin: '5px 30px',
    borderBottom: '1px solid #d8deea8a',
    width: 'calc(100% - 60px)'
  },
  costText: {
    height: 'auto',
    fontWeight: 400
  },
  sectionContainer: {
    height: 45,
    padding: 7.5,
    borderRadius: 3.75,
    marginBottom: headerRow ? 5 : 0,
    marginTop: headerRow ? 20 : 0,
    backgroundColor: headerRow ? 'rgba(235, 241, 251, 0.5)' : null
  },
  item: {
    textAlign: 'right',
    fontSize: headerRow ? null : 16,
    fontWeight: headerRow ? null : 400,
    lineHeight: '22px'
  },
  tableContainer: theme => ({
    position: 'relative',
    padding: '0 30px 30px',
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
      padding: '0 0 30px'
    }
  }),
  tabs: {
    margin: '20px 0',
    '& .MuiTab-root': {
      minWidth: 150
    }
  },
  content: {
    marginTop: 10,
    paddingBottom: 8,
    position: 'relative',
    minHeight: 'calc(100vh - 389px)'
  },
  linkContainer: {
    marginTop: 5,
    marginLeft: 7.5
  },
  link: theme => ({
    display: 'flex',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }),
  text: theme => ({
    fontSize: 14,
    color: '#0b1829',
    marginLeft: 15,
    marginTop: 7.5,
    [theme.breakpoints.down('md')]: {
      marginLeft: 7.5
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginLeft: 0,
      marginTop: 0
    }
  }),
  infoCard: theme => ({
    [theme.breakpoints.down('md')]: {
      margin: '80px 0 0'
    },
    [theme.breakpoints.down('sm')]: {
      margin: '80px 30px 0'
    },
    margin: '80px 30px 0',
    textAlign: 'left',
    padding: '26px 32px 28px',
    backgroundColor: 'rgba(235, 241, 251, 0.5)',
    borderRadius: 24
  }),
  footerSection: theme => ({
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }),
  footerTitle: {
    margin: 0,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    fontWeight: 500,
    color: '#0f2440'
  },
  footerImage: theme => ({
    [theme.breakpoints.down('sm')]: {
      marginBottom: 5
    }
  }),
  footerText: {
    marginTop: 2.5,
    marginBottom: 0,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.75,
    color: '#8d9299'
  }
});

export default getStyles;
