import { Grid, Button, Box, Typography, useMediaQuery } from '@mui/material';
import { imageSrcSet } from '../../../utils';
import gtm from '../../../../src/utils/gtm';
import CenteredGrid from '../../shared/CenteredGrid';
import SectionTag from '../../shared/SectionTag';
import Img from '../../shared/Img';
import styles from './styles/newSamplePack';

const NewSamplePack = ({
  sectionTagProps = { left: true, right: false },
  title,
  subTitle,
  image = '/imgs/landingPagesSSR/bitmap.png'
}) => {
  const isXs = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Grid container justifyContent="center" sx={styles.containerSamplePackVCS}>
      <CenteredGrid sx={styles.center}>
        <Box sx={styles.sectionContainerSPVCS}>
          <Box sx={styles.subSectionContainerSPVCS}>
            <Grid container sx={styles.contentNewGT}>
              <Grid item sm={6} xs={12} sx={styles.containerTitlesSP}>
                <SectionTag
                  tag="Sample Pack"
                  left={sectionTagProps.left || isXs}
                  right={sectionTagProps.right || isXs}
                />
                <Typography component="h2" variant="inherit" sx={styles.promotionTitleSP}>
                  {title || 'Try out our Free\n sample'}
                </Typography>
                <Typography variant="inherit" sx={styles.textSamplePackVCS}>
                  {subTitle || 'See for yourself what all the swag is\n about!'}
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12} sx={styles.containerSPVCS}>
                <Grid container sx={styles.subContainerSP}>
                  <Grid item xs={12}>
                    <Box sx={styles.wrapperSPImg}>
                      <Img src={image} srcSet={imageSrcSet(image)} alt="box" sx={styles.sPImg} />
                    </Box>
                    <Grid container justifyContent="center" sx={styles.containerButtonSPVCS}>
                      <Button
                        href="/dashboard/onboarding"
                        variant="contained"
                        sx={styles.buttonSPVCS}
                        onClick={gtm.onClickRequestSampleCTA}
                      >
                        GRAB A SAMPLE!
                      </Button>
                      <Grid item xs={12}>
                        <Typography variant="inherit" sx={styles.textIntCust}>
                          *International customers cover shipping
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </CenteredGrid>
    </Grid>
  );
};

export default NewSamplePack;
