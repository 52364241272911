import { Box, Grid, Typography } from '@mui/material';
import { imageSrcSet } from '../../../helpers/utils';
import CenteredGrid from '../../shared/CenteredGrid';
import Img from '../../shared/Img';

const styles = {
  title: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 48,
    lineHeight: 1.33,
    color: '#0b1829',
    [theme.breakpoints.down('sm')]: { fontSize: 32 }
  }),
  carePackageItem: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    lineHeight: 1.6,
    color: '#0b1829',
    [theme.breakpoints.down('sm')]: { fontSize: 14 }
  }),
  dot: {
    width: 6,
    height: 6,
    borderRadius: 16,
    margin: '12px 25px 46px 0',
    backgroundColor: '#3577d4'
  },
  carePackageImage: theme => ({
    maxWidth: '90%',
    objectFit: 'contain',
    marginTop: 13,
    [theme.breakpoints.down('sm')]: { display: 'none' }
  }),
  careImageContainer: theme => ({
    [theme.breakpoints.down('sm')]: { justifyContent: 'center' }
  })
};

const carePackageContent = [
  'Select from a vast selection of brands that people receiving them will love.',
  'Care packages can be customized with your logo or unbranded.',
  'Dedicated rep to help select items from top brands or select from preset options.',
  'Assembly of items is included at no additional cost.',
  'Easy process to send care packages from the comfort of your home!'
];

const WhatsInCarePackageSection = () => (
  <CenteredGrid container sx={{ padding: '0 12px' }}>
    <Grid item container sm={12} md={5}>
      <Grid item>
        <Typography variant="inherit" sx={styles.title}>
          What’s in a care package
        </Typography>
      </Grid>
      {carePackageContent.map(item => (
        <Grid key={item} item container direction="row" style={{ marginTop: 30 }}>
          <Box component="span" sx={styles.dot} />
          <Grid item xs>
            <Typography variant="inherit" sx={styles.carePackageItem}>
              {item}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
    <Grid item container justifyContent="flex-end" sm={12} md={7} sx={styles.careImageContainer}>
      <Img
        srcSet={imageSrcSet('/imgs/landingPagesSSR/care-package.jpg')}
        alt="Care Package"
        sx={styles.carePackageImage}
      />
    </Grid>
  </CenteredGrid>
);

export default WhatsInCarePackageSection;
