import { useCallback, useContext, useEffect, useRef } from 'react';
import { Grid, MenuItem, Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import GlobalInfoContext from './GlobalInfoContext';
import { styles } from './styles/shippingPriceCalculator';
import { SelectField, TextField } from '../shared/reactHookFormFields';
import { normalizeUSZip } from '../../helpers/utils';
import { getSchema } from '../../common/commonValidations';

const minQuantity = 1;
const initialShipmentData = {
  quantity: minQuantity,
  shipping_country: 'US',
  shipping_address1: 'Fake Address',
  shipping_address2: 'Fake Address',
  shipping_city: 'Fake City',
  shipping_state: '',
  shipping_zip: ''
};

const resolver = yupResolver(
  getSchema(['shipping_country', 'shipping_state', 'shipping_zip'], {
    quantity: yup
      .number()
      .transform(value => (Number.isNaN(value) ? null : value))
      .nullable()
      .required()
      .min(minQuantity, `The minimum amount is ${minQuantity}.`)
  })
);

const ShippingPriceCalculator = ({ fetchPrices, isBulk, isCalculating }) => {
  const { bulk, swag, countries } = useContext(GlobalInfoContext);

  const { formState, control, register, watch, setValue, handleSubmit } = useForm({
    mode: 'all',
    resolver,
    defaultValues: initialShipmentData
  });
  const { errors, isValid, isSubmitted } = formState;

  const country = watch('shipping_country');
  const provinces = countries.find(c => c.iso2 === country)?.provinces ?? [];

  const handleQuantityKeyDown = e => {
    if (e.key === '.' || e.key === '-') {
      e.preventDefault();
    }
  };

  const handleChangeZip = e => {
    if (country === 'US') {
      const { value } = e.target;
      e.target.value = normalizeUSZip(value);
    }
  };

  const calculateResults = useCallback(
    data => {
      const [idKey, idValue] = isBulk ? ['item', bulk.id] : ['pack_template', swag.id];

      const { quantity, ...formData } = data;

      fetchPrices({
        ...formData,
        shipping_zip: formData.shipping_zip || undefined,
        quantities: [{ [idKey]: idValue, quantity }],
        product_type: isBulk ? 'Item' : 'Pack'
      });
    },
    [bulk?.id, swag?.id, isBulk, fetchPrices]
  );

  const formRef = useRef();

  useEffect(() => {
    if (isValid && isSubmitted) {
      handleSubmit(calculateResults)();
    }
  }, [isValid, isSubmitted, handleSubmit, calculateResults]);

  const cardTitle = isBulk ? 'Item quantity' : 'Pack quantity';

  return (
    <form ref={formRef} onSubmit={handleSubmit(calculateResults)}>
      <Grid container item sm={12}>
        <Typography sx={styles.shippingTitle}>{cardTitle}</Typography>
        <Grid container sx={styles.mt20}>
          <TextField
            type="number"
            inputProps={{ min: minQuantity, onKeyDown: handleQuantityKeyDown }}
            error={errors.quantity?.message}
            {...register('quantity')}
            sx={styles.input}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container sx={styles.mt20}>
        <Typography sx={styles.shippingTitle}>Shipping location</Typography>
        <Grid container sx={styles.mt20}>
          <SelectField
            name="shipping_country"
            error={errors.shipping_country?.message}
            totalItems={countries.length}
            onSelectChange={() => setValue('shopping_state', '')}
            control={control}
            label="Country"
            defaultValue=""
            sx={[styles.input, styles.selectLabel, { paddingBottom: 5.75 }]}
            fullWidth
          >
            {countries?.map(c => (
              <MenuItem key={c.iso2} value={c.iso2}>
                {c.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid container justifyContent="space-between">
          <Grid container item sm={12} lg={6} sx={styles.fieldContainer}>
            {provinces.length === 0 ? (
              <TextField
                autoComplete="shipping_state"
                error={errors.shipping_state?.message}
                placeholder="Province"
                {...register('shipping_state')}
                sx={styles.input}
                fullWidth
              />
            ) : (
              <SelectField
                name="shipping_state"
                error={errors.shipping_state?.message}
                totalItems={provinces.length}
                label="State"
                defaultValue=""
                control={control}
                sx={styles.input}
                classes={{ labelInput: styles.selectLabel }}
                fullWidth
              >
                {provinces.map(p => (
                  <MenuItem key={p.code} value={p.code}>
                    {p.name}
                  </MenuItem>
                ))}
              </SelectField>
            )}
          </Grid>
          <Grid container item sm={12} lg={6} sx={styles.fieldContainer}>
            <TextField
              error={errors.shipping_zip?.message}
              placeholder="Zip code"
              {...register('shipping_zip')}
              onInput={handleChangeZip}
              sx={styles.input}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Button type="submit" variant="contained" disabled={!isValid || isCalculating} sx={styles.blueButton}>
            Calculate price
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ShippingPriceCalculator;
