import { Grid, Typography } from '@mui/material';
import { imageSrcSet } from '../../utils';
import Img from './Img';

const getStyles = (imageSize, small, padding) => {
  return {
    animateOnHover: {
      transitionProperty: 'box-shadow',
      transitionDuration: '250ms',
      '&:hover': {
        boxShadow: '0 12px 56px 0 rgba(0, 0, 0, 0.05)'
      }
    },
    featureCard: theme => ({
      marginTop: 10,
      backgroundColor: '#ffffff',
      padding: `${padding + 12}px ${padding}px`,
      maxWidth: 390,
      width: '100%',
      borderRadius: 5,
      [theme.breakpoints.between('sm', 'md')]: {
        maxWidth: 290
      },
      [theme.breakpoints.down('md')]: {
        padding: `${padding / 2 + 3}px ${padding / 2}px`
      }
    }),
    featureImage: {
      width: imageSize,
      height: imageSize,
      objectFit: 'contain'
    },
    featureTitle: {
      marginTop: small ? 0 : 8,
      fontFamily: 'Gilroy-SemiBold',
      fontSize: small ? 20 : 24,
      fontWeight: small ? 500 : 600,
      lineHeight: small ? 1.42 : 1.6,
      marginBottom: 4,
      color: '#0b1829'
    },
    featureDescription: {
      fontFamily: 'Gilroy-SemiBold',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.75,
      color: '#868a8f',
      margin: 0,
      whiteSpace: 'pre-wrap'
    }
  };
};

const FeatureCardItem = ({ image, imageSize, padding, title, description, small, animateOnHover }) => {
  const styles = getStyles(imageSize, small, padding);

  return (
    <Grid container sx={[styles.featureCard, animateOnHover && styles.animateOnHover]}>
      <Grid item container sm={small ? 2 : 12}>
        <Img srcSet={imageSrcSet(image)} alt={image} sx={styles.featureImage} />
      </Grid>
      <Grid item container sm={small ? 10 : 12} sx={{ paddingLeft: small ? 7.5 : 0 }}>
        <Typography sx={styles.featureTitle}>{title}</Typography>
        <Typography sx={styles.featureDescription}>{description}</Typography>
      </Grid>
    </Grid>
  );
};

export default FeatureCardItem;
