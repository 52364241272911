import { Box, styled } from '@mui/material';
import tags from '../../utils/seoTags';
import Helmet from '../shared/Helmet';
import CenteredGrid from '../shared/CenteredGrid';
import LogosSection from '../shared/LogosSection';
import HighQualitySwag from '../shared/HighQualitySwag';
import ContactSection from '../shared/ContactSection';
import Testimonials from '../shared/Testimonials';
import LandingSection from './LandingSection';
import SwagupProcess from './SwagupProcess';
import Features from './Features';
import LimitingContainer from '../shared/LimitingContainer';

const styles = {
  container: theme => ({
    paddingTop: 5,
    [theme.breakpoints.down('md')]: {
      paddingTop: 5
    }
  }),
  startContainer: theme => ({
    position: 'relative',
    width: '100%',
    marginTop: 30,
    left: 0,
    [theme.breakpoints.down('lg')]: {
      marginTop: 25
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 15
    }
  }),
  contactUsContainer: theme => ({
    paddingBottom: 23,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 30
    }
  })
};

const logos = ['Google', 'Walmart', 'Crunchbase', 'Amazon', 'Standford', 'Duolingo', 'Square', 'Facebook', 'Atlassian'];

const TopTriangle = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: -25,
  width: 56,
  height: 55,
  right: '42.5%',
  [theme.breakpoints.down('lg')]: {
    right: '24.5%'
  },
  [theme.breakpoints.down('sm')]: {
    width: 48,
    height: 47,
    right: '22%'
  }
}));

const BottomTriangle = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: 74,
  height: 58,
  right: '54%',
  bottom: -16,
  [theme.breakpoints.down('lg')]: {
    right: '46%',
    bottom: 92
  },
  [theme.breakpoints.down('md')]: {
    right: '36%',
    bottom: 96
  },
  [theme.breakpoints.down('sm')]: {
    right: '48%',
    width: 58,
    height: 48,
    bottom: 45
  }
}));

function Services() {
  return (
    <Box sx={styles.container}>
      <Helmet tags={tags.services} />
      <CenteredGrid>
        <LandingSection />
        <LogosSection justifyContent="center" logos={logos} logosSpacing={5} titleStyles={{ color: '#0B1829' }} />
        <SwagupProcess />
        <Features />
        <LimitingContainer>
          <Testimonials title="See why our clients love us!" />
        </LimitingContainer>
      </CenteredGrid>
      <Box sx={styles.startContainer}>
        <HighQualitySwag
          title="Let’s get started today!"
          subtitle="It’s that simple. We do all the work, and you take the credit!"
        />
        <TopTriangle src="/imgs/services/purple-triangle.png" />
        <BottomTriangle src="/imgs/services/blue-triangle.png" />
      </Box>
      <CenteredGrid>
        <LimitingContainer sx={styles.contactUsContainer} maxWidth={1230}>
          <ContactSection />
        </LimitingContainer>
      </CenteredGrid>
    </Box>
  );
}

export default Services;
