const styles = {
  container: theme => ({
    paddingBottom: 15.5,
    paddingTop: 24,
    backgroundColor: '#e9f0fa',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 6,
      paddingTop: 8
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('md')]: {
      px: 6,
      width: '100%'
    }
  }),
  sectionContainerOS: theme => ({
    paddingLeft: '62px !important',
    paddingRight: '92px !important',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important'
    }
  }),
  title: theme => ({
    letterSpacing: 'inherit',
    marginTop: 9,
    marginBottom: 18,
    [theme.breakpoints.down('md')]: {
      marginTop: 3,
      marginBottom: 8
    }
  }),
  servicesButtonContainer: theme => ({
    paddingTop: 8,
    paddingBottom: 18,
    [theme.breakpoints.down('md')]: {
      paddingTop: 4,
      paddingBottom: 8
    }
  }),
  mainButton: theme => ({
    width: 272,
    marginBottom: 6,
    fontSize: 18,
    [theme.breakpoints.down('md')]: {
      marginBottom: 4,
      width: 224,
      fontSize: 16,
      py: 4
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 2,
      fontSize: 14,
      py: 3
    }
  }),
  servicesItemSvg: theme => ({
    paddingRight: 5,
    [theme.breakpoints.down('md')]: {
      paddingRight: 0
    }
  }),
  servicesIcons: theme => ({
    height: 72,
    width: 72,
    [theme.breakpoints.down('md')]: {
      height: 42,
      width: 42
    }
  }),
  servicesItemText: theme => ({
    paddingBottom: '62px !important',
    [theme.breakpoints.down('md')]: {
      paddingTop: 4,
      paddingBottom: '32px !important'
    }
  }),
  servicesItemTextSec: theme => ({
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      paddingBottom: '48px !important'
    }
  }),
  newChip: {
    color: '#ffffff',
    backgroundColor: '#9846dd',
    fontSize: 16,
    padding: '4px 20px',
    borderRadius: 1.25,
    display: 'inline-block',
    textAlign: 'left'
  },
  serviceItemInner: theme => ({
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px !important'
    }
  }),
  sectionSubTitle: theme => ({
    marginBottom: 4,
    [theme.breakpoints.down('md')]: {
      marginBottom: 2
    }
  }),
  regularTextServices: theme => ({
    textAlign: 'left',
    marginBottom: 3,
    [theme.breakpoints.down('md')]: {
      marginBottom: -1
    }
  }),
  subTitle: theme => ({
    marginBottom: 4,
    [theme.breakpoints.down('md')]: {
      marginBottom: 2
    }
  }),
  servicesLinkContainer: theme => ({
    [theme.breakpoints.down('md')]: {
      marginBottom: 6
    }
  }),
  regularTextServicesLink: theme => ({
    color: '#3577d4',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    letterSpacing: 'normal',
    lineHeight: 0.91,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginBottom: 24
    }
  }),
  textAboveButtonOS: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'center',
    color: '#3577d4',
    marginBottom: 9.75,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginBottom: 4.5
    }
  })
};

export default styles;
