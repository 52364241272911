export const styles = {
  fieldContainer: theme => ({
    maxWidth: '190px !important',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset !important'
    }
  }),
  mt20: {
    marginTop: 5
  },
  shippingTitle: {
    margin: 0,
    width: '100%',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 0.75,
    color: '#8d9299'
  },
  blueButton: {
    marginTop: 10.5,
    width: '100%',
    height: 64,
    borderRadius: 8,
    backgroundColor: '#3577d4',
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 0.75,
    textTransform: 'none',
    color: '#ffffff'
  },
  input: {
    '& .MuiInputBase-root': { height: 66 },
    paddingTop: 1.5
  },
  selectLabel: { paddingTop: 3 }
};
