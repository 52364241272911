const styles = {
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: 1024,
      padding: '0px 24px'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 24px'
    }
  }),
  Title: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 56,
    fontWeight: 600,
    lineHeight: 1.29,
    color: '#0f2440',
    marginBottom: 10.5,
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 32,
      marginBottom: 4,
      paddingTop: 0,
      textAlign: 'center'
    }
  }),
  noMobile: theme => ({
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }),
  onMobile: theme => ({
    display: 'none',
    zIndex: 999,
    [theme.breakpoints.down('md')]: {
      display: 'inline'
    }
  }),
  featuredPostContainer: theme => ({
    padding: 21,
    marginLeft: -21,
    marginRight: -21,
    backgroundColor: '#f3ebfa',
    borderRadius: 6,
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      padding: 6,
      paddingTop: 14,
      marginLeft: -6,
      marginRight: -6
    },
    [theme.breakpoints.down('sm')]: {
      padding: 6,
      paddingTop: 9,
      marginLeft: -6,
      marginRight: -6
    }
  }),
  FeaturedImage: theme => ({
    width: 374,
    height: 424,
    borderRadius: 5,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 356
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 256
    }
  }),
  PostImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  PostDescription: theme => ({
    borderBottomRightRadius: 100,
    backgroundColor: '#9846dd',
    padding: '112px 70px',
    [theme.breakpoints.down('md')]: {
      padding: '32px 16px'
    }
  }),
  PostCategory: theme => ({
    height: 38,
    width: 'auto',
    padding: 1,
    paddingLeft: 3,
    paddingRight: 3,
    minWidth: 108,
    borderRadius: 1.5,
    backgroundColor: '#a45de1',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 2.29,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      height: 32,
      padding: 1,
      fontSize: 12
    }
  }),
  TimeReading: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: '16px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    margin: 'auto 0px',
    '&::before': {
      backgroundColor: '#FFFFFF',
      content: '""',
      display: 'inline-block',
      height: '1px',
      position: 'relative',
      verticalAlign: 'middle',
      width: '21%',
      right: '1em',
      marginLeft: '-50%'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  }),
  FeaturedPostTitle: theme => ({
    fontFamily: 'Gilroy',
    fontSize: 48,
    fontWeight: 'bold',
    lineHeight: 1.33,
    color: '#0f2440',
    marginBottom: 6,
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: 6,
      fontSize: 36
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 9,
      fontSize: 20
    }
  }),
  PostSummary: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    // fontWeight: 500,
    lineHeight: 1.67,
    maxWidth: 547,
    color: '#8d9299',
    '& p': {
      color: '#8d9299'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      fontWeight: 500,
      opacity: 0.74
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      fontWeight: 500
    }
  }),
  FeaturedPostActions: theme => ({
    paddingTop: 14,
    [theme.breakpoints.down('md')]: {
      paddingTop: 8,
      justifyContent: 'center'
    }
  }),
  ReadMoreButton: theme => ({
    width: 156,
    height: 64,
    borderRadius: 8,
    backgroundColor: '#9846dd',
    color: '#ffffff',
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.8,
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      color: '#9846dd'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      width: 164,
      height: 56,
      textAlign: 'center'
    }
  })
};

export default styles;
