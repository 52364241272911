import { Grid, Box, Typography } from '@mui/material';
import { imageSrcSet } from '../../utils';
import CenteredGrid from '../shared/CenteredGrid';
import Img from '../shared/Img';
import styles from './styles/company';

const TextSideElement = ({
  title,
  text,
  paddingRight = 0,
  paddingBottom = 0,
  paddingLeft = 0,
  paddingTop = 0,
  right
}) => (
  <Box
    sx={[
      right ? styles.mandatoryMobileRight : styles.mandatoryMobile,
      { paddingRight, paddingBottom, paddingLeft, paddingTop }
    ]}
  >
    <Typography component="p" sx={styles.famSection}>
      {title}
    </Typography>
    <Typography component="p" sx={styles.famSectionText}>
      {text}
    </Typography>
  </Box>
);

const ImageKindElement = ({
  src,
  srcSet,
  alt,
  justifyContent,
  paddingRight = 0,
  paddingBottom = 0,
  paddingLeft = 0,
  paddingTop = 0
}) => (
  <Grid
    container
    justifyContent={justifyContent}
    sx={[styles.mandatoryMobile2, { paddingRight, paddingBottom, paddingLeft, paddingTop }]}
  >
    <Img src={src} srcSet={srcSet} alt={alt} sx={styles.imageSwagUpPerks} />
  </Grid>
);

const SwagFamPerks = () => (
  <CenteredGrid container justifyContent="center" sx={styles.center}>
    <Grid item>
      <Typography component="h2" sx={styles.swagFamPerksTitle}>
        Being part of the Swag Fam has it’s perks
      </Typography>
    </Grid>
    <Grid container>
      <Grid item xs={6}>
        <TextSideElement
          title="Partner with the best clients"
          text="Not only are our clients the top companies in the world, they’re also fun to work with."
          paddingTop={39}
        />
      </Grid>
      <Grid item xs={6}>
        <ImageKindElement
          src="/imgs/company/swagup-team-people.png"
          srcSet={imageSrcSet('/imgs/company/swagup-team-people.png')}
          alt="SwagUp Teamp people"
          justifyContent="flex-end"
          paddingBottom={6}
          paddingTop={14}
        />
        <ImageKindElement
          src="/imgs/company/swagup-team-inside.png"
          srcSet={imageSrcSet('/imgs/company/swagup-team-inside.png')}
          alt="SwagUp Teamp inside"
          justifyContent="center"
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={6}>
            <ImageKindElement
              src="/imgs/company/swagup-at-warehouse.png"
              srcSet={imageSrcSet('/imgs/company/swagup-at-warehouse.png')}
              alt="SwagUp at warehouse"
              justifyContent="flex-end"
              paddingBottom={6}
              paddingTop={14}
            />
          </Grid>
          <Grid item xs={6}>
            <ImageKindElement
              src="/imgs/company/swag-team-bond.png"
              srcSet={imageSrcSet('/imgs/company/swag-team-bond.png')}
              alt="SwagUp Teamp bond"
              justifyContent="center"
              paddingTop={48}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <TextSideElement
          title="Rapid Growth"
          text="0 to 1000 clients and 40 employees in under 3 years."
          paddingTop={56}
          paddingLeft={29}
          right
        />
      </Grid>
      <Grid item xs={6}>
        <TextSideElement title="Catered Breakfast and Lunches" text="Chef Jorge always kills it." paddingTop={66} />
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={6}>
            <ImageKindElement
              src="/imgs/company/swagup-team-lunch.png"
              srcSet={imageSrcSet('/imgs/company/swagup-team-lunch.png')}
              alt="SwagUp Team Lunch"
              justifyContent="flex-end"
              paddingBottom={6}
              paddingTop={33}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <ImageKindElement
                  src="/imgs/company/swagup-hanging-out.png"
                  srcSet={imageSrcSet('/imgs/company/swagup-hanging-out.png')}
                  alt="SwagUp Team hanging out"
                  justifyContent="center"
                  paddingTop={16}
                />
              </Grid>
              <Grid item xs={12}>
                <ImageKindElement
                  src="/imgs/company/swagup-snack.png"
                  srcSet={imageSrcSet('/imgs/company/swagup-snack.png')}
                  alt="SwagUp Snack"
                  justifyContent="center"
                  paddingTop={5}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <ImageKindElement
          src="/imgs/company/swagup-team-outings.png"
          srcSet={imageSrcSet('/imgs/company/swagup-team-outings.png')}
          alt="SwagUp Team Outings"
          styles={styles}
          justifyContent="flex-start"
          paddingTop={29}
        />
        <ImageKindElement
          src="/imgs/company/team-meetings.png"
          srcSet={imageSrcSet('/imgs/company/team-meetings.png')}
          alt="Team meetings"
          styles={styles}
          justifyContent="flex-start"
          paddingTop={5}
        />
      </Grid>
      <Grid item xs={6}>
        <TextSideElement
          styles={styles}
          title="Team Outings and Events"
          text="All work and no play is not our style, YOLO."
          paddingTop={73.5}
          paddingLeft={29}
          right
        />
      </Grid>
      <Grid item xs={6}>
        <TextSideElement
          styles={styles}
          title="Dog-Friendly Office near NYC"
          text="From our office to midtown in 10 minutes."
          paddingTop={64}
          paddingLeft={0}
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={6}>
            <ImageKindElement
              src="/imgs/company/swagup-offices-looks.png"
              srcSet={imageSrcSet('/imgs/company/swagup-offices-looks.png')}
              alt="Swagup Offices Looks"
              styles={styles}
              justifyContent="flex-end"
              paddingBottom={6}
              paddingTop={31}
            />
          </Grid>
          <Grid item xs={6}>
            <ImageKindElement
              src="/imgs/company/new-jersey-based.png"
              srcSet={imageSrcSet('/imgs/company/new-jersey-based.png')}
              alt="New Jersey Based"
              styles={styles}
              justifyContent="center"
              paddingTop={60.5}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item>
            <ImageKindElement
              src="/imgs/company/swagup-vacation-benefits.png"
              srcSet={imageSrcSet('/imgs/company/swagup-vacation-benefits.png')}
              alt="SwagUp Vacation Benefits"
              styles={styles}
              justifyContent="flex-end"
              paddingBottom={6}
              paddingTop={33}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <ImageKindElement
                  src="/imgs/company/swagup-offices-environment.png"
                  srcSet={imageSrcSet('/imgs/company/swagup-offices-environment.png')}
                  alt="SwagUp Office Enviroment"
                  styles={styles}
                  justifyContent="center"
                  paddingTop={46}
                />
              </Grid>
              <Grid item xs={12}>
                <ImageKindElement
                  src="/imgs/company/swagup-healthy-food.png"
                  srcSet={imageSrcSet('/imgs/company/swagup-healthy-food.png')}
                  alt="SwagUp healthy food"
                  styles={styles}
                  justifyContent="center"
                  paddingTop={5}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <TextSideElement
          styles={styles}
          title="Unlimited vacation and benefits"
          text="Get your work done on your terms."
          paddingTop={66}
          paddingLeft={29}
          right
        />
      </Grid>
      <Grid item xs={6}>
        <TextSideElement
          styles={styles}
          title="Family, team-oriented environment"
          text="Everyone helps everyone. Not your style? This may not be for you."
          paddingTop={64}
          paddingLeft={0}
          paddingBottom={31}
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={6}>
            <ImageKindElement
              src="/imgs/company/swagup-is-teamplay.png"
              srcSet={imageSrcSet('/imgs/company/swagup-is-teamplay.png')}
              alt="SwagUp is Teamplay"
              styles={styles}
              justifyContent="flex-end"
              paddingBottom={6}
              paddingTop={48}
            />
          </Grid>
          <Grid item xs={6}>
            <ImageKindElement
              src="/imgs/company/swuagup-team-fun.png"
              srcSet={imageSrcSet('/imgs/company/swuagup-team-fun.png')}
              alt="SwagUp Team is Fun"
              styles={styles}
              justifyContent="center"
              paddingTop={29}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </CenteredGrid>
);

export default SwagFamPerks;
