import * as React from 'react';
import _ from 'lodash';
import { Grid, Typography, MenuItem, useMediaQuery } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { SelectField, TextField, PhoneField } from './reactHookFormFields';
import { emailRequired, getSchema } from '../../common/commonValidations';
import { getDemoLink, getFirstLastName, oneHour } from '../../utils';
import { ellipsisStyles } from '../../common/styles';
import platformApiClient from '../../api/platformApiClient';

const formStyles = {
  formContainer: theme => ({
    maxWidth: 636,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    }
  }),
  fieldContainer: {
    paddingTop: 1,
    paddingBottom: 7
  },
  subjectContainer: {
    paddingBottom: 1
  },
  listItem: {
    ...ellipsisStyles,
    display: 'block'
  }
};

const ContactLabel = ({ text, containerStyle }) => (
  <Grid sx={containerStyle} item xs={12}>
    <Typography variant="h6SemiBold" sx={{ fontSize: 20, lineHeight: 1.6, color: '#0b1829' }} align="left">
      {text}
    </Typography>
  </Grid>
);

const ContactField = ({ xs, sm, error, errorMessage, styleContainer, register, ...props }) => (
  <Grid item xs={xs} sm={sm} sx={{ ...styleContainer, paddingBottom: 1.25 }}>
    <TextField fullWidth error={_.capitalize(errorMessage?.message) || undefined} {...register} {...props} />
  </Grid>
);

const ContactForm = ({
  variant = 'default',
  formName = 'RegularContactForm',
  onlyPartnershipSubjects = false,
  defaultSubject = '',
  disableSubjects,
  onSubmitSuccess,
  SuccessComponent
}) => {
  const subjectsQuery = useQuery(
    ['subjects', onlyPartnershipSubjects],
    () => platformApiClient.fetchSubjects(onlyPartnershipSubjects),
    { enabled: !disableSubjects, cacheTime: oneHour, staleTime: oneHour }
  );
  const subjects = subjectsQuery.data?.data?.results || [];

  const isDefaultForm = variant === 'default';

  const fields = isDefaultForm ? ['first_name', 'last_name', 'subject', 'message'] : ['name', 'subject', 'message'];
  const schema = getSchema(fields, { email: emailRequired });
  const { handleSubmit, register, control, formState, reset } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { subject: defaultSubject }
  });

  const { isValid, errors } = formState;
  const isXs = useMediaQuery(theme => theme.breakpoints.only('xs'));

  const submitMutation = useMutation(data => platformApiClient.sendMail(data, formName), {
    onSuccess: (resp, data) => {
      const isDemoRequest = data.subject === 'I want to book a demo';
      if (isDemoRequest) {
        window.open(getDemoLink(), '_blank');
      }
      if (onSubmitSuccess) onSubmitSuccess();
    }
  });

  const handleOnSubmit = async data => {
    if (isDefaultForm) {
      submitMutation.mutate({ ...data });
    } else {
      const [first_name, last_name] = getFirstLastName(data.name);
      const cleanData = { ...data, name: undefined };
      submitMutation.mutate({ ...cleanData, first_name, last_name });
    }
  };

  if (SuccessComponent && submitMutation.isSuccess) {
    const Component = typeof SuccessComponent === 'function' ? <SuccessComponent /> : SuccessComponent;
    return React.cloneElement(Component, {
      resetForm: () => {
        reset();
        submitMutation.reset();
      }
    });
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <Grid sx={formStyles.formContainer} container alignItems="flex-start" justifyContent="center">
        {isDefaultForm && (
          <ContactLabel text="Your information" containerStyle={{ paddingBottom: 3.5, paddingTop: 1.5 }} />
        )}
        {isDefaultForm ? (
          <>
            <ContactField
              xs={12}
              sm={6}
              placeholder="First name"
              register={register('first_name', { deps: ['last_name'] })}
              errorMessage={errors.first_name}
              styleContainer={{ paddingRight: isXs ? 0 : 3 }}
            />
            <ContactField
              xs={12}
              sm={6}
              placeholder="Last name"
              register={register('last_name', { deps: ['first_name'] })}
              errorMessage={errors.last_name}
              styleContainer={{ paddingLeft: isXs ? 0 : 3 }}
            />
          </>
        ) : (
          <ContactField xs={12} placeholder="Your name" register={register('name')} errorMessage={errors.name} />
        )}

        <ContactField xs={12} placeholder="Company name" register={register('company')} />
        <ContactField xs={12} placeholder="Email address" errorMessage={errors.email} register={register('email')} />
        {isDefaultForm && (
          <Grid sx={formStyles.fieldContainer} item xs={12}>
            <PhoneField
              control={control}
              name="phone_number"
              placeholder="Your phone number"
              variant="outlined"
              defaultValue=""
            />
          </Grid>
        )}
        {isDefaultForm && <ContactLabel text="Your message" containerStyle={{ paddingBottom: 4.5, paddingTop: 0.5 }} />}
        {!disableSubjects && (
          <Grid sx={formStyles.subjectContainer} item xs={12}>
            <SelectField
              name="subject"
              control={control}
              label="Select why you are emailing us"
              defaultValue={defaultSubject}
              fullWidth
              error={errors.subject?.message}
            >
              {subjects?.map(({ subject }) => (
                <MenuItem key={subject} value={subject} title={subject} sx={formStyles.listItem}>
                  {subject}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
        )}
        <ContactField
          xs={12}
          multiline
          rows={6}
          placeholder="Leave a message here"
          register={register('message')}
          errorMessage={errors.message}
        />
        <Grid
          item
          sx={{
            paddingTop: variant === 'default' ? 12 : 0
          }}
        >
          <Button type="submit" variant="primary" disabled={submitMutation.isLoading || !isValid}>
            {submitMutation.isLoading ? 'Sending' : ' Send Us a Message'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactForm;
