import { useState, useEffect, useCallback } from 'react';
import log from '../common/logger';

const useApiCall = initialApiFunc => {
  const [apiFunction, setApiFunction] = useState(initialApiFunc ? () => initialApiFunc : null);
  const [isApiCallPending, setIsApiCallPending] = useState(false);
  const [isApiError, setIsApiError] = useState(false);
  const [response, setResponse] = useState(undefined);

  const callApiFunc = useCallback(
    async params => {
      log.debug('apiCall enter, apiFunction:', apiFunction);
      if (apiFunction) {
        setIsApiError(false);
        setIsApiCallPending(true);
        try {
          const resp = await apiFunction(params);
          (resp.result === 'error' ? log.error : log.debug)('apiFunction response:', resp);
          setResponse(resp);
          if (resp.result === 'error') setIsApiError(true);
        } catch (error) {
          log.error('apiFunction error:', error);
          setIsApiError(true);
        }
        setIsApiCallPending(false);
      }
      log.debug('apiCall, exit');
    },
    [apiFunction]
  );

  useEffect(() => {
    callApiFunc();
  }, [callApiFunc]);

  const setApiFunc = useCallback(func => setApiFunction(() => func), []);

  return [{ response, isApiCallPending, isApiError }, setApiFunc, callApiFunc];
};

export default useApiCall;
