import { LoginCallback } from '@okta/okta-react';
import HomePage from './HomePage';
import FAQ from './FAQ';
import Services from './Services';
import Company from './Company';
import Pricing from './Pricing';
import Partner from './Partner';
import Blog from './Blog/Blog';
import BlogPost from './Blog/BlogPost';
import NotFoundPage from './Error/NotFoundPage';
import Landing from './Landing';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import ReturnRefundPolicy from './ReturnRefundPolicy';
import Gallery from './Gallery';

export const routes = [
  { path: '/privacy-policy', Component: PrivacyPolicy },
  { path: '/terms-of-use', Component: TermsOfUse },
  { path: '/return-refund-policy', Component: ReturnRefundPolicy },
  { path: '/gallery', Component: Gallery },
  { path: '/pricing', Component: Pricing },
  { path: '/partners/:slug?', Component: Partner },
  { path: '/faq', Component: FAQ },
  { path: '/services', Component: Services },
  { path: '/company', Component: Company },
  { path: '/blog/:slug', Component: BlogPost },
  { path: '/blog', Component: Blog, exact: true },
  { path: '/landing/:slug', Component: Landing },
  { path: '/implicit/callback', Component: LoginCallback },
  { path: '/', Component: HomePage, exact: true },
  { path: '/404', Component: NotFoundPage, status: 404 },
  // Handles 404s w/o redirect. This is the last route in the list.
  { path: '*', Component: NotFoundPage, status: 404 }
];

export const redirects = [
  { from: '/landing/beondeck', to: '/partners/beondeck' },
  { from: '/landing/first-republic-bank-partnership', to: '/partners/first-republic-bank' },
  { from: '/landing/swag-for-hopin-events-made-simple', to: '/partners/hopin' }
];
