import * as React from 'react';
import { Drawer, useDrawerContext } from '@swagup-com/react-ds-components';
import { IconButton, Grid } from '@mui/material';
import Menu from '@mui/icons-material/Menu';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuth } from '../Authentication/index';
import { ListMenu } from './Menu';
import { productMenuItemList, companyMenuItemList, packsMenuItemList, platformMenuItemList } from './common';
import { getFieldFromCookie } from '../../utils';
import Questions from './Questions';
import { AccountProfile } from './SessionHandler';

const createListMenuItem = (title, subItems, onClick, path, absolute) => ({
  title,
  subItems,
  onClick,
  path,
  absolute
});

const DrawerContent = ({ menuItems }) => {
  const { login, logout, isPending, isAuthenticated } = useAuth();

  const [text, clickHandler] = isAuthenticated ? ['Sign out', logout] : ['Login / Sign Up', login];

  return (
    <Grid container direction="column" spacing={4} sx={{ color: 'white' }}>
      <Grid item>
        <ListMenu
          menuItems={menuItems}
          bottomItem={isPending ? undefined : createListMenuItem(text, [], () => clickHandler())}
          bottomItemSeparation={150}
        />
      </Grid>
    </Grid>
  );
};

const RegularSection = () => {
  const { isPending, isAuthenticated } = useAuth();
  const closeDrawer = useDrawerContext();

  const onClick = () => closeDrawer();

  const generateSubItems = menuItemList => menuItemList.map(item => ({ ...item, description: '', onClick }));

  const isPostalReferral = getFieldFromCookie('affiliate_id') === 'postal.io';

  const regularMenu = [
    createListMenuItem('Home', undefined, onClick, '/', true),
    createListMenuItem('Product', generateSubItems(isPostalReferral ? packsMenuItemList : productMenuItemList)),
    createListMenuItem('Platform', generateSubItems(platformMenuItemList)),
    createListMenuItem('Company', generateSubItems(companyMenuItemList)),
    createListMenuItem('Pricing', undefined, onClick, '/pricing', true),
    ...(!isPending && isAuthenticated ? [createListMenuItem('Dashboard', undefined, onClick, '/dashboard')] : [])
  ];

  return <DrawerContent menuItems={regularMenu} />;
};

const MobileHeaderContent = () => {
  const { isPending } = useAuth();

  if (isPending) return null;

  return <RegularSection />;
};

// * We only comment notifications until we update this functionality,spread getNotificationComponent on props
const MobileHeader = () => {
  const [open, setOpen] = React.useState(false);
  const { isAuthenticated } = useAuth();
  const { headerFeMultiAccountControlTemp101421: showMultiAccount } = useFlags();

  return (
    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
      {isAuthenticated && showMultiAccount && (
        <IconButton>
          <AccountProfile />
        </IconButton>
      )}
      <IconButton color="inherit" onClick={() => setOpen(true)}>
        <Menu />
      </IconButton>
      <Drawer
        primary
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { display: 'grid' } }}
      >
        <MobileHeaderContent />
      </Drawer>
      <IconButton sx={{ paddingTop: 3 }} color="inherit">
        <Questions />
      </IconButton>
    </div>
  );
};

export default MobileHeader;
