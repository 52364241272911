const getStyles = ({ buttonWidth, centerElementsOnXS, background, changeBackground }) => ({
  // Left component
  leftComponent: theme => ({
    [theme.breakpoints.down('lg')]: {
      padding: '20px 20px 20px 20px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: centerElementsOnXS ? '60px 15px' : '80px 15px 10px '
    }
  }),
  promotionTitle: theme => ({
    color: '#0b1829',
    lineHeight: '1.17 !important',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('1030')]: {
      fontSize: 40,
      marginBottom: 2.5
    },
    [theme.breakpoints.down('md')]: { fontSize: 34, marginBottom: 5.5 },
    [theme.breakpoints.down('sm')]: {
      textAlign: centerElementsOnXS ? 'center' : 'left',
      whiteSpace: centerElementsOnXS ? 'normal' : 'pre-wrap'
    }
  }),
  titleSecondary: { color: '#ffffff' },
  promotionSubtitle: theme => ({
    color: '#868a8f',
    fontSize: 20,
    marginTop: 6,
    lineHeight: 1.6,
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('1030')]: {
      fontSize: 16
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: 3
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 9,
      textAlign: centerElementsOnXS ? 'center' : 'left',
      whiteSpace: centerElementsOnXS ? 'normal' : 'pre-wrap'
    }
  }),
  subtitleSecondary: { color: '#f2f6fd' },
  buttonContainer: theme => ({
    paddingTop: 14,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      paddingBottom: 0,
      textAlign: 'center'
    }
  }),
  buttonContainerFix: theme => ({
    [theme.breakpoints.down('sm')]: {
      justifyContent: centerElementsOnXS ? 'center' : 'flex-start'
    }
  }),
  inverseButton: theme => ({
    width: '208px !important',
    fontSize: '16px !important',
    paddingTop: '22px !important',
    paddingBottom: '22px !important',
    textTransform: 'none !important',
    fontFamily: 'Gilroy-Bold !important',
    borderRadius: '56px !important',
    border: 'none',
    '& div': {
      textTransform: 'none !important',
      fontSize: '16px !important'
    },
    [theme.breakpoints.down('md')]: {
      width: '186px !important',
      fontSize: '12px !important',
      paddingTop: '12px !important',
      paddingBottom: '12px !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
      height: 48,
      width: buttonWidth ? `${buttonWidth}px !important` : 'auto',
      lineHeight: 0.86,
      paddingLeft: 6,
      paddingRight: 6
    }
  }),

  // Main component
  homeWrapper: theme => ({
    marginTop: 3,
    marginBottom: 0,
    paddingLeft: 5.5,
    paddingRight: 5.5,
    lineHeight: 1.5,
    minHeight: 565,
    background: background ? `url('${background}')` : 'unset',
    backgroundSize: background ? '100% 100% !important' : 'unset',
    backgroundColor: '#f2f6fd',
    borderRadius: 6.25,
    [theme.breakpoints.down('lg')]: { minHeight: 'auto' },
    [theme.breakpoints.down('md')]: { marginTop: 22.5 },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 5,
      paddingLeft: 3,
      paddingRight: 3,
      background: changeBackground && 'unset',
      backgroundSize: changeBackground && 'unset',
      backgroundColor: changeBackground ? '#3577d4' : '#f2f6fd',
      borderRadius: background ? 4 : 'unset'
    }
  }),
  containerImg: theme => ({
    display: 'flex',
    flexGrow: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: { width: '80%', position: 'inherit !important', padding: '20px 0' }
  }),
  img: theme => ({
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      width: '105%'
    }
  })
});

export default getStyles;
