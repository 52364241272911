import { Box, Link, Grid, Typography, Button, useMediaQuery } from '@mui/material';
import getStyles from './styles/contactSection';
import Tag from './Tag';
import Img from './Img';
import ContactForm from './ContactForm';

const Hidden = ({ hide, children }) => (hide ? null : children);

const InfoItem = ({ label, value, linkTo, imagePath, description }) => {
  const styles = getStyles();
  return (
    <Grid container>
      <Grid item container xs={12} alignItems="center">
        <Grid item sx={styles.imageContainer}>
          <img src={imagePath} alt={label} />
        </Grid>
        <Grid item>
          <Typography variant="inherit" sx={styles.label}>
            {label}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Link sx={styles.value} href={linkTo}>
          {value}
        </Link>
      </Grid>
      <Grid item>
        <Typography variant="inherit" sx={styles.description}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

const callContact = (
  <InfoItem
    label="Call Us"
    value="646-494-0520"
    description="Mon-Fri, 9am-7pm. ET"
    linkTo="tel:6464940520"
    imagePath="/imgs/landingPagesSSR/phoneGray.svg"
  />
);

const emailContact = (
  <InfoItem
    label="Send Email"
    value="sales@swagup.com"
    description="Typically replies within 2 hours"
    linkTo="mailto:sales@swagup.com"
    imagePath="/imgs/landingPagesSSR/emailGray.svg"
  />
);

const ContactInfo = ({ fullWidth }) => {
  const styles = getStyles();
  const isMd = useMediaQuery(theme => theme.breakpoints.down('lg'));
  return (
    <Grid sx={styles.sectionLeft} item container sm={12} md={fullWidth ? 6 : 12} lg={6} alignItems="flex-start">
      <Grid sm={12} item>
        <Tag label="Contact Us" color="secondary" />
        <Typography variant="inherit" sx={styles.title}>
          Have Questions? <br />
          Don&apos;t Be Shy
        </Typography>
        <Hidden hide={isMd}>
          <Box sx={{ paddingBottom: 14 }}>{callContact}</Box>
          {emailContact}
        </Hidden>
      </Grid>
    </Grid>
  );
};

const Confirmation = ({ resetForm }) => {
  const styles = getStyles();
  return (
    <Box sx={styles.confirmationContainer}>
      <Typography variant="inherit" sx={styles.message}>
        Your message has been sent!
      </Typography>
      <Button sx={styles.responsiveButton} variant="outlined" color="secondary" onClick={resetForm}>
        Submit another message
      </Button>
      <Img src="/imgs/landingPagesSSR/tick.png" alt="tick" />
    </Box>
  );
};

const FormSection = ({ gallery }) => {
  const styles = getStyles();

  return (
    <Grid sx={styles.sectionRight} item sm={12} md={gallery ? 6 : 12} lg={6}>
      <Box sx={styles.sectionContainer}>
        <ContactForm variant="simple" SuccessComponent={Confirmation} />
        <Hidden xsDown>
          <Img
            style={{ position: 'absolute', left: -30, bottom: 10 }}
            src="/imgs/landingPagesSSR/blue-ball.png"
            alt="blueSphere"
          />
        </Hidden>
      </Box>
    </Grid>
  );
};

const ContactSection = ({ gallery }) => {
  const styles = getStyles({ gallery });

  const isMd = useMediaQuery(theme => theme.breakpoints.down('lg'));

  return (
    <Grid sx={styles.separator} container justifyContent="center">
      <Grid item container sx={styles.contactContainer} sm={12}>
        <ContactInfo fullWidth={gallery} />
        <FormSection gallery={gallery} />
        <Hidden hide={!isMd}>
          <Grid sx={styles.contacts} container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs>
              {callContact}
            </Grid>
            <Grid item xs>
              {emailContact}
            </Grid>
          </Grid>
        </Hidden>
        <Hidden hide={isMd}>
          <Img
            sx={{ position: 'absolute', left: -35, top: 0 }}
            src="/imgs/landingPagesSSR/ring-blue.png"
            alt="blueRing"
          />
          <Img
            sx={{ position: 'absolute', right: -50, bottom: 10 }}
            src="/imgs/landingPagesSSR/purple-curly.png"
            alt="purpleCurly"
          />
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default ContactSection;
