const styles = {
  containerBuildYB: theme => ({
    marginTop: 17.5,
    marginBottom: 40,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: 10,
      paddingBottom: 2.5
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('1030')]: {
      width: 960
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  sectionContainerOS: theme => ({
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 44,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0
    }
  }),
  promotionTitleBYB: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    textAlign: 'center',
    marginTop: 6,
    marginBottom: 14,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      marginBottom: 6.5
    }
  }),
  containerAllBYBS: theme => ({
    paddingLeft: 15.5,
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }),
  containerBoxBYBShopify: theme => ({
    marginTop: 10,
    '& td': {
      verticalAlign: 'top',
      padding: '0px 60px 0px 0px',
      borderBottom: 0,
      width: '33%',
      '& img': {
        marginLeft: 0,
        marginBottom: 10
      }
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '10px !important',
      '& td': {
        verticalAlign: 'top',
        padding: '0px 20px 0px 45px',
        borderBottom: 0,
        width: '33%',
        '& img': {
          marginLeft: 0,
          marginBottom: '10px !important'
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px !important',
      '& td': {
        verticalAlign: 'top',
        padding: '0px 10px 0px 20px',
        borderBottom: 0,
        width: '33%',
        '& img': {
          marginLeft: 0,
          marginBottom: '10px !important'
        }
      }
    }
  }),
  imgRocketBYBShopify: theme => ({
    width: 72,
    height: 72,
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      height: 30,
      marginLeft: 11
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5
    }
  }),
  sectionTitleST: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 23,
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 6,
    lineHeight: 1.5,
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('1030')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
      marginBottom: 2
    }
  }),
  sectionSubTitleST: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    lineHeight: 1.63,
    opacity: 0.54,
    marginBottom: 4,
    [theme.breakpoints.down('lg')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
      marginBottom: 2
    }
  })
};

export default styles;
