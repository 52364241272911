const styles = {
  container: theme => ({
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 12
    }
  }),
  center: theme => ({
    [theme.breakpoints.only('md')]: {
      width: 1024,
      paddingLeft: 6,
      paddingRight: 6
    },
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
      paddingLeft: 0,
      paddingRight: 0
    }
  }),
  landingFrame: theme => ({
    padding: '47px 0px',
    paddingBottom: 14,
    minHeight: 1024,
    marginBottom: 23,
    [theme.breakpoints.down('md')]: {
      padding: '56px 0px',
      minHeight: '824px',
      marginBottom: 56,
      overflowY: 'hidden',
      overflowX: 'hidden'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px 0px',
      paddingBottom: 6,
      marginBottom: 8,
      minHeight: '650px',
      overflowY: 'hidden',
      overflowX: 'hidden'
    }
  }),
  noOutline: {
    '& div': {
      outline: 'none'
    },
    '& .menu-wrapper--inner': {
      display: 'table-row !important'
    },
    '& .menu-item-wrapper': {
      display: 'table-cell !important'
    }
  },
  freshSwagText: theme => ({
    color: '#0f2440',
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  }),
  bottomImagesContainer: theme => ({
    marginTop: 3,
    [theme.breakpoints.down('md')]: {
      padding: '0 8px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px'
    }
  }),
  landingBottomFrame: theme => ({
    marginTop: 14,
    [theme.breakpoints.down('md')]: {
      marginTop: 8
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  }),
  mainText: theme => ({
    textAlign: 'center',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 42,
      lineHeight: '32px'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      fontSize: 24,
      lineHeight: '32px'
    }
  }),
  descriptionText: theme => ({
    textAlign: 'center',
    paddingTop: 6,
    marginBottom: 12,
    marginTop: 0,
    maxWidth: 722,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      maxWidth: 250,
      marginBottom: 8,
      fontSize: 18
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      maxWidth: 250,
      marginBottom: 4,
      fontSize: 14
    }
  }),
  packItemButton: theme => ({
    border: 0,
    borderRadius: 7,
    backgroundColor: '#9846dd',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 18,
    letterSpacing: 'normal',
    marginTop: 2,
    [theme.breakpoints.down('md')]: {
      marginTop: 4,
      height: '56px !important',
      fontSize: '12px !important',
      paddingTop: '8px !important',
      paddingBottom: '8px !important'
    },
    '&:hover': {
      backgroundColor: '#9846dd',
      color: 'white'
    }
  }),
  mainLandingButton: theme => ({
    marginBottom: 6,
    fontSize: 18,
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: 4,
      width: '224px !important',
      fontSize: '16px !important',
      paddingTop: '16px !important',
      paddingBottom: '16px !important'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 2,
      width: '196px !important',
      fontSize: '12px !important',
      paddingTop: '12px !important',
      paddingBottom: '12px !important'
    }
  }),
  mainLandingSwagUpButton: theme => ({
    marginBottom: 6,
    width: 282,
    borderRadius: 8,
    [theme.breakpoints.down('md')]: {
      marginBottom: 4,
      marginTop: 0,
      height: '42px !important',
      width: '224px !important',
      fontSize: '16px !important'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 2,
      height: '31px !important',
      width: '196px !important',
      fontSize: '12px !important'
    }
  }),
  floatingCallUs: theme => ({
    position: 'fixed',
    left: '14vw',
    bottom: '5vh',
    width: 208,
    height: 72,
    borderRadius: 5,
    boxShadow: '0 20px 56px 0 rgba(27, 28, 31, 0.1)',
    backgroundColor: 'white',
    padding: '16px 22px',
    zIndex: 1201,
    '&:hover': {
      backgroundColor: '#fafafa'
    },
    [theme.breakpoints.down('md')]: {
      left: '5vw',
      bottom: '5vh',
      width: 176,
      height: 52,
      borderRadius: 4,
      padding: '8px 16px'
    },
    [theme.breakpoints.down('sm')]: {
      left: '5vw',
      bottom: '5vh',
      width: 156,
      height: 46,
      borderRadius: 2.5,
      padding: '8px 12px'
    }
  }),
  callUsSmall: theme => ({
    textAlign: 'left',
    margin: 0,
    marginBottom: 2.5,
    [theme.breakpoints.down('md')]: {
      marginBottom: 1.5,
      fontSize: 12
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 1,
      fontSize: 10
    }
  }),
  phoneText: theme => ({
    lineHeight: 0.88,
    color: theme.palette.primary['500'],
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  }),
  fabPhone: theme => ({
    width: 40,
    height: 40,
    borderRadius: 5,
    marginRight: 3,
    boxShadow: '-3px 3px 12px 0 rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
      width: 32,
      height: 32,
      borderRadius: 4,
      marginRight: 2
    },
    [theme.breakpoints.down('sm')]: {
      width: 28,
      height: 28,
      borderRadius: 3.5,
      marginRight: 2
    }
  }),
  titleContainer: theme => ({
    height: 86,
    [theme.breakpoints.down('md')]: {
      height: 56
    },
    [theme.breakpoints.down('md')]: {
      height: 42
    }
  }),
  forcedFirst: {
    maxWidth: '100%',
    height: 32,
    objectFit: 'contain',
    paddingLeft: '24px !important',
    paddingRight: '16px !important'
  },
  forcedPaddingLeft: {
    maxWidth: '100%',
    height: 32,
    objectFit: 'contain',
    paddingLeft: 16
  },
  forcedHeight: {
    maxWidth: '100%',
    height: 28,
    objectFit: 'contain'
  },
  forcedMarginLeft: {
    maxWidth: '100%',
    height: 32,
    objectFit: 'contain',
    marginLeft: -32
  },
  shirtImgContainer: theme => ({
    width: 400,
    marginLeft: -11,
    marginTop: -4,
    paddingRight: 9,
    [theme.breakpoints.down('md')]: {
      width: 224,
      marginLeft: -16,
      marginTop: 0,
      paddingRight: 0,
      paddingTop: 0
    },
    [theme.breakpoints.down('sm')]: {
      width: 150,
      marginLeft: -16,
      marginTop: 0,
      paddingRight: 0,
      paddingTop: 0
    }
  }),
  bagImgContainer: theme => ({
    width: 150,
    [theme.breakpoints.between('sm', 'md')]: {
      width: 124
    },
    [theme.breakpoints.down('md')]: {
      width: 100
    },
    [theme.breakpoints.down('sm')]: {
      width: 56
    }
  }),
  cupImgContainer: theme => ({
    width: 150,
    paddingTop: 3.75,
    [theme.breakpoints.between('sm', 'md')]: {
      width: 124,
      paddingTop: 2
    },
    [theme.breakpoints.down('md')]: {
      width: 100,
      paddingTop: 2
    },
    [theme.breakpoints.down('sm')]: {
      width: 56,
      paddingTop: 2
    }
  }),

  toteImgContainer: theme => ({
    marginTop: 10.5,
    textAlign: 'right',
    marginLeft: 14,
    width: 256,
    [theme.breakpoints.down('lg')]: {
      width: 212,
      paddingRight: 0,
      paddingLeft: 3,
      marginRight: -6,
      paddingBottom: 8,
      marginTop: 0,
      paddingTop: 0
    },
    [theme.breakpoints.down('md')]: {
      width: 192,
      paddingRight: 0,
      paddingLeft: 3,
      marginRight: -6,
      paddingBottom: 8,
      marginTop: 0,
      paddingTop: 0
    },
    [theme.breakpoints.down('sm')]: {
      width: 124,
      paddingRight: 0,
      paddingLeft: 0,
      marginRight: -10.5,
      paddingBottom: 8,
      marginLeft: 4,
      marginTop: 0,
      paddingTop: 0
    }
  }),
  boxImgContainer: theme => ({
    marginTop: -6.5,
    width: 360,
    paddingBottom: 2,
    marginLeft: -4,
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: -8,
      width: 292,
      paddingBottom: 8,
      marginLeft: -10
    },
    [theme.breakpoints.down('md')]: {
      marginTop: -8,
      width: 238,
      paddingBottom: 8,
      marginLeft: 5
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: -1,
      width: 142,
      paddingBottom: 8,
      marginLeft: -3.5
    }
  }),
  noMobile: theme => ({
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  }),
  onMobile: theme => ({
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inherit'
    }
  }),
  notebookImg: theme => ({
    marginTop: 0,
    width: 116,
    marginRight: -4,
    paddingBottom: 6,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      marginTop: -4,
      width: 92,
      marginRight: 16
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      marginTop: -4,
      width: 48,
      marginRight: 4,
      paddingBottom: 0
    }
  }),
  treeContainer: theme => ({
    paddingLeft: 4,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
      marginLeft: -6
    }
  }),
  waterbottleImg: theme => ({
    width: 74,
    marginLeft: -8,
    marginTop: 50,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      marginTop: 35.5,
      width: 56,
      marginLeft: 16
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      marginTop: 31,
      width: 30,
      marginLeft: 3
    }
  }),
  imgStandard: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  },
  imgSamplePack: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  },
  imgSamplePackHAH: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    boxShadow: '0 40px 50px 0 rgba(26, 58, 98, 0.05)',
    borderRadius: 5
  },
  imgStandardBYB: theme => ({
    height: 32,
    objectFit: 'contain',
    maxWidth: 200,
    [theme.breakpoints.down('xl')]: {
      height: 26,
      maxWidth: 150
    },
    [theme.breakpoints.down('md')]: {
      height: 20,
      maxWidth: 120
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      height: 16,
      maxWidth: 90
    }
  }),
  brandsContainer: theme => ({
    marginTop: 8,
    [theme.breakpoints.down('md')]: {
      marginTop: 4
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  }),
  spanOption: {
    color: '#3577d4'
  },
  slide: {
    display: 'inherit',
    color: '#3577d4'
  },
  hide: {
    display: 'none'
  },
  regularTextBYB: theme => ({
    textAlign: 'left',
    marginTop: 13.25,
    marginLeft: 10,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginTop: 6,
      marginLeft: 8,
      marginRight: 8
    }
  }),
  regularText: theme => ({
    color: '#868a8f',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    letterSpacing: 'normal',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  }),
  regularTextInc: theme => ({
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 13
    }
  }),
  promotionTitle: {
    textAlign: 'center',
    marginBottom: 6
  },
  promotionTitlePWU: theme => ({
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 6,
    [theme.breakpoints.down('md')]: {
      marginTop: 4,
      textAlign: 'center',
      marginBottom: 6
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
      textAlign: 'center',
      marginBottom: 6
    }
  }),
  promotionSubTextPWU: theme => ({
    marginBottom: 14,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  }),
  promoActual: theme => ({
    fontSize: 56,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      lineHeight: 1.5,
      textAlign: 'center'
    }
  }),
  promotionTitleYNTG: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 8,
    lineHeight: 1.29,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      lineHeight: '36px'
    }
  }),
  sectionTitleStandard: theme => ({
    textAlign: 'center',
    marginTop: 6,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: 2,
      marginBottom: 4
    }
  }),
  lgsTabTitle: theme => ({
    textAlign: 'left',
    marginBottom: 6,
    [theme.breakpoints.down('md')]: {
      marginBottom: 3,
      textAlign: 'center'
    }
  }),
  remoteSwagPostCard: theme => ({
    padding: '36px 44px',
    boxShadow: '0 12px 36px 0 rgba(0, 0, 0, 0.03)',
    backgroundColor: 'white',
    borderRadius: 6,
    height: '100%',
    border: 'solid 1px #ebeef2',
    [theme.breakpoints.down('md')]: {
      padding: '16px 24px'
    }
  }),
  remoteSwagPostCardText: theme => ({
    margin: 0,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      maxWidth: '100% !important'
    }
  }),
  remoteSwagPostCardIcon: theme => ({
    maxHeight: 44,
    objectFit: 'contain',
    marginBottom: 5,
    [theme.breakpoints.down('md')]: {
      marginBottom: 3
    }
  }),
  remoteSwagPostCardIconContainer: theme => ({
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  }),
  mobileSpecialTopRight: theme => ({
    [theme.breakpoints.down('md')]: {
      right: '1% !important',
      top: '0% !important'
    },
    [theme.breakpoints.down('sm')]: {
      right: '-7% !important',
      top: '0% !important'
    }
  }),
  mobileSpecialTopLeft: theme => ({
    [theme.breakpoints.down('md')]: {
      left: '1% !important',
      top: '0% !important'
    },
    [theme.breakpoints.down('sm')]: {
      top: '0% !important'
    }
  }),
  mobileSpecialBottomLeft: theme => ({
    [theme.breakpoints.down('md')]: {
      left: '1% !important',
      bottom: '16% !important'
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '16% !important'
    }
  }),
  mobileSpecialBottomRight: theme => ({
    [theme.breakpoints.down('md')]: {
      right: '1% !important'
    }
  }),
  remoteSwagCard: theme => ({
    padding: '23px 35px',
    backgroundColor: 'white',
    boxShadow: '0 36px 84px 0 rgba(0, 0, 0, 0.05)',
    borderRadius: 6,
    width: 'auto',
    position: 'absolute',
    border: 'solid 1px #ebeef2',
    [theme.breakpoints.down('md')]: {
      padding: '12px 18px',
      borderRadius: 4
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6px 10px',
      borderRadius: 3
    }
  }),
  remoteSwagBottomSection: theme => ({
    marginTop: -33,
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down('md')]: {
      marginTop: -16,
      paddingLeft: 6,
      paddingRight: 6
    }
  }),
  remoteSwagCardIcon: theme => ({
    height: 56,
    width: 56,
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      height: 24,
      width: 24
    },
    [theme.breakpoints.down('sm')]: {
      height: 18,
      width: 18
    }
  }),
  remoteSwagCardText: theme => ({
    fontFamily: 'Gilroy',
    fontWeight: 500,
    fontSize: 20,
    color: '#0f2440',
    lineHeight: 1.6,
    maxWidth: 216,
    marginLeft: 5,
    [theme.breakpoints.down('md')]: {
      maxWidth: '124px !important',
      fontSize: 14,
      marginLeft: 3.5
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '64px !important',
      fontSize: 6,
      marginLeft: 2
    }
  }),
  swagForRemoteCenter: {
    position: 'relative'
  },
  promotionTitleBYB: theme => ({
    textAlign: 'left',
    marginTop: 6,
    marginBottom: 23.75,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  }),
  sPImgRippling: theme => ({
    objectFit: 'cover',
    width: 916,
    [theme.breakpoints.down('lg')]: {
      width: '800px'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  swagForRemoteTitle: theme => ({
    textAlign: 'left',
    marginTop: 6,
    marginBottom: 4,
    maxWidth: 824,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  }),
  orText: theme => ({
    margin: '18px 0',
    textAlign: 'center',
    color: theme.palette.neutral['300'],
    [theme.breakpoints.down('md')]: {
      margin: '10px 0'
    }
  }),
  callUsText: theme => ({
    textAlign: 'center',
    color: '#3577d4',
    fontWeight: 'bold',
    fontFamily: 'Gilroy-SemiBold',
    textTransform: 'uppercase  !important',
    fontSize: 20,
    marginTop: 2,
    marginBottom: 2,
    [theme.breakpoints.down('md')]: {
      marginTop: 1,
      fontSize: 14
    }
  }),
  callUsPhone: theme => ({
    textAlign: 'center',
    color: '#3577d4',
    fontWeight: 'bold',
    fontFamily: 'Gilroy',
    textTransform: 'uppercase  !important',
    fontSize: 24,
    marginRight: 2,
    marginBottom: -1.5,
    [theme.breakpoints.down('md')]: {
      marginRight: 1,
      marginBottom: -1.25,
      fontSize: 14
    }
  }),
  swagForRemoteButton: theme => ({
    backgroundColor: '#3577d4 !important',
    width: 296,
    fontSize: 18,
    marginTop: 16,
    textTransform: 'none !important',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: 8,
      width: '224px !important',
      fontSize: '16px !important',
      paddingTop: '16px !important',
      paddingBottom: '16px !important'
    },
    [theme.breakpoints.down('sm')]: {
      width: '196px !important',
      fontSize: '12px !important',
      paddingTop: '12px !important',
      paddingBottom: '12px !important'
    }
  }),
  swagForRemoteSubTitle: theme => ({
    marginTop: 0,
    marginBottom: 29,
    textAlign: 'center',
    '& strong': {
      fontFamily: 'Gilroy',
      fontWeight: 600
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 0
    }
  }),
  bYBImg: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderBottomRightRadius: 15,
    borderTopLeftRadius: 15
  },
  containerBYB: theme => ({
    backgroundColor: ({ darkMode }) => (darkMode ? '#0F2440 !important' : 'inherit'),
    borderTopLeftRadius: 100,
    borderBottomRightRadius: 100,
    [theme.breakpoints.down('md')]: {
      borderTopLeftRadius: 60,
      borderBottomRightRadius: 60
    }
  }),
  menuSectionWrapper: theme => ({
    padding: '82px 85px 42px 64px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 12px'
    }
  }),
  wrapperBYBImg: theme => ({
    width: 448,
    height: '100%',
    maxHeight: 560,
    [theme.breakpoints.down('md')]: {
      width: 300,
      height: '100%',
      borderBottomRightRadius: 60,
      borderTopLeftRadius: 60
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 192,
      borderBottomRightRadius: 60,
      borderTopLeftRadius: 60
    }
  }),
  promotionText: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 40,
    fontWeight: 600,
    textAlign: 'center',
    color: '#0f2440',
    marginTop: 8,
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    }
  }),
  promotionSubText: {
    textAlign: 'center',
    marginBottom: 14
  },
  samplePackContainer: theme => ({
    paddingBottom: 39,
    paddingTop: 6,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
      paddingTop: 0
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 8,
      paddingTop: 0
    }
  }),
  promotionTextHEH: theme => ({
    textAlign: 'left',
    marginTop: -3,
    marginBottom: 4,
    [theme.breakpoints.down('md')]: {
      marginTop: 8,
      marginBottom: 8,
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 6,
      textAlign: 'center'
    }
  }),
  holydayHereButtonContainer: theme => ({
    paddingTop: 13,
    [theme.breakpoints.down('md')]: {
      paddingTop: 2,
      paddingBottom: 6,
      textAlign: 'center'
    }
  }),
  promotionHomeWrapperHAH: theme => ({
    marginTop: 23,
    paddingLeft: 23,
    paddingRight: 0,
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      marginTop: 14,
      paddingLeft: 14,
      paddingRight: 14
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  }),
  promotionHomeWrapperSP: theme => ({
    marginTop: 23,
    paddingLeft: 15,
    paddingRight: 15,
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      padding: 0
    }
  }),
  promotionHomeWrapper: theme => ({
    marginTop: 4,
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      paddingLeft: 0
    }
  }),
  ourServicesCenter: theme => ({
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '0px 56px !important'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 24px !important'
    }
  }),
  promotionHomeCenter: theme => ({
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '0px 56px !important'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 56px !important'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 24px !important'
    }
  }),
  imagePromoContainer: theme => ({
    [theme.breakpoints.down('md')]: {
      width: '205px !important'
    }
  }),
  promotionSubTextPH: theme => ({
    textAlign: 'center',
    color: theme.palette.neutral['500'],
    marginBottom: 10.5,
    [theme.breakpoints.down('md')]: {
      marginBottom: 0
    }
  }),
  promotionTitleHome: theme => ({
    textAlign: 'left',
    marginTop: 6,
    marginBottom: 18,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 6,
      paddingTop: 4,
      textAlign: 'center',
      marginBottom: 4
    }
  }),
  ourServicesContent: theme => ({
    paddingBottom: 15.5,
    paddingTop: 14,
    backgroundColor: '#e9f0fa',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 6,
      paddingTop: 8
    }
  }),
  sampleSubText: theme => ({
    maxWidth: 480,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    }
  }),
  samplePackButtonContainerSP: theme => ({
    marginTop: -18,
    [theme.breakpoints.down('md')]: {
      marginTop: -6
    }
  }),
  samplePackNotice: theme => ({
    color: theme.palette.primary['900'],
    marginTop: 8,
    opacity: 0.56,
    [theme.breakpoints.down('md')]: {
      marginTop: 4,
      textAlign: 'center'
    }
  }),
  samplePackButtonWrapper: theme => ({
    marginTop: 14,
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  }),
  homePromoMiddle: theme => ({
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      marginBottom: 8,
      lineHeight: 21
    }
  }),
  homePromoMiddleHAH: theme => ({
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: 8,
      textAlign: 'center'
    }
  }),
  downloadButtonHAH: theme => ({
    width: 264,
    fontSize: '16px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px !important'
    },
    [theme.breakpoints.down('sm')]: {
      height: 32,
      fontSize: '11px !important'
    }
  }),
  regularText500: {
    color: '#0f2440',
    fontFamily: 'Gilroy',
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 500,
    marginBottom: 4
  },
  samplePackWhiteCard: theme => ({
    borderRadius: '20px 100px 20px 20px',
    maxWidth: 480,
    height: 480,
    boxShadow: '0 40px 140px 0 rgba(26, 58, 98, 0.05)',
    backgroundColor: '#ffff',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: 240,
      height: 260
    }
  }),
  promotionContentNew: theme => ({
    borderRadius: '4px 4px 100px 4px',
    width: 470,
    boxShadow: '0 40px 140px 0 rgba(26, 58, 98, 0.05)',
    backgroundColor: '#ffff',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      margin: '0px auto'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }),
  imageAfterSawgUp: theme => ({
    maxWidth: 420,
    [theme.breakpoints.down('md')]: {
      width: 210
    }
  }),
  imageSamplePack: theme => ({
    maxWidth: 432,
    marginTop: -35,
    maxHeight: 320,
    [theme.breakpoints.down('md')]: {
      maxWidth: 216,
      maxHeight: 160,
      marginTop: -8
    }
  }),
  pWUButtonWrapper: theme => ({
    marginTop: 14,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      paddingLeft: '0px !important',
      marginTop: 6
    }
  }),
  RPContent: theme => ({
    width: 440,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '42px 42px'
    }
  }),
  beforeSwagUp: theme => ({
    backgroundColor: '#0f2440',
    padding: '2px 12px',
    borderRadius: 2.5,
    paddingTop: 1,
    marginTop: 10.5,
    '& p': {
      color: theme.palette.common.white,
      paddingBottom: '2px !important',
      margin: 0
    },
    '& h6': {
      fontSize: '16px !important',
      color: theme.palette.common.white,
      paddingBottom: '2px !important',
      margin: 0
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 8,
      padding: '0px 8px',
      '& p': {
        color: theme.palette.common.white,
        paddingBottom: '2px !important',
        marginBottom: 0
      }
    }
  }),
  afterSwagUp: theme => ({
    backgroundColor: '#3577d4',
    padding: '2px 12px',
    borderRadius: 2.5,
    marginTop: 10.5,
    '& p': {
      color: theme.palette.common.white,
      paddingBottom: '2px !important',
      margin: 0
    },
    '& h6': {
      color: theme.palette.common.white,
      paddingBottom: '2px !important',
      margin: 0
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 8,
      padding: '0px 8px',
      '& p': {
        color: theme.palette.common.white,
        paddingBottom: '2px !important',
        marginBottom: 0
      }
    }
  }),
  sectionContainerRP: theme => ({
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 3,
      paddingRight: 3
    }
  }),
  pWUButton: theme => ({
    textAlign: 'center',
    fontSize: '18px !important',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '196px !important',
      fontSize: '12px !important'
    }
  }),
  toteImgAnimationContainer: theme => ({
    backgroundColor: '#3577d4',
    borderRadius: 8,
    borderBottomRightRadius: 25,
    padding: '0 48px 0 48px',
    overflowY: 'hidden',
    overflowX: 'hidden',
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  }),
  sectionNamePWU: theme => ({
    color: '#0f2440',
    marginBottom: 4,
    marginTop: 4,
    [theme.breakpoints.down('md')]: {
      fontSize: 11,
      fontFamily: 'Gilroy-Light',
      fontWeight: 'normal'
    }
  }),
  pWUContainer: theme => ({
    paddingTop: 14,
    paddingBottom: 35,
    paddingRight: 14,
    paddingLeft: 32.5,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      paddingBottom: 8,
      paddingRight: 4,
      paddingLeft: 4
    }
  }),
  hoveredButton: {
    boxShadow: '0px 40px 140px 0px rgba(26, 58, 98, 0.22)'
  },
  menuSection: theme => ({
    textAlign: 'left',
    lineHeight: 1.5,
    '& li': {
      marginBottom: 10.5
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 18,
      '& li': {
        marginBottom: 8
      }
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      '& li': {
        marginBottom: 3
      }
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      '& li': {
        marginBottom: 2
      }
    }
  }),
  sectionContainer: theme => ({
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 6,
      paddingRight: 6
    }
  }),
  sectionContainerRemote: {
    backgroundColor: '#e9f0fa'
  },
  sectionContainerPWU: theme => ({
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      width: '80%',
      margin: '0px auto'
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      paddingLeft: 6,
      paddingRight: 6
    }
  }),
  partnerTextBYB: theme => ({
    color: theme.palette.primary['500'],
    textAlign: 'center',
    marginTop: 26,
    marginBottom: 4,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginTop: 10.5
    }
  }),
  sectionSubTitle: theme => ({
    textAlign: 'left',
    margin: 0,
    marginBottom: 4,
    [theme.breakpoints.down('md')]: {
      marginBottom: 2,
      textAlign: 'left'
    }
  }),
  imageContainer: {
    width: 22,
    marginLeft: 2,
    position: 'relative',
    top: 4
  },
  responsiveButton: theme => ({
    [theme.breakpoints.down('sm')]: {
      height: 32,
      fontSize: 14
    }
  }),
  marg50: {
    textAlign: 'left',
    marginBottom: 36
  },
  containerRequestPack: theme => ({
    paddingTop: 25,
    paddingBottom: 25,
    [theme.breakpoints.down('md')]: {
      paddingTop: 10.5,
      paddingBottom: 10.5
    }
  }),
  imageRequestPack: theme => ({
    width: 456,
    height: 320,
    [theme.breakpoints.down('md')]: {
      width: 230,
      height: 161
    }
  }),
  imageContainerRequestPack: theme => ({
    width: 556,
    height: 366,
    padding: '12.5px 0 12.5px 15px',
    [theme.breakpoints.down('md')]: {
      width: 230,
      height: 161,
      padding: 0
    }
  }),
  requestPackTitle: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    textAlign: 'left',
    marginTop: 0,
    fontWeight: 600,
    marginBottom: 6,
    lineHeight: 1.29,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      textAlign: 'center',
      marginTop: 6
    }
  }),
  ContainerBoxRRP: {
    padding: '0 0 0 54px'
  },
  textRequestPack: theme => ({
    marginBottom: 5,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  }),
  imagePartnerWT: theme => ({
    height: 640,
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      height: 229,
      marginLeft: 4
    }
  }),
  containerPartnerWU: theme => ({
    paddingTop: 29,
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      paddingTop: 10.5
    }
  }),
  imgPack: {
    width: '100%',
    objectFit: 'contain'
  },
  customPackImg: {
    height: 380,
    position: 'relative'
  },
  packNameText: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 32,
    fontWeight: 'bold',
    color: '#0f2440',
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    }
  }),
  packNameCustomText: {
    marginBottom: 12,
    marginTop: 28
  },
  packImageWrapper: theme => ({
    cursor: 'pointer',
    height: 330,
    width: 330,
    position: 'relative',
    padding: '5px 0px 0px 0px',
    [theme.breakpoints.down('md')]: {
      height: 230,
      width: 230,
      borderBottom: '2px solid #434c5f'
    }
  }),
  packImageWrapperCustom: theme => ({
    height: 330,
    width: 330,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      height: 230,
      width: 230
    }
  }),
  packItemText: theme => ({
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  }),
  tabItemOn: theme => ({
    backgroundColor: '#3577d4',
    borderRadius: 6,
    textAlign: 'center',
    textTransform: 'none',
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 600,
    fontSize: 20,
    color: `${theme.palette.common.white} !important`,
    width: 180,
    height: 48,
    marginRight: 6,
    [theme.breakpoints.down('md')]: {
      flexGrow: 0,
      fontSize: 12,
      width: 132,
      height: 32,
      marginRight: 0.5,
      marginBottom: 3
    },
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
      fontSize: 11,
      width: 102,
      height: 20,
      marginRight: 0.5,
      marginBottom: 0.5
    }
  }),
  tabItemOff: theme => ({
    backgroundColor: '#d4d9e2',
    borderRadius: 6,
    textAlign: 'center',
    textTransform: 'none',
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 600,
    fontSize: 20,
    color: `${theme.palette.neutral['700']} !important`,
    width: 180,
    height: 48,
    marginRight: 6,
    [theme.breakpoints.down('md')]: {
      flexGrow: 0,
      width: 132,
      fontSize: 12,
      height: 32,
      marginRight: 0.5,
      marginBottom: 3
    },
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
      fontSize: 11,
      width: 102,
      height: 20,
      marginRight: 0.5,
      marginBottom: 0.5
    }
  }),
  letGetStartedTitle: theme => ({
    marginBottom: 8,
    marginTop: 4,
    [theme.breakpoints.down('md')]: {
      marginTop: 3
    }
  }),
  letGSTabTitle: theme => ({
    marginTop: 13.5,
    marginBottom: 13.5,
    color: theme.palette.primary['900'],
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }),
  packItemContainer: theme => ({
    height: 650,
    padding: '25px 66px',
    backgroundColor: 'white',
    paddingTop: 0,
    textAlign: 'left',
    borderTopLeftRadius: 80,
    borderBottomRightRadius: 80,
    display: 'inline-block',
    [theme.breakpoints.down('md')]: {
      borderTopLeftRadius: 60,
      borderBottomRightRadius: 60,
      padding: '12px 38px',
      paddingTop: 0,
      height: 530,
      maxWidth: 315
    },
    [theme.breakpoints.down('sm')]: {
      height: 480
    }
  }),
  customPackContainer: theme => ({
    height: 650,
    padding: '64px 66px',
    paddingBottom: 7.5,
    textAlign: 'center',
    borderTopLeftRadius: 60,
    borderBottomRightRadius: 60,
    backgroundColor: 'white',
    display: 'inline-block',
    [theme.breakpoints.down('md')]: {
      marginTop: 1,
      padding: '28px 40px',
      height: 530,
      maxWidth: 315
    },
    [theme.breakpoints.down('sm')]: {
      height: 480
    }
  }),
  packItemTitle: theme => ({
    textAlign: 'left',
    marginTop: 2.5,
    height: 102,
    [theme.breakpoints.down('sm')]: {
      marginTop: 3,
      height: 68
    }
  }),
  packItemSubTitle: {
    textAlign: 'left'
  },
  input: {
    color: 'white !important',
    backgroundColor: 'transparent',
    borderBottom: '2px #1b3a62 solid',
    '& input': {
      backgroundColor: 'transparent !important'
    },
    '& p': {
      backgroundColor: 'transparent !important'
    }
  },
  underline: {
    borderBottom: '2px white solid'
  },
  linkPack: theme => ({
    color: '#9846dd',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    textAlign: 'left',
    fontWeight: 600,
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  }),
  otherMenu: theme => ({
    paddingLeft: 0,
    '& li': {
      listStylePosition: 'inside'
    },
    [theme.breakpoints.down('sm')]: {
      listStyleType: 'none',
      '& li': {
        marginBottom: '4px !important'
      },
      marginBottom: 6
    }
  }),
  otherOptionsContainer: theme => ({
    padding: 24,
    paddingLeft: 25,
    textAlign: 'left',
    borderTopLeftRadius: 100,
    borderBottomRightRadius: 100,
    [theme.breakpoints.down('sm')]: {
      borderTopLeftRadius: 60,
      borderBottomRightRadius: 60,
      padding: 8,
      marginTop: 6,
      marginLeft: 6,
      marginRight: 6
    }
  }),
  cardImgContainer: theme => ({
    width: 262,
    position: 'relative',
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      width: 120
    }
  }),
  cardImg: {
    width: '100%',
    objectFit: 'contain'
  },
  modalContainer: theme => ({
    position: 'absolute',
    borderRadius: 2.5,
    top: 140,
    left: '50%',
    marginLeft: -81.5,
    margin: '0 auto',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      top: 56,
      width: '90%',
      marginLeft: '-45%'
    }
  }),
  contactFormWrapper: theme => ({
    padding: '0px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 24px'
    }
  }),
  scrollArrow: theme => ({
    color: 'white',
    position: 'absolute',
    opacity: 0.56,
    fontSize: 42,
    width: 56,
    height: 56,
    fontWeight: 'normal',
    backgroundColor: '#3577d4',
    borderRadius: 1,
    top: '35%',
    // top: 616,
    // boxShadow: theme.shadows[3],
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[8],
      opacity: 1
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      width: 42,
      height: 42
    }
  }),

  subscribeContent: theme => ({
    paddingTop: 31,
    paddingBottom: 18,
    [theme.breakpoints.down('md')]: {
      paddingTop: 8,
      paddingBottom: 0
    }
  }),
  SubscribeSummary: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.5,
    color: '#0f2440',
    paddingBottom: 10
  },
  mainContainer: theme => ({
    width: 368,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 8
    }
  }),
  topBarSection: {
    padding: 0
  },
  barTitle: {
    fontFamily: 'Gilroy-Medium',
    fontWeight: 600,
    fontSize: 24,
    color: '#0f2440'
  },
  centerSubscribe: theme => ({
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1280px',
      paddingLeft: '0px',
      minWidth: '1280px'
    },
    [theme.breakpoints.down('xl')]: {
      maxWidth: '1120px',
      paddingLeft: '0px',
      minWidth: '1120px'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw',
      paddingRight: '24px !important',
      paddingLeft: '24px !important',
      minWidth: '10vw'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      paddingRight: '24px',
      paddingLeft: '24px',
      minWidth: '100%'
    }
  }),
  subcribeContent: theme => ({
    paddingTop: 41,
    paddingBottom: 14,
    [theme.breakpoints.down('md')]: {
      paddingTop: 14,
      paddingBottom: 25.5
    }
  }),
  subscribeButton: theme => ({
    [theme.breakpoints.down('md')]: {
      width: '128px !important',
      fontSize: '14px !important',
      paddingTop: '12px !important',
      paddingBottom: '12px !important'
    }
  }),
  subscribeLight: theme => ({
    paddingTop: 10.5,
    [theme.breakpoints.down('md')]: {
      paddingTop: 6
    }
  }),
  subscribeInnerContent: theme => ({
    [theme.breakpoints.down('md')]: {
      padding: '72px 24px',
      paddingBottom: 25.5
    }
  }),
  containerRecent: theme => ({
    paddingTop: 31,
    paddingBottom: 23,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 8,
      paddingBottom: 6
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 14,
      paddingBottom: 6
    }
  }),
  recentTitle: theme => ({
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 6,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 4
    }
  }),
  recentSubTitle: theme => ({
    textAlign: 'center',
    color: theme.palette.neutral['300'],
    marginTop: 0,
    marginBottom: 6,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 4,
      paddingRight: 3,
      paddingLeft: 3
    }
  }),
  recentImgContainer: theme => ({
    height: 240,
    width: 240,
    marginBottom: 6,
    [theme.breakpoints.down('lg')]: {
      height: 172,
      width: 172
    },
    [theme.breakpoints.down('md')]: {
      height: 124,
      width: 124,
      marginBottom: 4
    },
    [theme.breakpoints.down('sm')]: {
      height: 56,
      width: 56,
      marginBottom: 3
    }
  }),
  recentImg: theme => ({
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 2.5
    }
  }),
  viewAllButton: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: '18px !important',
    textTransform: 'none !important',
    height: '64px !important',
    borderRadius: '32px !important',
    width: '156px !important',
    marginTop: 42,
    [theme.breakpoints.between('sm', 'md')]: {
      width: '100% !important'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      height: '56px !important',
      borderRadius: '28px !important',
      fontSize: '18px !important',
      width: '100%!important'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      height: '42px !important',
      borderRadius: '21px !important',
      fontSize: '16px !important',
      width: '100%!important'
    }
  }),
  columnSec: theme => ({
    paddingLeft: '6px !important',
    [theme.breakpoints.down('md')]: {
      paddingRight: '8px !important'
    }
  }),
  seasonPromotionSubtext: theme => ({
    lineHeight: 1.8,
    marginTop: 1.25,
    textAlign: 'left',
    color: '#868a8f',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: 8,
      textAlign: 'center'
    }
  }),
  seasonPromotionHeaderText: theme => ({
    marginTop: 6,
    [theme.breakpoints.down('md')]: {
      marginTop: 6
    }
  }),
  ctaButtonWrapperSeasonPromotion: theme => ({
    marginTop: 6,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      marginTop: 0
    }
  }),
  seasonPromotionCtaButton: theme => ({
    marginBottom: 6,
    fontSize: 18,
    [theme.breakpoints.down('md')]: {
      marginBottom: 4,
      width: '224px !important',
      paddingTop: '16px !important',
      paddingBottom: '16px !important',
      height: 50,
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 2,
      width: '196px !important',
      paddingTop: '12px !important',
      paddingBottom: '12px !important',
      height: 32,
      fontSize: 12
    }
  }),
  imageGridItemSeasonPromotion: theme => ({
    [theme.breakpoints.down('md')]: {
      minWidth: 440
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 320
    }
  }),
  imgSeasonPromotion: theme => ({
    maxWidth: 608,
    maxHeight: 608,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 320
    }
  })
};

export default styles;
