import { Grid, Card, Typography } from '@mui/material';
import CategoryLabel from './CategoryLabel';
import { Link } from 'react-router-dom';
import styles from './styles/PostCard';
import Img from '../shared/Img';

const descriptionLeverage = description =>
  description && description.length > 120 ? `${description.substring(0, 120)}...` : description;

const PostCard = ({ post, category }) => (
  <Link to={`/blog/${post.slug}`}>
    <Card sx={styles.card}>
      <Img
        sx={styles.media}
        src={post.cardImage || post.featuredImage || post.image}
        title={post.title}
        alt={post.title}
      />
      {/* <CardContent> */}
      <div style={{ padding: '20px 18px' }}>
        <Grid container alignContent="center">
          <Grid item xs>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <CategoryLabel category={category} variant="small" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item align="right">
            <Grid container justifyContent="flex-end" alignItems="center" style={{ height: '100%' }}>
              <Grid item>
                <div style={styles.timeReading}>{post.readtime} min read</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography component="h3" sx={styles.postTitle}>
          {post.title}
        </Typography>
        <Typography component="p" sx={styles.postSummary}>
          {descriptionLeverage(post.description)}
        </Typography>
      </div>
    </Card>
  </Link>
);

export default PostCard;
