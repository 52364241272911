const styles = {
  centerLV: theme => ({
    [theme.breakpoints.up('lg')]: {
      minWidth: 'auto',
      maxWidth: 'max-content',
      paddingLeft: '0 !important'
    },
    [theme.breakpoints.down('xl')]: {
      minWidth: 625,
      maxWidth: 625,
      paddingLeft: '0 !important'
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
      maxWidth: '100%',
      paddingLeft: '0 !important'
    }
  }),
  landingFrame: theme => ({
    padding: '40px 0px',
    minHeight: 500,
    [theme.breakpoints.down('md')]: {
      padding: '44px 0px 0px 0px',
      paddingBottom: 0,
      minHeight: '100%',
      overflowY: 'hidden',
      overflowX: 'hidden'
    }
  }),
  socksImg: theme => ({
    paddingTop: 0,
    paddingRight: 18.75,
    marginLeft: 20,
    width: 217,
    [theme.breakpoints.down('md')]: {
      width: 57,
      marginTop: -4,
      marginRight: 1.25,
      marginLeft: 0,
      paddingTop: 0,
      paddingRight: 0
    }
  }),
  waterbottleImg: theme => ({
    width: 63,
    marginTop: -17.5,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      width: 30,
      paddingTop: 0,
      marginTop: -11.25,
      marginLeft: 14.5
    }
  }),
  contentPenImg: theme => ({
    display: 'table',
    height: '100%',
    marginLeft: 22,
    [theme.breakpoints.down('md')]: {
      marginTop: -3.75,
      marginLeft: 16
    }
  }),
  penImg: theme => ({
    display: 'table-cell',
    verticalAlign: 'top',
    maxWidth: 160,
    marginTop: 0,
    marginLeft: 69,
    [theme.breakpoints.down('md')]: {
      width: 7,
      paddingTop: 0,
      marginTop: -5,
      marginRight: 4
    }
  }),
  toteImgContainer: theme => ({
    width: 283,
    marginTop: 4.5,
    marginLeft: -22,
    marginRight: 8,
    [theme.breakpoints.down('md')]: {
      width: 124,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
      paddingLeft: 3,
      marginRight: -6,
      marginLeft: -4,
      marginTop: 0
    }
  }),
  treeContainer: theme => ({
    paddingLeft: 4,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
      marginLeft: -6
    }
  }),
  adapterImg: theme => ({
    marginTop: 0,
    marginBottom: 14,
    marginLeft: -16.25,
    [theme.breakpoints.down('md')]: {
      width: 40,
      marginTop: -7.5,
      marginLeft: -4,
      marginBottom: 0,
      paddingBottom: 0
    }
  }),
  boxImgContainer: theme => ({
    width: 288,
    paddingBottom: 0.5,
    marginTop: -56.5,
    marginLeft: -3.75,
    marginRight: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: -10,
      width: 57,
      paddingBottom: 0,
      marginLeft: 0
    }
  }),
  bagImgContainer: theme => ({
    width: 150,
    marginLeft: -18.75,
    [theme.breakpoints.down('md')]: {
      width: 57,
      marginLeft: 6
    }
  }),
  cupImgContainer: theme => ({
    marginTop: 11.25,
    display: 'table',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: 2,
      marginTop: 0,
      marginLeft: -15
    }
  }),
  cupImg: theme => ({
    width: 150,
    marginTop: 11.25,
    display: 'table-cell',
    verticalAlign: 'bottom',
    [theme.breakpoints.down('md')]: {
      width: 60,
      paddingTop: 0
    }
  }),
  notebookImg: theme => ({
    maxWidth: 34,
    marginTop: 35,
    [theme.breakpoints.down('md')]: {
      width: 20,
      paddingTop: 0,
      marginTop: 1.25
    }
  })
};

export default styles;
