import OurServices from '../../shared/OurServices';
import LandingSection from '../shared/LandingSection';
import SamplePack from '../shared/NewSamplePack';
import ContactUs from '../../shared/ContactUs';
import NewBuildYourBrand from '../shared/NewBuildYourBrand';
import BackgroundLandingPages from '../shared/BackgroundAdornments';

const services = [
  {
    title: 'Design',
    text: 'We understand you’re busy! So let our team design the swag for you',
    src: '/imgs/landingPagesSSR/design.svg'
  },
  {
    title: 'Storage',
    text: 'We don’t mean to show off, but you can store with us as well!',
    src: '/imgs/landingPagesSSR/warehouse-storage.svg'
  },
  {
    title: 'Assemble',
    text: 'Don’t sweat it! We are the experts in assembling packs',
    src: '/imgs/landingPagesSSR/assemble.svg'
  },
  {
    title: 'Ship',
    text: 'We offer worldwide shipment via a dashboard to keep track of your packs',
    src: '/imgs/landingPagesSSR/ship.svg'
  }
];

const responses = [
  {
    img: { src: '/imgs/landingPagesSSR/premium-kit.svg', alt: 'premium-kit' },
    subTitle: 'The most awesome assortment of swag assembled into packs.'
  },
  {
    img: { src: '/imgs/landingPagesSSR/client-employee-bond.svg', alt: 'client-employee-bond' },
    subTitle: 'Loyalty/ Rewards swag can be drinkware, notebooks and more! Our team works to meet your brand &budget!'
  },
  {
    img: { src: '/imgs/landingPagesSSR/culture.svg', alt: 'culture' },
    subTitle: 'Swag Packs is the ultimate way to reward customers for their loyalty.'
  }
];

const BuildLoyalty = () => (
  <>
    <BackgroundLandingPages />
    <LandingSection />
    <NewBuildYourBrand responses={responses} title="What’s a Swag Pack for Loyalty/Rewards?" />
    <SamplePack />
    <OurServices
      services={services}
      title="Custom Swag Packs for Rewards/Loyalty!"
      textAboveButton="You see! We make Swag Packs Simple"
    />
    <ContactUs namePage="Build Loyalty with Swag Packs!" />
  </>
);

export default BuildLoyalty;
