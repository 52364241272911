import { Box, Grid, Typography } from '@mui/material';
import { imageSrcSet } from '../../../utils';
import CenteredGrid from '../../shared/CenteredGrid';
import Img from '../../shared/Img';
import SectionTag from '../../shared/NewSectionTag';
import styles from './styles/benefits';

const SectionFacilities = ({ facility, src }) => (
  <Grid item container xs={6} direction="row" alignItems="flex-start" sx={styles.sectionContainerFacility}>
    <Grid item xs={2}>
      <Img sx={styles.imageContainerFacility} src={src} alt="benefits" />
    </Grid>
    <Grid item xs={10}>
      <Typography variant="inherit" sx={styles.textFacility}>
        {facility}
      </Typography>
    </Grid>
  </Grid>
);

const bottomImagesList = [
  { src: '/imgs/landingPagesSSR/shipstation.svg', alt: 'ShipStation', margin: 10 },
  { src: '/imgs/landingPagesSSR/zakeke.svg', alt: 'Zakeke', margin: 10 },
  { src: '/imgs/landingPagesSSR/zapier.svg', alt: 'Zapier', margin: 0 }
];

const BottomImages = ({ list }) =>
  list.map(({ alt, src, margin }) => {
    return (
      <Grid
        key={alt}
        item
        container
        xs
        align="center"
        sx={[styles.bottomImageContainerBenefits, { marginRight: margin }]}
        justifyContent="center"
      >
        <Img src={src} alt={alt} sx={{ alignSelf: 'center' }} />
      </Grid>
    );
  });

const ShowBottomImages = ({ list }) => (
  <Box>
    <Grid container alignItems="center" space={10} sx={styles.bottomImagesContainerBenefits}>
      <BottomImages list={list} />
    </Grid>
  </Box>
);

const Benefits = ({ benefits }) => (
  <Grid container justifyContent="center" sx={styles.containerRequestPackBenefits}>
    <CenteredGrid sx={styles.center}>
      <Box sx={styles.sectionContainerBenefits}>
        <Grid container>
          <Grid item md={5} sm={5} xs={12}>
            <Box sx={styles.wrapperImgBenefits}>
              <Img
                src={benefits.src || '/imgs/landingPagesSSR/benefits.png'}
                srcSet={imageSrcSet(benefits.src || '/imgs/landingPagesSSR/benefits.png')}
                alt="benefits"
                sx={styles.imageBenefitsEG}
              />
            </Box>
          </Grid>
          <Grid item md={7} sm={7} xs={12} sx={styles.containerSectionBenefits}>
            <SectionTag tag={benefits.tag || 'Section Tag'} sectionContainerClass="sectionTagContainerBenefits" />
            <Typography variant="inherit" component="h2" sx={styles.titleBenefits}>
              {benefits.title || 'Benefits'}
            </Typography>
            <Typography variant="inherit" sx={styles.subTextBenefits}>
              {benefits.subText || 'Benefits of using SwagUp app for your store'}
            </Typography>
            <Grid container justifyContent="flex-start">
              <SectionFacilities facility="Easy Set-up" src="/imgs/landingPagesSSR/benefits-custom.svg" />
              <SectionFacilities facility="Production" src="/imgs/landingPagesSSR/benefits-rocket.svg" />
              <SectionFacilities facility="Fulfillment" src="/imgs/landingPagesSSR/benefits-fulfillment.svg" />
              <SectionFacilities facility="Shipping" src="/imgs/landingPagesSSR/benefits-shipping.svg" />
              <SectionFacilities facility="Assembly" src="/imgs/landingPagesSSR/benefits-assembly.svg" />
              <SectionFacilities
                facility={benefits.facilities[0].facility || 'Select from a large collection of custom swag / merch '}
                src={benefits.facilities[0].src || '/imgs/landingPagesSSR/benefits-swags.svg'}
              />
            </Grid>
          </Grid>
        </Grid>
        {!benefits.hideIntegrations && (
          <Grid item xs={12} sx={styles.landingBottomFrame}>
            <Typography variant="inherit" sx={styles.integrationsText}>
              Integrates with
            </Typography>
            <ShowBottomImages list={benefits.bottomImagesList || bottomImagesList} />
          </Grid>
        )}
      </Box>
    </CenteredGrid>
  </Grid>
);

export default Benefits;
