import BlogHome from './BlogHome';
import Helmet from '../shared/Helmet';
import seoTags from '../../utils/seoTags';
import { useQuery } from 'react-query';
import wordpressApiClient from '../../api/wordpressApiClient';

const Blog = () => {
  const { data: posts } = useQuery('posts', wordpressApiClient.fetchAllPosts);
  const { data: categories } = useQuery('blogCategories', wordpressApiClient.fetchBlogCategories);

  return (
    <>
      <Helmet tags={seoTags.blog} />
      <BlogHome posts={posts} categories={categories} />
    </>
  );
};

export default Blog;
