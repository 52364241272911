import { Grid, Typography } from '@mui/material';
import { Animated } from 'react-animated-css';
import Img from '../shared/Img';

const getLogosList = darkMode => {
  const commonLogos = {
    Amazon: { src: process.env.PUBLIC_URL + '/imgs/public/amazon.svg', displacement: 2 },
    Cocacola: { src: process.env.PUBLIC_URL + '/imgs/public/cocacola.svg' },
    Wework: { src: process.env.PUBLIC_URL + '/imgs/homeLight/wework.svg' },
    Gemini: { src: process.env.PUBLIC_URL + '/imgs/public/gemini.svg', displacement: 1 },
    MM: { src: process.env.PUBLIC_URL + '/imgs/public/mandm.png' },
    WP: { src: process.env.PUBLIC_URL + '/imgs/public/wandp.png' },
    Popcorners: { src: process.env.PUBLIC_URL + '/imgs/public/popcorners.png' },
    Snickers: { src: process.env.PUBLIC_URL + '/imgs/public/snickers.svg' },
    Realthread: { src: process.env.PUBLIC_URL + '/imgs/landingPagesSSR/realthread.svg', displacement: -0.5 },
    Patagonia: { src: process.env.PUBLIC_URL + '/imgs/landingPagesSSR/patagonia.svg', displacement: 1 },
    Sockclub: { src: process.env.PUBLIC_URL + '/imgs/landingPagesSSR/sockclub.svg', displacement: 1 },
    Moo: { src: process.env.PUBLIC_URL + '/imgs/landingPagesSSR/moo.svg', displacement: 0.5 },
    Swell: { src: process.env.PUBLIC_URL + '/imgs/landingPagesSSR/swell.svg', displacement: -0.5 },
    Stickermule: { src: process.env.PUBLIC_URL + '/imgs/landingPagesSSR/stickermule.svg', displacement: -0.5 }
  };

  const variableLogos = darkMode
    ? {
        Google: { src: process.env.PUBLIC_URL + '/imgs/public/google-white.svg', displacement: 4 },
        Crunchbase: { src: process.env.PUBLIC_URL + '/imgs/public/crunchbase-white.svg' },
        Atlassian: { src: process.env.PUBLIC_URL + '/imgs/public/atlassian-white.svg' },
        Duolingo: { src: process.env.PUBLIC_URL + '/imgs/public/duolingo-white.svg', displacement: 4 },
        Square: { src: process.env.PUBLIC_URL + '/imgs/public/square-white.svg', displacement: -1 },
        Walmart: { src: process.env.PUBLIC_URL + '/imgs/public/walmart-white.svg' },
        Facebook: { src: process.env.PUBLIC_URL + '/imgs/public/facebook-white.svg' },
        Standford: { src: process.env.PUBLIC_URL + '/imgs/public/stanford-white.svg', displacement: 3 }
      }
    : {
        Google: { src: process.env.PUBLIC_URL + '/imgs/public/google.svg', displacement: 1 },
        Crunchbase: { src: process.env.PUBLIC_URL + '/imgs/public/crunchbase.svg' },
        Atlassian: { src: process.env.PUBLIC_URL + '/imgs/public/atlassian.svg' },
        Duolingo: { src: process.env.PUBLIC_URL + '/imgs/homeLight/duolingo.svg', displacement: 1 },
        Square: { src: process.env.PUBLIC_URL + '/imgs/public/square.svg', displacement: -0.25 },
        Walmart: { src: process.env.PUBLIC_URL + '/imgs/public/walmart.svg' },
        Facebook: { src: process.env.PUBLIC_URL + '/imgs/public/facebook.svg' },
        Standford: { src: process.env.PUBLIC_URL + '/imgs/public/standford.svg', displacement: 1 }
      };

  return { ...commonLogos, ...variableLogos };
};

const styles = {
  title: {
    margin: 0
  },
  logos: theme => ({
    marginTop: 7.5,
    [theme.breakpoints.down('sm')]: { marginTop: 2.5 }
  }),
  logoContainer: theme => ({
    display: 'flex',
    alignItems: 'center ',
    [theme.breakpoints.down('sm')]: { alignItems: 'space-around', maxWidth: '33%' }
  }),
  logo: theme => ({
    maxWidth: 160,
    [theme.breakpoints.down('xl')]: {
      maxWidth: 127
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 85
    }
  })
};

const LogosSection = ({
  justifyContent,
  title = 'Serving up fresh swag for',
  logosJustify,
  logos,
  logosSpacing,
  animated,
  titleStyles,
  logosStyles,
  sx
}) => {
  const logosList = getLogosList();

  const content = (
    <Grid sx={sx} container justifyContent={justifyContent}>
      <Typography
        variant="h5SemiBold"
        sx={[styles.title, ...(Array.isArray(titleStyles) ? titleStyles : [titleStyles])]}
      >
        {title}
      </Typography>
      <Grid
        item
        container
        spacing={logosSpacing}
        justifyContent={logosJustify || justifyContent}
        sx={[styles.logos, ...(Array.isArray(logosStyles) ? logosStyles : [logosStyles])]}
      >
        {logos.map(logo => (
          <Grid sx={styles.logoContainer} item key={logo}>
            <Img
              sx={[styles.logo, { marginTop: logosList[logo].displacement ?? 0 }]}
              src={logosList[logo].src}
              alt={logo}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  return animated ? (
    <Animated animationIn="fadeIn" animationInDelay={2000}>
      {content}
    </Animated>
  ) : (
    content
  );
};

export default LogosSection;
