import Grid from '@mui/material/Grid';
import Img from './Img';

const BottomImages = ({ list, imgSx, xs = true }) =>
  list.map(({ src, alt, margin: marginBottom = 0, noPadding, forcedMargin: marginLeft = 0, align = 'center' }) => {
    const paddingLeft = (!noPadding && !marginLeft && 4) || 0;
    return (
      <Grid key={alt} item xs={xs} align={align} sx={{ marginBottom, paddingLeft, marginLeft }}>
        <Img src={src} sx={imgSx} alt={alt} />
      </Grid>
    );
  });

export default BottomImages;
