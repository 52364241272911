import ClausesDocument from '../shared/ClausesDocument';

const termsOfUseClauses = {
  'Return and Refund Policy For SwagUp': `Our main goal at SwagUp is to build a relationship of trust and quality between us and you.

To that end, we do everything in our power to make situations right and ensure you are satisfied.

If you are not 100% satisfied with your order, here is how we handle it...`,

  'General Policy Terms': `We want you and your swag recipients to have the best swag experience. 

As a result, we strongly recommend checking in with your swag recipients and inspecting all items upon receipt. If you discover issues with your swag, please reach out to us as soon as possible within 14 days of delivery so we could make it right.`,

  'Not Satisfied with Product or Experience': `If, for whatever reason, you are not 100% satisfied with your items, please let us know within 14 days of receipt and we will either replace the items, if possible, or issue a credit or refund for such items.  

To be eligible for a refund or credit, certain items must be returned to us. In those instances, such items must be returned undamaged and unused.  SwagUp will provide a return shipping label in order to send back the items. Returned items that do not satisfy these requirements may not qualify for a refund or credit. Once we receive your qualifying return and approve your refund or credit, you will be able to choose whether to receive a credit or refund of your payment.

Partial refunds or credits may be offered on a case-by-case basis in the event you are not satisfied with only a portion of your order.  If you think this applies to you, please reach out to our Customer Experience Team at <a href="https://help.swagup.com/hc/en-us/requests/new" rel="noreferrer noopener">our help center</a>`,

  'Logo Printed Incorrectly': `If your logo is printed incorrectly on any of the delivered items, please reach out to us as soon as possible within 14 days of delivery at <a href="https://help.swagup.com/hc/en-us/requests/new" rel="noreferrer noopener">our help center</a> and we will either correct and replace the items, if possible, or issue a credit or refund.  To help us make corrections, please provide a clear photo of the items containing the logo issue.`,

  "Items Don't Fit": `Prior to placing your order, our Swag Experts can help you with sizing for all products available in the catalog and recommend the best options based on desired fit preferences.  We strongly recommend confirming sizing for specific products before ordering.  If you discover fit issues, please notify us as soon as possible within 14 days of delivery at <a href="https://help.swagup.com/hc/en-us/requests/new" rel="noreferrer noopener">our help center</a> and provide photos where the fit failed to meet expectations. Upon review, we will either replace the items with corrected sizing, if available, or recommend alternative products.  In either case, the original items must be returned undamaged and unused.`,

  'Damaged Items': `We strongly recommend inspecting all items upon receipt to ensure everything arrived safely and undamaged. If you discover broken and/or damaged items, please reach out to us as soon as possible within 14 days of delivery at <a href="https://help.swagup.com/hc/en-us/requests/new" rel="noreferrer noopener">our help center</a> and provide photos of the damaged products.  Upon review, we will either replace the damaged products, if possible, or issue a credit or refund.

Notice Regarding Ceramic Mugs:

Please note that ceramic mugs are not covered by this policy due to their higher risk of breakage. While we can ship ceramic mugs for you if you wish to include them in your swag projects, we recommend going with alternative mug options that are known to be more durable in the shipping process. Proceeding with a ceramic mug order is an assumption of risk that will exclude the order from replacement and refunding or crediting if received damaged or broken. If you do order ceramic mugs, we suggest purchasing extra units in case replacements are needed.

Notice Regarding 3rd Party Items:

Please note that while SwagUp may allow customers to use the SwagUp Platform to store, manage and ship third party items that are not otherwise offered by SwagUp, SwagUp cannot guarantee the quality of such items. As a result, we are not responsible for such third party items in the event that they break, lost during shipment or become damaged during shipping or otherwise, and such third party items are expressly excluded from this policy. If an item is lost in transit due to incorrect information, SwagUp will not refund or credit the 3rd party charge. If an item is lost in transit due to carrier error, SwagUp will refund the 3rd party charge.`,

  'Missing Items in Swag Packs': `Please confirm with your swag recipients that all the swag items were properly received. If you find that there are missing items in any of the packs, please notify our team as soon as possible within 14 days of delivery. We will either replace the missing items, if possible, or issue a refund or credit in the amount of the missing items.`,

  'Undelivered Items': `Shipping Errors:
  
During shipping, some packages may be considered ‘undeliverable’ by the carrier. Most commonly, this means that one of the following situations occurred, in which case SwagUp is not responsible for refunding or crediting any shipments or items for the following shipping errors:
  - An incorrect address was provided
  - The recipient was unresponsive/unable to receive the package
  - The recipient is no longer at the provided address
  - The recipient refused to accept delivery of the package
  
Please confirm that all swag packs were received by your intended swag recipients. If you discover that an intended recipient did not get their swag pack due to a shipping error made by us, please notify us immediately within 14 days after the expected delivery and we will do our best to reproduce the items and ship them to the intended recipient, if possible, or we will issue a credit or refund for the undelivered items.

Please note that SwagUp is not responsible for packages that are abandoned or undelivered due to incorrect recipient information provided by the customer or other errors or omissions caused by the customer or intended recipient.  We strongly recommend ensuring that you correctly fill out and upload the CSV information according to SwagUp guidelines, and, where possible, advise your intended recipients to expect a package.  The latter is particularly important for international shipments where carriers oftentimes seek to contact the intended recipients prior to attempting delivery.

For additional help on ensuring successful swag shipment delivery, please contact us at <a href="https://help.swagup.com/hc/en-us/requests/new" rel="noreferrer noopener">our help center</a>.

Items Lost by Carrier:

Swag shipments are fulfilled through third party shipping companies (e.g. FedEx, UPS, and USPS).  As with all shipments, there is a risk that your items may become lost in transit. In the rare case that this occurs, we will do our best to reproduce the items and ship them to the intended recipient, if possible, or we will issue a credit or refund for the items that were lost in transit. SwagUp will not refund the cost of 3rd party items not produced by SwagUp. SwagUp will only refund the cost charged for the 3rd party item that is reflected on the SwagUp invoice. 

Please note that merchandise marked delivered and signed cannot be considered lost, and will not be covered by this policy.

Items Returned to Sender:

In many cases, undelivered packages are returned to our warehouse.  In such cases, the return postage fees will be billed to your account. You will still be able to use the returned swag items as part of your inventory. Upon receiving the items, we will update your swag inventory to reflect the new status. Returned swag items ship as-is and require additional postage costs to re-ship.`,

  'Other ': `Simply not happy? Sometimes unique situations call for a unique solution.
  
At the end of the day, we value our relationship with you much more than anything else and want to ensure you are happy.
  
If you feel your request falls outside of the scope of this policy or an exception to the rule applies, please reach out to us at <a href="https://help.swagup.com/hc/en-us/requests/new" rel="noreferrer noopener">our help center</a> or by calling 646-494-0520.`
};

const ReturnRefundPolicy = () => <ClausesDocument clausesHeader="Return/Refund Policy" clauses={termsOfUseClauses} />;

export default ReturnRefundPolicy;
