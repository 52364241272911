const styles = {
  container: theme => ({
    padding: '40px 0 100px 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      px: 6
    }
  }),
  textContainer: {
    color: '#0f2440',
    fontFamily: 'Gilroy-Bold',
    maxWidth: 520,
    paddingTop: '100px !important',
    paddingRight: '60px !important'
  },
  title: {
    fontSize: 72,
    lineHeight: 1.29,
    maxWidth: 465,
    marginBottom: 14
  },
  subtitle: {
    fontSize: '1.5rem',
    marginBottom: 12.5
  },
  category: {
    marginBottom: 10,
    '& img': {
      width: 48,
      height: 48,
      marginBottom: 2.5
    },
    '& p': {
      maxWidth: 220,
      opacity: 0.54,
      fontSize: '1rem',
      lineHeight: 1.63
    },
    '&.mb-0': { marginBottom: '0 !important' }
  },
  imgContainer: theme => ({
    width: '88%',
    height: '70%',
    position: 'absolute',
    top: '29%',
    left: '8%',
    zIndex: -999,
    borderRadius: '75px 5px 75px 5px',
    boxShadow: '0 30px 100px 0 rgba(212, 217, 226, 0.5)',
    [theme.breakpoints.down('sm')]: {
      height: '80%',
      width: '95%',
      left: '2%'
    }
  }),
  image: {
    objectFit: 'contain',
    width: '100%'
  },
  button: theme => ({
    fontSize: '1.25rem',
    marginTop: 15,
    borderRadius: 9,
    padding: '23px 75px',
    [theme.breakpoints.down('sm')]: { padding: '23px 50px' }
  }),
  link: {
    marginTop: 2.5,
    fontSize: '1.25rem'
  }
};

export default styles;
