import { Grid, Typography, Box } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import CenteredGrid from '../shared/CenteredGrid';
import Img from '../shared/Img';
import styles from './styles/grabAFreeSample';
import { imageSrcSet } from '../../utils';

const GrabAFreeSample = () => (
  <Grid container justifyContent="center" sx={styles.root}>
    <CenteredGrid>
      <Grid container justifyContent="center" alignItems="center" sx={styles.sampleSectionContainer}>
        <Grid item xs>
          <div>
            <Grid container justifyContent="center" alignItems="center" sx={styles.samplePackTag}>
              Sample Pack
            </Grid>
            <Typography variant="h3SemiBold" sx={styles.sampleTitle}>
              Grab a Free sample today!
            </Typography>
            <Typography variant="body1Medium" sx={styles.sampleText}>
              Want to see a sample? We got you covered!
            </Typography>
            <Button href="/dashboard/onboarding" variant="primary">
              Request a Sample Pack
            </Button>
          </div>
        </Grid>
        <Grid item>
          <Box sx={styles.sampleImageContainer}>
            <Img
              src="/imgs/public/sample-pack.png"
              alt="Sample Pack"
              srcSet={imageSrcSet('/imgs/public/sample-pack.png')}
              sx={styles.sampleImage}
            />
          </Box>
        </Grid>
      </Grid>
    </CenteredGrid>
  </Grid>
);

export default GrabAFreeSample;
