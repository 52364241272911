import { useState, useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import styles from './styles/swagPricesSection';
import { Image, SubSection, PriceSection } from './SwagPricesCard';
import ProductTypeSwitch from './ProductTypeSwitch';
import ShippingPriceCalculator from './ShippingPriceCalculator';
import GlobalInfoContext from './GlobalInfoContext';
import useAsync from '../../api/useAsync';
import { moneyStr, sumByProperty } from '../../helpers/utils';
import platformApiClient from '../../api/platformApiClient';
import Img from '../shared/Img';

const fetchPrices = async data => {
  return platformApiClient.getShippingPrices(data);
};

const ShippingPriceCalculatorSection = () => {
  const { bulk, swag } = useContext(GlobalInfoContext);
  const [priceText, setPriceText] = useState('');
  const [isBulk, setIsBulk] = useState(false);
  const [decoratedFetchPrices, response, isPending, error] = useAsync(fetchPrices);

  const currentProduct = isBulk ? bulk : swag;
  const handleProductTypeChange = ({ target: { checked } }) => setIsBulk(checked);

  useEffect(() => {
    if (!isPending) {
      const dm = response?.data?.delivery_methods?.[0];

      const text = dm
        ? moneyStr(sumByProperty(dm.prices.breakdown, 'total_price'))
        : response?.data?.error_detail?.message ?? '';

      setPriceText(text);
    }
  }, [response, isPending, error]);

  if (typeof currentProduct === 'undefined') return null;

  return (
    <Grid id="shipping-prices" sx={styles.sectionContainer}>
      <Typography variant="h3" sx={{ lineHeight: 1.17, textAlign: 'center' }}>
        Shipping price calculator
      </Typography>
      <Typography variant="h6" sx={styles.smallSubTitle}>
        Take advantage of our discounted rates
      </Typography>
      <Grid container justifyContent="center" sx={styles.cardContainer}>
        <Grid container justifyContent="space-between" sx={styles.card}>
          <Grid item xs={12} lg={6} sx={styles.column}>
            <ShippingPriceCalculator isBulk={isBulk} fetchPrices={decoratedFetchPrices} isCalculating={isPending} />
            <PriceSection isLoading={isPending} priceText={priceText} isBulk={isBulk} shipping />
          </Grid>
          <Grid item xs={12} lg={6} sx={styles.column}>
            <Grid container justifyContent="center">
              <Grid container justifyContent="center" alignItems="center" item xs={12}>
                <ProductTypeSwitch color="#3577d4" checked={isBulk} onChange={handleProductTypeChange} />
              </Grid>
              <Grid container justifyContent="center" alignItems="center" item xs={12}>
                <Image img={<Img src={currentProduct.img} alt={currentProduct.name} sx={styles.image} />} />
              </Grid>
              <Grid container justifyContent="center" alignItems="center" item xs={12}>
                <SubSection isBulk={isBulk} product={currentProduct} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ShippingPriceCalculatorSection;
