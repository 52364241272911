import { Grid, Table, TableBody, TableRow, TableCell, Typography, Box } from '@mui/material';
import CenteredGrid from '../../shared/CenteredGrid';
import Img from '../../shared/Img';
import SectionTag from '../../shared/NewSectionTag';
import styles from './styles/ourServices';

const OurServices = ({ tag, title, services }) => (
  <Grid container justifyContent="center" sx={styles.containerBuildYB}>
    <CenteredGrid sx={styles.center}>
      <Box sx={styles.sectionContainerOSRippling}>
        <SectionTag
          tag={tag}
          sectionContainerClass="sectionTagContainer"
          sectionLineClass="sectionTagLine"
          hr1Style={{ marginLeft: 'auto' }}
          hr2Style={{ marginRight: 'auto' }}
        />

        <Typography variant="inherit" component="h2" sx={styles.promotionTitleBYB}>
          {title}
        </Typography>
        <Grid container alignItems="stretch" sx={styles.containerAllBYBS} justifyContent="center">
          <Grid item sx={styles.containerBoxBYBShopify}>
            <Grid>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow key={1}>
                    {services.map(service => (
                      <TableCell align="left">
                        <Img sx={styles.imgRocketBYBShopify} src={service.img} alt={service.alt} />
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow key={2}>
                    {services.map(service => (
                      <TableCell align="left">
                        <Typography variant="inherit" sx={styles.sectionTitleST}>
                          {service.title}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow key={3}>
                    {services.map(service => (
                      <TableCell align="left">
                        <Typography variant="inherit" sx={styles.sectionSubTitleST}>
                          {service.text}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CenteredGrid>
  </Grid>
);

export default OurServices;
