import { useQuery } from 'react-query';
import apiPaths from './apiPaths';
import platformApiClient from './platformApiClient';
import { fifteenMinutes, oneHour } from '../utils';

const defaultOptions = {
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  staleTime: fifteenMinutes,
  cacheTime: oneHour
};

const useDefaultQuery = (queryKey, queryFn, options) => {
  return useQuery(queryKey, queryFn, { ...defaultOptions, ...options });
};

const useProfileCore = (options = {}) => {
  return useDefaultQuery(apiPaths.profiles, () => platformApiClient.fetchProfile(), {
    select: data => {
      const currentAccount = data?.related_accounts?.find(a => a.id === data.current_account);
      return { ...data, currentAccount };
    },
    ...options
  });
};

export { useProfileCore };
