import _ from 'lodash';
import parse from 'html-react-parser';
import { Cell, WideCell, LongCell, EmptyCell, ExcerptCell } from './galleryItems';

const galleryTemplate = [
  'Square',
  'Square',
  'Wide',
  'Square',
  'Excerpt',
  'Square',
  'Square',
  'Long',
  'Square',
  'Square'
];

const getImageDisplay = image => {
  if (image.display) return image.display;
  const { width, height } = image.media_details;
  const aspetRatioCoefficient = parseInt(width, 10) / parseInt(height, 10);
  switch (true) {
    case aspetRatioCoefficient >= 1.26:
      return 'Wide';
    case aspetRatioCoefficient <= 0.85:
      return 'Long';
    default:
      return 'Square';
  }
};

const extractResponsiveAttributes = image => {
  let responsive = {};
  parse(image.description?.rendered, {
    trim: true,
    replace: ({ name, attribs }) => {
      if (name === 'img') responsive = { srcSet: attribs.srcset, sizes: attribs.sizes };
    }
  });
  return responsive;
};

const tagImagesDisplay = images =>
  images.map(i => ({
    ...i,
    display: i.acf?.display || getImageDisplay(i),
    responsive: extractResponsiveAttributes(i)
  }));

const imagesDisplaySums = images =>
  images.reduce(
    (sum, img) => {
      switch (img.display) {
        case 'Wide':
          return { ...sum, wides: sum.wides + 1 };
        case 'Long':
          return { ...sum, longs: sum.longs + 1 };
        case 'Square':
          return { ...sum, squares: sum.squares + 1 };
        default:
          return sum;
      }
    },
    { wides: 0, longs: 0, squares: 0 }
  );

let attempts = 0;
let conversionCooldown = 100;

const isLastABlock = arranged => {
  if (arranged.length < 3) return false;
  const sums = imagesDisplaySums(arranged.slice(arranged.length - 3));
  return sums.longs === 1 && sums.squares === 2 && arranged[arranged.length - 1].display !== 'Long';
};

const admissible = (firstImage, arranged) => {
  const notWide = firstImage.display !== 'Wide';
  const previousSquares = arranged.reduce((sum, img) => sum + (['Square', 'Excerpt'].includes(img.display) ? 1 : 0), 0);
  const firstColumn = previousSquares % 2 === 0 && isLastABlock(arranged);
  const previousWasWide = arranged[arranged.length - 1]?.display === 'Wide';
  const last = arranged[arranged.length - 1];
  const secondLast = arranged[arranged.length - 2];
  const previousRowHadNoOrAllLong =
    !!secondLast &&
    ([last, secondLast].every(img => img.display !== 'Long') ||
      [last, secondLast].every(img => img.display === 'Long'));
  const lastNotLongFirstCol =
    firstImage.display === 'Long' && firstImage.isLast && firstColumn && previousSquares !== arranged.length;
  const lastNotSquareFirstCol =
    firstImage.display === 'Square' &&
    firstImage.isLast &&
    firstColumn &&
    previousSquares !== arranged.length &&
    !(
      arranged.length > 2 &&
      arranged[arranged.length - 2].display !== 'Long' &&
      arranged[arranged.length - 1].display !== 'Square'
    );
  const isFirst = !arranged.length;
  const isExcerpt = firstImage.display === 'Excerpt';
  const noWideOutFirstCol = firstImage.display === 'Wide' && !firstColumn;
  const noLongOnFirstColAfterSquare =
    firstImage.display === 'Long' && arranged[arranged.length - 1]?.display === 'Square' && firstColumn;

  return (
    isExcerpt ||
    ((notWide || (firstColumn && (previousRowHadNoOrAllLong || isFirst)) || previousWasWide) &&
      !lastNotLongFirstCol &&
      !lastNotSquareFirstCol &&
      !noWideOutFirstCol &&
      !noLongOnFirstColAfterSquare)
  );
};

const canGalleryBePerfect = (images, excerptExcluded) => {
  const sums = imagesDisplaySums(images);
  return sums.wides > 0 && sums.squares > (excerptExcluded ? 7 : 6) && sums.longs > 0;
};

const extractFirstImageKind = (images, kind) =>
  kind === 'Excerpt'
    ? { display: 'Excerpt' }
    : _.pullAt(
        images,
        _.findIndex(images, image => image.display === kind)
      )[0];

const justMakeItPerfect = (images, excerptExcluded) =>
  galleryTemplate.map(tag => extractFirstImageKind(images, tag === 'Excerpt' && excerptExcluded ? 'Square' : tag));

const aimBestScenario = images => {
  let perfectImages = [];
  let excerptExcluded = images.find(img => img.display === 'Excerpt');
  if (excerptExcluded) return images;
  while (canGalleryBePerfect(images, excerptExcluded)) {
    perfectImages = [...perfectImages, ...justMakeItPerfect(images, excerptExcluded)];
    excerptExcluded = perfectImages.find(img => img.display === 'Excerpt');
  }
  return [...perfectImages, ...images];
};

const convertFirst2Square = (images, display) => {
  const index = images.findIndex(img => img.display === display);
  images.splice(index, 1, { ...images[index], display: 'Square' });
  conversionCooldown = 0;
  return images;
};

const isTiny = images => images.length < 24;

const squarizeImages = images => {
  const sums = imagesDisplaySums(images);
  conversionCooldown += 1;
  return sums.wides + sums.longs > sums.squares || (isTiny(images) && conversionCooldown > 100)
    ? convertFirst2Square(images, sums.wides > sums.longs ? 'Wide' : 'Long')
    : images;
};

const improveOdds = images => squarizeImages(images);
const improveOdssDecide = images => (attempts > 500 ? improveOdds(images) : images);

const imagesArrangements = (images, arranged = []) => {
  if (!images.length) return arranged;
  const firstImage = images.shift();
  if (admissible({ ...firstImage, isLast: !images.length }, arranged)) {
    arranged.push(firstImage);
    return imagesArrangements([...images], [...arranged]);
  }

  const shuffled =
    [firstImage, ...images].length > arranged.length || isTiny([firstImage, ...images, ...arranged])
      ? improveOdssDecide(_.shuffle([firstImage, ...images, ...arranged]))
      : [...arranged, improveOdssDecide(_.shuffle([firstImage, ...images]))];
  attempts += 1;
  return imagesArrangements(shuffled);
};

const sortImages = images => {
  const taggedImages = tagImagesDisplay(images);
  try {
    return aimBestScenario(imagesArrangements([...taggedImages]));
  } catch {
    return aimBestScenario(taggedImages);
  }
};

const cellGenerator = image => {
  switch (image.display) {
    case 'Wide':
      return <WideCell image={image} />;
    case 'Long':
      return <LongCell image={image} />;
    case 'Square':
      return <Cell image={image} />;
    case 'Excerpt':
      return <ExcerptCell />;
    default:
      return <EmptyCell />;
  }
};

const TWO_EMPTY = 'TWO_EMPTY';
const ONE_EMPTY = 'ONE_EMPTY';

const rowsGenerator = (maxRows = 100, images, rows = [], condition) => {
  if (condition === TWO_EMPTY) {
    const row = <tr key={`${TWO_EMPTY}-${images.length}`} />;
    rows.push(row);
    return rowsGenerator(maxRows, images, rows);
  }

  if (!images.length || rows.length >= maxRows) return rows;

  const firstImage = images.shift();

  if (condition === ONE_EMPTY) {
    const row = <tr key={`${ONE_EMPTY}-${images.length}`}>{cellGenerator(firstImage)}</tr>;
    rows.push(row);
    return rowsGenerator(maxRows, images, rows, firstImage.display === 'Long' ? ONE_EMPTY : undefined);
  }

  const secondImage = images.shift();
  if (secondImage) {
    const bothImages = [firstImage, secondImage];
    const allLong = bothImages.every(image => image.display === 'Long');
    if (allLong) {
      const row = (
        <tr key={`Long-${images.length}`}>
          {cellGenerator(firstImage)}
          {cellGenerator(secondImage)}
        </tr>
      );
      rows.push(row);
      return rowsGenerator(maxRows, images, rows, TWO_EMPTY);
    }

    const firstWide = firstImage.display === 'Wide';
    if (firstWide) {
      const row = <tr key={`Wide-${images.length}`}>{cellGenerator(firstImage)}</tr>;
      rows.push(row);
      return rowsGenerator(maxRows, [secondImage, ...images], rows);
    }

    const someLong = bothImages.some(image => image.display === 'Long');
    if (someLong) {
      const row = (
        <tr key={`Normal-${images.length}`}>
          {cellGenerator(firstImage)}
          {cellGenerator(secondImage)}
        </tr>
      );
      rows.push(row);
      return rowsGenerator(maxRows, images, rows, ONE_EMPTY);
    }
  }

  const row = (
    <tr key={`Normal-${images.length}`}>
      {cellGenerator(firstImage)}
      {secondImage && cellGenerator(secondImage)}
    </tr>
  );
  rows.push(row);
  return rowsGenerator(maxRows, images, rows);
};

const getCurrentRows = images => {
  const wides = images.reduce((sum, img) => sum + img.display === 'Wide', 0);
  const nonWides = images.length - wides;
  return nonWides / 2 + wides;
};

export { sortImages, rowsGenerator, tagImagesDisplay, getCurrentRows };
