const styles = {
  container: {
    color: '#0f2440',
    fontFamily: 'Gilroy-Bold',
    letterSpacing: 'normal'
  },
  titleContainer: theme => ({
    [theme.breakpoints.down('md')]: {
      width: '100%',
      px: 15
    }
  }),
  title: {
    fontSize: '3.6rem',
    my: 8
  },
  subtitle: {
    opacity: 0.54,
    fontSize: '1.25rem',
    lineHeight: 1.5,
    marginTop: 2.5
  },
  processesContainer: theme => ({
    width: '100%',
    marginTop: 30,
    marginBottom: 50,
    position: 'relative',
    '& .react-horizontal-scrolling-menu--item:first-child': {
      marginLeft: 'calc(50% - 240px)',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 'calc(50% - 155px)'
      }
    }
  }),
  processWrapper: theme => ({
    maxWidth: 580,
    minWidth: 580,
    minHeight: 700,
    width: '100%',
    display: 'inline-block',
    marginBottom: 10,
    [theme.breakpoints.down('md')]: { minWidth: 415 },
    [theme.breakpoints.down('sm')]: { minWidth: 415 }
  }),
  processNumber: {
    color: '#ffffff',
    fontSize: '1.5rem',
    lineHeight: 1.33,
    padding: '10px 20px',
    backgroundColor: '#3577d4',
    borderRadius: '50%',
    border: '3px solid white',
    position: 'absolute',
    top: -30,
    left: 36
  },
  paper: theme => ({
    display: 'inline-block',
    position: 'relative',
    width: '99%',
    maxWidth: 480,
    minWidth: 315,
    minHeight: 620,
    borderRadius: '25px 75px 25px 25px',
    padding: '20px 65px',
    marginTop: 10,
    boxShadow: '0 25px 50px 0 rgba(212, 217, 226, 0.25)',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('md')]: { padding: '20px 25px', maxWidth: 315 }
  }),
  paperMedia: {
    width: '100%',
    minHeight: 300,
    alignItems: 'flex-end',
    '& img': { width: '50%' }
  },
  paperContent: {
    whiteSpace: 'pre-wrap',
    '& h2': {
      fontSize: '2.5rem',
      lineHeight: 1.3,
      maxWidth: 270,
      opacity: '1 !important'
    },
    '& p': {
      fontSize: '1.25rem',
      lineHeight: 1.6
    }
  },
  line: {
    borderTop: '2px solid #9846dd',
    width: 100,
    marginTop: 35,
    position: 'absolute',
    display: 'inline-block'
  },
  scrollArrow: theme => ({
    color: '#003366',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    fontSize: 42,
    width: 65,
    height: 65,
    fontWeight: 'normal',
    backgroundColor: 'transparent',
    border: '3px solid #003366',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#114477'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      width: 30,
      height: 30
    }
  })
};

export default styles;
