const contentful = require('contentful');

let client;

export const createContentfulClient = (spaceId, accessToken) => {
  client = contentful.createClient({ space: spaceId, accessToken });
};

const contentTypes = { testimonial: 'testimonial' };

const contentfulApiClient = {
  fetchTestimonials: async () => {
    const { items } = await client.getEntries({
      content_type: contentTypes.testimonial,
      order: 'sys.createdAt'
    });

    const testimonials = items.map(({ sys, fields }) => ({
      id: sys.id,
      name: fields.clientName,
      reference: fields.clientTitle,
      quote: fields.testimonial,
      img: fields.clientImage.fields.file.url,
      logo: fields.companyLogo.fields.file.url,
      caseStudy: fields.caseStudyUrl
    }));

    return testimonials;
  }
};

export default contentfulApiClient;
