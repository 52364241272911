const getStyles = ({ iconWidth, paddingBottom } = {}) => ({
  featureContainer: theme => ({
    paddingBottom: paddingBottom,
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '72px !important'
    }
  }),
  title: theme => ({
    fontFamily: 'Gilroy-Bold',
    fontSize: 36,
    lineHeight: 1.39,
    textAlign: 'left',
    color: '#0b1829',
    margin: 0,
    [theme.breakpoints.down('lg')]: {
      fontSize: 30
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      marginTop: 9
    }
  }),
  description: theme => ({
    fontFamily: 'Gilroy',
    fontSize: 16,
    lineHeight: 1.88,
    textAlign: 'left',
    color: '#0b1829',
    margin: '16px 0',
    paddingBottom: 6.5,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  }),
  button: theme => ({
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  }),
  mediaContainer: theme => ({
    textAlign: 'center',
    '& > video': {
      width: 500,
      height: 400,
      [theme.breakpoints.down('lg')]: {
        width: 485
      },
      [theme.breakpoints.down('sm')]: {
        width: 312,
        height: 250
      }
    },
    '& img': {
      width: 500,
      height: 400,
      objectFit: 'cover',
      [theme.breakpoints.down('lg')]: {
        width: 485
      },
      [theme.breakpoints.down('sm')]: {
        width: 312,
        height: 250
      }
    }
  }),
  iconContainer: theme => ({
    width: 64,
    height: 64,
    padding: '27.3px 6px 23.3px',
    boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.05)',
    backgroundColor: '#ffffff',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 48,
      height: 48
    },
    '& img': {
      width: 50,
      objectFit: 'contain',
      [theme.breakpoints.down('sm')]: {
        width: (iconWidth * 3) / 4
      }
    }
  }),
  iconText: {
    fontFamily: 'Gilroy-Semibold',
    fontSize: 16,
    textAlign: 'center',
    color: '#0b1829'
  },
  sendContainer: theme => ({
    backgroundColor: '#F3EBFA',
    borderRadius: 5,
    backgroundImage: "url('/imgs/services/send-information-bg.svg')",
    backgroundSize: 'cover',
    padding: '30px 45px',
    marginBottom: 30,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 18
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 5
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 15px'
    }
  }),
  sendTitle: theme => ({
    marginBottom: 1,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    fontWeight: '600',
    lineHeight: 1.42,
    textAlign: 'left',
    color: '#0b1829',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 18,
      marginBottom: 3.5
    }
  }),
  sendSubtitle: theme => ({
    marginTop: 0,
    fontFamily: 'Gilroy',
    fontSize: 18,
    lineHeight: 2,
    color: '#787b80',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      fontSize: 14
    }
  }),
  sendButtonContainer: theme => ({
    textAlign: 'end',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      paddingBottom: 6
    }
  }),
  sendButton: {
    backgroundColor: 'transparent',
    color: '#9846DD',
    border: '1px solid rgba(152, 70, 221, 0.5)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
      border: '1px solid rgba(152, 70, 221, 1)'
    }
  },
  pipeContainer: theme => ({
    position: 'relative',
    zIndex: -1,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }),
  commonPipe: theme => ({
    '& img': {
      left: 211,
      top: -246,
      [theme.breakpoints.down('lg')]: {
        left: 146,
        top: -244,
        maxWidth: 680
      }
    }
  }),
  warehousePipe: theme => ({
    '& img': {
      position: 'absolute',
      left: 196,
      top: -268,
      zIndex: -1,
      [theme.breakpoints.down('lg')]: {
        left: 82,
        top: -260
      }
    }
  }),
  domesticPipe: theme => ({
    '& img': {
      position: 'absolute',
      maxWidth: 700,
      left: 222,
      top: -360,
      zIndex: -1,
      [theme.breakpoints.down('lg')]: {
        left: 132,
        top: -300,
        maxWidth: 680
      }
    }
  }),
  worldPipe: theme => ({
    left: 211,
    top: -246,
    [theme.breakpoints.down('lg')]: {
      left: 146,
      top: -244
    }
  }),
  integrationsContainer: {
    marginTop: 9.5
  },
  responsiveButton: theme => ({
    '&:hover': { borderWidth: 2 },
    [theme.breakpoints.down('sm')]: {
      height: 48,
      fontSize: 14,
      paddingLeft: 23,
      paddingRight: 23
    }
  }),
  infoContainer: theme => ({
    padding: '0px 50px',
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  })
});

export default getStyles;
