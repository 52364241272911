import { Grid, Typography } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import CenteredGrid from '../shared/CenteredGrid';
import styles from './styles/createSwagSection';
import Img from '../shared/Img';

const CreateSwagSection = ({
  title = 'Create Swag Everyone Will Love!',
  subtitle = 'It’s that simple, we do all the work, so you can take the credit!'
}) => (
  <Grid container justifyContent="center" sx={styles.root}>
    <CenteredGrid>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs>
          <Typography variant="h5SemiBold" sx={styles.title}>
            {title}
          </Typography>
          <Typography variant="body1Medium" sx={styles.subtitle}>
            {subtitle}
          </Typography>
        </Grid>
        <Grid item>
          <Button sx={styles.getStartedButton} href="/dashboard/custom-swag-packs/catalog">
            Get started
          </Button>
        </Grid>
      </Grid>
    </CenteredGrid>
    <Img src="/imgs/public/blue-triangle.png" alt="blue-triangle" sx={styles.blueTriangle} />
    <Img src="/imgs/public/purple-triangle.png" alt="purple-triangle" sx={styles.purpleTriangle} />
  </Grid>
);

export default CreateSwagSection;
