const styles = {
  ovalContainer: {
    position: 'absolute',
    overflow: 'hidden',
    zIndex: -1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  figureBackground: theme => ({
    width: 300,
    height: 150,
    borderRadius: '0px 0px ',
    opacity: 0.05,
    backgroundColor: '#003399',
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }),
  figureBackgroundMobile: theme => ({
    width: 150,
    height: 74,
    borderRadius: '0px 0px ',
    opacity: 0.05,
    backgroundColor: '#003399',
    position: 'absolute',
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  }),
  ovalPrimary: theme => ({
    width: 500,
    height: 500,
    opacity: 0.05,
    borderRadius: 250,
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }),
  ovalPrimaryMobile: theme => ({
    width: 500,
    height: 500,
    opacity: 0.05,
    borderRadius: 250,
    position: 'absolute',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  }),
  ovalBlueEmptyTop: {
    width: 500,
    height: 500,
    opacity: 0.05,
    borderRadius: 250,
    border: 'solid 32px #134283',
    position: 'absolute',
    top: -230,
    right: 0
  },
  ovalBlueEmptyTop2: {
    width: 470,
    height: 470,
    opacity: 0.08,
    borderRadius: 235,
    border: 'solid 32px #134283',
    position: 'absolute',
    top: 0,
    right: 0
  },
  ovalBlueEmpty: {
    width: 340,
    height: 340,
    opacity: 0.05,
    borderRadius: 250,
    border: 'solid 32px #134283',
    position: 'absolute'
  },
  ovalBlueBox: {
    width: 290,
    height: 290,
    borderRadius: 145,
    backgroundColor: '#3577d4',
    opacity: 0.08,
    position: 'absolute',
    top: 0,
    right: 0
  },
  ovalBlueCoffeeCup: {
    width: 66,
    height: 66,
    borderRadius: 33,
    backgroundColor: '#9846dd',
    opacity: 0.08,
    position: 'absolute',
    top: 580,
    right: 0
  },
  ovalBlueGoogle: {
    width: 198,
    height: 198,
    borderRadius: 99,
    backgroundColor: '#3577d4',
    opacity: 0.08,
    position: 'absolute',
    top: 600,
    left: 0
  },
  ovalBlueBYB: {
    width: 190,
    height: 190,
    borderRadius: 95,
    backgroundColor: '#3577d4',
    opacity: 0.08,
    right: 0,
    top: 1250,
    position: 'absolute'
  },
  ovalBlueRocket: {
    width: 520,
    height: 520,
    borderRadius: 260,
    opacity: 0.08,
    border: 'solid 32px #3577d4',
    position: 'absolute',
    top: 1730,
    color: '#3577d4',
    left: 0
  },
  ovalBlueOS: {
    width: 418,
    height: 418,
    borderRadius: 209,
    backgroundColor: '#3577d4',
    opacity: 0.08,
    position: 'absolute',
    left: 390,
    top: 2870
  },
  ovalBlueCU: {
    width: 141,
    height: 141,
    borderRadius: 70.5,
    backgroundColor: '#3577d4',
    opacity: 0.08,
    position: 'absolute',
    left: 0,
    top: 4000
  },
  ovalBluePhone: {
    width: 150,
    height: 150,
    borderRadius: 75,
    border: 'solid 12px #134283',
    opacity: 0.08,
    position: 'absolute',
    top: 0,
    right: 0
  },
  ovalPurpleBYB: {
    width: 490,
    height: 490,
    borderRadius: 245,
    backgroundColor: '#9846dd',
    opacity: 0.08,
    position: 'absolute',
    top: 890,
    right: 0
  },
  ovalPurpleRocket: {
    width: 66,
    height: 66,
    borderRadius: 33,
    backgroundColor: '#9846dd',
    opacity: 0.08,
    position: 'absolute',
    right: 590,
    top: 2080
  },
  ovalPurpleRockets: {
    width: 160,
    height: 160,
    borderRadius: 75,
    border: 'solid 12px #9846dd',
    opacity: 0.08,
    position: 'absolute',
    top: 950,
    left: 0
  },
  ovalPurpleOS: {
    width: 83,
    height: 83,
    borderRadius: 42.5,
    backgroundColor: '#9846dd',
    opacity: 0.08,
    position: 'absolute',
    left: 390,
    top: 3200
  },
  ovalPurpleCU1: {
    width: 49,
    height: 49,
    borderRadius: 24.5,
    backgroundColor: '#9846dd',
    opacity: 0.08,
    position: 'absolute',
    left: 0,
    top: 3580
  },
  ovalPurpleCU2: {
    width: 518,
    height: 518,
    borderRadius: 258,
    backgroundColor: '#9846dd',
    opacity: 0.08,
    position: 'absolute',
    left: 0,
    top: 3600
  },
  ovalPurpleCU3: {
    width: 141,
    height: 141,
    borderRadius: 70.5,
    backgroundColor: '#9846dd',
    opacity: 0.08,
    position: 'absolute',
    right: 0,
    top: 4140
  }
};

export default styles;
