import { Grid, Typography } from '@mui/material';
import { imageSrcSet } from '../../../utils';
import CenteredGrid from '../../shared/CenteredGrid';
import Img from '../../shared/Img';
import SectionTag from '../../shared/SectionTag';
import styles from './styles/ourPhilosophy';

const OurPhilosophy = () => (
  <CenteredGrid container justifyContent="center" sx={styles.container}>
    <Grid item md={5} xs={12}>
      <Img
        alt="title"
        src="/imgs/landingPagesSSR/custom-pack/philosophy.png"
        srcSet={imageSrcSet('/imgs/landingPagesSSR/custom-pack/philosophy.png')}
        sx={styles.img}
      />
    </Grid>
    <Grid item sx={styles.textContainer}>
      <SectionTag tag="PHILOSOPHY" left={false} />
      <Typography variant="inherit" component="h2" sx={styles.title}>
        Our Swag Philosophy
      </Typography>
      <Typography variant="inherit" sx={styles.text}>
        No one needs another crappy item they are going to throw away. We’re constantly on the hunt for high quality,
        useful items that people will love. With that in mind, if you don’t see what you are looking for, just let us
        know and we’ll source options.
      </Typography>
    </Grid>
  </CenteredGrid>
);

export default OurPhilosophy;
