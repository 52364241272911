import { Grid, Button, FormControl, Typography } from '@mui/material';
import { imageSrcSet } from '../../../../src/helpers/utils';
import { TextField } from '../../shared/reactHookFormFields';
import Subscribe from '../../shared/Subscribe';
import Img from '../../shared/Img';
import styles from './styles/landingSubscribe';

const Subscriber = ({ register, errors }) => (
  <>
    <Typography variant="inherit" component="h2" sx={styles.title}>
      Subscribe to SwagUp Blog!
    </Typography>
    <Typography variant="inherit" sx={styles.subTitle}>
      Be up to date with the latest trends on your favorite topics ranging from marketing, sales, leadership, startup
      culture and of course swag! (p.s no we will not spam you with a million emails, that&apos;s just rude!)
    </Typography>
    <Grid container sx={{ paddingTop: 14 }}>
      <Grid item xs={12} sm={9} md={7}>
        <FormControl fullWidth>
          <TextField
            placeholder="Email address"
            {...register('email')}
            error={errors?.email?.message}
            sx={styles.emailInput}
          />
        </FormControl>
      </Grid>
      <Grid container item xs={12} sm={3} md={5} sx={styles.buttonContainer}>
        <Button variant="contained" type="submit" sx={styles.subscribeButton}>
          Subscribe
        </Button>
      </Grid>
    </Grid>
  </>
);

const LandingSubscribe = () => (
  <Grid container justifyContent="center" sx={styles.container}>
    <Grid container sx={styles.center}>
      <Grid item xs={12} md={6} sx={styles.textContainer}>
        <Subscribe FormComponent={Subscriber} socials={false} fullWidth />
      </Grid>
      <Grid item container justifyContent="center" md={6} sx={styles.imageContainer}>
        <Img
          src="/imgs/blog/mailbox.png"
          srcSet={imageSrcSet('/imgs/blog/mailbox.png')}
          sx={styles.image}
          alt="Stay in the loop"
        />
      </Grid>
    </Grid>
  </Grid>
);

export default LandingSubscribe;
