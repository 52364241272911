import { useState, useEffect } from 'react';
import { fetchNotificationServer } from '../../api/notifications';
import { Drawer } from '@swagup-com/react-ds-components';
import {
  Badge,
  Grid,
  Typography,
  Box,
  IconButton,
  ListItem,
  ListItemText,
  Divider,
  List,
  styled,
  badgeClasses
} from '@mui/material';
import ClickableTooltip from './ClickableTooltip';
import { scrollBar } from '../../common/styles';

const NotificationMessage = ({ message, markAsRead }) => (
  <ListItem>
    <ListItemText>
      <Typography component="div" color={markAsRead ? 'textSecondary' : 'textPrimary'}>
        <Box fontSize={12} lineHeight="normal" fontWeight={markAsRead ? 500 : 600}>
          {message}
        </Box>
      </Typography>
    </ListItemText>
  </ListItem>
);

const notificationStyles = {
  notificationList: {
    height: 'auto',
    maxHeight: '50vh',
    overflow: 'auto',
    '&, & *': {
      ...scrollBar
    }
  }
};

const StyledBadge = styled(Badge)(theme => ({
  [badgeClasses.badge]: {
    fontFamily: 'Gilroy',
    fontSize: 12,
    boxShadow: ' 0 2px 12px 0 rgba(53, 119, 212, 0.25)',
    border: `2px solid ${theme.palette.common.white}`,
    padding: '0 4px'
  }
}));

const NotificationsIcon = () => (
  <div style={{ cursor: 'pointer' }}>
    <img src="/imgs/public/notifications.svg" alt="notifications" />
  </div>
);

const unreadNotifications = notifications => notifications?.filter(a => !a.read_at).length || 0;

export const NotificationList = ({ notifications = [] }) => {
  return (
    <List sx={notificationStyles.notificationList}>
      {notifications.map((notification, i) => (
        <div key={notification.id}>
          {i !== 0 && <Divider />}
          <NotificationMessage message={notification.message} markAsRead={notification.read_at} />
        </div>
      ))}
    </List>
  );
};

const Notifications = ({ notifications = [], isPending }) => (
  <ClickableTooltip disabled={notifications.length === 0} title={<NotificationList notifications={notifications} />}>
    <StyledBadge
      badgeContent={unreadNotifications(notifications)}
      color="primary"
      variant="standard"
      invisible={isPending || unreadNotifications(notifications) === 0}
    >
      <NotificationsIcon />
    </StyledBadge>
  </ClickableTooltip>
);

const NotificationDrawer = ({ notifications = [], isPending }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton color="inherit" onClick={() => setOpen(true)}>
        <StyledBadge
          badgeContent={unreadNotifications(notifications)}
          color="primary"
          variant="standard"
          invisible={isPending || unreadNotifications(notifications) === 0}
        >
          <NotificationsIcon />
        </StyledBadge>
      </IconButton>

      <Drawer open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
        <Grid container>
          <Grid item xs={12}>
            <Typography component="h5">
              <Box fontSize={24} color="#0f2440" fontWeight="fontWeightBold" m={1}>
                Notifications
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <NotificationList notifications={notifications} />
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

const NotificationsContainer = ({ variant }) => {
  const [isPending, setIsPending] = useState(true);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      setIsPending(true);
      const fetchedNotifications = await fetchNotificationServer();
      setNotifications(fetchedNotifications.result === 'ok' ? fetchedNotifications.data : []);
      setIsPending(false);
    };

    fetchNotifications();
  }, []);

  return variant === 'tooltip' ? (
    <Notifications notifications={notifications} isPending={isPending} />
  ) : (
    <NotificationDrawer notifications={notifications} isPending={isPending} />
  );
};

export default NotificationsContainer;
