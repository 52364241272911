import { useContext, useEffect, useState } from 'react';
import { Grid, Tabs, Tab, Box, Typography } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import styles from './styles/home';
import { TabPanel, PackItemCard, OtherPacksCard } from './LetsGetStartedExtension';
import platformApiClient from '../../api/platformApiClient';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import Img from '../shared/Img';

const tabsStyles = theme => ({
  backgroundColor: 'transparent',
  [theme.breakpoints.down('sm')]: {
    fontSize: 14
  }
});

const ScrollMenuDataItemContainer = ({ children }) => (
  <div
    style={{
      maxWidth: 486,
      borderBottom: 0,
      padding: 0,
      marginLeft: 12,
      marginRight: 12,
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 12,
      overflowX: 'hidden',
      overflowY: 'hidden',
      maxHeigth: 570
    }}
  >
    {children}
  </div>
);

const ScrollMenuItemCustomPack = () => (
  <Box sx={styles.customPackContainer}>
    <a href="/dashboard/custom-swag-packs/catalog">
      <Box sx={styles.packImageWrapperCustom}>
        <Img src="/imgs/public/custom-pack-illustration.svg" sx={styles.imgPack} alt="custom-pack" />
      </Box>
    </a>
    <a href="/dashboard/custom-swag-packs/catalog">
      <Typography variant="h3SemiBold" sx={styles.packNameCustomText}>
        Create a <br /> custom pack!
      </Typography>
    </a>
  </Box>
);

const LeftArrow = () => {
  const { scrollPrev } = useContext(VisibilityContext);

  return <KeyboardArrowLeftRounded sx={[styles.scrollArrow, { left: 0, zIndex: 999 }]} onClick={() => scrollPrev()} />;
};

const RightArrow = () => {
  const { scrollNext } = useContext(VisibilityContext);
  return (
    <KeyboardArrowRightRounded sx={[styles.scrollArrow, { right: 0, zIndex: 999 }]} onClick={() => scrollNext()} />
  );
};

const LetsGetStarted = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [onlyIn, setOnlyIn] = useState(false);
  const [packs, setPacks] = useState(undefined);

  const fetchPacks = async () => {
    const packData = await platformApiClient.getPresetPacks();
    setPacks(packData);
  };

  useEffect(() => {
    fetchPacks();
  }, []);

  const handleChange = (event, newValue) => setTabIndex(newValue);

  const getTabStyle = index => {
    return tabIndex === index ? styles.tabItemOn : styles.tabItemOff;
  };

  if (!packs) return <div />;

  return (
    <Grid
      id="sw-let-get-started"
      container
      justifyContent="center"
      sx={{ paddingTop: 10, paddingBottom: 56, backgroundColor: '#e9f0fa' }}
    >
      <Grid item xs={12}>
        <Box sx={[styles.sectionContainer, { paddingLeft: 0, paddingRight: 0 }]}>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="display2SemiBold" sx={[styles.sectionTitleStandard, styles.letGetStartedTitle]}>
                Let’s get started
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Tabs sx={tabsStyles} value={tabIndex} onChange={handleChange} textColor="primary" centered>
                <Tab label="Preset packs" sx={getTabStyle(0)} />
                <Tab label="Custom pack" sx={getTabStyle(1)} />
                <Tab label="Bulk swag" sx={getTabStyle(2)} />
              </Tabs>
              <SwipeableViews axis="x" index={tabIndex}>
                <TabPanel value={tabIndex} index={0} style={{ maxWidth: '100%', position: 'relative' }}>
                  <Typography variant="subtitle1SemiBold" sx={[styles.promotionTitle, styles.letGSTabTitle]}>
                    For the hustler - quick launch your Swag pack and get on with life.
                  </Typography>
                  <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                    {packs.map((pack, index) => (
                      <ScrollMenuDataItemContainer
                        onlyIn={onlyIn}
                        onlyInTrigger={() => setOnlyIn(true)}
                        animationDelay={100 + index * 700}
                        key={pack.id}
                        itemId={pack.id}
                      >
                        <PackItemCard pack={pack} />
                      </ScrollMenuDataItemContainer>
                    ))}
                    <ScrollMenuItemCustomPack
                      onlyIn={onlyIn}
                      onlyInTrigger={() => setOnlyIn(true)}
                      animationDelay={100 + packs.length * 700}
                      key="custom-packs"
                      itemId="custom-packs"
                    />
                  </ScrollMenu>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <Typography variant="subtitle1SemiBold" sx={[styles.promotionTitle, styles.letGSTabTitle]}>
                    Get the full SwagUp experience with total control <br /> over your pack, down to the last detail.
                  </Typography>
                  <OtherPacksCard
                    title="How it works:"
                    buttonText="BUILD YOUR PACK"
                    href="/dashboard/custom-swag-packs/catalog"
                    imgSrc="/imgs/public/custom-pack.png"
                    imgSrcSet="/imgs/public/custom-pack.png 1x, /imgs/public/custom-pack@2x.png 2x,
                      /imgs/public/custom-pack@3x.png 3x"
                    list={[
                      { key: 1, text: 'Pick your packaging and items, then fill in your order details' },
                      { key: 2, text: 'We offer around 10 high quality items per category' },
                      { key: 3, text: 'You can choose multiple items per category' },
                      { key: 4, text: 'Pricing are estimates' }
                    ]}
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                  <Typography variant="subtitle1SemiBold" sx={[styles.promotionTitle, styles.letGSTabTitle]}>
                    While swag packs are our thing, we handle all types <br /> of swag requests.
                  </Typography>
                  <OtherPacksCard
                    title="How it works:"
                    buttonText="CHOOSE YOUR SWAG"
                    href="/dashboard/bulk/catalog"
                    imgSrc="/imgs/public/wsocks.png"
                    imgSrcSet="/imgs/public/wsocks.png 1x, /imgs/public/wsocks@2x.png 2x,
                      /imgs/public/wsocks@3x.png 3x"
                    list={[
                      { key: 1, text: 'Selected from our curated selection (more options available)' },
                      { key: 2, text: 'Submit your request, receive design proofs, and approve order' },
                      { key: 3, text: 'Ship to you or store in our warehouse for later' }
                    ]}
                  />
                </TabPanel>
              </SwipeableViews>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LetsGetStarted;
