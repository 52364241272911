import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import CenteredGrid from '../../shared/CenteredGrid';
import styles from './styles/haveAQuestion';

const HaveAQuestion = ({ onOpenModal }) => (
  <Grid container justifyContent="center" sx={styles.externalContainer}>
    <CenteredGrid container justifyContent="center" sx={styles.container}>
      <Grid item xs={12}>
        <Typography variant="inherit" sx={styles.title}>
          Still have a question?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="inherit" sx={styles.subText}>
          Feel free to contact us now
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={onOpenModal} sx={styles.button}>
          Contact Us
        </Button>
      </Grid>
    </CenteredGrid>
  </Grid>
);

export default HaveAQuestion;
