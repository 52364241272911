const styles = {
  containerBuildYB: theme => ({
    mb: 25,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      paddingBottom: 2.5
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('1030')]: {
      width: 960
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  sectionContainerRippling: theme => ({
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 1.25
    }
  }),
  promotionTitleAU: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-Semibold',
    fontSize: 56,
    textAlign: 'center',
    marginTop: 9.25,
    marginBottom: 6,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      marginTop: 6,
      textAlign: 'center',
      marginBottom: 2.5
    }
  }),
  containerAllAURippling: theme => ({
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 18,
    [theme.breakpoints.down('md')]: {
      marginTop: 2.5
    }
  }),
  wrapperAUImg: theme => ({
    width: '100%',
    height: '100%',
    borderBottomRightRadius: 25,
    borderTopLeftRadius: 25,
    padding: '0px 20px 0px 20px',
    [theme.breakpoints.down('lg')]: {
      padding: '0px 45px 0px 45px'
    },
    [theme.breakpoints.down('sm')]: {
      borderBottomRightRadius: 15,
      borderTopLeftRadius: 15,
      padding: '0px 20px 0px 20px'
    }
  }),
  AUImg: theme => ({
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderBottomRightRadius: 25,
    borderTopLeftRadius: 25,
    [theme.breakpoints.down('lg')]: {
      borderBottomRightRadius: 6.25,
      borderTopLeftRadius: 6.25
    }
  }),
  paragraphAboutUsRippling: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    textAlign: 'left',
    lineHeight: '36px',
    paddingLeft: 10,
    '& p': {
      marginTop: 0,
      fontFamily: 'Gilroy-Medium',
      opacity: 0.8
    },
    [theme.breakpoints.down('1035')]: {
      fontSize: 10,
      textAlign: 'justify',
      paddingLeft: 0,
      '& p': {
        fontSize: '16px !important',
        padding: '0px 45px 0px 0px',
        opacity: 0.8,
        justify: 'left',
        lineHeight: '25px'
      }
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
      textAlign: 'justify',
      paddingLeft: 0,
      '& p': {
        fontSize: '14px !important',
        padding: '0px 45px 0px 0px',
        justify: 'left',
        opacity: 0.8,
        lineHeight: 'normal'
      }
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      textAlign: 'justify',
      paddingLeft: 0,
      marginTop: 5,
      '& p': {
        fontSize: '12px !important',
        padding: '0px 20px 0px 20px',
        justify: 'left',
        lineHeight: 'normal'
      }
    }
  }),
  buttonAUR: theme => ({
    width: 172,
    py: 4,
    marginTop: 15,
    [theme.breakpoints.down('md')]: {
      width: 150,
      fontSize: 12,
      py: 3,
      marginTop: 5.75,
      marginLeft: 7.25
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 7.5
    }
  })
};

export default styles;
