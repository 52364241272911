const styles = {
  container: theme => ({
    padding: '180px 0 100px 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      px: 6
    }
  }),
  textContainer: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-Bold',
    maxWidth: 520,
    paddingTop: 37.5,
    paddingRight: 15,
    [theme.breakpoints.down('sm')]: { paddingTop: 0 }
  }),
  title: {
    fontSize: '3.5rem',
    lineHeight: 1.29,
    maxWidth: 430,
    my: 7
  },
  text: {
    opacity: 0.54,
    fontSize: '1.25rem',
    lineHeight: 1.8,
    maxWidth: 490
  },
  imgContainer: theme => ({
    width: '88%',
    height: '75%',
    position: 'absolute',
    top: '24%',
    left: '8%',
    zIndex: -999,
    borderRadius: '75px 5px 75px 5px',
    boxShadow: '0 30px 100px 0 rgba(212, 217, 226, 0.5)',
    [theme.breakpoints.down('sm')]: {
      height: '80%',
      width: '95%',
      left: '2%'
    }
  }),
  image: {
    objectFit: 'contain',
    width: '100%',
    marginTop: -20
  },
  button: theme => ({
    borderRadius: 8,
    padding: '26px 84px',
    margin: '45px 0 60px 0',
    [theme.breakpoints.down('sm')]: { padding: '23px 60px' }
  })
};

export default styles;
