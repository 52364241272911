import { Box, Grid, Typography, Link, Button } from '@mui/material';
import { imageSrcSet } from '../../../utils';
import SectionTag from '../../shared/NewSectionTag';
import CenteredGrid from '../../shared/CenteredGrid';
import styles from './styles/buildYourBrand';
import Img from '../../shared/Img';

const BuildYourBrand = () => (
  <Grid container justifyContent="center" sx={styles.containerMainBYBEG}>
    <CenteredGrid sx={styles.center}>
      <Box sx={styles.sectionContainerEG}>
        <SectionTag tag="BUILD YOUR BRAND" />
        <Typography variant="inherit" component="h2" sx={styles.promotionTitleBYBEventGeek}>
          What’s a Swag Pack?
        </Typography>
        <Box sx={styles.containerBYBEventGeek}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={5}>
              <Box sx={styles.wrapperBYBImgEG}>
                <Img
                  src="/imgs/landingPagesSSR/build-your-brand.png"
                  srcSet={imageSrcSet('/imgs/landingPagesSSR/build-your-brand.png')}
                  alt="group"
                  sx={styles.bYBImg}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={7} sx={styles.menuSectionWrapper}>
              <Box component="ul" sx={styles.menuSectionEventGeek}>
                <li>An assortment of high quality branded items bundled together</li>
                <li>Consists of various items, from notebooks to sweatshirts and beyond</li>
                <li>Perfect for clients and employees to build brand awareness & loyalty</li>
              </Box>

              <Typography variant="inherit" sx={styles.regularTextBYBEventGeek}>
                Don’t need a swag pack? <Link to="/dashboard/bulk/catalog">Order bulk swag instead</Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid item container xs={12} justifyContent="center" sx={styles.containerButtonBYB}>
          <Button href="/dashboard/onboarding" width={250} sx={styles.buttonBYB}>
            Grab a Free Sample Pack
          </Button>
        </Grid>
      </Box>
    </CenteredGrid>
  </Grid>
);

export default BuildYourBrand;
