import { Grid, Typography, Box } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import AnimationVisibilityTracker from '../shared/AnimationVisibilityTracker';
import styles from './styles/home';
import CenteredGrid from '../shared/CenteredGrid';
import { imageSrcSet } from '../../utils';
import Img from '../shared/Img';

const PromotionHome = () => {
  return (
    <>
      <Grid container justifyContent="center" sx={{ paddingBottom: 14, paddingTop: 16 }}>
        <CenteredGrid sx={[styles.center, styles.promotionHomeCenter]}>
          <Typography variant="display1Bold" sx={styles.promotionTitle}>
            Swag should be fun
          </Typography>
          <Typography variant="subtitle1SemiBold" sx={styles.promotionSubTextPH}>
            There’s nothing like receiving an awesome swag pack, but putting them{' '}
            <Box component="br" sx={styles.noMobile} /> together was a nightmare. We’ve made it simple.
          </Typography>
          <Grid container justifyContent="center" spacing={10} sx={styles.promotionHomeWrapper}>
            <Grid item md={6} xs={12}>
              <Grid container justifyContent="center" direction="column">
                <AnimationVisibilityTracker animationIn="bounceIn" animationOut="bounceOut">
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Box sx={styles.beforeSwagUp}>
                        <Typography variant="h6SemiBold">
                          BEFORE SWAGUP
                          <Img
                            sx={[styles.imageContainer, { marginBottom: -0.5 }]}
                            src={`${process.env.PUBLIC_URL}/imgs/homeLight/hand.png`}
                            alt="hand"
                          />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </AnimationVisibilityTracker>

                <Typography variant="h2SemiBold" sx={[styles.promotionText, { marginBottom: 10.5 }]}>
                  Out with the old
                </Typography>

                <Grid item xs align="center" sx={{ marginBottom: 10.5 }}>
                  <Box sx={[styles.imagePromoContainer, { width: 240 }]}>
                    <Img
                      src={`${process.env.PUBLIC_URL}/imgs/homeLight/old-illustration.svg`}
                      sx={styles.imgStandard}
                      alt="Swag old process Illustration"
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1SemiBold"
                    sx={[
                      styles.regularTextInc,
                      theme => ({
                        [theme.breakpoints.up('md')]: {
                          marginBottom: 8,
                          lineHeight: 1.5
                        }
                      })
                    ]}
                  >
                    Long days searching for swag <br />
                    Losing track of inventory <br />
                    Overflowing closets with swag <br />
                    Bribe coworkers to put together packs
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container justifyContent="center" sx={styles.promotionContentNew}>
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <AnimationVisibilityTracker animationIn="bounceIn" animationOut="bounceOut">
                        <Box sx={styles.afterSwagUp}>
                          <Typography variant="h6SemiBold">
                            AFTER SWAGUP
                            <Img
                              sx={styles.imageContainer}
                              src={`${process.env.PUBLIC_URL}/imgs/homeLight/rocket.png`}
                              alt="rocket"
                            />
                          </Typography>
                        </Box>
                      </AnimationVisibilityTracker>
                    </Grid>
                  </Grid>

                  <Typography variant="h2SemiBold" sx={[styles.promotionText, { marginBottom: 10, color: '#0f2440' }]}>
                    In with the new
                  </Typography>
                </Grid>
                <Grid item sx={[styles.imageAfterSawgUp, { marginBottom: 24 }]} align="center">
                  <Box sx={[styles.imagePromoContainer, { width: 344 }]}>
                    <Img
                      srcSet={imageSrcSet(`${process.env.PUBLIC_URL}/imgs/homeLight/box-2-x.png`)}
                      sx={styles.imgStandard}
                      alt="New Swag Process"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1SemiBold"
                    sx={[styles.regularTextInc, { marginBottom: 6, color: '#0f2440' }]}
                  >
                    Swag sourced, designed, packed,
                    <br /> stored, and shipped for you!
                  </Typography>
                  <Grid item xs align="center" sx={{ margin: '50px 0 56px' }}>
                    <Button
                      href="/dashboard/custom-swag-packs/catalog"
                      variant="primary"
                      sx={[
                        styles.mainLandingButton,
                        styles.responsiveButton,
                        { width: 272, backgroundColor: '#9846dd' }
                      ]}
                    >
                      BUILD YOUR PACK
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CenteredGrid>
      </Grid>
    </>
  );
};

export default PromotionHome;
