import { Box, Button, Grid, Typography } from '@mui/material';
import CenteredGrid from '../../shared/CenteredGrid';
import Img from '../../shared/Img';
import SectionTag from '../../shared/SectionTag';
import styles from './styles/letsGetStarted';

const LetsGetStarted = () => (
  <CenteredGrid container justifyContent="center" sx={styles.container}>
    <Grid item sx={styles.textContainer}>
      <SectionTag tag="GET STARTED" left={false} />
      <Typography variant="inherit" component="h2" sx={styles.title}>
        Let’s get started with building your pack
      </Typography>
      <Typography variant="inherit" sx={styles.text}>
        And get your packs in no time!
      </Typography>
    </Grid>
    <Grid item md={5} xs={12} sx={{ paddingTop: 20, position: 'relative' }}>
      <Grid container justifyContent="center">
        <Img alt="title" src="/imgs/landingPagesSSR/custom-pack/getstarted.svg" sx={styles.image} />
        <Button href="/dashboard/custom-swag-packs/catalog" variant="contained" sx={styles.button}>
          Get Started
        </Button>
      </Grid>
      <Box sx={styles.imgContainer}>&nbsp;</Box>
    </Grid>
  </CenteredGrid>
);

export default LetsGetStarted;
