import { Grid } from '@mui/material';
import styles from './styles/CategoryLabel';

const switchStyles = variant => {
  switch (variant) {
    case 'small':
      return styles.PostCategorySmall;
    case 'outlined':
      return styles.PostCategoryOutlined;
    default:
      return styles.PostCategory;
  }
};

const CategporyLabel = ({ category, variant }) => (
  <Grid container justifyContent="center" alignItems="center" sx={switchStyles(variant)}>
    <Grid item>{category}</Grid>
  </Grid>
);
export default CategporyLabel;
