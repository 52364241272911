import { Grid, Box, Typography } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import SectionTag from './SectionTag';
import styles from './styles/home';
import CenteredGrid from '../shared/CenteredGrid';
import { imageSrcSet } from '../../utils';
import Img from '../shared/Img';

const SamplePack = () => {
  return (
    <Grid container justifyContent="center" sx={styles.samplePackContainer}>
      <CenteredGrid sx={[styles.center, styles.promotionHomeCenter]}>
        <Grid container justifyContent="center" spacing={6} sx={styles.promotionHomeWrapperSP}>
          <Grid item xs={12}>
            <SectionTag tag="SAMPLE PACK" />
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent="flex-start" direction="column">
              <Typography variant="display2SemiBold" sx={styles.promotionTextHEH}>
                Grab a free <Box component="br" sx={styles.noMobile} />
                sample pack!
              </Typography>
              <Box sx={styles.sampleSubText}>
                <Typography variant="subtitle2SemiBold" sx={styles.homePromoMiddleHAH}>
                  Get a sense of what it’s like to unbox one of our fully custom swag packs. Request your sample pack
                  below and we’ll have it to your office the next day, totally free*
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12} sx={{ paddingRight: 0 }}>
            <Grid container justifyContent="center" sx={styles.samplePackButtonContainerSP}>
              <Grid item>
                <Grid container justifyContent="center" direction="column" sx={styles.samplePackWhiteCard}>
                  <Grid item xs sx={styles.imageSamplePack} align="center">
                    <Box sx={styles.imagePromoContainer}>
                      <Img
                        srcSet={imageSrcSet(`${process.env.PUBLIC_URL}/imgs/homeLight/swagup-sample-pack.png`)}
                        sx={styles.imgSamplePack}
                        alt="SwagUp Sample Pack"
                      />
                    </Box>
                  </Grid>

                  <Grid container justifyContent="center" sx={styles.samplePackButtonWrapper}>
                    <Button
                      href="/dashboard/onboarding"
                      variant="primary"
                      sx={[
                        styles.mainLandingButton,
                        styles.responsiveButton,
                        { width: 330, backgroundColor: '#9846dd' }
                      ]}
                    >
                      REQUEST PACK
                    </Button>
                    <Typography variant="h6SemiBold" sx={styles.samplePackNotice}>
                      *International customers cover shipping
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CenteredGrid>
    </Grid>
  );
};

export default SamplePack;
