import Helmet from '../shared/Helmet';
import seoTags from '../../utils/seoTags';
import GalleryHome from './GalleryHome';
import { useQuery } from 'react-query';
import wordpressApiClient from '../../api/wordpressApiClient';

const Gallery = () => {
  const { data: mediaGallery } = useQuery('mediaGallery', wordpressApiClient.getGalleryImages);
  const { data: categories } = useQuery('galleryCategories', wordpressApiClient.fetchGalleryCategories);

  return (
    <>
      <Helmet tags={seoTags.gallery} />
      <GalleryHome mediaGallery={mediaGallery} categories={categories} />
    </>
  );
};

export default Gallery;
