import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAuth } from '../Authentication/index';
import { createMenuItemProps, accountMenuItemList } from './common';
import HeaderMenu from './Menu';
import ClickableTooltip from './ClickableTooltip';
import Questions from './Questions';
import AccountControl from './AccountControl';
import UserAvatar from './UserAvatar';
import platformApiClient from '../../api/platformApiClient';
import { useProfileCore } from '../../api/platformApiHooks';

const styles = {
  container: {
    paddingTop: 4,
    maxWidth: 160
  },
  loginText: theme => ({
    cursor: 'pointer',
    paddingBottom: 1,
    textDecoration: 'none',
    color: theme.palette.neutral['900'],
    padding: 0
  })
};

export const LoggedOut = () => {
  const { login } = useAuth();

  return (
    <Grid container justifyContent="space-between" alignItems="center" sx={styles.container}>
      <div>
        <Questions />
      </div>
      <Grid item>
        <Button variant="text" sx={styles.loginText} onClick={() => login()}>
          Login / Sign Up
        </Button>
      </Grid>
    </Grid>
  );
};

const ProfileImg = () => {
  return (
    <div style={{ cursor: 'pointer' }}>
      <img src={`/imgs/public/profile-outline.svg`} alt="profile" />
    </div>
  );
};

export const AccountProfile = ({ isDashboardHeader }) => {
  const { logout } = useAuth();
  const { data: profile, isFetching, isError } = useProfileCore();
  const { currentAccount } = profile ?? {};
  const { headerFeMultiAccountControlTemp101421: showMultiAccount } = useFlags();
  const logoutMenuItem = createMenuItemProps('Sign out', '', '', false, '/imgs/public/signOut.svg', logout);

  const handleAccountSwap = async accountId => {
    await platformApiClient.setAccount(profile.id, { current_account: accountId });
    const newLocation = isDashboardHeader ? '/dashboard' : '/';
    window.location.assign(`${window.location.origin}${newLocation}`);
  };

  if (showMultiAccount) {
    return (
      !isFetching &&
      !isError && (
        <ClickableTooltip
          title={<AccountControl profile={profile} logout={logout} onSwapAccount={handleAccountSwap} />}
        >
          <UserAvatar name={profile.first_name} nameForColorGeneration={currentAccount.name} size={30} />
        </ClickableTooltip>
      )
    );
  }

  return (
    <ClickableTooltip title={<HeaderMenu menuItemList={[...accountMenuItemList, logoutMenuItem]} />}>
      <ProfileImg />
    </ClickableTooltip>
  );
};

// * We only comment notifications until we update this functionality,spread getNotificationComponent on props
export const LoggedIn = ({ isDashboardHeader }) => (
  <Grid
    sx={{ maxWidth: 100 }}
    container
    alignItems="center"
    justifyContent="flex-end"
    data-testid="notifications-and-profile"
  >
    <div style={{ paddingRight: 20 }}>
      <Questions />
    </div>

    {/* {getNotificationComponent()} */}
    <AccountProfile isDashboardHeader={isDashboardHeader} />
  </Grid>
);
