import { Box, Button, Grid, Typography } from '@mui/material';
import { imageSrcSet } from '../../../utils';
import CenteredGrid from '../../shared/CenteredGrid';
import Img from '../../shared/Img';
import styles from '../shared/styles/landingSection';

const LandingSection = () => (
  <Grid container justifyContent="center" sx={styles.landingAreHereContainer}>
    <CenteredGrid sx={[styles.center, styles.promotionHomeCenter]}>
      <Grid container justifyContent="center" spacing={10} sx={styles.promotionHomeWrapperHAH}>
        <Grid item sm={7} xs={12} sx={styles.headerSW}>
          <Grid container direction="column">
            <Grid
              item
              container
              justifyContent="center"
              direction="row"
              alignItems="center"
              sx={styles.containerTextEventGeek}
            >
              <Grid item style={{ display: 'flex' }}>
                <Img
                  src="/imgs/landingPagesSSR/eventgeek-swagup.svg"
                  alt="Eventgeek and Swagup logos"
                  sx={styles.imageContainerEventGeek}
                />
              </Grid>
            </Grid>
            <Typography variant="inherit" component="h1" sx={styles.promotionTextHEH}>
              <span style={{ color: '#3577d4' }}>Swag</span> made simple for event managers
            </Typography>
            <Typography variant="inherit" sx={[styles.regularText, styles.homePromoMiddleHAH]}>
              SwagUp has partnered with EventGeek <br />
              to offer event managers an exclusive <br />
              10% discount off of your initial order
            </Typography>
            <Box sx={styles.landingHereButtonContainer}>
              <Grid container sx={styles.landingHereButtonContainerFix}>
                <Grid item>
                  <Button
                    href="/dashboard/custom-swag-packs/catalog"
                    variant="contained"
                    sx={[styles.buttonLandingSection, styles.buttonLandingSectionEG]}
                  >
                    Let’s get started!
                  </Button>
                </Grid>
                <Grid item style={{ display: 'flex' }}>
                  <Img sx={styles.imageContainerVector} src="/imgs/landingPagesSSR/vector1.svg" alt="arrow" />
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="inherit" sx={styles.textRightSideVector}>
                    10% discount
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid item sm={5} xs={12} sx={[styles.containerImgHeroEventGeek, { padding: 0 }]}>
          <Grid container justifyContent="center">
            <Img
              src="/imgs/landingPagesSSR/pack-hero.png"
              srcSet={imageSrcSet('/imgs/landingPagesSSR/pack-hero.png')}
              alt="pack"
              sx={styles.sPImg}
            />
          </Grid>
        </Grid>
      </Grid>
    </CenteredGrid>
  </Grid>
);

export default LandingSection;
