import { Animated } from 'react-animated-css';
import TrackVisibility from 'react-on-screen';

const ComponentToTrack = ({
  isVisible,
  children,
  animationIn,
  animationOut,
  animationInDelay,
  animationOutDelay,
  onlyIn,
  onlyInTrigger
}) => {
  if (!onlyIn && isVisible && onlyInTrigger) onlyInTrigger();

  return (
    <Animated
      animationIn={animationIn}
      animationOut={animationOut}
      isVisible={isVisible || !!onlyIn}
      animationInDelay={animationInDelay || 0}
      animationOutDelay={animationOutDelay || 0}
    >
      {children}
    </Animated>
  );
};

const AnimationVisibilityTracker = ({
  children,
  animationIn,
  animationOut,
  animationInDelay,
  animationOutDelay,
  onlyIn = false,
  onlyInTrigger
}) => (
  <TrackVisibility>
    <ComponentToTrack
      animationIn={animationIn}
      animationOut={animationOut}
      animationInDelay={animationInDelay}
      animationOutDelay={animationOutDelay}
      onlyIn={onlyIn}
      onlyInTrigger={onlyInTrigger}
    >
      {children}
    </ComponentToTrack>
  </TrackVisibility>
);

export default AnimationVisibilityTracker;
