import { useState } from 'react';
import { Grid, Typography, Box, useMediaQuery, Button } from '@mui/material';
import { imageSrcSet } from '../../utils';
import ContactDialog from '../shared/ContactDialog';
import getStyles from './styles/features';
import LimitingContainer from '../shared/LimitingContainer';

const Feature = ({ media, featureInfo, mediaPosition = 'onLeft', paddingBottom = 50 }) => {
  const styles = getStyles({ paddingBottom });
  const gridDirection = mediaPosition === 'onLeft' ? 'row' : 'row-reverse';

  return (
    <Grid sx={styles.featureContainer} direction={gridDirection} justifyContent="center" alignItems="center" container>
      <Grid item xs={12} md={6}>
        {media}
      </Grid>
      <Grid sx={styles.infoContainer} item xs={12} md={6}>
        {featureInfo}
      </Grid>
    </Grid>
  );
};

const FeatureInfo = ({ title, description, label, onClick, href, children }) => {
  const styles = getStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="inherit" sx={styles.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography varaint="inherit" sx={styles.description}>
          {description}
        </Typography>
      </Grid>
      <Grid sx={styles.button} item xs={12}>
        <Button variant="outlined" href={href} onClick={onClick} sx={styles.responsiveButton}>
          {label}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

const FeatureMedia = ({ src }) => {
  const styles = getStyles();
  const isVideo = src.split('.').pop() === 'mp4';
  return (
    <Box sx={styles.mediaContainer}>
      {isVideo ? (
        <video autoPlay="autoplay" loop muted>
          <source src={src} type="video/mp4" />
        </video>
      ) : (
        <img src={src} srcSet={imageSrcSet(src)} alt="feature" />
      )}
    </Box>
  );
};

const Pipe = ({ src, imgStyles }) => {
  const styles = getStyles();
  return (
    <Box sx={[styles.pipeContainer, imgStyles]}>
      <img src={src} alt="pipe" style={{ position: 'absolute' }} />
    </Box>
  );
};

const IntegrationIcon = ({ src = '', width = 55, alt, children }) => {
  const styles = getStyles({ iconWidth: width });

  return (
    <Grid item sx={styles.iconContainer}>
      {src ? <img src={src} alt={alt} /> : children}
    </Grid>
  );
};

const Integrations = () => {
  const styles = getStyles();
  return (
    <Grid justifyContent="space-around" sx={styles.integrationsContainer} container>
      <IntegrationIcon src="/imgs/public/postalio.svg" alt="Postal.io" />
      <IntegrationIcon src="/imgs/services/salesforce.png" alt="Salesforce" width={50} />
      <IntegrationIcon src="/imgs/services/vector.png" alt="Vector" width={30} />
      <IntegrationIcon src="/imgs/services/zapier.png" alt="Zapier" width={45} />
      <IntegrationIcon>
        <Typography variant="inherit" sx={styles.iconText}>
          +12
        </Typography>
      </IntegrationIcon>
    </Grid>
  );
};

const SendOptions = ({ onClick }) => {
  const styles = getStyles();
  return (
    <LimitingContainer maxWidth={1080}>
      <Grid container alignItems="center" sx={styles.sendContainer}>
        <Grid item xs={12} md={8} lg={10}>
          <Typography variant="inherit" sx={styles.sendTitle}>
            Want to send swag to several recipients?
          </Typography>
          <Typography variant="inherit" sx={styles.sendSubtitle}>
            Use our platform to schedule shipments to multiple recipients by uploading a CSV.
          </Typography>
        </Grid>
        <Grid item sx={styles.sendButtonContainer} xs={12} md={4} lg={2}>
          <Button onClick={onClick} sx={styles.sendButton} color="secondary">
            Learn More
          </Button>
        </Grid>
      </Grid>
    </LimitingContainer>
  );
};

const Features = () => {
  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState('');

  const styles = getStyles();
  const isSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  const isMd = useMediaQuery(theme => theme.breakpoints.down('lg'));

  const handleOpenForm = newSubject => {
    setSubject(newSubject);
    setOpen(true);
  };
  return (
    <LimitingContainer maxWidth={1080}>
      <Feature
        media={<FeatureMedia src="https://swagup-static.s3.amazonaws.com/swagup-videos/custom_swag.mp4" />}
        featureInfo={
          <FeatureInfo
            title="Custom Swag Designs"
            description="No need to pull your design team off of their projects. Simply upload your logo and we'll take care of the rest. Custom designs will be ready for your review within 24 hours. Let's get started on some free designs."
            label="Get Started "
            href="/dashboard/product-onboarding"
          />
        }
      />
      <Pipe src="/imgs/services/pipe-left.svg" imgStyles={styles.commonPipe} />
      <Feature
        media={<FeatureMedia src="/imgs/services/assembly-and-packing.png" />}
        featureInfo={
          <FeatureInfo
            title="Assembly & Packing"
            description="Presentation is everything! We want anyone receiving your swag to be impressed with it. That’s why we personally assemble everything to your specs at no additional cost. No more assembly lines at your office!"
            label="Learn More"
            onClick={() => handleOpenForm('Other')}
          />
        }
        mediaPosition="onRight"
      />
      <Pipe src="/imgs/services/pipe-right.svg" imgStyles={styles.commonPipe} />
      <Feature
        media={<FeatureMedia src="/imgs/services/warehouse.png" />}
        featureInfo={
          <FeatureInfo
            title="Warehouse With Us"
            description="The days of stuffed storage closets and extra swag in your apartment are over. Store your swag with us and send it as needed, all in real-time through the SwagUp platform."
            label="Learn More"
            onClick={() => handleOpenForm('Other')}
          />
        }
      />
      <Pipe src="/imgs/services/pipe-left.svg" imgStyles={styles.warehousePipe} />
      <Feature
        media={<FeatureMedia src="/imgs/services/party-items.png" />}
        mediaPosition="onRight"
        featureInfo={
          <FeatureInfo
            title="Send Us 3rd-Party Items"
            description="Whether you want to offload a storage closet full of swag or include a stack of unique items, you can send it all to us and we’ll add to your inventory."
            label="Learn More"
            onClick={() => handleOpenForm('Other')}
          />
        }
      />
      <Pipe src="/imgs/services/pipe-right.svg" imgStyles={styles.commonPipe} />
      <Feature
        paddingBottom={isMd ? 12.5 : 30}
        media={<FeatureMedia src="https://swagup-static.s3.amazonaws.com/swagup-videos/shipping_worldwide.mp4" />}
        featureInfo={
          <FeatureInfo
            title="Domestic & International Shipping"
            description="Have you ever shipped swag around the globe? It can be an ordeal! Luckily, we’ve done it a few thousand times. Ship anywhere in the world through our platform, and take advantage of our discounted rates. We’ll handle the rest."
            label="Learn More"
            href="/pricing"
          />
        }
      />
      {!isSm && (
        <>
          <SendOptions onClick={() => handleOpenForm('Other')} />
          <Pipe src="/imgs/services/pipe-big.png" imgStyles={styles.domesticPipe} />
        </>
      )}
      <Feature
        paddingBottom={44.5}
        media={<FeatureMedia src="/imgs/services/integrations.png" />}
        mediaPosition="onRight"
        featureInfo={
          <FeatureInfo
            title="Technology & Integrations"
            description="Whether it’s a custom-branded, self-service page for recipients to order swag with or an API integration that automates your campaigns, we make it as easy as possible. Do you have a specific workflow in mind? Let us know!"
            label="Learn More"
            onClick={() => handleOpenForm('Other')}
          >
            <Integrations />
          </FeatureInfo>
        }
      />
      {open && <ContactDialog defaultSubject={subject} open={open} onClose={() => setOpen(false)} />}
    </LimitingContainer>
  );
};
export default Features;
