import _ from 'lodash';
import Box from '@mui/material/Box';
import { ellipsisStyles } from '../../common/styles';

// from https://flatuicolors.com/
const defaultColors = [
  '#3c40c6', // free speech blue
  '#ff5e57', // sunset orange
  '#ffa801', // chrome yellow
  '#05c46b', // green teal
  '#1e272e', // black pearl
  '#575fcf', // dark periwinkle
  '#2c3e50' // midnight blue
];

const sumChars = str => _.sumBy(str, c => c.charCodeAt(0));

const styles = {
  userAvatar: {
    display: 'inline-block',
    color: 'white',
    cursor: 'pointer'
  },
  userAvatarInner: {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    fontSize: '16px',
    fontFamily: 'Gilroy',
    fontWeight: 500,
    ...ellipsisStyles
  }
};

const UserAvatar = ({ name, nameForColorGeneration, size }) => {
  const userInitial = name?.charAt(0) ?? '?';
  // pick a deterministic color from the list
  const colorGenName = nameForColorGeneration || name || '';
  const backgroundColor = defaultColors[sumChars(colorGenName) % defaultColors.length];

  const innerStyle = {
    lineHeight: `${size}px`,
    backgroundColor,
    width: size,
    maxWidth: size,
    height: size,
    maxHeight: size,
    textAlign: 'center',
    borderRadius: '100%'
  };

  return (
    <Box aria-label={name} sx={styles.userAvatar}>
      <Box sx={[styles.userAvatarInner, innerStyle]}>{userInitial}</Box>
    </Box>
  );
};

export default UserAvatar;
