const styles = {
  containerMainBYBEG: theme => ({
    paddingTop: 15,
    paddingBottom: 18,
    backgroundColor: '#E9F0FA',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 12
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: 960
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  sectionContainerEG: theme => ({
    [theme.breakpoints.down('md')]: {
      px: 6,
      pt: 6
    }
  }),
  promotionTitleBYBEventGeek: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    marginTop: 6,
    marginBottom: 14,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      textAlign: 'center',
      marginBottom: 6
    }
  }),
  containerBYBEventGeek: theme => ({
    borderTopLeftRadius: 100,
    borderBottomRightRadius: 100,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 40px 140px 0px rgba(26, 58, 98, 0.22)',
    [theme.breakpoints.down('md')]: {
      borderTopLeftRadius: 100,
      borderBottomRightRadius: 60
    },
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none'
    }
  }),
  wrapperBYBImgEG: theme => ({
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      borderBottomRightRadius: 60,
      borderTopLeftRadius: 60,
      padding: '0px 45px 0px 0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 0px 0px'
    }
  }),
  bYBImg: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderBottomRightRadius: 60,
    borderTopLeftRadius: 60
  },
  menuSectionWrapper: theme => ({
    padding: '100px 85px 42px 64px',
    [theme.breakpoints.down('md')]: {
      padding: '40px 62px 12px 0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '40px 12px'
    }
  }),
  menuSectionEventGeek: theme => ({
    color: '#0F2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    textAlign: 'left',
    lineHeight: 36,
    my: 0,
    '& li': {
      lineHeight: 1.5,
      marginBottom: 4
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      '& li': {
        marginBottom: 2
      }
    }
  }),
  regularTextBYBEventGeek: theme => ({
    color: '#0f2440',
    fontSize: 20,
    fontFamily: 'Gilroy-SemiBold',
    marginTop: 13,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      textAlign: 'center',
      marginTop: 6,
      mx: 0
    }
  }),
  containerButtonBYB: theme => ({
    marginTop: 26,
    marginBottom: 8,
    [theme.breakpoints.down('md')]: {
      marginTop: 6,
      marginBottom: 2.5
    }
  }),
  buttonBYB: theme => ({
    width: 318,
    borderRadius: 9,
    fontSize: '20px !important',
    paddingTop: '17.5px !important',
    paddingBottom: '17.5px !important',
    [theme.breakpoints.down('md')]: {
      width: 190,
      fontSize: '12px !important',
      paddingTop: '12px !important',
      paddingBottom: '12px !important'
    }
  })
};

export default styles;
