import ClausesDocument from '../shared/ClausesDocument';

const termsOfUseClauses = {
  '': `Welcome to www.swagup.com (the “Site”), brought to you by SwagUp Inc (“SwagUp”). Please review the following Terms that govern your use of the Site, and information and products obtained through the Site. Your use of the Site, and/or acceptance of information and products through the Site, constitutes your agreement to these Terms of Use (the “Terms”). If you do not agree to these Terms, please do not use the Site, or use or obtain the products and services provided through the Site.

Should you have any questions concerning these Terms or any of our policies, please contact us.`,
  'MODIFICATIONS TO SITE': `The Terms may be amended by SwagUp from time to time. In such case, SwagUp will provide notice on the Site that the Terms have been modified and your use of the Site after placement of such notice shall constitute your agreement with the modified Terms. You agree that providing notice on the Site is adequate notice, and that it is your responsibility to, and you agree to, regularly review the Terms to be sure you are familiar with those that are most current.
    
SwagUp may at any time, and from time to time, modify or discontinue, temporarily or permanently, the Site (or any part thereof). You agree that SwagUp shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Site. Notwithstanding the foregoing, SwagUp undertakes no responsibility whatsoever for updating the Site or content therein. To be certain that any information is current or remains accurate, please contact <a href="mailTo:legal@swagup.com">legal@swagup.com</a>.`,
  'TERMINATION of ACCESS':
    'We reserve the right, in our sole discretion, immediately and without notice to suspend or terminate your ability to access the Site, for any reason or no reason, including any breach by you of these Terms or conduct by you that we determine to be inappropriate.',
  'PERMITTED USE AND PROHIBITED USE IN GENERAL': `We hereby grant you limited permission to use the Site so long as we provide you with access, and solely for your personal use. For the avoidance of doubt, we are not giving you any rights or license with respect to any aspect of the Site, all rights being held by us. 

We reserve the right, in our sole and absolute discretion, to block and/or refuse to allow use of the Site by one or more users, including you, at any time and for any reason. 

Without limiting the foregoing, we specifically prohibit the use of the Site for any purpose deemed improper by us. Improper uses include but shall not be limited to: (i) copying, distributing or modifying any part of the Site without our prior written authorization; (ii) use of the Site to transmit or attempt to transmit advertisements without our prior written authorization; (iii) use of the Site to transmit or attempt to transmit spam, chain letters, harassing, libelous or defamatory statements, or any unlawful content or material; (iv) use or attempted use of the Site to transmit software viruses, spyware, or any other computer code, files or programs which is harmful or otherwise unwanted by us or other users; (v) disruption of servers or networks connected to the Site; and (vi) use that is not in compliance with these Terms.

Without limitation as to other restrictions identified herein, you shall not upload to, distribute through or otherwise publish through the Site any content which is obscene, threatening, invasive of privacy or publicity rights, harmful of minors in any way, abusive, or contain expressions of hatred, bigotry, racism or pornography, or are otherwise objectionable, or that would constitute or encourage a criminal offense, violate the rights of any party or violate any law or that you do not have a right to make available under contractual or fiduciary relationships. You shall not act in a manner that negatively affects other users’ ability to interact with the Site.`,
  'ERRORS AND OMISSIONS':
    'Occasionally there may be information on our Site that contains typographical errors, inaccuracies or omissions. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information if any information in the Site is inaccurate at any time without prior notice.',
  'OUR PROPRIETARY RIGHTS': `You acknowledge and agree that the Site contains proprietary information, content and other materials owned or controlled by SwagUp and protected by applicable intellectual property and other laws (including, without limitation, copyrights, trademarks, service marks and patents).

Any use or copying of such information, content and other materials other than for your own personal, non-commercial use, without written permission from SwagUp, is strictly prohibited. 

You acknowledge that you do not acquire any ownership rights in any intellectual property through your use of the Site.`,
  'DIGITAL MILLENNIUM COPYRIGHT ACT': `We are under no obligation to, and do not, scan content used in connection with the Site for the inclusion of illegal or impermissible content. However, we respect the copyright and intellectual property interests of others. It is our policy not to permit materials known by us to infringe another party’s copyright to remain on the Site.

If you believe that an infringement of intellectual property rights exists, please provide us with notice of the same to <a href="mailTo:legal@swagup.com">legal@swagup.com</a>. 

If you believe there is or has been a copyright infringement, you should provide us with written notice that contains the following information required by the Digital Millennium Copyright Act (“DMCA”), 17 U.S.C. 512: 

(a) a physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; 

(b) identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works are covered by a single notification, a representative list of such works; 

(c) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled and information reasonably sufficient to permit us to locate the material; 

(d) information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number and, if available, an e-mail address at which the complaining party may be contacted; 

(e) a statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent or the law; and 

(f) a statement that the information in the notification is accurate and, under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. 

All DMCA notices should be sent to:

<a href="mailTo:legal@swagup.com">legal@swagup.com</a>`,
  'DISCLAIMER OF WARRANTIES': `YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SITE IS AT YOUR OWN RISK. 

THE SITE AND ALL INFORMATION, CONTENT, SERVICES OR PRODUCTS OBTAINED THROUGH THE SERVICE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WE EXPRESSLY DISCLAIM, TO THE FULLEST EXTENT PERMITTED BY LAW, ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SITE OR THE INFORMATION OR CONTENT INCLUDED ON THE SITE.

WE MAKE NO WARRANTY THAT THE SITE OR ANY INFORMATION OBTAINED THROUGH THE SITE WILL MEET YOUR REQUIREMENTS, BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, WILL BE ACCURATE OR RELIABLE, OR MEET YOUR EXPECTATIONS.

ANY MATERIAL OBTAINED THROUGH THE USE OF THE SITE IS OBTAINED AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE THAT RESULTS FROM OBTAINING ANY SUCH MATERIAL.

NO INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR FROM THE SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED.`,

  'LIMITATION OF LIABILITY':
    'YOU EXPRESSLY UNDERSTAND AND AGREE THAT WE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE SITE; (ii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iii) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (iv) ANY OTHER MATTER RELATING TO THE SITE.',
  'EXCLUSIONS AND LIMITATIONS':
    'SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.',
  INDEMNIFICATION:
    'You agree to hold us and our subsidiaries, affiliates, officers, directors, and employees, and each of their respective successors and assigns (collectively, the “Indemnified Persons”), harmless from, and indemnify them for, all damages, costs, expenses and other liabilities, including reasonable attorneys’ fees and expenses, relating to any third party claim arising out of or related to your use of the Site or any information or content on the Site, or violation of these Terms.',
  'choice of law; INTERPRETATION': `These Terms and the relationship between you and us shall be governed by the laws of the State of New Jersey without regard to its conflict of law provisions. You agree to submit to the personal and exclusive jurisdiction and venue of the courts located within the county of Hudson, in the State of New Jersey.

If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect. `,
  'THIRD-PARTY WEBSITES AND RESOURCES': `The Site may provide links to third party websites or resources (“Third Party Resources”). Because we have no control over external sites and resources, including Third Party Resources, you acknowledge and agree that we are not responsible for the availability of such Third Party Resources, and do not endorse and are not responsible or liable for any content, advertising, goods, services or other materials on, available through or provided by such Third Party Resources. You agree that we shall not be responsible or liable for any loss or damage of any sort incurred as the result of any interaction you may have with such Third Party Resources.

We may enable social media connectivity on the Site from time to time. This connectivity may link to our own social media accounts. The specific content we may post on our own social media accounts is subject to these Terms. However, those social media websites are also Third-Party Resources. If you choose to use social media connect functions on the Site, you acknowledge and agree to abide by the relevant terms and conditions of each respective social media website that we may utilize. The terms and conditions for the social media websites may be found on each respective social media website.

The social media connect functions are intended to enable us to connect with social media websites so that you can send text, photographs, videos and other information about your activities to each of these websites. Such social media websites may also be able to use information about action you take on our Site.

However, note that where you choose to publish or share information through the social media links on our Site, we have no control over that activity. It will not be protected by us. You should assume that your activity may be accessed by any person using the Web in any part of the world and can be found using independent search engines. If you choose to engage in social media connectivity from our Site, you do so at your own risk.`,
  PRODUCTS: `The Site and/or the SwagUp online catalog may contain references to specific products and services that may no longer be available. Any such reference does not imply or warrant that any such products or services shall be available at any time. 

By purchasing a product for yourself or others through the Site, you acknowledge that the products and other items that may be included within the Swag Packs, Bulk Swag Orders, Preset Packs and/or within any other purchase from SwagUp’s Site (collectively, the “Order”), are designed for use with or under specific conditions. BY MAKING A PURCHASE ON SWAGUP’S SITE, YOU AGREE TO INDEMNIFY AND HOLD HARMLESS SWAGUP FROM AND AGAINST ANY CLAIM, DAMAGE, LOSS, LIABILITY, SUIT OR EXPENSE (INCLUDING REASONABLE ATTORNEY’S FEES) INCURRED OR SUFFERED BY YOU OR THE RECIPIENT OF THE ORDER OTHERWISE ATTRIBUTABLE TO THE PERFORMANCE OF THE PRODUCT YOU HAVE PURCHASED, INCLUDING, BUT NOT LIMITED TO, CLAIMS FOR DAMAGES, BECAUSE OF INJURY OR DAMAGE TO PROPERTY, BODILY INJURY, OCCUPATIONAL SICKNESS OR DISEASE, OR DEATH. FURTHERMORE, YOU REPRESENT, ACKNOWLEDGE AND AGREE THAT YOU ARE TAKING 100% RESPONSIBILITY FOR THE SELECTION AND USE OF THE PRODUCT(S) YOU PURCHASE FOR YOURSELF OR OTHERS, INCLUDING THE DETERMINATION OF WHETHER SUCH PRODUCT(S) ARE APPROPRIATE FOR THE RECIPIENT, AND THAT EXCEPT AS MAY OTHERWISE BE EXPRESSLY AGREED TO IN WRITING IN A SEPARATE DOCUMENT, SWAGUP HAS NOT OFFERED ANY ASSISTANCE, AID OR SUPPORT IN THE SELECTION OF SUCH PRODUCT(S) AND THAT SWAGUP WILL NOT BE HELD LIABLE OR ACCOUNTABLE TO YOU OR THE RECIPIENT OF THE ORDER FOR ANY DAMAGES. 

SwagUp expressly disclaims any and all liability as to any results obtained or arising from any use of the information contained on SwagUp’s Site.`,
  'TAX EXEMPTION': `SwagUp may be required by law to collect sales and similar taxes on products it sells. If you believe you qualify for a tax exemption, you may submit a valid tax exemption certificate demonstrating same to SwagUp. If a valid exemption certificate is received and processed before your order ships, the tax will be adjusted based on the certificate. Otherwise, SwagUp will not be able to honor an exemption.
SwagUp must have received and approved your exemption certificate prior to shipment of any products for which the exemption is claimed by you. SWAGUP CANNOT ISSUE REFUNDS FOR TAXES COLLECTED ONCE YOUR PRODUCT SHIPS.

Exemption certificates may be submitted to <a href="mailTo:billing@swagup.com">billing@swagup.com</a> for SwagUp to review. Upon review, SwagUp will inform you if your certificate is accepted, or SwagUp may request additional information or modification or correction to the certificate.

SwagUp cannot and does not provide tax advice and assumes no responsibility for your tax matters. You agree to indemnify SwagUp for any damages SwagUp might incur as a result of acceptance of your tax exemption certificates.`
};

const TermsOfUse = () => <ClausesDocument clausesHeader="Terms of Use" clauses={termsOfUseClauses} />;

export default TermsOfUse;
