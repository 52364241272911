import { Grid, Fab, Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import styles from './styles/FeaturedPost';
import CenteredGrid from '../shared/CenteredGrid';
import Img from '../shared/Img';

const FeaturedPost = ({ post }) => {
  const history = useHistory();
  return (
    <Grid container justifyContent="center" sx={styles.containerPartnerWU}>
      <CenteredGrid sx={styles.center}>
        <Box sx={styles.featuredPostContainer}>
          <Grid container>
            <Grid item md={5} xs={12}>
              <Box sx={styles.FeaturedImage}>
                <Img src={post.featuredImage || post.image} alt={post.slug} sx={styles.PostImage} />
              </Box>
            </Grid>
            <Grid item md={7} xs={12}>
              <Typography component="h1" sx={styles.FeaturedPostTitle}>
                {post.title}
              </Typography>
              <Box sx={styles.PostSummary} dangerouslySetInnerHTML={{ __html: post.description }} />
              <Box sx={styles.FeaturedPostActions}>
                <Fab
                  sx={styles.ReadMoreButton}
                  onClick={() => {
                    history.push(`/blog/${post.slug}`);
                  }}
                >
                  Read more
                </Fab>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CenteredGrid>
    </Grid>
  );
};

export default FeaturedPost;
