import { Grid, Box, Typography } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import Phone from '@mui/icons-material/Phone';
import SectionTag from './SectionTag';
import styles from './styles/home';
import CenteredGrid from '../shared/CenteredGrid';
import Img from '../shared/Img';

const RemoteSwagCard = ({ icon, text, top, left, right, bottom, maxWidth, mobileSpecial }) => (
  <Box sx={[styles.remoteSwagCard, mobileSpecial, { top, left, right, bottom }]}>
    <Grid container alignItems="center">
      <Grid item>
        <Img alt="option Icon" src={icon} sx={styles.remoteSwagCardIcon} />
      </Grid>
      <Grid item xs>
        <Typography variant="subtitle2SemiBold" sx={[styles.remoteSwagCardText, { maxWidth }]}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  </Box>
);
const RemoteSwagPostCard = ({ icon, text, maxWidth = 187 }) => (
  <Box sx={styles.remoteSwagPostCard}>
    <Grid container alignItems="flex-start">
      <Grid item xs={12}>
        <Box sx={styles.remoteSwagPostCardIconContainer}>
          <Img alt="option Icon" src={icon} sx={styles.remoteSwagPostCardIcon} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1SemiBold" sx={[styles.remoteSwagPostCardText, { maxWidth }]}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  </Box>
);

const SwagForRemote = () => {
  const publicUrl = process.env.PUBLIC_URL;
  return (
    <div>
      <Grid
        container
        justifyContent="center"
        sx={[styles.container, { paddingTop: 14, paddingBottom: 18, backgroundColor: 'rgb(242, 246, 253, 0.5)' }]}
      >
        <CenteredGrid sx={styles.center}>
          <Box sx={styles.sectionContainer}>
            <SectionTag tag="SWAG FOR REMOTE" />

            <Typography variant="display2Bold" sx={styles.swagForRemoteTitle}>
              Send swag to remote teams and customers to keep them engaged
            </Typography>

            <Grid container justifyContent="center">
              <Grid item sx={styles.swagForRemoteCenter}>
                <Img
                  src={`${publicUrl}/imgs/landingPagesSSR/group-143.png`}
                  srcSet={`${publicUrl}/imgs/landingPagesSSR/group-143.png 1x, ${publicUrl}/imgs/landingPagesSSR/group-143@2x.png 2x,
                      ${publicUrl}/imgs/landingPagesSSR/group-143@3x.png 3x`}
                  alt="hero shopify"
                  sx={styles.sPImgRippling}
                />
                <RemoteSwagCard
                  icon={`${publicUrl}/imgs/homeLight/sky-star.svg`}
                  text="Show top clients you care"
                  top="3.5%"
                  left="-5.5%"
                  mobileSpecial={styles.mobileSpecialTopLeft}
                />
                <RemoteSwagCard
                  icon={`${publicUrl}/imgs/homeLight/engagement.svg`}
                  text="Engage with Remote Teams"
                  top="10%"
                  right="-12.5%"
                  mobileSpecial={styles.mobileSpecialTopRight}
                />
                <RemoteSwagCard
                  icon={`${publicUrl}/imgs/homeLight/humanize-event.svg`}
                  text="Humanize virtual events"
                  bottom="28%"
                  left="-6.5%"
                  mobileSpecial={styles.mobileSpecialBottomLeft}
                />
                <RemoteSwagCard
                  icon={`${publicUrl}/imgs/homeLight/team-appreciation.svg`}
                  text="Operations Teams Appreciation"
                  bottom="16%"
                  right="-4.5%"
                  maxWidth={248}
                  mobileSpecial={styles.mobileSpecialBottomRight}
                />
              </Grid>
            </Grid>
            <div>
              <Typography variant="h3SemiBold" sx={styles.swagForRemoteSubTitle}>
                One integrated platform to <br /> <strong>Create</strong>, <strong>Automate</strong>, and{' '}
                <strong>Distribute</strong> high quality swag anywhere!
              </Typography>
            </div>
          </Box>
        </CenteredGrid>
      </Grid>

      <Grid container justifyContent="center">
        <CenteredGrid sx={styles.center}>
          <Box sx={styles.remoteSwagBottomSection}>
            <Grid container spacing={10}>
              <Grid item sm={4} xs={12}>
                <RemoteSwagPostCard
                  icon={`${publicUrl}/imgs/homeLight/zapier.svg`}
                  text="Integrate with over 2000 apps via Zapier"
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <RemoteSwagPostCard
                  icon={`${publicUrl}/imgs/homeLight/delivery-truck.svg`}
                  text="Ship 1 by 1 to customers, attendees and employees"
                  maxWidth={244}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <RemoteSwagPostCard
                  icon={`${publicUrl}/imgs/homeLight/icon-onboarding-automate.svg`}
                  text="All powered by your dashboard"
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Button
                      href="/dashboard/product-onboarding"
                      variant="primary"
                      sx={[styles.swagForRemoteButton, styles.responsiveButton]}
                    >
                      Get Started
                    </Button>
                  </Grid>
                </Grid>
                <Typography variant="subtitle3SemiBold" sx={styles.orText}>
                  or
                </Typography>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <Box component="a" href="tel:6464940520" sx={styles.callUsText}>
                      <Phone sx={styles.callUsPhone} /> Call Us, 646-494-0520
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CenteredGrid>
      </Grid>
    </div>
  );
};

export default SwagForRemote;
