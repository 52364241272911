import { Box } from '@mui/system';
import styles from '../shared/styles/backgroundAdorments';

const BackgroundFigureCompany = ({
  top,
  bottom,
  left,
  right,
  width,
  height,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  mobile
}) => {
  const style = {
    zIndex: -1
  };

  if (borderTopLeftRadius) style.borderTopLeftRadius = 100;
  if (borderTopRightRadius) style.borderTopRightRadius = 100;
  if (borderBottomLeftRadius) style.borderBottomLeftRadius = 100;
  if (borderBottomRightRadius) style.borderBottomRightRadius = 100;

  if (width) style.width = width;
  if (height) style.height = height;

  if (top) style.top = top;
  if (bottom) style.bottom = bottom;
  if (left) style.left = left;
  if (right) style.right = right;

  return <Box sx={[mobile ? styles.figureBackgroundMobile : styles.figureBackground, style]} />;
};

export default BackgroundFigureCompany;
