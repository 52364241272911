const styles = {
  containerRequestPackBenefits: theme => ({
    paddingBottom: 35.5,
    [theme.breakpoints.down('1030')]: {
      paddingBottom: 10
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      paddingBottom: 2.5
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('1030')]: {
      width: 960
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  sectionContainerBenefits: theme => ({
    paddingTop: 45,
    [theme.breakpoints.down('md')]: {
      paddingTop: 7.5
    }
  }),
  wrapperImgBenefits: theme => ({
    borderBottomRightRadius: 100,
    borderTopLeftRadius: 100,
    [theme.breakpoints.down('md')]: {
      borderBottomRightRadius: 55,
      borderTopLeftRadius: 55,
      padding: '0px 20px 0px 45px'
    },
    [theme.breakpoints.down('sm')]: {
      borderBottomRightRadius: 55,
      borderTopLeftRadius: 55,
      padding: '0px 20px 0px 20px'
    }
  }),
  imageBenefitsEG: theme => ({
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: '100px 0px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
      borderRadius: '80px 0px'
    }
  }),
  containerSectionBenefits: theme => ({
    padding: '14px 0px 0px 102px !important',
    [theme.breakpoints.down('md')]: {
      padding: '14px !important'
    }
  }),
  titleBenefits: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    lineHeight: 1.29,
    marginTop: 0,
    marginBottom: 4.5,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      paddingTop: 4,
      paddingBottom: 2.5,
      marginBottom: 0,
      marginLeft: 0
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      textAlign: 'center'
    }
  }),
  subTextBenefits: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    opacity: 0.54,
    marginTop: 2.5,
    marginBottom: 16,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginTop: 1,
      marginBottom: 5
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }),
  landingBottomFrame: theme => ({
    marginTop: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }),
  integrationsText: {
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    textAlign: 'center',
    marginTop: 42,
    marginBottom: 4
  },
  sectionContainerFacility: theme => ({
    marginBottom: 12.5,
    [theme.breakpoints.down('md')]: {
      marginBottom: 2.5
    }
  }),
  imageContainerFacility: theme => ({
    height: 'auto',
    width: 27,
    marginRight: 4.5,
    position: 'relative',
    top: 2,
    [theme.breakpoints.down('md')]: {
      top: 0,
      marginRight: 1.5,
      width: 18
    }
  }),
  textFacility: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    marginLeft: 1,
    color: '#0f2440',
    [theme.breakpoints.down('md')]: {
      fontSize: 10
    }
  }),
  bottomImagesContainerBenefits: theme => ({
    padding: '0px 0px 0px 0px',
    marginTop: 6,
    [theme.breakpoints.down('sm')]: {
      marginTop: 3,
      padding: '0px 16px 0px 0px'
    }
  }),
  bottomImageContainerBenefits: theme => ({
    borderRadius: 20,
    boxShadow: '0 25px 50px 0 rgba(212, 217, 226, 0.25)',
    backgroundColor: '#ffffff',
    padding: '0px 60px',
    height: 132,
    [theme.breakpoints.down('1030')]: {
      padding: '0px 20px'
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 10,
      padding: '0px 30px',
      height: 80
    }
  })
};

export default styles;
