import ContactUs from '../../shared/ContactUs';
import OurServices from '../../shared/OurServices';
import BuildYourBrand from '../shared/NewBuildYourBrand';
import SamplePack from '../shared/NewSamplePack';
import LandingSection from '../shared/LandingSection';
import BackgroundAdornments from '../shared/BackgroundAdornments';

const services = [
  {
    src: '/imgs/landingPagesSSR/design.svg',
    title: 'Design',
    text: 'Our team of experts will create designs that fit your brand!'
  },
  {
    src: '/imgs/landingPagesSSR/warehouse-storage.svg',
    title: 'Storage',
    text: 'Don’t have space for swag? No worries, you can track & store your inventory with us!'
  },
  {
    src: '/imgs/landingPagesSSR/assemble.svg',
    title: 'Design',
    text: 'Let us make you look good - we got the assemble part down!'
  },
  {
    src: '/imgs/landingPagesSSR/ship.svg',
    title: 'Ship',
    text: 'We ship worldwide and have competitive rates to help you save :)'
  }
];

const promotionTitle = 'We offer more than just Swag';

const responses = [
  {
    img: { src: '/imgs/landingPagesSSR/premium-kit.svg', alt: 'premium-kit' },
    subTitle: 'An assortment of high quality branded items bundled together'
  },
  {
    img: { src: '/imgs/landingPagesSSR/quality-items.svg', alt: 'quality-items' },
    subTitle: ' Consists of various items, from notebooks to sweatshirts and beyond'
  },
  {
    img: { src: '/imgs/landingPagesSSR/client-employee-bond.svg', alt: 'client-employee-bond' },
    subTitle: 'Perfect for clients and employees to build brand awareness & loyalty'
  }
];

const sectionData = {
  title: 'Swag Packs Made Simple!',
  subTitle: 'We design, pack, store, \nship swag!'
};

const SwagPacksMadeSimple = () => (
  <>
    <BackgroundAdornments />
    <LandingSection sectionData={sectionData} />
    <BuildYourBrand responses={responses} />
    <SamplePack subtitle="Get a sense of what it’s like to unbox one of our fully custom swag packs. Request your sample pack below and we’ll have it to your office the next day, totally free*" />
    <OurServices services={services} title={promotionTitle} />
    <ContactUs namePage="Swag Packs Made Simple" />
  </>
);

export default SwagPacksMadeSimple;
