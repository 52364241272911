const styles = {
  container: theme => ({
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: 'rgba(235, 241, 251, 0.5)',
    [theme.breakpoints.down('md')]: {
      paddingTop: 8,
      paddingBottom: 6
    }
  }),
  sectionContainerOS: theme => ({
    paddingLeft: 15,
    [theme.breakpoints.down('lg')]: {
      width: 960
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0 !important'
    }
  }),
  promotionTitle: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    marginBottom: 7,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      paddingTop: 4,
      marginBottom: 2.5,
      marginLeft: 11
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginLeft: 0
    }
  }),
  textHDW: theme => ({
    color: '#8d9299',
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    marginBottom: 18,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginBottom: 5,
      marginLeft: 11,
      marginRight: 5
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginLeft: 5
    }
  }),
  containerBoxHDW: theme => ({
    marginTop: 0,
    marginBottom: 16,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: 10,
      mx: 11
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 10,
      mx: 0
    }
  }),
  servicesButtonContainerOS: theme => ({
    paddingTop: 8,
    paddingBottom: 14,
    [theme.breakpoints.down('md')]: {
      paddingTop: 4,
      paddingBottom: 8
    }
  }),
  servicesButton: theme => ({
    height: 75,
    width: 300,
    borderRadius: 9,
    paddingTop: '20px !important',
    paddingBottom: '20px !important',
    fontSize: '20px !important',
    [theme.breakpoints.down('sm')]: {
      width: 196,
      height: '42px !important',
      fontSize: '12px !important',
      paddingTop: '12px !important',
      paddingBottom: '12px !important'
    }
  }),
  boxSectionImageRippling: theme => ({
    paddingLeft: 5,
    paddingRight: 15,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
      paddingRight: 5
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '60px !important',
      paddingRight: 20,
      marginLeft: 5
    }
  }),
  numberSectionImage: theme => ({
    width: 48,
    height: 48,
    boxShadow: '0 15px 56px 0 rgba(53, 119, 212, 0.15)',
    backgroundColor: '#ffffff',
    borderRadius: 24,
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    lineHeight: 1.33,
    textAlign: 'center',
    color: '#3577d4',
    display: 'grid',
    alignItems: 'center',
    position: 'relative',
    top: 24,
    marginLeft: 7,
    [theme.breakpoints.down('md')]: {
      width: 40,
      height: 40,
      borderRadius: 5,
      fontSize: 18,
      top: 20
    }
  }),
  itemSectionImage: theme => ({
    flexBasis: 0,
    boxShadow: '0 25px 50px 0 rgba(212, 217, 226, 0.25)',
    backgroundColor: '#ffffff',
    borderTopRightRadius: 100,
    paddingTop: 9,
    [theme.breakpoints.down('md')]: {
      borderTopRightRadius: 90
    }
  }),
  imgSectionImage: theme => ({
    width: '100%',
    height: 285,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 206
    }
  }),
  containerTextSectionImages: theme => ({
    flexGrow: 0,
    padding: '38px 0px 0px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '25px 20px 0px 0px'
    }
  }),
  textSectionImages: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    lineHeight: 1.5,
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
      marginBottom: 0
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    }
  }),
  containerSubTextSectionImages: theme => ({
    marginTop: 6,
    padding: '0px 60px 20px 0px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 2.5,
      padding: 0
    }
  }),
  subTextSectionImages: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    lineHeight: 1.5,
    opacity: 0.54,
    [theme.breakpoints.down('lg')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12
    }
  })
};

export default styles;
