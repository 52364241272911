import { forwardRef } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

const ThemeStyledGrid = styled(Grid)(({ theme }) => ({
  margin: 'auto',
  width: 1440,
  [theme.breakpoints.between('lg', 1920)]: {
    width: 1200
  },
  [theme.breakpoints.only('md')]: {
    width: 900
  },
  [theme.breakpoints.only('sm')]: {
    width: 600
  },
  [theme.breakpoints.only('xs')]: {
    width: 320
  }
}));

const CenteredGridWithRef = ({ children, classes, sx, ...rest }, ref) => (
  <ThemeStyledGrid {...rest} ref={ref} sx={sx}>
    {children}
  </ThemeStyledGrid>
);

const CenteredGrid = forwardRef(CenteredGridWithRef);

export default CenteredGrid;
