import { Box, Grid, Typography } from '@mui/material';
import Img from '../shared/Img';
import SectionTag from '../shared/SectionTag';
import { headerStyles, containerStyles, mainStyles } from './styles/benefits';

const BenefitsHeader = ({ headerText, subHeaderText }) => {
  return (
    <Grid item lg={6} sx={headerStyles.mainArea}>
      <SectionTag tag="Benefits" left={false} />
      <Box sx={headerStyles.promotionTitleContainer}>
        <Typography component="h3" variant="inherit" sx={headerStyles.promotionTitle}>
          {headerText}
        </Typography>
        <Grid item sx={headerStyles.exclusiveChip}>
          <Typography component="span" variant="inherit" sx={headerStyles.exclusiveChipFont}>
            Exclusive
          </Typography>
        </Grid>
      </Box>
      <Typography variant="inherit" sx={headerStyles.promotionSubtitle}>
        {subHeaderText}
      </Typography>
    </Grid>
  );
};

const BenefitsMain = ({ discount, discountText }) => {
  return (
    <Box sx={mainStyles.mainArea}>
      {/* Icon Cards */}
      <Grid container sx={mainStyles.iconCardsContainer}>
        <Grid item sx={mainStyles.iconCard}>
          <Img sx={mainStyles.vectorImageContainer} src="/imgs/landingPagesSSR/headset.svg" alt="headset icon" />
          <Typography component="h4" variant="inherit" sx={mainStyles.subtitle}>
            Access to dedicated customer success rep
          </Typography>
        </Grid>
        <Grid item sx={mainStyles.iconCard}>
          <Img sx={mainStyles.vectorImageContainer} src="/imgs/landingPagesSSR/percent.svg" alt="percent icon" />
          <Typography component="h4" variant="inherit" sx={mainStyles.subtitle}>
            Get {+discount}% off
            <br />
            {discountText}
          </Typography>
        </Grid>
        <Grid item sx={mainStyles.iconCard}>
          <Img sx={mainStyles.vectorImageContainer} src="/imgs/landingPagesSSR/swag-box.svg" alt="swag box icon" />
          <Typography component="h4" variant="inherit" sx={mainStyles.subtitle}>
            Get a free <br />
            sample pack
          </Typography>
        </Grid>
      </Grid>

      {/* Swagup Images */}
      <Box sx={mainStyles.benefitsImgsContainer}>
        <Grid container justifyContent="center">
          <Grid item sx={mainStyles.benefitsImgContainer}>
            <Img sx={mainStyles.benefitImg} src="/imgs/landingPagesSSR/socks.png" alt="socks" />
          </Grid>
          <Grid item sx={mainStyles.benefitsImgContainer}>
            <Img sx={mainStyles.benefitImg} src="/imgs/landingPagesSSR/pack.png" alt="pack" />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ marginTop: 7 }}>
          <Grid item sx={mainStyles.benefitsImgContainer}>
            <Img sx={mainStyles.benefitImg} src="/imgs/landingPagesSSR/pack-box.png" alt="pack box" />
          </Grid>
          <Grid item sx={mainStyles.benefitsImgContainer}>
            <Img sx={mainStyles.benefitImg} src="/imgs/landingPagesSSR/pack-product.png" alt="pack product" />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const BenefitsSection = ({ page }) => {
  return (
    <Grid container sx={containerStyles.container} justifyContent="center">
      <BenefitsHeader headerText={page.benefits_header_text} subHeaderText={page.benefits_sub_header_text} />
      <BenefitsMain discount={page.discount} discountText={page.discount_text} />
    </Grid>
  );
};
export default BenefitsSection;
