const styles = {
  containerBuildYB: theme => ({
    marginTop: 17.5,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      paddingBottom: 2.5
    }
  }),
  center: theme => ({
    paddingBottom: 10,
    [theme.breakpoints.down('lg')]: {
      width: 960
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingBottom: 6
    }
  }),
  title: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    textAlign: 'center',
    marginTop: 9,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      marginTop: 6
    }
  }),
  textAboutUs: theme => ({
    marginTop: 4,
    marginBottom: 25,
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    textAlign: 'center',
    lineHeight: 1.5,
    opacity: 0.54,
    [theme.breakpoints.down('md')]: {
      marginBottom: 2.5,
      fontSize: 12,
      padding: '0px 60px'
    }
  }),
  containerAllBYB: theme => ({
    px: 15,
    [theme.breakpoints.down('md')]: {
      px: 0
    }
  }),
  wrapperBYBImg: theme => ({
    width: '100%',
    [theme.breakpoints.down('md')]: {
      borderBottomRightRadius: 60,
      borderTopLeftRadius: 60,
      padding: '0px 45px 0px 45px'
    },
    [theme.breakpoints.down('sm')]: {
      borderBottomRightRadius: 60,
      borderTopLeftRadius: 60,
      padding: '0px 20px 0px 20px'
    }
  }),
  bYBImg: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderBottomRightRadius: 60,
    borderTopLeftRadius: 60
  },
  paragraphAboutUs: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    lineHeight: 1.8,
    marginTop: 15,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      textAlign: 'justify',
      marginTop: 2.5,
      padding: '0px 45px 0px 45px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 0px 20px'
    }
  })
};

export default styles;
