export const useStyles = {
  sectionTagContainerOS: theme => ({
    [theme.breakpoints.down('md')]: {
      justifyContent: 'left',
      paddingTop: 0,
      paddingLeft: 11.25
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingTop: 0,
      paddingLeft: 0
    }
  }),
  sectionTagLineMobil: theme => ({
    height: 0,
    border: 'solid 2px #9846dd',
    marginRight: 4,
    width: 64,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      border: 'solid 0.5px #9846dd',
      marginLeft: 4,
      width: 42,
      display: 'block'
    }
  }),
  sectionTag: theme => ({
    color: '#3577d4',
    fontSize: 16,
    textTransform: 'uppercase',
    textAlign: 'left',
    fontFamily: 'Gilroy-SemiBold',
    lineHeight: 1.5,
    fontWeight: 600,
    marginTop: 7.25,
    marginBottom: 7.25,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
      margin: 0
    }
  }),
  sectionTagLine: theme => ({
    height: 0,
    border: 'solid 1px #9846dd',
    marginRight: 4,
    width: 64,
    [theme.breakpoints.down('sm')]: {
      border: 'solid 0.5px #9846dd',
      marginLeft: 4,
      width: 42
    }
  }),
  sectionTagContainer: theme => ({
    paddingTop: 16,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingTop: 3.75
    }
  }),
  sectionTagContainerBenefits: theme => ({
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      justifyContent: 'left'
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingLeft: 0,
      paddingTop: 0
    }
  }),
  sectionTagContainerCU: theme => ({
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 11.25,
      justifyContent: 'left'
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingLeft: 0,
      paddingTop: 0
    }
  }),
  sectionTagContainerSPRippling: theme => ({
    paddingTop: 15.25,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'left',
      paddingTop: 5,
      marginLeft: 7.25
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      // paddingTop: 0,
      marginLeft: 0
    }
  }),
  sectionTagRippling: theme => ({
    color: '#3577d4',
    fontSize: 16,
    textTransform: 'uppercase',
    textAlign: 'left',
    fontFamily: 'Gilroy-SemiBold',
    lineHeight: 1.5,
    fontWeight: 600,
    marginTop: 7.25,
    marginBottom: 7.25,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
      margin: 0
    }
  })
};

export default useStyles;
