export const cardStyles = {
  swagCard: theme => ({
    padding: '30px 18px 30px 27px',
    borderRadius: 6.25,
    boxShadow: '0 20px 80px 0 rgba(27, 28, 31, 0.03)',
    border: 'solid 1px #ebeef2',
    backgroundColor: '#ffffff',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '10px 30px',
      marginTop: 5,
      borderRadius: 0,
      border: 'transparent'
    }
  }),
  swagCardText: theme => ({
    width: 200,
    margin: '0px 0px 0px 23px',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.75,
    letterSpacing: 'normal',
    color: '#0f2440',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  })
};

export const bulkStyles = {
  bulkSwagCardContainer: theme => ({
    height: 100,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 25
    },
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      marginBottom: 0
    }
  }),
  bulkSwagCard: theme => ({
    padding: '56px 68px',
    position: 'relative',
    top: -25,
    width: 895,
    borderRadius: 6.25,
    boxShadow: '0 20px 80px 0 rgba(27, 28, 31, 0.03)',
    border: 'solid 1px #ebeef2',
    backgroundColor: '#ffffff',
    borderBottomRightRadius: 25,
    [theme.breakpoints.down('lg')]: {
      position: 'relative',
      top: -100,
      border: 'transparent',
      borderRadius: 0,
      width: '100%',
      marginTop: 15
    },
    [theme.breakpoints.down('md')]: {
      position: 'static',
      marginTop: 5,
      boxShadow: 'none'
    }
  }),
  bulkPromotionTitleContainer: {
    marginTop: 4
  },
  bulkPromotionTitle: theme => ({
    display: 'inline-block',
    color: '#0f2440',
    fontFamily: 'Gilroy',
    fontSize: 24,
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      textAlign: 'center'
    }
  }),
  bulkPromotionSubtitle: {
    width: 180,
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#8d9299',
    marginTop: 4.25,
    marginBottom: 0
  },
  buttonOutlined: theme => ({
    border: 'solid 2px #3577d4 !important',
    padding: '31px 26px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
      paddingTop: '12px !important',
      paddingBottom: '12px !important',
      marginTop: 6,
      marginBottom: 2.5,
      marginLeft: 7.25
    }
  })
};

export const mainStyles = {
  mainAreaContainer: theme => ({
    position: 'relative',
    marginTop: 25,
    marginBottom: 50,
    boxShadow: '0px 200px #ffffff',
    backgroundColor: 'rgba(234, 240, 251, 0.5)',
    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
      boxShadow: 'none'
    }
  }),
  mainArea: theme => ({
    marginTop: 17.5,
    marginLeft: 100,
    marginRight: 100,
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 50,
      marginRight: 50,
      marginTop: 5
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 0
    }
  }),
  tagContainer: theme => ({
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }),
  promotionTitleContainer: theme => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: 4.5,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }),
  promotionTitle: theme => ({
    display: 'inline-block',
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      textAlign: 'center'
    }
  }),
  swagBoxImgHeroContainer: {
    position: 'relative',
    left: '20%',
    bottom: '15%',
    backgroundColor: 'transparent'
  },
  swagBox: theme => ({
    objectFit: 'cover',
    width: 'auto',
    height: '94%',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }),
  swagCardContainer: theme => ({
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      position: 'static',
      width: '100%',
      flexShrink: 0
    }
  }),
  button: theme => ({
    position: 'relative',
    bottom: 140,
    fontSize: '20px !important',
    padding: '24px 46px',
    backgroundColor: '#3577D4',
    textTransform: 'none !important',
    [theme.breakpoints.down('lg')]: {
      position: 'static',
      bottom: 0,
      fontSize: '12px !important',
      paddingTop: '12px !important',
      paddingBottom: '12px !important',
      marginTop: 7.5,
      marginBottom: 2.5,
      marginLeft: 7.25
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }),
  sectionTagContainer: theme => ({
    '& div[class*="container"]': {
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
      }
    }
  })
};
