import { Button } from '@swagup-com/react-ds-components';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid, useTheme } from '@mui/material';
import SectionTag from './SectionTag';
import styles from './styles/home';
import CenteredGrid from '../shared/CenteredGrid';
import { imageSrcSet } from '../../utils';
import Img from '../shared/Img';

const newGoToStyles = {
  containerGrid: {
    paddingTop: 14,
    paddingBottom: 14
  },
  center: theme => ({
    [theme.breakpoints.only('md')]: {
      width: 1024,
      paddingLeft: 6,
      paddingRight: 6
    },
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
      paddingLeft: 0,
      paddingRight: 0
    }
  }),
  sectionContainerYNTG: theme => ({
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('lg')]: {
      padding: '0px 56px !important'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px !important'
    }
  }),
  contentNewGT: theme => ({
    marginTop: 6,
    [theme.breakpoints.down('md')]: {
      margin: '0px auto',
      width: '80%'
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px auto',
      width: '100%'
    }
  }),
  sectionTitleStandardYNTG: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    marginTop: 6,
    marginBottom: 6,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 24,
      marginTop: 2,
      marginBottom: 1
    }
  }),
  imageNewGTMobileWrapper: theme => ({
    display: 'none',
    width: 375,
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      width: '100%'
    }
  }),
  imageNewGTMobile: {
    width: '100%',
    objectFit: 'contain',
    paddingLeft: 6,
    paddingRight: 6
  },
  imageNewGT: theme => ({
    width: 650,
    boxShadow: '0px 32.3232px 72.7273px rgba(0, 0, 0, 0.06)',
    [theme.breakpoints.between('sm', 'md')]: {
      width: 420
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }),
  linesWrapper: theme => ({
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    }
  }),
  buttonWrapper: theme => ({
    marginTop: 14,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      paddingLeft: '0px !important',
      marginTop: 6
    }
  }),
  lineContainerGrid: theme => ({
    marginBottom: 4,
    [theme.breakpoints.down('md')]: {
      justify: 'center',
      paddingLeft: 6,
      paddingRight: 6
    }
  }),
  lineContainerImage: theme => ({
    width: 30,
    height: 30,
    marginRight: 4.5,
    position: 'relative',
    verticalAlign: 'middle',
    marginTop: 1.5,
    [theme.breakpoints.down('md')]: {
      width: 18,
      height: 18
    }
  }),
  lineContainerText: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    fontWeight: 600,
    textAlign: 'ĺeft',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  })
};

const LineContainer = ({ text }) => {
  const src = `${process.env.PUBLIC_URL}/imgs/homeLight/group-12.svg`;

  return (
    <Grid container sx={newGoToStyles.lineContainerGrid}>
      <Grid item>
        <Img sx={newGoToStyles.lineContainerImage} src={src} alt={text} />
      </Grid>
      <Grid item xs>
        <Typography component="p" sx={newGoToStyles.lineContainerText}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

const dashboardImgPath = `${process.env.PUBLIC_URL}/imgs/homeLight/dashboard-image.png`;

const YourNewGoTo = () => {
  const theme = useTheme();
  return (
    <Grid container justifyContent="center" sx={newGoToStyles.containerGrid}>
      <CenteredGrid sx={newGoToStyles.center}>
        <Box sx={newGoToStyles.sectionContainerYNTG}>
          <Grid container sx={newGoToStyles.contentNewGT}>
            <Grid item md xs={12}>
              <SectionTag tag="YOUR NEW GO-TO" color="#3577d4" />
              <Typography variant="h2SemiBold" sx={newGoToStyles.sectionTitleStandardYNTG}>
                All powered by
                <br /> your dashboard
              </Typography>
              <Grid item xs align="center" sx={newGoToStyles.imageNewGTMobileWrapper}>
                <Img
                  sx={newGoToStyles.imageNewGTMobile}
                  src={dashboardImgPath}
                  srcSet={imageSrcSet(dashboardImgPath)}
                  alt="SWAGUP Dashboard"
                />
              </Grid>
              <Grid container justifyContent="center">
                <Grid item md sm="auto" sx={newGoToStyles.linesWrapper}>
                  <LineContainer text="Real time inventory updates" />
                  <LineContainer text="Track all your shipments in one place" />
                  <LineContainer text="Easily reorder your favorite swag" />
                  <LineContainer text="Send swag to contacts in your directory" />
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container sx={newGoToStyles.buttonWrapper}>
                  <Button
                    href="https://swagup.chilipiper.com/router/product-demo-router?utm_source=homepage&utm_medium=dashboard_tour_homepage&utm_campaign=dashboard_tour_homepage&utm_content=dashboard_tour_homepage"
                    variant="primary"
                    sx={[styles.mainLandingButton, styles.responsiveButton, { width: 272, backgroundColor: '#9846DD' }]}
                  >
                    SCHEDULE A DEMO
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={styles.noMobile}>
              <Grid container style={{ marginTop: 0 }} justifyContent="center">
                <Grid item xs align="center" style={{ marginTop: 0, paddingBottom: 56 }}>
                  <img
                    style={newGoToStyles.imageNewGT(theme)}
                    src={dashboardImgPath}
                    srcSet={imageSrcSet(dashboardImgPath)}
                    alt="SWAGUP Dashboard"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CenteredGrid>
    </Grid>
  );
};

export default YourNewGoTo;
