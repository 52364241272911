import { useState } from 'react';
import { styled, Box, Typography, Step, Stepper, Slide } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import FeatureHeader from '../shared/FeatureHeader';

const purple = '#9846dd';
const circle = { width: 20, height: 20, borderRadius: '50%', position: 'relative' };

const stepIconStyles = {
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center'
  },
  active: {
    color: purple
  },
  whiteCircle: {
    border: `solid 1px ${purple}`,
    boxShadow: '0 0 0 5px #fbf7ff',
    ...circle
  },
  purpleCircle: {
    backgroundColor: purple,
    ...circle
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: purple,
    position: 'absolute',
    top: '34%',
    left: '34%'
  },
  completed: {
    color: '#ffffff',
    borderRadius: '50%',
    backgroundColor: purple,
    zIndex: 1,
    fontSize: 16,
    position: 'absolute',
    top: '10%',
    left: '10%'
  },
  default: {
    ...circle,
    textAlign: 'center',
    backgroundColor: '#f0f2f5'
  },
  text: {
    fontSize: 10,
    margin: 0,
    fontFamily: 'Gilroy-Semibold',
    textAlign: 'center',
    color: '#787b80',
    position: 'absolute',
    top: '16%',
    left: '34%'
  }
};

const StepIcon = ({ active, completed, icon }) => {
  if (completed)
    return (
      <Box sx={stepIconStyles.purpleCircle}>
        <Check sx={stepIconStyles.completed} />
      </Box>
    );
  if (active)
    return (
      <Box sx={stepIconStyles.whiteCircle}>
        <Box sx={stepIconStyles.dot} />
      </Box>
    );
  return (
    <Box sx={stepIconStyles.default}>
      <Typography variant="inherit" sx={stepIconStyles.text}>
        {icon}
      </Typography>
    </Box>
  );
};

const DotsConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    [theme.breakpoints.down('sm')]: {
      padding: 0
    }
  },
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 10px)',
    right: 'calc(50% + 10px)',
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      left: 'calc(-50% + 11px)',
      right: 'calc(50% + 11px)'
    }
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'purple'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: 'purple'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#f0f2f5',
    height: 1,
    borderRadius: 1
  }
}));

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  [`& .${stepLabelClasses.label}`]: {
    fontSize: 16,
    color: '#0b1829',
    fontFamily: 'Gilroy-Medium',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10
    },
    [`&.${stepLabelClasses.active}`]: {
      color: purple,
      fontFamily: 'Gilroy-SemiBold'
    }
  }
}));

const ProcessSteps = ({ activeStep }) => (
  <Stepper connector={<DotsConnector />} alternativeLabel activeStep={activeStep}>
    <Step>
      <CustomStepLabel StepIconComponent={StepIcon}> Build Pack or Select Items</CustomStepLabel>
    </Step>
    <Step>
      <CustomStepLabel StepIconComponent={StepIcon}>Approve Designs</CustomStepLabel>
    </Step>
    <Step>
      <CustomStepLabel StepIconComponent={StepIcon}>Place Order</CustomStepLabel>
    </Step>
    <Step>
      <CustomStepLabel StepIconComponent={StepIcon}>Store in Warehouse or Ship to Recipients</CustomStepLabel>
    </Step>
    <Step>
      <CustomStepLabel StepIconComponent={StepIcon}>Mission Complete</CustomStepLabel>
    </Step>
  </Stepper>
);

const stepVideos = {
  0: 'https://swagup-static.s3.amazonaws.com/swagup-videos/select_swag.mp4',
  1: 'https://swagup-static.s3.amazonaws.com/swagup-videos/approve_design.mp4',
  2: 'https://swagup-static.s3.amazonaws.com/swagup-videos/place_order.mp4',
  3: 'https://swagup-static.s3.amazonaws.com/swagup-videos/send_swag.mp4',
  4: 'https://swagup-static.s3.amazonaws.com/swagup-videos/mission_complete.mp4 '
};

const styles = {
  processContainer: theme => ({
    paddingTop: 48,
    [theme.breakpoints.down('xl')]: {
      paddingTop: 22.5
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 18
    }
  }),
  video: theme => ({
    width: '100%',
    height: '50vh',
    marginBottom: 37.5,
    [theme.breakpoints.down('sm')]: {
      marginTop: 25,
      marginBottom: 0,
      height: 389
    }
  }),
  stepperContainer: theme => ({
    marginTop: 14,
    marginBottom: 10,
    padding: 6,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      marginTop: 7.5,
      left: 0
    }
  })
};

const SwagupProcess = () => {
  const [activeStep, setActiveStep] = useState(0);

  const moveNext = () => setActiveStep(prevStep => (prevStep + 1) % 5);

  return (
    <Box sx={styles.processContainer}>
      <FeatureHeader
        title="The SwagUp Process"
        subtitle="Handle each step through our easy-to-use platform."
        subtitleStyle={{ color: '#787B80' }}
      />
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={styles.stepperContainer}>
          <ProcessSteps activeStep={activeStep} />
        </Box>
        <Slide direction="right" key={activeStep} orientation="horizontal" in>
          <Box component="video" autoPlay muted onEnded={moveNext} sx={styles.video}>
            <source src={stepVideos[activeStep]} type="video/mp4" />
          </Box>
        </Slide>
      </Box>
    </Box>
  );
};

export default SwagupProcess;
