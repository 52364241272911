const apiPaths = {
  accountOrders: '/account-orders/',
  accountProductPrices: '/account-product-prices/',
  accountProducts: '/account-products/',
  accounts: '/accounts/',
  addresses: '/addresses/',
  authorizeCreditCards: '/authorize-credit-cards/',
  bulkPricing: '/bulk-pricing/',
  categories: '/categories/',
  contactForms: '/contact-forms/',
  contactFormSubjects: '/contact-form-subjects/',
  countries: '/countries/',
  credits: '/credits/',
  defaultBulk: '/items/?default_bulk_only=true',
  defaultSwag: '/pack-templates/?default_pack_only=true',
  deliveryMethods: '/delivery-methods/',
  directory: '/directory/',
  employeeOrders: '/employee-orders/',
  individualPackPrices: '/shipping-prices/individual-packs/',
  inventory: '/stock/',
  invoices: '/invoices/',
  metadata: '/public-metadata/',
  designs: '/designs/',
  notifications: '/notifications/',
  samplePacks: '/sample-packs/',
  opportunities: '/opportunities/',
  orders: '/orders/',
  packMultiplePrice: '/pack-multiple-price/',
  packTemplates: '/pack-templates/',
  partners: '/partners/',
  paymentProfiles: '/payment-profiles/',
  processes: '/processes/',
  profiles: '/profiles/',
  proofPrices: '/proof-prices/',
  proofs: '/proofs/',
  samplePacksEmails: '/sample-packs/emails/',
  saveContacts: '/save-contacts/',
  shippingPrices: '/shipping-prices/',
  sizes: '/sizes/',
  storageCategories: '/storage-categories/',
  storagePrices: '/storage-prices/',
  subscribers: '/subscribers/',
  swagContacts: '/swag-contacts/',
  updateContactsSizes: '/output-contact-sizes/',
  transactions: '/transactions/'
};

export default apiPaths;
