import { useState } from 'react';
import { Box, Grid, Link, Paper, Typography, Button } from '@mui/material';
import platformApiClient from '../../api/platformApiClient';
import CenteredGrid from '../shared/CenteredGrid';
import Loader from '../shared/Loader';
import Img from './Img';
import styles from './styles/productSection';
import { useQuery } from 'react-query';

const ProductCard = ({ pack, first = false, isHovered, toggleHover, to }) => (
  <Grid key={pack.id} item sm={4} xs={12} sx={styles.productCard}>
    <Link
      href={to}
      underline="none"
      sx={[styles.buttonPS, { height: '100%' }]}
      id={pack.id}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      <Paper
        elevation={2}
        sx={[first ? styles.simpleButtonEGFirst : styles.simpleButtonEG, isHovered && styles.hoveredButton]}
      >
        <Grid sx={[styles.insidePaper, { height: '100%' }]} container direction="column">
          <Grid item xs sx={{ display: 'flex', justifyContent: 'center', flexGrow: 'unset' }}>
            <Img width="225" src={pack.img} sx={styles.packImg} alt={`pack: ${pack.name}`} />
          </Grid>
          <Grid item xs style={{ flexGrow: 'unset' }}>
            <Typography variant="inherit" sx={styles.minimumTextEG}>
              {`Min ${pack.minimum} packs`}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="inherit" sx={styles.packNameTextEG}>
              {pack.name}
            </Typography>
          </Grid>
          <Grid item xs style={{ flexGrow: 'unset' }}>
            <Typography variant="inherit" sx={styles.packPriceTextEG}>
              ${pack.price_per_pack}
              /pack
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Link>
  </Grid>
);

const ProductCardLast = ({ packImg, toggleHover, isHovered }) => (
  <Grid item sm={4} xs={12} sx={styles.productCard}>
    <Box
      id="custom-packs"
      sx={[styles.buttonPS, { height: '100%' }]}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      <Paper elevation={2} sx={[styles.simpleButtonEGLast, isHovered && styles.hoveredButton]}>
        <Grid sx={styles.insidePaper} container direction="column">
          <Grid item xs sx={styles.containPackImg}>
            <Img width="225" src={packImg} sx={styles.packImg} alt="pack" />
          </Grid>

          <Grid item xs style={{ flexGrow: 'unset', height: 'auto' }}>
            <Typography variant="inherit" sx={styles.textCustomPack}>
              Custom pack
            </Typography>
            <Typography variant="inherit" sx={styles.subTextCustomPack}>
              Use our pack builder to build your own pack
            </Typography>
            <Grid item container justifyContent="center" sx={styles.containerButtonEG} xs={12}>
              <Button sx={styles.buttonEG} variant="outlined" href="/dashboard/custom-swag-packs/catalog">
                Build your Own
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  </Grid>
);

const ProductSection = ({ subtitle = 'We’ve accomplished so much but we’re not stopping anytime soon' }) => {
  const { data: packs, isLoading } = useQuery('preset-packs', () => platformApiClient.getPresetPacks());

  const [itemHovered, setItemHovered] = useState(undefined);

  const toggleHover = ({ currentTarget }) => {
    setItemHovered(itemHovered === currentTarget.id ? undefined : currentTarget.id);
  };

  return (
    <Grid container justifyContent="center" sx={styles.containerBuildPS}>
      <CenteredGrid sx={styles.center}>
        <Box sx={styles.sectionContainerPS}>
          <Typography componeny="h2" variant="inherit" sx={styles.promotionTitleAU}>
            Popular Packs
          </Typography>
          <Grid container justifyContent="center">
            <Grid item sx={styles.textAboutUs}>
              <p style={{ whiteSpace: 'pre-wrap' }}> {subtitle}</p>
            </Grid>
          </Grid>
        </Box>
        <Grid container sx={styles.containerAllPS} justifyContent="center">
          {!isLoading && packs && (
            <Grid container sx={{ alignItems: 'stretch' }}>
              {packs.map(pack => (
                <ProductCard
                  key={pack.id}
                  pack={pack}
                  isHovered={parseInt(itemHovered, 10) === pack.id}
                  to={`/dashboard/preset-packs/${pack.slug || pack.id}`}
                  toggleHover={toggleHover}
                />
              ))}
              <ProductCardLast
                packImg="/imgs/landingPagesSSR/swag-up-pack.png"
                packMinimum={50}
                packName="SwagUp Pack"
                packPricePerPack="115.00"
                isHovered={itemHovered === 'custom-packs'}
                toggleHover={toggleHover}
              />
            </Grid>
          )}
          {isLoading && <Loader />}
        </Grid>
      </CenteredGrid>
    </Grid>
  );
};

export default ProductSection;
