import { Grid, Box, Typography } from '@mui/material';
import Phone from '@mui/icons-material/Phone';
import styles from './styles/home';

const FloatingCallUs = () => {
  return (
    <a href="tel:6464940520">
      <Box sx={styles.floatingCallUs}>
        <Grid container alignItems="center">
          <Grid item>
            <Grid container justifyContent="center" alignItems="center" sx={styles.fabPhone}>
              <Grid item>
                <Phone
                  sx={[
                    styles.callUsPhone,
                    { opacity: 0.45, marginLeft: 1.5, boxShadow: '-3px 3px 12px 0 rgba(0, 0, 0, 0.1)' }
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Typography variant="body3SemiBold" sx={styles.callUsSmall}>
              Call us
            </Typography>
            <Typography variant="body1SemiBold" sx={styles.phoneText}>
              646-494-0520
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </a>
  );
};

export default FloatingCallUs;
