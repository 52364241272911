import { Grid } from '@mui/material';

const LimitingContainer = ({ children, sx, maxWidth = 1440 }) => (
  <Grid container justifyContent="center" sx={sx}>
    <Grid item xs={12} sx={{ maxWidth: `${maxWidth}px !important` }}>
      {children}
    </Grid>
  </Grid>
);

export default LimitingContainer;
