import { Grid, Box, Typography } from '@mui/material';
import styles from './styles/company';
import { imageSrcSet } from '../../utils';
import CenteredGrid from '../shared/CenteredGrid';
import Img from '../shared/Img';

const WeAreSwagup = () => (
  <Grid container justifyContent="center">
    <CenteredGrid item sx={styles.center}>
      <Typography component="h1" sx={styles.companyTitle}>
        We are SwagUp!
      </Typography>
      <Typography component="p" sx={styles.companySubTitle}>
        How we got here and the people behind it all
      </Typography>
    </CenteredGrid>
    <Grid item>
      <Box sx={styles.headImageContainer}>
        <Grid container spacing={7}>
          <Grid item xs={4} md={3} sx={styles.gridImgContainer2}>
            <Box>
              <Box sx={styles.imagesColumn}>
                <Img
                  src="/imgs/company/dak.png"
                  srcSet={imageSrcSet('/imgs/company/dak.png')}
                  alt="SwagUp CTO"
                  sx={styles.imageWASwagUp}
                />
              </Box>
              <Box>
                <Img
                  src="/imgs/company/swagup-warehouse.png"
                  srcSet={imageSrcSet('/imgs/company/swagup-warehouse.png')}
                  alt="Swagup warehouse"
                  sx={styles.imageWASwagUp}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} md={3} sx={styles.gridImgContainer}>
            <Box>
              <Img
                src="/imgs/company/swagup-team-pic.png"
                srcSet={imageSrcSet('/imgs/company/swagup-team-pic.png')}
                alt="SwagUp Team Ready"
                sx={styles.imageWASwagUp}
              />
            </Box>
          </Grid>
          <Grid item xs={4} sx={styles.gridImgContainer}>
            <Box sx={styles.imageMetalikaContainer}>
              <Img
                src="/imgs/company/swagup-female-emplyee.png"
                srcSet={imageSrcSet('/imgs/company/swagup-female-emplyee.png')}
                alt="team"
                sx={styles.imageWASwagUp}
              />
            </Box>
            <Box sx={styles.imageSwagPackContainer2}>
              <Img
                src="/imgs/company/swag-pack.png"
                srcSet={imageSrcSet('/imgs/company/swag-pack.png')}
                alt="team"
                sx={styles.imageWASwagUp}
              />
            </Box>
          </Grid>
          <Grid item xs={2} sx={styles.gridImgContainer3}>
            <Box sx={styles.imageSwagPackContainer}>
              <Img
                src="/imgs/company/swag-pack.png"
                srcSet={imageSrcSet('/imgs/company/swag-pack.png')}
                alt="swag-pack"
                sx={styles.imageWASwagUp2}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  </Grid>
);

export default WeAreSwagup;
