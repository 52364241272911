import BackgroundAdornments from '../shared/BackgroundAdornments';
import Benefits from '../shared/Benefits';
import ContactUs from '../../shared/ContactUs';
import OurServices from '../shared/OurServices';
import ProductSection from '../../shared/ProductSection';
import BuildYourBrand from './BuildYourBrand';
import LandingSection from './LandingSection';
import OurMission from './OurMission';

const bottomImagesList = [
  { src: '/imgs/landingPagesSSR/shopify-2.svg', alt: 'Shopify', margin: 10 },
  { src: '/imgs/landingPagesSSR/zapier.svg', alt: 'Zapier', margin: 10 },
  { src: '/imgs/landingPagesSSR/api.svg', alt: 'API', margin: 0 }
];

const benefits = {
  tag: 'Benefits',
  title: 'Take a break',
  subText: 'Benefits of using SwagUp app for your store',
  src: '/imgs/landingPagesSSR/img-benefits.png',
  facilities: [{ facility: 'Curated selection of quality swag' }],
  bottomImagesList
};

const ourServicesData = {
  tag: 'SwagUp Explained',
  title: 'We Make it Easy',
  services: [
    {
      img: '/imgs/landingPagesSSR/benefits-swags.svg',
      alt: 'custom',
      title: 'High Quality Items',
      text: 'We curate the best of the best, no more searching'
    },
    {
      img: '/imgs/landingPagesSSR/rocket-shopify.svg',
      alt: 'rocket',
      title: 'Your Chief Swag Officer',
      text: 'From design, assembly, storage, and shipping, we manage it all'
    },
    {
      img: '/imgs/landingPagesSSR/dashboard-event-geek.svg',
      alt: 'moon',
      title: 'Swag Management Dashboard',
      text: 'Keep track of your inventory, place reorders, and send swag around the globe'
    }
  ]
};

const SwagMadeSimpleEventManagers = () => (
  <>
    <BackgroundAdornments />
    <LandingSection />
    <OurServices {...ourServicesData} />
    <OurMission />
    <BuildYourBrand />
    <ProductSection />
    <Benefits benefits={benefits} />
    <ContactUs namePage="Swag made simple for event managers" />
  </>
);

export default SwagMadeSimpleEventManagers;
