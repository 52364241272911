import { useLocation } from 'react-router-dom';
import { notificationBuilder } from '../../utils';
import TopBarContent from './TopBarContent';
import NotificationsContainer from './Notifications';
import PartnersHeader from './PartnersHeader';

const partnersPaths = ['/partners', '/landing'];

const Header = () => {
  const { pathname, search } = useLocation();

  if (partnersPaths.some(path => pathname.startsWith(path))) {
    return <PartnersHeader />;
  }

  return (
    <TopBarContent
      pathname={pathname + search}
      getNotificationComponent={notificationBuilder(NotificationsContainer)}
    />
  );
};

export default Header;
