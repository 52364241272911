import { scrollBar } from '../../../common/styles';

export const style = {
  title: theme => ({
    textAlign: 'center',
    margin: '0 0 24px',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      marginTop: 2,
      marginBottom: 1,
      textAlign: 'center'
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: 1024,
      padding: '0 24px'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  quoteContainer: {
    paddingBottom: 0,
    whiteSpace: 'normal',
    ...scrollBar
  },
  scrollArrowSecondary: theme => ({
    color: '#0b1829',
    position: 'absolute',
    width: 50,
    height: 50,
    padding: 3,
    borderRadius: 25,
    border: 'solid 1px #f0f2f5',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    zIndex: 999,
    '&:hover': {
      backgroundColor: '#f0f2f5'
    },
    top: '43%',
    [theme.breakpoints.down('sm')]: {
      top: '14%'
    }
  }),
  carouselContainer: {
    maxWidth: 1440,
    overflowX: 'hidden',
    position: 'relative',
    outline: ' none !important'
  },
  quote: theme => ({
    margin: 0,
    fontStyle: 'italic',
    textAlign: 'left',
    color: '#0b1829',
    [theme.breakpoints.down('lg')]: { maxWidth: '100%' },

    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  }),
  testimonialContainer: theme => ({
    padding: '32px 32px',
    backgroundColor: 'transparent',
    textAlign: 'left',
    borderTopLeftRadius: 60,
    borderBottomRightRadius: 60,
    outline: 'none',
    minHeight: 282,
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      padding: 6,
      minHeight: 358
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: 6,
      minHeight: 358
    }
  }),
  imgTestimonial: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 'unset'
  },
  imgTestimonialContainer: theme => ({
    width: 400,
    height: 480,

    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '75%',
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      width: 200,
      height: 240
    },
    [theme.breakpoints.down('sm')]: {
      width: 160,
      height: 190
    }
  }),
  testimonialsTextContainer: theme => ({
    margin: '0px 40px',

    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  }),
  line: theme => ({
    border: 'solid 1px #f0f2f5',
    margin: '35px 0px 35px',
    [theme.breakpoints.down('md')]: {
      margin: '15px 0px 15px'
    }
  }),
  testimonialName: theme => ({
    textAlign: 'left',
    color: '#0b1829',
    margin: '0px 0px 4px',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      textAlign: 'center',
      marginBottom: 2.5
    }
  }),
  testimonialReference: theme => ({
    fontSize: 16,
    fontFamily: 'Gilroy-Medium',
    lineHeight: 1.75,
    marginTop: 0,
    textAlign: 'left',
    color: '#787b80',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      textAlign: 'center'
    }
  }),
  responsiveButton: theme => ({
    [theme.breakpoints.down('md')]: {
      height: 38,
      fontSize: 14
    }
  }),
  logoContainer: theme => ({
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    },
    paddingBottom: 4
  }),
  buttonContainer: theme => ({
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }),
  infoContainer: theme => ({
    height: '100%',
    paddingRight: 7.5,
    [theme.breakpoints.down('lg')]: {
      paddingRight: 0
    }
  }),
  disabledArrow: {
    visibility: 'hidden'
  }
};
