import { Grid, Button, Typography, Box } from '@mui/material';
import CenteredGrid from '../../shared/CenteredGrid';
import Img from '../../shared/Img';

import styles from './styles/beforeGetStarted';

const BeforeGetStarted = ({ onOpenModal }) => (
  <CenteredGrid container justifyContent="center" id="getStarted" sx={styles.container}>
    <Grid item sx={styles.textContainer}>
      <Typography variant="inherit" component="h2" sx={styles.title}>
        Before you get started
      </Typography>
      <Typography variant="inherit" sx={styles.subtitle}>
        Some things to know
      </Typography>
      <Grid container spacing={5}>
        <Grid item xs={6} sx={styles.category}>
          <img alt="title" src="/imgs/landingPagesSSR/custom-pack/landing/icon_Category_1.svg" />
          <p>We offer 8-15 curated items per category</p>
        </Grid>
        <Grid item xs={6} sx={styles.category}>
          <img alt="title" src="/imgs/landingPagesSSR/custom-pack/landing/icon_Category_2.svg" />
          <p>All pricing includes custom branding and any fees</p>
        </Grid>
        <Grid item xs={6} sx={styles.category}>
          <img alt="title" src="/imgs/landingPagesSSR/custom-pack/landing/icon_Category_3.svg" />
          <p>By making submitting you are not commiting to an order</p>
        </Grid>
        <Grid item xs={6} sx={styles.category}>
          <img alt="title" src="/imgs/landingPagesSSR/custom-pack/landing/icon_Category_4.svg" />
          <p>Our design team will create mockups at no cost</p>
        </Grid>
      </Grid>
    </Grid>
    <Grid item md={5} xs={12} style={{ paddingTop: 30, position: 'relative' }}>
      <Grid container justifyContent="center">
        <Img alt="title" src="/imgs/landingPagesSSR/custom-pack/getstarted.svg" sx={styles.image} />
        <Button href="/dashboard/custom-swag-packs/catalog" variant="contained" sx={styles.button}>
          Start Building
        </Button>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button variant="text" onClick={onOpenModal} sx={styles.link}>
            Speak with a rep
          </Button>
        </Grid>
      </Grid>
      <Box sx={styles.imgContainer}>&nbsp;</Box>
    </Grid>
  </CenteredGrid>
);

export default BeforeGetStarted;
