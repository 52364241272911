const styles = {
  root: theme => ({
    width: '100%',
    height: 112,
    boxShadow: 'none',
    right: 0,
    top: 0,
    left: 0,
    textAlign: 'center',
    zIndex: 502,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw',
      paddingLeft: '0 !important',
      minWidth: '100vw',
      height: 54
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('1030')]: {
      width: 960
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  logo: theme => ({
    [theme.breakpoints.down('md')]: {
      height: 24
    }
  }),
  textPhoneHeader: theme => ({
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    color: '#434c5f',
    display: 'inline-flex',
    width: '100%',
    lineHeight: 1.6,
    '& a': {
      color: '#434c5f'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginRight: 2.5,
      lineHeight: 1.6
    }
  }),
  imageContainerPhoneHeader: theme => ({
    height: 25,
    width: 'auto',
    marginRight: 4,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      top: 0,
      marginRight: 1.5,
      height: 18
    }
  })
};

export default styles;
