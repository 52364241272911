import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import loadConfig from './utils/loadConfig';
import { createApiInstances } from './api/platformApiClient';
import { createWordpressApiInstances } from './api/wordpressApiClient';
import { createContentfulClient } from './api/contentfulApiClient';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import TagManager from 'react-gtm-module';
import appConfig from './appConfig';
import generateSwagupCookie from './utils/generateSwagupCookie';
// importing style for carousel
import 'react-responsive-carousel/lib/styles/carousel.min.css';
const appVersion = require('../package.json').version;

const initializeApp = async () => {
  const resp = await loadConfig();
  createApiInstances({
    apiEndpoint: resp.REACT_APP_API_ENDPOINT,
    apiEndpoint101: resp.REACT_APP_API_ENDPOINT_101,
    formApiEndpoint: resp.REACT_APP_API_FORM_ENDPOINT,
    publicApiEndpoint: resp.REACT_APP_PUBLIC_API_ENDPOINT,
    partnersApiEndpoint: resp.REACT_APP_API_PARTNERSHIPS_ENDPOINT
  });
  createWordpressApiInstances(resp.REACT_APP_WORDPRESS_API_REST);
  createContentfulClient(resp.REACT_APP_CONTENTFUL_SPACE_ID, resp.REACT_APP_CONTENTFUL_ACCESS_TOKEN);
  appConfig.data = resp;
  console.log('app version: ', appVersion);

  TagManager.initialize({
    gtmId: resp.REACT_APP_GTM_CONTAINER,
    auth: resp.REACT_APP_GTM_AUTH,
    preview: resp.REACT_APP_GTM_PREVIEW
  });

  const LDProvider = await asyncWithLDProvider({
    clientSideID: resp.REACT_APP_LAUNCH_DARKLY_CLIENTID,
    user: {
      key: 'AnonymousUser',
      anonymous: true
    }
  });

  generateSwagupCookie();

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <BrowserRouter>
          <App config={resp} />
        </BrowserRouter>
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

initializeApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
