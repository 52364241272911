import { useState } from 'react';
import { useQuery } from 'react-query';
import { Grid, Link, Typography, Box, Divider } from '@mui/material';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { Button } from '@swagup-com/react-ds-components';
import { Carousel } from 'react-responsive-carousel';
import { oneHour } from '../../utils';
import contentfulApiClient from '../../api/contentfulApiClient';
import CenteredGrid from './CenteredGrid';
import { style } from './styles/testimonials';

const quoteMaxLength = 350;

const QuoteText = ({ quote }) => {
  const isLongQuote = quote.length > quoteMaxLength;
  const [showTrimmedQuote, setShowTrimmedQuote] = useState(isLongQuote);

  const toggleShowTrimmedQuote = () => setShowTrimmedQuote(prevState => !prevState);

  return (
    <>
      <Box sx={style.quoteContainer}>
        <Typography variant="subtitle1SemiBold" sx={style.quote}>{`“${
          showTrimmedQuote ? `${quote.slice(0, quoteMaxLength)}...` : quote
        }”`}</Typography>
      </Box>
      {isLongQuote && (
        <Button sx={style.responsiveButton} onClick={toggleShowTrimmedQuote} color="primary" variant="text">
          {`Show ${showTrimmedQuote ? 'more' : 'less'}...`}
        </Button>
      )}
    </>
  );
};

const Testimonial = ({ testimonial: { name, quote, logo, caseStudy, img, reference } }) => {
  return (
    <CenteredGrid>
      <Box sx={style.testimonialContainer}>
        <Grid container alignItems="stretch">
          <Grid sx={{ paddingBottom: 15 }} item container justifyContent="center" lg={5} xs={12}>
            <Box sx={style.imgTestimonialContainer}>
              <img src={img} style={style.imgTestimonial} alt="Customer" />
            </Box>
          </Grid>
          <Grid item sx={style.testimonialsTextContainer} lg xs={12}>
            <Grid container sx={style.infoContainer} direction="column">
              <Grid item xs>
                <QuoteText quote={quote} />
              </Grid>
              <Grid item>
                <Divider sx={style.line} />
              </Grid>
              <Grid item container>
                <Grid item xs={12} md={7}>
                  <Typography variant="h3Bold" sx={style.testimonialName}>
                    {name}
                  </Typography>
                  <Typography variant="body1Medium" sx={style.testimonialReference}>
                    {reference}
                  </Typography>
                  <Box sx={style.logoContainer}>
                    <img src={logo} alt="logo" style={{ width: 'inherit' }} />
                  </Box>
                </Grid>
                <Grid container item sx={style.buttonContainer} xs={12} md={5}>
                  <Button
                    sx={[style.responsiveButton, { justifyContent: 'center', textAlign: 'center' }]}
                    component={Link}
                    href={caseStudy}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="secondary"
                  >
                    Read the Case Study
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CenteredGrid>
  );
};

const scrollMenuData = testimonials =>
  testimonials.map(testimonial => (
    <div key={testimonial.id}>
      <Testimonial testimonial={testimonial} />
    </div>
  ));

const LeftArrow = (onClickHandler, hasPrev) => {
  return (
    hasPrev && <KeyboardArrowLeftRounded sx={[style.scrollArrowSecondary, { left: 0 }]} onClick={onClickHandler} />
  );
};

const RightArrow = (onClickHandler, hasNext) => {
  return (
    hasNext && <KeyboardArrowRightRounded sx={[style.scrollArrowSecondary, { right: 0 }]} onClick={onClickHandler} />
  );
};

const TestimonialCarousel = ({ testimonials }) => (
  <CenteredGrid item>
    <Box sx={style.carouselContainer}>
      <Carousel
        renderArrowPrev={LeftArrow}
        renderArrowNext={RightArrow}
        autoPlay={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        swipeable={false}
      >
        {scrollMenuData(testimonials)}
      </Carousel>
    </Box>
  </CenteredGrid>
);

const Testimonials = ({ title }) => {
  const { data: testimonials } = useQuery('testimonials', contentfulApiClient.fetchTestimonials, {
    staleTime: oneHour,
    cacheTime: oneHour
  });

  if (!testimonials) return null;

  return (
    <CenteredGrid sx={style.center}>
      <Grid justifyContent="center" direction="column" container>
        <Grid item>
          <Typography variant="h1Bold" sx={style.title}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <TestimonialCarousel testimonials={testimonials} />
        </Grid>
      </Grid>
    </CenteredGrid>
  );
};

export default Testimonials;
