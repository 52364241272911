import { Grid, Typography } from '@mui/material';

const styles = {
  title: theme => ({
    fontFamily: 'Gilroy-Bold',
    fontSize: 48,
    lineHeight: '64px',
    color: '#0b1829',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      textAlign: 'center',
      lineHeight: 'normal',
      padding: '17px 0px'
    }
  }),
  subtitle: theme => ({
    fontFamily: 'Gilroy',
    fontSize: 16,
    lineHeight: '28px',
    color: '#868a8f',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      textAlign: 'center',
      lineHeight: 'normal',
      padding: '6.5px 0px'
    }
  }),
  titleContainer: theme => ({
    paddingTop: 5,
    paddingBottom: 4,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0
    }
  })
};

const FeatureHeader = ({ tags, title, subtitle, subtitleStyle, alignItems = 'center' }) => (
  <Grid justifyContent="center" direction="column" alignItems={alignItems} container>
    <Grid item>{tags}</Grid>
    <Grid sx={styles.titleContainer} item>
      <Typography varaint="inherit" sx={styles.title}>
        {title}
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="inherit" sx={[styles.subtitle, subtitleStyle]}>
        {subtitle}
      </Typography>
    </Grid>
  </Grid>
);

export default FeatureHeader;
