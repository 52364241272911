const styles = {
  container: theme => ({
    width: '100%',
    '& div[class*="animated"]': {
      paddingLeft: 26,
      [theme.breakpoints.down('md')]: {
        paddingLeft: 17.5
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 30px'
      }
    }
  }),
  trustedBy: {
    width: 96,
    height: 25,
    fontFamily: 'Gilroy-Semibold',
    fontSize: 20,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    my: 5,
    marginLeft: 1.75,
    color: '#8d9299'
  },
  bottomImagesContainer: theme => ({
    marginTop: 6,
    marginLeft: 1.56,
    '& div': {
      [theme.breakpoints.down('lg')]: {
        marginTop: '16px !important'
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '20px !important'
      }
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '-14px !important'
    }
  })
};

export default styles;
