import { Box, Grid, Link, Typography } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import styles from './styles/contactUs';
import ContactForm from './ContactForm';
import SectionTag from './NewSectionTag';
import CenteredGrid from './CenteredGrid';
import Img from './Img';

const Confirmation = ({ resetForm }) => (
  <Box sx={styles.thankYouContainer}>
    <Typography component="h3" sx={styles.thankYouText}>
      Message Received!
    </Typography>
    <Typography sx={styles.thankYouSubText}>
      Your message has made it through to mission control. We’ll be in touch soon. To the moon!
    </Typography>
    <Grid container justifyContent="center">
      <Button variant="primary" onClick={resetForm} sx={{ width: 290 }}>
        Back
      </Button>
    </Grid>
  </Box>
);

const ContactUs = ({ namePage, mailTo = 'sales@swagup.com' }) => (
  <Grid id="contact-us" container justifyContent="center" sx={styles.containerRequestPack}>
    <CenteredGrid sx={styles.center}>
      <Box sx={styles.sectionContainerCU}>
        <Grid container sx={styles.contentNewGT}>
          <Grid item md={6} sm={12} sx={{ flexGrow: 1 }}>
            <SectionTag tag="Contact Us" />
            <Typography component="h2" sx={styles.promotionTitleCU}>
              Have Questions? <br />
              Don&apos;t be shy
            </Typography>
            <Grid item>
              <Box sx={styles.containerPhone}>
                <Link sx={styles.textPhone} href="tel:6464940520">
                  Call Us
                </Link>
              </Box>
            </Grid>
            <Grid item>
              <Typography sx={styles.textPhoneNumber}>
                <Img sx={styles.imageContainerPhone} src="/imgs/landingPagesSSR/phone.svg" alt="phone" />
                <Link href="tel:6464940520">646-494-0520</Link>
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={styles.containerPhone}>
                <Link
                  sx={styles.textPhone}
                  href={`mailto:${mailTo}${namePage ? `?subject=I would like more information about ${namePage}` : ''}`}
                >
                  Send Email
                </Link>
              </Box>
            </Grid>
            <Grid item>
              <Typography sx={styles.textPhoneNumber}>
                <Img sx={styles.imageContainerPhone} src="/imgs/landingPagesSSR/email.svg" alt="email" />
                <Link
                  href={`mailto:${mailTo}${namePage ? `?subject=I would like more information about ${namePage}` : ''}`}
                >
                  {mailTo}
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={6} sm={12} sx={styles.ContainerForm}>
            <ContactForm
              variant="simple"
              defaultSubject={`New Contact Form - ${namePage}`}
              SuccessComponent={Confirmation}
              disableSubjects
            />
          </Grid>
        </Grid>
      </Box>
    </CenteredGrid>
  </Grid>
);

export default ContactUs;
