import { Grid } from '@mui/material';
import BackgroundCircle from '../../shared/BackgroundCircle';
import styles from '../../shared/styles/backgroundAdorments';

const BackgroundAdornments = () => {
  return (
    <Grid container sx={styles.ovalContainer}>
      <BackgroundCircle size={353} color="#9846dd" top={-401} left={-700} opacity={0.08} />
      <BackgroundCircle size={256} color="rgba(53, 119, 212, 0.15)" top={-310} left={-456} opacity={0.5} />
      <BackgroundCircle sx={styles.ovalBlueEmptyTop2} />
      <BackgroundCircle sx={styles.ovalBlueBox} />
      <BackgroundCircle sx={styles.ovalBlueCoffeeCup} />
      <BackgroundCircle sx={styles.ovalBlueGoogle} />
      <BackgroundCircle sx={styles.ovalPurpleBYB} />
      <BackgroundCircle sx={styles.ovalBlueBYB} />
      <BackgroundCircle sx={styles.ovalBlueRocket} outlined />
      <BackgroundCircle sx={styles.ovalPurpleRocket} />
      <BackgroundCircle sx={styles.ovalPurpleOS} />
      <BackgroundCircle sx={styles.ovalBlueOS} />
      <BackgroundCircle sx={styles.ovalPurpleCU1} />
      <BackgroundCircle sx={styles.ovalPurpleCU2} />
      <BackgroundCircle sx={styles.ovalBlueCU} />
      <BackgroundCircle sx={styles.ovalPurpleCU3} />
      {/* mobiles */}
      <BackgroundCircle mobile size={53} color="#9846dd" top={-70} left={-100} opacity={0.08} />
      <BackgroundCircle mobile size={100} color="#3577d4" top={-80} left={-170} opacity={0.08} />
      <BackgroundCircle mobile sx={styles.ovalBluePhone} />
      <BackgroundCircle mobile size={60} color="#3577d4" top={150} left={-190} opacity={0.08} />
      <BackgroundCircle mobile size={120} color="#9846dd" top={550} right={-240} opacity={0.08} />
      <BackgroundCircle mobile sx={styles.ovalPurpleRockets} />
      <BackgroundCircle mobile size={50} color="#3577d4" top={950} right={-150} opacity={0.08} />
      <BackgroundCircle mobile size={120} color="#9846dd" top={1220} right={-250} opacity={0.08} />
      <BackgroundCircle mobile size={170} color="#9846dd" top={2270} right={-270} opacity={0.08} />
      <BackgroundCircle mobile size={50} color="#3577d4" top={2370} right={-150} opacity={0.08} />
    </Grid>
  );
};

export default BackgroundAdornments;
