import { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import CenteredGrid from '../shared/CenteredGrid';
import LoadMoreSection from '../shared/LoadMoreSection';
import { sortImages, rowsGenerator, tagImagesDisplay, getCurrentRows } from './galleryHandlers';
import styles from './styles/gallery';

const INITIAL_ROWS = 6;

const GalleryDisplay = ({ mediaGallery }) => {
  const [maxRows, setMaxRows] = useState(100);
  const [sortedImages, setSortedImages] = useState(() => tagImagesDisplay(mediaGallery));
  const [currentRows, setcurrentRows] = useState(0);
  useEffect(() => {
    const arrangedImages = sortImages([...mediaGallery]);
    setSortedImages(arrangedImages);
    setcurrentRows(getCurrentRows(arrangedImages));
    setMaxRows(INITIAL_ROWS);
  }, [mediaGallery]);

  return (
    <Grid container justifyContent="center">
      <CenteredGrid sx={styles.galleryCenter}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr key="theader">
              <th aria-label="First" style={{ width: '50%' }} />
              <th aria-label="Second" style={{ width: '50%' }} />
            </tr>
            {rowsGenerator(maxRows, [...sortedImages]).map(row => row)}
          </tbody>
        </table>
        <Box sx={styles.loadMoreSection}>
          {currentRows > maxRows && <LoadMoreSection onLoadMore={() => setMaxRows(maxRows + 6)} />}
        </Box>
      </CenteredGrid>
    </Grid>
  );
};

export default GalleryDisplay;
