import Cookie from 'js-cookie';

var utmKeys = [
  'gclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'affiliate_id',
  'affiliate_cid'
];
const generateSwagupCookie = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const hostName = window.location.hostname;
  const domain = hostName === 'localhost' ? hostName : `swagup.${hostName.split('.').pop()}`;
  const expirationLength = 2592000 * 1000;
  const expirationDate = new Date(Date.now() + expirationLength);
  const utmCookie = JSON.parse(Cookie.get('swagup') || '{}');
  utmKeys.forEach(utmKey => {
    const utmValue = urlParams.get(utmKey);
    if (utmValue) {
      utmCookie[utmKey] = utmValue;
    }
  });
  if (Object.keys(utmCookie).length > 0) {
    Cookie.set('swagup', JSON.stringify(utmCookie), { expires: expirationDate, path: '/', domain });
  }
};

export default generateSwagupCookie;
