import LandingView from '../shared/LandingView';
import LandingSection from '../shared/LandingSection';
import OurServices from '../../shared/OurServices';
import ContactUs from '../../shared/ContactUs';
import SamplePack from '../shared/NewSamplePack';
import BuildYourBrand from '../shared/NewBuildYourBrand';
import BackgroundLandingPages from '../shared/BackgroundAdornments';

const services = [
  {
    src: '/imgs/landingPagesSSR/design.svg',
    title: 'Design',
    text: 'We understand you have been assigned to order swag for your next tradeshow. Leave the artsy stuff to us. We will design the swag for you'
  },
  {
    src: '/imgs/landingPagesSSR/automate.svg',
    title: 'Automate',
    text: 'Collecting data on employees or clients can be hard. We can integrate with any CRM/HR system or simply upload a CSV of contacts you want to distribute swag'
  },
  {
    src: '/imgs/landingPagesSSR/assemble.svg',
    title: 'Assemble',
    text: 'We understand assembling swag is not part of your job description! Leave the assembly line to us! We have the packing covered'
  },
  {
    src: '/imgs/landingPagesSSR/ship.svg',
    title: 'Distribute',
    text: 'Manage, track inventory and shipments for your swag on our SwagUp Platform. One centralized platform making swag experiences for remote workers simple'
  }
];

const bottomImagesList = [
  { src: '/imgs/landingPagesSSR/atlassian.svg', alt: 'Atlassian', margin: 0.75, forcedMargin: 5.5 },
  { src: '/imgs/landingPagesSSR/notion.svg', alt: 'Notion', margin: 0, noPadding: true, forcedMargin: 10.5 },
  { src: '/imgs/landingPagesSSR/breeze.svg', alt: 'Breeze', noPadding: true, forcedMargin: 10.5 },
  { src: '/imgs/landingPagesSSR/smartbug.svg', alt: 'SmartBug', margin: 0, forcedMargin: 10.5 },
  { src: '/imgs/landingPagesSSR/nasdaq.svg', alt: 'Nasdaq', margin: 0, forcedMargin: 10.5 },
  { src: '/imgs/landingPagesSSR/boomi.svg', alt: 'Boomi', forcedMargin: 10.5 }
];

const sectionData = {
  title: 'Stay Connected with \nRemote Employees \nthrough Swag',
  h1Style: { fontSize: 60, width: '125%', whiteSpace: 'pre-wrap' },
  subTitle: 'Create, Automate and Distribute Swag to \nRemote Workers Through One Platform',
  subTitleClass: 'homePromoMiddleRE',
  landingView: <LandingView />,
  imagesTitle: 'SwagUp Client’s Sending Swag to Remote Teams',
  containerClass: 'bottomImagesContainerVCS',
  bottomImagesList
};

const answers = [
  { subTitle: 'Sending Swag to clients and employees is a great way to connect and engage conversations.' },
  {
    subTitle:
      'Select high-quality swag branded with your logo to give clients and employees a sense to the community while being remote.'
  },
  {
    subTitle: 'Distribute Swag to remote employees or clients using SwagUp through one platform!'
  }
];

const RemoteEmployee = () => (
  <>
    <BackgroundLandingPages />
    <LandingSection sectionData={sectionData} />
    <BuildYourBrand
      title={`What’s Swag Look like for\nRemote Workers?`}
      maingImg="/imgs/landingPagesSSR/remote-work.png"
      responses={answers}
      hideTag
    />
    <SamplePack
      sectionTagProps={{ left: false, right: true }}
      title={`Want to check\nout what a\nswag pack\ncan look like?`}
      subTitle={`Click on the link below to have a\nsample pack shipped directly to you!`}
      image="/imgs/landingPagesSSR/pack0050.png"
    />
    <OurServices
      services={services}
      title="We do the work - You take the credit!"
      buttonProps={{ text: 'Let’s get started!' }}
    />
    <ContactUs namePage="Stay Connected with Remote Employees through Swag " />
  </>
);

export default RemoteEmployee;
