import { Grid, Typography, Tooltip, Zoom } from '@mui/material';
import SectionTag from '../shared/SectionTag';
import styles from './styles/pricingModelSection';
import Img from '../shared/Img';

const tableContent = [
  {
    title: 'CREATE',
    iconSrc: '/imgs/pricing/rocket-tshirt.svg',
    rows: [
      {
        title: 'Product Design',
        tooltipText: 'Our team of designers take care of design from start to finish, no need to involve your team',
        free: true
      },
      {
        title: 'Designated Account Manager',
        tooltipText: 'Every company is assigned their own dedicated Account Manager, no matter the order size',
        free: true
      },
      {
        title: 'Platform Usage',
        tooltipText: 'Our Swag Management Dashboard is completely free to use, no monthly fees!',
        free: true
      },
      {
        title: 'Quality Control',
        tooltipText:
          "Every product that we work on goes through a rigorous quality check before going out. If we don't like it, you'll never see it",
        free: true
      },
      {
        title: 'Swag',
        tooltipText: 'We offer a curated selection of quality items. Pricing varies based on quality and quantity',
        free: false
      }
    ]
  },
  {
    title: 'AUTOMATE',
    iconSrc: '/imgs/pricing/screen.svg',
    rows: [
      {
        title: 'Swag Redeem Pages',
        tooltipText: 'Set up branded landing pages to easily collect recipient info and automate shipments',
        free: true
      },
      {
        title: 'App Integrations',
        tooltipText:
          'Whether through our open API, Zapier App, or Native integrations, you can automatically send swag based on triggers in your apps',
        free: true
      },
      {
        title: 'Zapier',
        tooltipText: 'Easily connect to over 2000 apps to integrate SwagUp into your workflows',
        free: true
      },
      {
        title: 'API Usage',
        tooltipText: 'Our API-first platform is available for you to develop on top of, go wild!',
        free: true
      },
      {
        title: 'Custom Integrations',
        tooltipText: 'Have a complex integration that needs to be set up? Our team can help you get started',
        free: false
      },
      {
        title: 'Swag Shops',
        tooltipText: (
          <span>
            With the <a href="https://apps.shopify.com/swagup">SwagUp Shopify app</a>, you can easily set up storefronts
            for your employees or customers to purchase swag with credits or their own funds
          </span>
        ),
        free: true,
        interactive: true
      }
    ]
  },
  {
    title: 'DISTRIBUTE',
    iconSrc: '/imgs/pricing/delivery-truck.svg',
    rows: [
      { title: 'Picking', tooltipText: 'We never charge a fee to pick items out from inventory', free: true },
      {
        title: 'Swag Pack Assembly',
        tooltipText: 'Pack assembly is included in the price of our items, no additional fees to build your packs',
        free: true
      },
      {
        title: 'Int. Customs Documentation',
        tooltipText: 'We’ve shipped to over 100 countries. We’ll handle all of the paperwork and customs for you',
        free: true
      },
      {
        title: 'Same Day Fulfillment',
        tooltipText: 'All requests that come in by 3pm EST go out the same day, standard!',
        free: true
      },
      {
        title: 'Dropshipping',
        tooltipText: 'Ship to 1 or 1000+ addresses, no additional costs beyond the postage',
        free: true
      },
      {
        title: 'Storage + Fulfillment',
        tooltipText: 'Store your inventory in our warehouse and ship as needed for a simple, one-time flat fee',
        free: false
      },
      {
        title: 'Add in 3rd Party Items',
        tooltipText:
          'Have some existing items you’d like to send in to be included in your packs or inventory, no problem!',
        free: false
      },
      {
        title: 'Shipping',
        tooltipText: 'Take advantage of our strong discounts on domestic and international shipping rates.',
        free: false
      }
    ]
  }
];

const TooltipContent = ({ title, text, free }) => (
  <>
    <Grid container justifyContent="space-between" alignItems="center">
      <Typography component="h3" sx={styles.tooltipTitle}>
        {title}
      </Typography>
      <Typography component="h3" sx={styles.tooltipFreeText}>
        {free ? 'Free' : 'Charge'}
      </Typography>
    </Grid>
    <Grid container sx={{ paddingTop: 6.5 }}>
      {text}
    </Grid>
  </>
);

const CheckIcon = () => <Img src="/imgs/pricing/check.svg" alt="check icon" />;

const CheckColumn = ({ checked }) => (
  <Grid sx={styles.rowColumn} container justifyContent="center" alignItems="center" item xs={3}>
    {checked && <CheckIcon />}
  </Grid>
);

const MainRowBody = ({ rows }) =>
  rows.map(row => (
    <Grid container key={row.title} sx={styles.row}>
      <Tooltip
        placement="top-start"
        componentsProps={{ tooltip: { sx: styles.tooltip } }}
        title={<TooltipContent title={row.title} text={row.tooltipText} free={row.free} />}
        TransitionComponent={Zoom}
        interactive={row.interactive}
      >
        <Grid sx={styles.rowColumn} container alignItems="center" item xs={6}>
          <Typography sx={styles.rowText}>{row.title}</Typography>
        </Grid>
      </Tooltip>
      <CheckColumn sx={styles} checked={row.free} />
      <CheckColumn sx={styles} checked={!row.free} />
    </Grid>
  ));

const TableBody = () =>
  tableContent.map(mainRow => (
    <Grid container key={mainRow.title} sx={styles.tableBodyContainer}>
      <Grid sx={styles.mainRowImageSection} container justifyContent="center" alignItems="center" item xs={4}>
        <Img src={mainRow.iconSrc} alt={mainRow.title} />
        <Typography component="h2" sx={styles.mainRowTitle}>
          {mainRow.title}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <MainRowBody rows={mainRow.rows} />
      </Grid>
    </Grid>
  ));

const TableSection = () => (
  <>
    <Grid container>
      <Grid item xs={4} />
      <Grid container item xs={8}>
        <Grid item xs={6} />
        <Grid item xs={3}>
          <Typography component="h3" sx={styles.FreeText}>
            Free
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography component="h3" sx={styles.ChargeText}>
            Charge
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid container sx={styles.tableContainer}>
      <TableBody />
    </Grid>
  </>
);

const PricingModelSection = () => (
  <Grid container justifyContent="center" sx={styles.sectionContainer}>
    <Grid sx={styles.mainArea}>
      <SectionTag tag="Pricing Model" left={false} />
      <Typography variant="h3" sx={styles.sectionTitle}>
        What We Do and Don&apos;t Charge For
      </Typography>
      <TableSection />
    </Grid>
  </Grid>
);

export default PricingModelSection;
