import { useState, useContext, useEffect } from 'react';
import { Grid, Typography, Tooltip, Zoom, Divider, Link } from '@mui/material';
import _ from 'lodash';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styles from './styles/swagPricesSection';
import { Image, SubSection, PriceSection } from './SwagPricesCard';
import GlobalInfoContext from './GlobalInfoContext';
import ProductTypeSwitch from './ProductTypeSwitch';
import CustomSlider from './CustomSlider';
import useApiCall from '../../api/useApiCall';
import { moneyStr } from '../../helpers/utils';
import { getContactList } from '../../helpers/utilsLink';
import platformApiClient from '../../api/platformApiClient';
import Img from '../shared/Img';

const quantityMarks = [
  {
    value: 50,
    label: '50'
  },
  {
    value: 1000,
    label: '1000'
  }
];

const QuantitySection = ({ text, quantity, onQuantityChange }) => (
  <>
    <Grid container justifyContent="space-between" alignItems="center" sx={{ marginTop: 18 }}>
      <Grid container item xs={8} alignItems="center">
        <Typography component="h5" sx={[styles.mt0, styles.mb0, styles.font, styles.labelFont, styles.labelFontSize]}>
          {text}
        </Typography>
        <Tooltip
          arrow
          placement="top"
          componentsProps={{ tooltip: { sx: styles.tooltip } }}
          title="Pricing varies based on quantity. The higher the quantity, the lower the price."
          TransitionComponent={Zoom}
        >
          <InfoOutlinedIcon fontSize="small" sx={[styles.ml16, styles.blueColor]} />
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Typography
          sx={[styles.mt0, styles.mb0, styles.font, styles.numberFontPurple, styles.labelFontSize, styles.alignRight]}
        >
          {quantity}
        </Typography>
      </Grid>
    </Grid>
    <Grid container sx={{ paddingTop: 7.5 }}>
      <CustomSlider value={quantity} onChange={onQuantityChange} marks={quantityMarks} min={50} max={1000} />
    </Grid>
  </>
);

const colorMarks = [
  {
    value: 1,
    label: '1'
  },
  {
    value: 2,
    label: '2'
  },
  {
    value: 3,
    label: '3'
  },
  {
    value: 4,
    label: '4'
  }
];

const ColorsSection = ({ colorCount, onColorCountChange }) => (
  <>
    <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: 25 }}>
      <Grid container item xs={8} alignItems="center">
        <Typography component="h5" sx={[styles.mt0, styles.mb0, styles.font, styles.labelFont, styles.labelFontSize]}>
          Colors in logo
        </Typography>
        <Tooltip
          arrow
          placement="top"
          componentsProps={{ tooltip: { sx: styles.tooltip } }}
          title="Generally speaking, the more colors in your designs the higher the price. To bring down prices, simplify logos to one color."
          TransitionComponent={Zoom}
        >
          <InfoOutlinedIcon fontSize="small" sx={[styles.ml16, styles.blueColor]} />
        </Tooltip>
      </Grid>
      <Grid item xs={4}>
        <Typography
          sx={[styles.mt0, styles.mb0, styles.font, styles.numberFontPurple, styles.labelFontSize, styles.alignRight]}
        >
          {colorCount}
        </Typography>
      </Grid>
    </Grid>
    <Grid container style={{ paddingTop: 30 }}>
      <CustomSlider value={colorCount} onChange={onColorCountChange} min={1} marks={colorMarks} max={4} step={null} />
    </Grid>
  </>
);

const Ordering = () => (
  <Grid container justifyContent="center" sx={styles.orderingCardContainer}>
    <Grid container sx={styles.orderingCard}>
      <Grid item xs={12} md={8} sx={styles.orderingTextContainer}>
        <Typography component="h4" sx={styles.orderingTitle}>
          Ordering more than $100,000 <br /> per year of swag?
        </Typography>
        <Typography component="h6" sx={styles.orderingSubTitle}>
          Ask us about our volume discount tiers
        </Typography>
      </Grid>
      <Grid container item xs={12} md={4} justifyContent="center" alignItems="center">
        <Link href={getContactList()} sx={styles.orderingButton}>
          Talk to a rep
        </Link>
      </Grid>
    </Grid>
  </Grid>
);

const swagPriceUpdater = ({ quantities: [{ price }] }) => +price;

const bulkPriceUpdater = ({
  products: [
    {
      quantities: [{ price }]
    }
  ]
}) => +price;

const SwagPricesSection = () => {
  const { bulk, swag } = useContext(GlobalInfoContext);
  const [priceText, setPriceText] = useState(moneyStr(''));
  const [isBulk, setIsBulk] = useState(false);
  const [quantity, setQuantity] = useState(564);
  const [colorCount, setColorCount] = useState(2);
  const [bulkStatus, setBulkApiFunc] = useApiCall();
  const [swagStatus, setSwagApiFunc] = useApiCall();

  useEffect(() => {
    if (bulk && swag) {
      const fetchPrices = async () => {
        const fetchBulkPrices = () => platformApiClient.getBulkPrices(bulk, quantity, colorCount);
        const fetchPackPrices = () => platformApiClient.getPackPrices(swag, quantity, colorCount);
        if (isBulk) setBulkApiFunc(fetchBulkPrices);
        else setSwagApiFunc(fetchPackPrices);
      };
      const updatePrices = _.debounce(fetchPrices, 750);
      updatePrices();
      return () => updatePrices.cancel();
    }
  }, [quantity, colorCount, isBulk, bulk, swag, setSwagApiFunc, setBulkApiFunc]);

  useEffect(() => {
    if (bulkStatus.response) {
      const newPrice = bulkPriceUpdater(bulkStatus.response);
      setPriceText(moneyStr(newPrice));
    }
  }, [bulkStatus.response]);

  useEffect(() => {
    if (swagStatus.response) {
      const newPrice = swagPriceUpdater(swagStatus.response);
      setPriceText(moneyStr(newPrice));
    }
  }, [swagStatus.response]);

  const handleColorCountChange = (e, newValue) => setColorCount(newValue);
  const handleQuantityChange = (e, newValue) => setQuantity(newValue);
  const handleProductTypeChange = event => setIsBulk(event.target.checked);

  const currentProduct = isBulk ? bulk : swag;

  if (typeof currentProduct === 'undefined') return null;

  return (
    <Grid id="swag-prices" sx={styles.sectionContainer}>
      <Typography variant="h3" sx={{ lineHeight: 1.17, textAlign: 'center' }}>
        Get a Sense of the Swag Prices
      </Typography>
      <Grid container justifyContent="center" sx={styles.cardContainer}>
        <Grid container justifyContent="space-between" sx={styles.card}>
          <Grid item xs={12} lg={6} sx={styles.column}>
            <QuantitySection
              text={isBulk ? 'Item Quantity' : 'Pack Quantity'}
              quantity={quantity}
              onQuantityChange={handleQuantityChange}
            />
            <Divider sx={(styles.widthFull, styles.dividerRoot)} />
            <ColorsSection colorCount={colorCount} onColorCountChange={handleColorCountChange} />
            <PriceSection
              isLoading={bulkStatus.isApiCallPending || swagStatus.isApiCallPending}
              priceText={priceText}
              isBulk={isBulk}
            />
          </Grid>
          <Grid item xs={12} lg={6} sx={styles.column}>
            <Grid container justifyContent="center">
              <Grid container justifyContent="center" alignItems="center" item xs={12}>
                <ProductTypeSwitch color="#9846dd" checked={isBulk} onChange={handleProductTypeChange} />
              </Grid>
              <Grid container justifyContent="center" alignItems="center" item xs={12}>
                <Image img={<Img src={currentProduct.img} alt={currentProduct.name} sx={styles.image} />} />
              </Grid>
              <Grid container justifyContent="center" alignItems="center" item xs={12}>
                <SubSection isBulk={isBulk} product={currentProduct} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography component="h5" sx={styles.sectionSubTitle}>
        Swag prices include all fees and branding
      </Typography>
      <Ordering />
    </Grid>
  );
};
export default SwagPricesSection;
