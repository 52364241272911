import { Grid } from '@mui/material';
import ContactSection from '../../shared/ContactSection';
import LogosSection from '../../shared/LogosSection';
import MainSection, { LeftComponent } from '../../shared/MainSection';
import HighQualitySwag from '../../shared/HighQualitySwag';
import FeaturesSection from '../../shared/FeaturesSection';
import FeatureCardItem from '../../shared/FeatureCardItem';
import CenteredGrid from '../../shared/CenteredGrid';
import WhatsInCarePackageSection from './WhatsInCarePackageSection';

const renderFeature = ({ image, title, description }) => (
  <FeatureCardItem padding={50} imageSize={90} image={image} title={title} description={description} animateOnHover />
);

const CarePackagesHome = () => (
  <>
    <Grid sx={{ paddingTop: 5 }}>
      <MainSection
        justifyContent="space-around"
        variant="primary"
        leftComponent={
          <LeftComponent
            title="Care Packages For Everyone!"
            subtitle="Cheer someone up with a care package!"
            backgroundImage="/imgs/landingPagesSSR/hero-bg.svg"
            buttonLabel="Let's get started!"
            lg={4}
          />
        }
        img="/imgs/landingPagesSSR/care-package-landing.png"
        backgroundImage="/imgs/landingPagesSSR/hero-bg.svg"
      />
    </Grid>
    <Grid item container xs={12} md={8} lg={6} xl={4} sx={{ paddingTop: 25, margin: 'auto' }}>
      <LogosSection
        justifyContent="center"
        title="Actively providing direct mail campaigns for"
        logosJustify="space-around"
        logos={['Snickers', 'Patagonia', 'MM', 'WP', 'Popcorners']}
        logosSpacing={2}
      />
    </Grid>
    <Grid style={{ paddingTop: 150 }}>
      <FeaturesSection
        alignItems="center"
        title="How it works?"
        features={[
          {
            image: '/imgs/landingPagesSSR/create-feature.png',
            title: 'Create',
            description:
              'Get mockups in 24 hours after selecting from our curated swag catalog filled with brands you love!'
          },
          {
            image: '/imgs/landingPagesSSR/automate-feature.png',
            title: 'Automate',
            description: 'Automate the swag process by using our redeem pages, Zapier, or request a custom integration.'
          },
          {
            image: '/imgs/landingPagesSSR/distribute-feature.png',
            title: 'Distribute',
            description: 'Easy to use platform to track shipments and manage inventory all in one centralized location.'
          }
        ]}
        featuresSpacing={5}
        renderFeature={renderFeature}
      />
    </Grid>
    <Grid sx={{ paddingTop: 32.5 }}>
      <WhatsInCarePackageSection />
    </Grid>
    <Grid sx={{ paddingTop: 37.5 }}>
      <HighQualitySwag title="Creating High Quality Swag Without the Hassle" />
    </Grid>
    <CenteredGrid sx={{ paddingTop: 37.5, paddingBottom: 12.5 }}>
      <ContactSection />
    </CenteredGrid>
  </>
);

export default CarePackagesHome;
