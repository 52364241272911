import { useLayoutEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CenteredGrid from './CenteredGrid';
import styles from './styles/clausesDocument';

const Clauses = ({ clauses }) =>
  Object.entries(clauses).map(([title, content]) => (
    <div key={title}>
      <article>
        <header>
          <Typography component="h3" variant="inherit" sx={styles.title}>
            {title}
          </Typography>
        </header>
        <section>
          {/* eslint-disable-next-line react/no-danger */}
          <Typography variant="inherit" sx={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
        </section>
      </article>
    </div>
  ));

const ClausesDocument = ({ clausesHeader, clauses }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box component="main" sx={styles.root}>
      <Grid container justifyContent="center">
        <CenteredGrid item sx={styles.center}>
          <article>
            <header>
              <Typography component="h3" variant="inherit" sx={styles.header}>
                {clausesHeader}
              </Typography>
            </header>
            <section>
              <Clauses clauses={clauses} />
            </section>
          </article>
        </CenteredGrid>
      </Grid>
    </Box>
  );
};

export default ClausesDocument;
