const styles = {
  container: theme => ({
    [theme.breakpoints.down('md')]: {
      paddingBottom: 12
    }
  }),
  valuesTitle: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 6,
    maxWidth: 232,
    marginTop: 10,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      marginTop: 2
    }
  }),
  valuesText: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 'bold',
    fontSize: 16,
    maxWidth: 264,
    opacity: 0.56,
    marginBottom: 23,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      marginBottom: 14
    }
  }),
  mobilePie: theme => ({
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start'
    }
  }),
  valuesIcon: theme => ({
    width: 64,
    height: 64,
    [theme.breakpoints.down('md')]: {
      width: 42,
      height: 42
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: 1024,
      padding: '0 24px'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  centerValue: theme => ({
    [theme.breakpoints.only('lg')]: {
      width: 1024,
      padding: '0 56px'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '0px 24px'
    }
  }),
  founderChief: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-Bold',
    fontSize: 28,
    marginBottom: 6,
    marginTop: 14,
    [theme.breakpoints.down('md')]: {
      marginTop: 6,
      marginBottom: 3,
      fontSize: 20
    }
  }),
  PersonIcon: {
    color: '#e7eced',
    fontSize: 156
  },
  iconWASwagUpContainer: {
    height: 164,
    width: 164,
    border: 'solid 4px #9846dd',
    borderRadius: 82,
    overflowY: 'hidden',
    overflowX: 'hidden'
  },
  founderChiefSubTxt: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-Medium',
    opacity: 0.56,
    fontSize: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    }
  }),
  imageMetalikaContainer: theme => ({
    marginTop: -71,
    [theme.breakpoints.down('md')]: {
      marginTop: -30
    }
  }),
  imageSwagPackContainer: theme => ({
    marginTop: 10.5,
    height: '90%',
    [theme.breakpoints.down('md')]: {
      marginTop: 8
    }
  }),
  imagesColumn: theme => ({
    marginBottom: 6,
    [theme.breakpoints.down('md')]: {
      marginTop: 3,
      marginBottom: 0
    }
  }),
  storyContent: theme => ({
    paddingLeft: 18,
    '& p': {
      fontSize: 20,
      fontFamily: 'Gilroy-SemiBold',
      color: '#0f2440',
      marginBottom: 7,
      lineHeight: 1.8
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      '& p': {
        fontSize: 16,
        lineHeight: 1.5
      }
    }
  }),
  storyParagraph: { marginTop: '1em' },
  joinOurTeamTitle: theme => ({
    color: '#ffffff',
    fontSize: 56,
    textAlign: 'center',
    fontWeight: 600,
    fontFamily: 'Gilroy-SemiBold',
    marginTop: 0,
    marginBottom: 8,
    [theme.breakpoints.down('md')]: {
      fontSize: 26
    }
  }),
  joinOurTeamText: theme => ({
    color: '#ffffff',
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 600,
    fontFamily: 'Gilroy-SemiBold',
    marginBottom: 12,
    marginTop: 0,
    maxWidth: 808,
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    }
  }),
  gridImgContainer: theme => ({
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0px !important',
      paddingRight: '5px !important'
    }
  }),
  gridImgContainer2: theme => ({
    [theme.breakpoints.down('md')]: {
      paddingLeft: '15px !important',
      paddingRight: '5px !important'
    }
  }),
  gridImgContainer3: theme => ({
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0px !important',
      paddingRight: '15px !important',
      display: 'none'
    }
  }),
  imageSwagPackContainer2: theme => ({
    display: 'none',
    maxHeight: 112,
    overflowY: 'hidden',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  }),
  imageTemSwagUp: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: 224,
    objectFit: 'cover'
  },
  imageWASwagUp: {
    width: '100%',
    maxWidth: '100%',
    objectFit: 'contain'
  },
  imageSwagUpPerks: {
    maxWidth: '100%',
    objectFit: 'contain'
  },
  imageWASwagUp2: {
    width: '100%',
    maxWidth: '100%',
    height: '100%'
  },
  companyTitle: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-Bold',
    fontWeight: 500,
    fontSize: 72,
    marginBottom: 8,
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 58,
      marginBottom: 6
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 34,
      marginBottom: 4
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 34,
      marginBottom: 4
    }
  }),
  companySubTitle: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 'bold',
    fontSize: 24,
    maxWidth: 360,
    marginBottom: 30,
    [theme.breakpoints.down('md')]: {
      marginBottom: 16,
      fontSize: 16,
      maxWidth: 192
    }
  }),
  ourMissionTitle: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    textAlign: 'center',
    marginTop: 23,
    marginBottom: 0,
    display: 'inline-block',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: 8,
      marginBottom: 4,
      fontSize: 32
    }
  }),
  ourMissionSubTitle: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    opacity: 0.54,
    textAlign: 'center',
    marginBottom: 18,
    marginTop: 1,
    fontWeight: 600,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: 8,
      fontSize: 18
    }
  }),
  ourValuesTitle: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 600,
    fontSize: 56,
    textAlign: 'left',
    marginTop: 4,
    marginBottom: 8,
    [theme.breakpoints.down('md')]: {
      marginTop: 3,
      marginBottom: 4,
      fontSize: 32
    }
  }),
  mandatoryMobileRight: theme => ({
    [theme.breakpoints.down('md')]: {
      paddingLeft: '24px !important',
      paddingTop: '56px !important'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '16px !important',
      paddingTop: '0px !important'
    }
  }),
  mandatoryMobile: theme => ({
    [theme.breakpoints.down('md')]: {
      paddingLeft: '24px !important',
      paddingRight: '16px !important',
      paddingTop: '48px !important'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      paddingTop: '0px !important'
    }
  }),
  mandatoryMobile2: theme => ({
    [theme.breakpoints.down('md')]: {
      paddingTop: '20px !important'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '28px !important'
    }
  }),
  swagFamPerksTitle: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    textAlign: 'center',
    marginTop: 23,
    marginBottom: 4,
    maxWidth: 750,
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      marginTop: 6,
      marginBottom: 0,
      fontSize: 32
    }
  }),
  imageWASwagUpContainer: {
    width: '100%',
    minHeight: 224,
    maxHeight: 224,
    overflowY: 'hidden',
    borderRadius: '15px 50px 15px 15px'
  },
  teamItemCover: theme => ({
    overflowY: 'hidden',
    position: 'relative',
    // background: '#ffffff',
    borderRadius: '15px 50px 15px 15px',
    // boxShadow: '0 40px 140px 0 rgba(26, 58, 98, 0.22)',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 372,
    '&&:hover': {
      // borderRadius: '100px 0px 100px 0px'
    },
    [theme.breakpoints.down('md')]: {
      width: '80%',
      height: 372
    }
  }),
  teamNewItemCover: theme => ({
    overflowY: 'hidden',
    position: 'relative',
    background: '#ffffff',
    borderRadius: '100px 0px 0px 0px',
    padding: '64px 24px',
    boxShadow: '0 40px 140px 0 rgba(26, 58, 98, 0.22)',
    height: 564,
    '&&:hover': {
      borderRadius: '100px 0px 100px 0px'
    },
    [theme.breakpoints.down('md')]: {
      height: 484
    }
  }),
  teamMemberName: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 600,
    color: '#0f2440',
    marginBottom: 4,
    fontSize: 24,
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    }
  }),
  teamMembersButton: {
    width: 292,
    padding: '0 10px',
    color: '#9846dd !important',
    borderColor: '#9846dd !important'
  },
  famSection: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 600,
    color: '#0f2440',
    marginBottom: 4,
    fontSize: 40,
    maxWidth: 408,
    [theme.breakpoints.down('md')]: {
      fontSize: 24
    }
  }),
  famSectionText: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 600,
    color: '#0f2440',
    opacity: 0.56,
    marginBottom: 4,
    fontSize: 20,
    maxWidth: 324,
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    }
  }),
  joinOurTeamBox: theme => ({
    background: '#9846dd',
    marginBottom: 18,
    marginTop: 31,
    borderRadius: '100px 0px 100px 0px',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 14,
    paddingBottom: 23,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 15,
      paddingRight: 15
    }
  }),
  teamMemberTitle: theme => ({
    fontFamily: 'Gilroy-Medium',
    color: '#0f2440',
    fontSize: 16,
    opacity: 0.56,
    margin: 0,
    '&& p': {
      color: '#ffffff',
      fontFamily: 'Gilroy-SemiBold',
      fontWeight: 500
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  }),
  teamCardBottom: theme => ({
    padding: '32px 0px',
    borderRadius: '0px 0px 100px 0px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '0px 16px',
      paddingRight: 6,
      paddingBottom: 6,
      paddingTop: 4
    }
  }),
  teamNewCardBottom: theme => ({
    marginTop: 0,
    padding: '42px 24px',
    borderRadius: '0px 0px 100px 0px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      padding: '24px 16px'
    }
  }),
  buttonMobile: theme => ({
    color: '#9846dd !important',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    letterSpacing: 'normal',
    textAlign: 'center',
    lineHeight: 1,
    border: '2px solid #9846dd !important',
    borderRadius: 45,
    width: 286,
    height: 90,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      height: 56,
      width: 272
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      height: 56,
      width: 258
    }
  }),
  ourTeamTitle: theme => ({
    color: '#0f2440',
    fontSize: 56,
    fontFamily: 'Gilroy-SemiBold',
    textAlign: 'left',
    marginTop: 23,
    marginBottom: 4,
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      marginTop: 14,
      fontSize: 32
    }
  }),
  ourTeamSubTitle: theme => ({
    color: '#0f2440',
    fontSize: 20,
    fontFamily: 'Gilroy-SemiBold',
    opacity: 0.56,
    textAlign: 'left',
    marginBottom: 18,
    fontWeight: 600,
    maxWidth: 564,
    [theme.breakpoints.down('md')]: {
      marginBottom: 8,
      fontSize: 18
    }
  }),
  valuesContainer: theme => ({
    backgroundColor: '#f3f6fa',
    padding: '56px 0px',
    marginTop: 31,
    [theme.breakpoints.down('md')]: {
      padding: '42px 0px',
      marginTop: 14
    }
  }),
  headImageContainer: {
    overflow: 'hidden',
    maxWidth: 1440
  },
  teamItemExcerpt: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'rgba(152, 70, 221, 0.95)',
    opacity: 0,
    display: 'none',
    borderRadius: '15px 50px 15px 15px'
  },
  teamItemView: {
    '&:hover section': {
      display: 'block',
      '-webkit-animation-name': 'showup',
      '-webkit-animation-duration': '0.5s',
      animationName: 'showup',
      animationDuration: '0.5s',
      opacity: 1
    },
    '&:hover .name-title-white': {
      '-webkit-animation-name': 'fadeOut',
      '-webkit-animation-duration': '0.5s',
      animationName: 'fadeOut',
      animationDuration: '0.5s',
      opacity: 0
    }
  }
};

export default styles;
