import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Helmet from '../shared/Helmet';
import BlogExpanded from './BlogExpanded';
import wordpressApiClient from '../../api/wordpressApiClient';
import { getSortedPostByDate, parseHtmlEntities } from './blogUtilFunctions';

const getSmoothPost = (
  {
    id,
    title,
    date,
    content: { rendered },
    acf: {
      category,
      description,
      summary,
      readtime,
      image,
      author_nickname: nickname,
      author_fullname: fullname,
      author_avatar: avatar,
      author_title: authorTitle,
      featuredimage,
      cardimage,
      two_columns: twoColumns,
      last_modified: lastModified
    },
    slug
  },
  comments = [],
  relatedPosts
) => ({
  id,
  slug,
  date,
  title: parseHtmlEntities(title.rendered),
  rendered,
  category,
  comments,
  relatedPosts: relatedPosts || [],
  description: parseHtmlEntities(description),
  summary: parseHtmlEntities(summary),
  readtime,
  image,
  author: { nickname, fullname, avatar, title: authorTitle },
  featuredImage: featuredimage,
  cardImage: cardimage,
  twoColumns,
  lastModified
});

const generateBlogTags = post => {
  const titleTag = `SwagUp - ${post?.title}`;
  const postUrl = `https://www.swagup.com/blog/${post?.slug}`;

  return {
    title: titleTag,
    description: post?.description,
    'og:image': post?.image || post?.featuredImage || post?.cardImage,
    'og:url': postUrl,
    'og:site_name': 'swagup.com',
    'twitter:card': 'summary',
    'twitter:title': titleTag,
    'twitter:description': post?.description,
    'twitter:url': postUrl,
    'twitter:image': post?.image || post?.featuredImage || post?.cardImage
  };
};

const BlogPost = () => {
  let { slug } = useParams();
  const { isLoading: isLoadingPost, data: postResponse } = useQuery(['post', slug], () =>
    wordpressApiClient.getPostBySlug(slug)
  );
  const post = postResponse ? postResponse[0] : undefined;
  const { isLoading: isLoadingAllPosts, data: posts } = useQuery('posts', wordpressApiClient.fetchAllPosts);
  const { isLoading: isLoadingRelated, data: relatedPosts } = useQuery(
    ['relatedPosts', post],
    async () => wordpressApiClient.getRelatedPosts(post.id, post.acf.category),
    { enabled: !!post }
  );
  const { isLoading: isLoadingComments, data: comments } = useQuery(
    ['comments', post],
    async () => {
      wordpressApiClient.getPostComments(post.id);
    },
    { enabled: !!post }
  );
  const [allRelatedPosts, setRelatedPosts] = useState(null);
  const [currentPost, setCurrentPost] = useState(null);

  useEffect(() => {
    const loading = isLoadingPost || isLoadingRelated || isLoadingComments || isLoadingAllPosts || false;
    if (loading) {
      return;
    }

    const latestPost = getSortedPostByDate(posts).filter(
      p => p.id !== post.id && !relatedPosts.find(rp => rp.id === p.id)
    );

    const smoothRelatedPosts = relatedPosts.map(p => getSmoothPost(p));

    setCurrentPost(getSmoothPost(post, comments, relatedPosts));
    setRelatedPosts([
      ...smoothRelatedPosts.filter(p => p.id !== post.id),
      ...latestPost.map(p => ({ ...p, isLatest: true }))
    ]);
  }, [isLoadingComments, isLoadingRelated, isLoadingPost, isLoadingAllPosts, posts, post, comments, relatedPosts]);

  if (!post) return <div />;

  return (
    <>
      {currentPost && <Helmet tags={generateBlogTags(currentPost)} article />}
      <BlogExpanded post={currentPost} relatedPosts={allRelatedPosts} />
    </>
  );
};

export default BlogPost;
