export const chipStyles = {
  iconChip: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    backgroundColor: '#f3ebfa',
    borderRadius: 3.75,
    py: 3.75,
    px: 4.25
  }
};

export const cardStyles = {
  card: theme => ({
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      marginTop: 3,
      width: '100%'
    }
  }),
  cardArea: theme => ({
    flex: 1,
    padding: '38px 44px 41px 44px',
    borderRadius: 8.25,
    border: 'solid 1px #ebeef2',
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      padding: '20px 44px',
      justifyContent: 'space-around',
      borderRadius: 0,
      alignItems: 'center',
      marginTop: 3
    }
  }),
  textContainer: theme => ({
    [theme.breakpoints.down('lg')]: {
      marginLeft: 7.5
    }
  }),
  cardTitle: theme => ({
    marginTop: 10,
    marginBottom: 6,
    fontFamily: 'Gilroy',
    fontSize: 24,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#0f2440',
    [theme.breakpoints.down('lg')]: {
      marginTop: 2.5,
      marginBottom: 0
    }
  }),
  cardText: theme => ({
    maxWidth: 232,
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.63,
    letterSpacing: 'normal',
    color: '#8d9299',
    [theme.breakpoints.down('lg')]: {
      lineHeight: 1.3,
      marginTop: 0
    }
  })
};

export const mainStyles = {
  mainAreaContainer: theme => ({
    my: 25,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      paddingTop: 0,
      paddingBottom: 2.5
    }
  }),
  mainAreaCenter: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('1025')]: {
      width: 960
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  mainArea: {
    paddingLeft: 0,
    paddingRight: 0
  },
  promotionTitle: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    textAlign: 'center',
    marginTop: 5,
    marginBottom: 14,
    fontStretch: 'normal',
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      textAlign: 'center',
      marginBottom: 6.5
    }
  }),
  cardsContainer: theme => ({
    paddingRight: 0,
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  })
};
