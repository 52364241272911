import { useQuery } from 'react-query';
import wordpressApiClient from '../../api/wordpressApiClient';
import Helmet from '../shared/Helmet';
import tags from '../../utils/seoTags';
import Subscribe from '../shared/Subscribe';
import FAQHeader from './FAQHeader';
import FAQList from './FAQList';
import AskAQuestion from './AskAQuestion';

const FAQ = () => {
  const { data: faqs } = useQuery('faqs', wordpressApiClient.fetchAllFAQs);

  return (
    <main>
      <Helmet tags={tags.faq} />
      <FAQHeader />
      <FAQList faqs={faqs} />
      <AskAQuestion />
      <Subscribe />
    </main>
  );
};

export default FAQ;
