import { Grid, Typography } from '@mui/material';

// TODO: Move logos into /imgs/public ?
const getLogosList = darkMode => {
  const commonLogos = {
    Amazon: { src: '/imgs/public/amazon.svg', displacement: 7 },
    Cocacola: { src: '/imgs/public/cocacola.svg' },
    Wework: { src: '/imgs/homeLight/wework.svg' },
    Gemini: { src: '/imgs/public/gemini.svg', displacement: 3 },
    MM: { src: '/imgs/public/mandm.png' },
    WP: { src: '/imgs/public/wandp.png' },
    Popcorners: { src: '/imgs/public/popcorners.png' },
    Snickers: { src: '/imgs/public/snickers.svg' },
    Realthread: { src: '/imgs/landingPagesSSR/realthread.svg', displacement: -2 },
    Patagonia: { src: '/imgs/landingPagesSSR/patagonia.svg', displacement: 3 },
    Sockclub: { src: '/imgs/landingPagesSSR/sockclub.svg', displacement: 3 },
    Moo: { src: '/imgs/landingPagesSSR/moo.svg', displacement: 2 },
    Swell: { src: '/imgs/landingPagesSSR/swell.svg', displacement: -2 },
    Stickermule: { src: '/imgs/landingPagesSSR/stickermule.svg', displacement: -2 },
    Bellroy: { src: '/imgs/public/bellroy.svg', displacement: -8 },
    Brex: { src: '/imgs/public/brex.svg', displacement: 0 },
    Corkcicle: { src: '/imgs/public/corkcicle.svg', displacement: 2 },
    Dropbox: { src: '/imgs/public/dropbox.svg', displacement: 0 },
    Everlane: { src: '/imgs/public/everlane.svg', displacement: 0 },
    Karst: { src: '/imgs/public/karst.svg', displacement: 0 },
    Patreon: { src: '/imgs/public/patreon.svg', displacement: 0 },
    Policygenius: { src: '/imgs/public/policygenius.svg', displacement: 6 },
    Rippling: { src: '/imgs/public/rippling.svg', displacement: 0 }
  };

  const variableLogos = darkMode
    ? {
        Google: { src: '/imgs/public/google-white.svg', displacement: 4 },
        Crunchbase: { src: '/imgs/public/crunchbase-white.svg' },
        Atlassian: { src: '/imgs/public/atlassian-white.svg' },
        Duolingo: { src: '/imgs/public/duolingo-white.svg', displacement: 4 },
        Square: { src: '/imgs/public/square-white.svg', displacement: -1 },
        Walmart: { src: '/imgs/public/walmart-white.svg' },
        Facebook: { src: '/imgs/public/facebook-white.svg' },
        Standford: { src: '/imgs/public/stanford-white.svg', displacement: 3 }
      }
    : {
        Google: { src: '/imgs/public/google.svg', displacement: 4 },
        Crunchbase: { src: '/imgs/public/crunchbase.svg' },
        Atlassian: { src: '/imgs/public/atlassian.svg' },
        Duolingo: { src: '/imgs/homeLight/duolingo.svg', displacement: 4 },
        Square: { src: '/imgs/public/square.svg', displacement: -1 },
        Walmart: { src: '/imgs/public/walmart.svg' },
        Facebook: { src: '/imgs/public/facebook.svg' },
        Standford: { src: '/imgs/public/standford.svg', displacement: 3 }
      };

  return { ...commonLogos, ...variableLogos };
};

const styles = {
  title: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    color: '#868a8f',
    margin: 0,
    [theme.breakpoints.down('md')]: { fontSize: 14 }
  }),
  logos: theme => ({
    marginTop: 7.5,
    [theme.breakpoints.down('md')]: { marginTop: 2.5 }
  }),
  logoContainer: theme => ({
    display: 'flex',
    alignItems: 'center ',
    padding: 2.5,
    [theme.breakpoints.down('sm')]: { alignItems: 'space-around', maxWidth: '33%' },
    '& > img': {
      maxWidth: 160,
      [theme.breakpoints.down('xl')]: {
        maxWidth: 127
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 85
      }
    }
  })
};

const LogosSection = ({
  sx,
  justifyContent,
  title = 'Serving up fresh swag for',
  titleStyles,
  logos,
  logosJustify,
  logosSpacing,
  logosStyles
}) => {
  const logosList = getLogosList();

  return (
    <Grid container justifyContent={justifyContent} sx={sx}>
      <Typography variant="inherit" sx={[styles.title, titleStyles]}>
        {title}
      </Typography>
      <Grid
        item
        container
        spacing={logosSpacing}
        justifyContent={logosJustify || justifyContent}
        sx={[styles.logos, logosStyles]}
      >
        {logos.map(logo => (
          <Grid sx={styles.logoContainer} item key={logo}>
            <img src={logosList[logo].src} alt={logo} style={{ marginTop: logosList[logo].displacement ?? 0 }} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default LogosSection;
