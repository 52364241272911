import ContactUs from '../../shared/ContactUs';
import OurServices from '../../shared/OurServices';
import LandingSection from '../shared/LandingSection';
import BuildYourBrand from '../shared/NewBuildYourBrand';
import SamplePack from '../shared/NewSamplePack';
import BackgroundLandingPages from '../shared/BackgroundAdornments';

const services = [
  {
    src: '/imgs/landingPagesSSR/design.svg',
    title: 'Design',
    text: 'No need to design these yourself! Our team will design them to fit your brand'
  },
  {
    src: '/imgs/landingPagesSSR/warehouse-storage.svg',
    title: 'Storage',
    text: 'Hiring a lot of employees? We get it storing swag can be a pain. We offer storage and tracking for your swag'
  },
  {
    src: '/imgs/landingPagesSSR/assemble.svg',
    title: 'Assemble',
    text: 'No need to beg your coworkers to stay after-hours to assemble swag. We got this covered'
  },
  {
    src: '/imgs/landingPagesSSR/ship.svg',
    title: 'Ship',
    text: 'Have new hires in remote places or in other countries? No worries, we ship swag globally'
  }
];

const responses = [
  {
    img: { src: '/imgs/landingPagesSSR/culture.svg', alt: 'culture' },
    subTitle: 'Our Employee Swag Packs are customized swag that fit your company culture and branding!'
  },
  {
    img: { src: '/imgs/landingPagesSSR/welcome-new-with-swag.svg', alt: 'welcome-new-with-swag' },
    subTitle: 'Onboarding employees with Swag Packs is a great way to welcome members to the team!'
  },
  {
    img: { src: '/imgs/landingPagesSSR/shipment-with-hr-integration.svg', alt: 'shipment-with-hr-integration' },
    subTitle: 'We make shipping easy with HR integrations available and a dashboard to track and manage it all!'
  }
];

const sectionData = {
  title: 'New Hire\nSwag Packs',
  h1Style: { whiteSpace: 'pre-wrap' },
  subTitle: 'Onboard them with Swag\nthey will love!'
};

const NewHire = () => (
  <>
    <BackgroundLandingPages />
    <LandingSection sectionData={sectionData} />
    <BuildYourBrand responses={responses} title="What’s an Employee Swag Pack?" />
    <SamplePack />
    <OurServices services={services} title="We do the work - You take the credit!" />
    <ContactUs namePage="New Hire Swag Packs" />
  </>
);

export default NewHire;
