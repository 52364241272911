import GalleryHeader from './GalleryHeader';
import GalleryTabs from './GalleryTabs';
import CreateSwagSection from './CreateSwagSection';
import HowSwagsWorks from './HowSwagsWorks';
import GrabAFreeSample from './GrabAFreeSample';
import ContactSection from '../shared/ContactSection';
import Grid from '@mui/material/Grid';
import LimitingContainer from '../shared/LimitingContainer';

const GalleryHome = ({ mediaGallery, categories }) => {
  if (!categories || !mediaGallery) {
    return <div style={{ height: '100vh' }}></div>;
  }
  return (
    <Grid container justifyContent="center">
      <GalleryHeader />
      <GalleryTabs mediaGallery={mediaGallery} categories={categories} />
      <GrabAFreeSample />
      <HowSwagsWorks />
      <CreateSwagSection />
      <LimitingContainer maxWidth={1230}>
        <ContactSection />
      </LimitingContainer>
    </Grid>
  );
};

export default GalleryHome;
