const font = {
  fontFamily: 'Gilroy-SemiBold',
  fontWeight: 500,
  color: '#8d9299'
};

const styles = {
  sectionContainer: theme => ({
    paddingTop: 45,
    paddingLeft: 47.5,
    paddingRight: 47.5,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 15,
      paddingRight: 15
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 2.5,
      paddingRight: 2.5
    }
  }),
  sectionSubTitle: {
    marginTop: 15.5,
    marginBottom: 0,
    textAlign: 'center',
    fontSize: 24,
    ...font
  },
  orderingCardContainer: theme => ({
    paddingTop: 17.5,
    paddingLeft: 12.5,
    paddingRight: 12.5,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 10,
      paddingLeft: 4,
      paddingRight: 4
    }
  }),
  orderingCard: theme => ({
    maxWidth: 945,
    borderRadius: 6,
    borderTopRightRadius: 100,
    backgroundColor: '#3577d4',
    padding: '56px 0px 56px 81px',
    [theme.breakpoints.down('md')]: {
      padding: '56px 20px 26px 21px',
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      padding: 5,
      borderTopRightRadius: 24
    }
  }),
  orderingTitle: theme => ({
    margin: 0,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 36,
    fontWeight: 500,
    color: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    }
  }),
  orderingSubTitle: {
    margin: 0,
    marginTop: 6,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 500,
    color: '#ebf1fb'
  },
  orderingButton: theme => ({
    display: 'flex',
    marginLeft: 4.25,
    width: 208,
    textTransform: 'none',
    textDecoration: 'none',
    borderRadius: 9,
    backgroundColor: '#ffffff',
    color: '#3577d4',
    padding: '23px 42px',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    fontWeight: 800,
    [theme.breakpoints.down('md')]: {
      padding: '13px 22px',
      width: 168,
      marginTop: 5
    }
  }),
  smallSubTitle: {
    lineHeight: 1.17,
    marginTop: 4,
    marginBottom: 0,
    textAlign: 'center',
    ...font
  },

  cardContainer: {
    paddingTop: 18
  },
  card: {
    maxWidth: 1060,
    borderRadius: 6,
    boxShadow: '0 20px 80px 0 rgba(27, 28, 31, 0.03)',
    border: 'solid 1px #ebeef2',
    backgroundColor: '#ffffff'
  },
  column: theme => ({
    padding: '50px 56px 44px 62px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
      justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 20px 44px 22px'
    }
  }),
  imageContainer: {
    overflow: 'hidden',
    marginTop: 9,
    maxWidth: 356,
    maxHeight: 356,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 8
  },
  image: {
    objectFit: 'contain',
    width: '100%'
  },
  mt0: {
    marginTop: 0
  },
  mb0: {
    marginBottom: 0
  },
  mt20: {
    marginTop: 5
  },
  mt36: {
    marginTop: 9
  },
  ml16: {
    marginLeft: 4
  },
  alignRight: {
    textAlign: 'right'
  },
  packSubSection: theme => ({
    paddingLeft: 5,
    paddingRight: 5,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }),
  font: {
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 500
  },
  labelFontSize: {
    fontSize: 20
  },
  mediumFontSize: {
    fontSize: 24
  },
  largeFontSize: {
    fontSize: 36
  },
  mediumFont: {
    color: '#434d5c'
  },
  labelFont: {
    color: '#0f2440'
  },
  numberFontPurple: {
    color: '#9846dd'
  },
  numberFontBlue: {
    color: '#3577d4'
  },
  linkFont: {
    fontSize: 16,
    textDecoration: 'none'
  },
  blueColor: {
    color: '#3577d4'
  },
  button: {
    height: 'fit-content'
  },
  tooltip: {
    boxShadow: '0 12px 28px 0 rgba(27, 28, 31, 0.08)',
    border: 'solid 1px #ebeef2',
    backgroundColor: '#ffffff',
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: 12,
    color: '#0f2440',
    borderRadius: 2.5
  },
  priceCard: {
    paddingTop: 7,
    paddingBottom: 7,
    borderRadius: 3.75,
    backgroundColor: '#ffffff'
  },
  withBorder: {
    border: 'solid 1px #ebeef2'
  },
  alignCenter: {
    textAlign: 'center'
  },
  priceTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 500,
    color: '#0f2440'
  },
  dividerRoot: {
    marginTop: 8,
    backgroundColor: '#ebeef2'
  },
  widthFull: {
    width: '100%'
  }
};

export default styles;
