import { useState } from 'react';
import ContactDialog from '../../shared/ContactDialog';
import LogosSection from '../../shared/LogosSection';
import FAQs from './FAQs';
import BeforeGetStarted from './BeforeGetStarted';
import HaveAQuestion from './HaveAQuestion';
import LetsGetStarted from './LetsGetStarted';
import OurPhilosophy from './OurPhilosophy';
import Processes from './Processes';
import SignUpNewsLetter from './SignUpNewsLetter';

const launchingSwagpacks = [
  'Walmart',
  'Facebook',
  'Policygenius',
  'Atlassian',
  'Patreon',
  'Rippling',
  'Brex',
  'Dropbox'
];

const brandsInOurPacks = ['Karst', 'Everlane', 'Stickermule', 'Patagonia', 'Bellroy', 'Corkcicle'];

const logosTitleStyles = { fontFamily: 'Gilroy-SemiBold', color: '#0f2440' };

const CustomPacks = () => {
  const [modalOptions, setModalOptions] = useState({
    open: false,
    defaultSubject: undefined,
    isPartnership: false
  });

  const handleOpenModal = ({ defaultSubject, isPartnership = false } = {}) =>
    setModalOptions({ open: true, defaultSubject, isPartnership });

  const handleCloseModal = () => setModalOptions({ openModal: false, defaultSubject: undefined, isPartnership: false });

  return (
    <>
      <BeforeGetStarted onOpenModal={() => handleOpenModal({ defaultSubject: 'I want to book a demo' })} />
      <Processes />
      <LogosSection
        title="Launching Swag Packs for"
        logos={launchingSwagpacks}
        justifyContent="center"
        titleStyles={logosTitleStyles}
      />
      <FAQs />
      <HaveAQuestion onOpenModal={handleOpenModal} />
      <OurPhilosophy />
      <LogosSection
        title="Brands in our Packs"
        logos={brandsInOurPacks}
        justifyContent="center"
        titleStyles={logosTitleStyles}
      />
      <LetsGetStarted />
      <SignUpNewsLetter />
      {modalOptions.open && <ContactDialog {...modalOptions} onClose={handleCloseModal} />}
    </>
  );
};

export default CustomPacks;
