import { useState, useEffect } from 'react';
import { Box, Grid, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import CenteredGrid from '../shared/CenteredGrid';
import Img from '../shared/Img';
import styles from './styles/partnersHeader';

const PartnersHeader = () => {
  const [showBottomLine, setShowBottomLine] = useState(false);

  useEffect(() => {
    const listenToScroll = () => {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      if (height) {
        const scrolled = winScroll / height;
        setShowBottomLine(scrolled > 0);
      }
    };
    window.addEventListener('scroll', listenToScroll);

    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []);

  return (
    <Box sx={[styles.root, showBottomLine && { borderBottom: '1px solid #d4d9e2', background: 'white' }]}>
      <CenteredGrid container justifyContent="center" sx={styles.center}>
        <Toolbar sx={{ width: '100%' }}>
          <Grid container sx={{ height: '100%' }} alignItems="center">
            <Grid item>
              <Link to="/">
                <Img src="/imgs/landingPagesSSR/blue.svg" sx={styles.logo} alt="SwagUp" />
              </Link>
            </Grid>
            <Grid item xs>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Box sx={styles.textPhoneHeader}>
                    <Img
                      sx={styles.imageContainerPhoneHeader}
                      src="/imgs/landingPagesSSR/phone-black.svg"
                      alt="Black phone"
                    />
                    <a href="tel:6464940520">646-494-0520</a>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </CenteredGrid>
    </Box>
  );
};

export default PartnersHeader;
