import axios from 'axios';
import apiPaths from './apiPaths';
import { okAndLog, errorAndLog, mergeCampaignIdsWithData } from '../utils';
import gtm from '../utils/gtm';

let instance = axios.create();

let instance101 = axios.create();

let publicApiInstance = axios.create();

let formApiInstance = axios.create();

let partnersApiInstance = axios.create();

const createApiInstances = ({
  apiEndpoint,
  apiEndpoint101,
  formApiEndpoint,
  publicApiEndpoint,
  partnersApiEndpoint
}) => {
  instance = axios.create({
    baseURL: apiEndpoint
  });

  instance101 = axios.create({
    baseURL: apiEndpoint101
  });

  publicApiInstance = axios.create({
    baseURL: publicApiEndpoint
  });

  formApiInstance = axios.create({
    baseURL: formApiEndpoint
  });

  partnersApiInstance = axios.create({
    baseURL: partnersApiEndpoint
  });
};

const statusCode = codes => ({
  validateStatus: status => (Array.isArray(codes) ? codes.includes(status) : codes === status)
});

const status200 = statusCode(200);
const status201 = statusCode(201);

const fetchMetadata = () => instance.get(apiPaths.metadata, status200).then(response => response.data.results);

let cancelToken;
const platformApiClient = {
  fetchAccount: () => instance.get(apiPaths.accounts, status200).then(response => response.data.results[0]),

  fetchCountries: (active = true) =>
    instance.get(apiPaths.countries, { params: { active }, ...status200 }).then(response => response.data.results),

  fetchSizes: () => instance.get(apiPaths.sizes, status200).then(response => response.data.results),

  fetchStoragePrices: () => instance.get(apiPaths.storagePrices, status200).then(response => response.data),

  fetchStorageCategories: () =>
    instance.get(apiPaths.storageCategories, status200).then(response => response.data.results),

  fetchMetadata,

  fetchMetadataAsObj: () =>
    fetchMetadata().then(results => {
      const metadata = {};
      results.forEach(r => {
        metadata[r.field] = r.value;
      });

      return metadata;
    }),

  fetchProfile: () => instance.get(apiPaths.profiles, status200).then(response => response.data.results[0]),

  setAccount: (profileId, newCurrentAccount) =>
    instance.patch(`${apiPaths.profiles}${profileId}/`, newCurrentAccount, status200).then(response => response.data),

  validatePhones: phoneNumbers => {
    if (cancelToken) cancelToken.cancel('Phones validation request canceled due to new request');

    cancelToken = axios.CancelToken.source();
    return publicApiInstance
      .post('/phone-numbers/', phoneNumbers, {
        ...status200,
        cancelToken: cancelToken.token
      })
      .finally(() => {
        cancelToken = null;
      });
  },

  fetchSubjects: (partnership = false) =>
    formApiInstance
      .get(apiPaths.contactFormSubjects, { ...status200, params: { partnership } })
      .then(({ status, data }) => okAndLog('fetchSubjects', status, data)),

  sendMail: async (values, formName = '') => {
    const mergedData = mergeCampaignIdsWithData(values);
    return formApiInstance.post(apiPaths.contactForms, mergedData, status201).then(({ status, data }) => {
      gtm.submitContactForm(formName);
      return okAndLog('sendMail', status, data);
    });
  },

  subscribe: async data => {
    try {
      const dataWithCookie = mergeCampaignIdsWithData(data);
      const apiCall = await instance.post(apiPaths.subscribers, dataWithCookie);
      if (apiCall.status === 200) {
        // mailchimp returns status 200 if ok
        return okAndLog('subscribe', apiCall.status, apiCall.data);
      }
      return errorAndLog('subscribe', apiCall.status, apiCall.data);
    } catch (e) {
      return errorAndLog('subscribe', e.status, e.data);
    }
  },
  getBulkPrices: (bulk, quantity, colorCount) => {
    return formApiInstance
      .post(apiPaths.bulkPricing, {
        products: [
          {
            item_id: bulk.id,
            colors: colorCount,
            quantities: [
              {
                quantity
              }
            ]
          }
        ]
      })
      .then(response => response.data);
  },
  getPackPrices: (swag, quantity, colorCount) => {
    return formApiInstance
      .post(apiPaths.packMultiplePrice, {
        quantities: [
          {
            quantity
          }
        ],
        products: swag.items.map(item => ({
          item_id: item.item.id,
          units_per_pack: item.units_per_pack,
          colors: colorCount
        }))
      })
      .then(response => response.data);
  },
  getPresetPacks: () => {
    return formApiInstance
      .get(apiPaths.packTemplates, {
        params: { limit: 48, ordering: 'sort_order' }
      })
      .then(response => response.data.results);
  },
  getShippingPrices: data => {
    return instance101
      .post(apiPaths.shippingPrices, data, { validateStatus: status => status === 200 })
      .then(response => okAndLog('fetchPrices', response.status, response.data))
      .catch(({ response }) => errorAndLog('fetchPrices', response?.status, response?.data));
  },
  getIndividualPacks: () => {
    return instance.get(apiPaths.individualPackPrices).then(response => response.data.results);
  },
  getStorageCategories: () => {
    return instance.get(apiPaths.storageCategories).then(response => response.data.results);
  },
  getStoragePrices: () => {
    return instance.get(apiPaths.storagePrices).then(response => response.data.results);
  },
  getDefaultBulk: () => {
    return formApiInstance.get(apiPaths.defaultBulk).then(response => response.data.results[0]);
  },
  getDefaultSwag: () => {
    return formApiInstance.get(apiPaths.defaultSwag).then(response => response.data.results[0]);
  },
  getCountries: () => {
    return instance.get(apiPaths.countries).then(response => response.data.results);
  },
  fetchPartern: slug =>
    partnersApiInstance
      .get(apiPaths.partners, { ...status200, params: { slug } })
      .then(response => response.data.results[0])
};

export {
  instance as platformAxiosInstance,
  formApiInstance as platformFormAxiosInstance,
  publicApiInstance as publicPlatformAxiosInstance,
  createApiInstances
};
export default platformApiClient;
