const styles = {
  container: theme => ({
    paddingTop: 11.5,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 5
    }
  })
};

export default styles;
