const styles = {
  ArticleInformation: {
    width: '100%'
  },
  ArticleStats: {
    borderBottom: '1px solid #d4d9e2'
  },
  LikesCounter: {
    opacity: 0.56,
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 600,
    color: '#0f2440',
    '&::before': {
      background: "url('../imgs/blog/circleRocket.svg') no-repeat",
      content: '""',
      display: 'inline-block',
      height: '40px',
      position: 'relative',
      verticalAlign: 'middle',
      width: '4%',
      right: '0.5em'
    }
  },
  authorData: theme => ({
    paddingLeft: 8,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 2
    }
  }),
  datePublished: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 0.63,
    color: '#8d9299',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 10
    }
  }),
  AboutAuthor: { paddingTop: 12, paddingBottom: 12, borderBottom: '1px solid #ebeef2' },
  BigAvatar: {
    width: 64,
    height: 64
  },
  AuthorName: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    fontWeight: 600,
    color: '#0f2440',
    paddingTop: 2,
    paddingBottom: 2,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      fontSize: 18
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      fontSize: 14
    }
  }),
  AuthorTitle: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 500,
    color: '#8d9299',
    [theme.breakpoints.down('md')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 8
    }
  }),
  TitleText: {
    opacity: 0.56,
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 600,
    color: '#0f2440'
  }
};

export default styles;
