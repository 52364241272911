async function loadConfig() {
  const response = await fetch('/configuration.json', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });
  return response.json();
}

export default loadConfig;
