import { Grid, Box, Typography } from '@mui/material';
import SectionTag from '../shared/SectionTag';
import styles from './styles/company';
import CenteredGrid from '../../../src/components/shared/CenteredGrid';

const values = [
  {
    id: 1,
    title: 'Simplify, Simplify, Simplify',
    text: 'Less is more. Through the elimination of the unnecessary we unlock new opportunity.',
    icon: '/imgs/company/Cog.svg'
  },
  {
    id: 2,
    title: 'Enjoy the Ride',
    text: 'Life is short and swag should be fun. When this spills into work, everyone wins.',
    icon: '/imgs/company/Rocket.svg'
  },
  {
    id: 3,
    title: 'Take it to The Moon',
    text: 'The biggest opportunities are the ones others don’t see. Always think bigger, it’s all possible.',
    icon: '/imgs/company/Moon.svg'
  },
  {
    id: 4,
    title: 'Get comfortable being uncomfortable',
    text: 'With growth comes chaos. Embrace the discomfort as it’s an inevitability of progress and impactful work.',
    icon: '/imgs/company/Decisions.svg'
  },
  {
    id: 5,
    title: 'There’s potential in everyone',
    text: 'Great ideas can come from anywhere. Every voice has a seat at the table, no matter whose it is.',
    icon: '/imgs/company/Head.svg'
  },
  {
    id: 6,
    title: 'Trust > Profit',
    text: 'Build deep connections and trust with our partners by always putting their best interests ahead of ours.',
    icon: '/imgs/company/Hand.svg'
  }
];

const ValueItem = ({ value }) => (
  <Grid sx={styles.mobilePie}>
    <Box component={'img'} src={value.icon} sx={styles.valuesIcon} alt={value.title} />
    <Typography component={'h3'} sx={styles.valuesTitle}>
      {value.title}
    </Typography>
    <Typography component={'p'} sx={styles.valuesText}>
      {value.text}
    </Typography>
  </Grid>
);

const CompanyValues = () => (
  <Grid container justifyContent="center" sx={styles.valuesContainer}>
    <CenteredGrid container sx={styles.centerValue}>
      <Grid item>
        <SectionTag tag="Company values" right={false} />
        <Typography component={'h2'} sx={styles.ourValuesTitle}>
          Values
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" alignItems="stretch" sx={styles.headImageContainer}>
          {values.map(value => (
            <Grid key={value.id} item xs={12} sm={6} md={4}>
              <ValueItem styles={styles} value={value} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </CenteredGrid>
  </Grid>
);

export default CompanyValues;
