import { styled } from '@mui/material';
import Chip, { chipClasses } from '@mui/material/Chip';

export default styled(Chip)(({ theme }) => ({
  [`& .${chipClasses.label}`]: {
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontFamily: 'Gilroy-SemiBold',
      fontSize: 10
    }
  }
}));
