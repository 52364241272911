import { useState } from 'react';
import { Grid, List, Link as MuiLink } from '@mui/material';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { Link } from 'react-router-dom';
import { scrollBar } from '../../common/styles';

const styles = {
  root: {
    minWidth: 150,
    padding: 0,
    textDecoration: 'none'
  },
  scrollBar: {
    '&, & *': {
      ...scrollBar
    }
  },
  headerItem: {
    maxWidth: 202,
    padding: '0px 12px',
    borderRadius: 3,
    marginTop: 1,
    marginBottom: 1,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f7f8fa',
      borderRadius: 3
    }
  }
};

const HeaderMenuItemContent = ({ title, description, imageSrc }) => {
  return (
    <Grid
      direction="row"
      justifyContent="flex-start"
      alignItems={description ? 'flex-start' : 'center'}
      container
      sx={styles.headerItem}
    >
      <Grid item xs={description ? 4 : 3}>
        <img
          style={{ verticalAlign: description ? 'top' : 'middle', marginTop: description ? 12 : 0 }}
          src={imageSrc}
          alt={title}
        />
      </Grid>
      <Grid container item xs={8}>
        <Typography variant="h6Bold" sx={description ? { margin: '6px 0px 0px 6px' } : undefined}>
          {title}
        </Typography>
        {description && (
          <Typography
            sx={{ margin: '0px 6px 6px 6px', color: theme => theme.palette.neutral[500] }}
            variant="body3SemiBold"
          >
            {description}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const HeaderLink = ({ path, absolute, onClick, as, children, ...contentProps }) => {
  if (path && path.startsWith('/dashboard')) {
    return (
      <MuiLink sx={styles.root} href={path}>
        {children}
      </MuiLink>
    );
  }

  return (
    <Link to={path} absolute={absolute} style={styles.root} key={contentProps.title} as={as}>
      {children}
    </Link>
  );
};

const HeaderMenu = ({ menuItemList }) => {
  return (
    <>
      {menuItemList.map(({ path, absolute, onClick, as, ...contentProps }) =>
        onClick ? (
          <div role="presentation" onClick={onClick} style={styles.root} key={contentProps.title}>
            <HeaderMenuItemContent {...contentProps} />
          </div>
        ) : (
          <HeaderLink path={path} absolute={absolute} style={styles.root} key={contentProps.title} as={as}>
            <HeaderMenuItemContent {...contentProps} />
          </HeaderLink>
        )
      )}
    </>
  );
};

export const ListMenu = ({ menuItems = [], bottomItem, bottomItemSeparation = 200 }) => (
  <List component="nav">
    {menuItems.map(({ title, subItems, onClick, path, absolute }) =>
      subItems ? (
        <ExpandableListItem key={title} title={title} subItems={subItems} />
      ) : (
        <HeaderLink style={{ color: 'white' }} path={path} absolute={absolute} key={title}>
          <ClickableListItem sx={{ color: 'white' }} title={title} onClick={onClick} />
        </HeaderLink>
      )
    )}
    {bottomItem && (
      <ClickableListItem
        sx={{ marginTop: bottomItemSeparation }}
        title={bottomItem.title}
        onClick={bottomItem.onClick}
      />
    )}
  </List>
);

const ClickableListItem = ({ onClick, title, sx }) => (
  <ListItem component="div" role="presentation" sx={sx} onClick={onClick}>
    <ListItemText>
      <Typography variant="h4SemiBold" color="inherit">
        {title}
      </Typography>
    </ListItemText>
  </ListItem>
);

const ExpandableListItem = ({ title, subItems }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={toggleOpen}>
        <ListItemText sx={{ cursor: 'pointer' }}>
          <Typography variant="h4SemiBold" color="inherit">
            {`${title} ${open ? '-' : '+'}`}
          </Typography>
        </ListItemText>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {subItems.map(item => (
          <List key={item.title} component="div" disablePadding>
            <ListItem
              onClick={item.onClick}
              component={item.path?.startsWith('/dashboard') ? MuiLink : Link}
              absolute={item.absolute}
              to={item.path}
              href={item.path}
            >
              <ListItemIcon>
                <img style={{ borderRadius: 5, backgroundColor: '#FFFFFF' }} src={item.imageSrc} alt={title} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="button" sx={{ color: '#FFFFFF' }}>
                  {item.title}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        ))}
      </Collapse>
    </>
  );
};

export default HeaderMenu;
