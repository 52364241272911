import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import { createTheme } from '@swagup-com/react-ds-components';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Layout from './Layout';
import AuthProvider from './Authentication';
import TokenInterceptor from './shared/TokenInterceptor';
import AppConfigContext from '../contexts/AppConfigContext';
import { routes, redirects } from './routes';
import log from '../common/logger';

const defaultOptions = {
  queries: {
    refetchOnWindowFocus: false
  }
};

const queryClient = new QueryClient({ defaultOptions });
const mainTheme = createTheme();

const App = ({ config }) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={mainTheme}>
      <AppConfigContext.Provider value={config}>
        <IntercomProvider
          autoBoot
          appId={config.REACT_APP_INTERCOM_APP_ID}
          onShow={() => log.debug("Showing Intercom's Messenger.")}
          onHide={() => log.debug("Hiding Intercom's Messenger.")}
        >
          <AuthProvider>
            <TokenInterceptor>
              <CssBaseline />
              <Layout>
                <Switch>
                  {redirects.map(redirect => (
                    <Redirect key={redirect.from} {...redirect} exact />
                  ))}
                  {routes.map(({ Component, ...routeProps }) => (
                    <Route key={routeProps.path} {...routeProps}>
                      <Component />
                    </Route>
                  ))}
                </Switch>
              </Layout>
            </TokenInterceptor>
          </AuthProvider>
        </IntercomProvider>
      </AppConfigContext.Provider>
    </ThemeProvider>
  </QueryClientProvider>
);

export default App;
