const getStyles = ({ hideBackground, removePaddingLeft, modifyFontSize }) => ({
  containerSamplePack: theme => ({
    marginTop: 45,
    marginBottom: 22.5,
    paddingLeft: 3,
    [theme.breakpoints.down('lg')]: {
      px: 15
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 10.5,
      marginTop: 11.25,
      px: 4.5
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('1030')]: {
      width: 960
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  subSectionContainer: theme => ({
    paddingTop: 15,
    paddingLeft: 20.75,
    borderRadius: 6.25,
    borderTopRightRadius: 25,
    background: hideBackground ? 'unset' : 'url("/imgs/landingPagesSSR/rectangle-3669.svg")',
    backgroundSize: ' 85% 100% !important',
    backgroundRepeat: 'no-repeat !important',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0
    },
    [theme.breakpoints.down('1030')]: {
      paddingLeft: removePaddingLeft ? 0 : 15.5,
      backgroundSize: ' 82% 100% !important'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 5
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      borderTopRightRadius: '25px !important',
      backgroundSize: ' cover !important'
    }
  }),
  containerTitlesSP: theme => ({
    paddingRight: 4,
    [theme.breakpoints.down('md')]: {
      paddingRight: 4.5
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  }),
  promotionTitleSP: theme => ({
    textAlign: 'left',
    fontSize: 56,
    marginBottom: 11,
    fontFamily: 'Gilroy-Bold',
    color: '#0f2440',
    lineHeight: 1.29,
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('1030')]: {
      lineHeight: 1,
      marginBottom: 6
    },
    [theme.breakpoints.down('lg')]: { fontSize: modifyFontSize ? 40 : 56 },
    [theme.breakpoints.down('md')]: {
      paddingBottom: 2.5,
      paddingTop: 1.25,
      fontSize: modifyFontSize ? 30 : 24,
      marginBottom: 2.5,
      marginLeft: 7.25
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 2.5,
      paddingTop: 4,
      textAlign: 'center',
      marginLeft: 0,
      marginBottom: 0
    }
  }),
  textSamplePack: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    color: '#8D9299',
    lineHeight: 1.5,
    paddingRight: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: modifyFontSize ? 15 : 12,
      marginLeft: 7.25,
      paddingRight: 0
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginLeft: 0,
      padding: '0px 20px'
    }
  }),
  buttonR: theme => ({
    py: 4.25,
    marginTop: 11,
    marginBottom: 7.5,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      py: 3,
      marginTop: 6,
      marginBottom: 2.5,
      marginLeft: 7
    },
    [theme.breakpoints.down('sm')]: {
      mx: 'auto'
    }
  }),
  sPRImg: theme => ({
    objectFit: 'contain',
    position: 'relative',
    width: '100%',
    height: '100%',
    marginBottom: 2.5,
    top: -24,
    [theme.breakpoints.down('sm')]: {
      position: 'unset'
    }
  })
});

export default getStyles;
