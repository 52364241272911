import { Grid, Typography, Box, Button } from '@mui/material';
import gtm from '../../utils/gtm';
import CenteredGrid from './CenteredGrid';
import getStyles from './styles/mainSection';
import { imageSrcSet } from '../../helpers/utils';
import Img from './Img';

export const LeftComponent = ({
  title,
  subtitle,
  variant,
  buttonLabel,
  buttonWidth,
  onClick,
  href,
  lg = 6,
  alignContent = 'center',
  centerElementsOnXS
}) => {
  const styles = getStyles({ buttonWidth, centerElementsOnXS });
  const isSecondary = variant === 'secondary';
  return (
    <Grid item sm={6} lg={lg} xs={12} container alignItems="center" sx={styles.leftComponent}>
      <Grid container direction="column" alignContent={alignContent}>
        <Typography variant="h1Bold" sx={[styles.promotionTitle, isSecondary && styles.titleSecondary]}>
          {title}
        </Typography>
        <Typography variant="body1Medium" sx={[styles.promotionSubtitle, isSecondary && styles.subtitleSecondary]}>
          {subtitle}
        </Typography>
        <Box sx={styles.buttonContainer}>
          <Grid container direction="row" sx={styles.buttonContainerFix}>
            <Grid item>
              <Button
                href={onClick ? undefined : href ?? '/dashboard/product-onboarding'}
                variant={isSecondary ? 'outlined' : 'contained'}
                color="primary"
                sx={styles.inverseButton}
                onClick={onClick || gtm.onClickLandingCTA}
              >
                {buttonLabel}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const RightComponent = ({ img, animation, imgStyle, lg = 4 }) => {
  const styles = getStyles({});
  return (
    <Grid item lg={lg} sm={6} xs={12}>
      {(img || animation) && (
        <Grid container sx={{ height: '100%' }} justifyContent="center">
          <Grid item sx={styles.containerImg}>
            <Img src={img} srcSet={imageSrcSet(img)} alt="Img" sx={[styles.img, imgStyle]} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const MainSection = ({
  justifyContent = 'center',
  leftComponent,
  rightComponent,
  backgroundImage,
  changeBackgroundOnXS,
  animation,
  img
}) => {
  const styles = getStyles({ background: backgroundImage, changeBackground: changeBackgroundOnXS });
  return (
    <Grid container justifyContent="center">
      <CenteredGrid>
        <Grid container justifyContent={justifyContent} sx={styles.homeWrapper}>
          {leftComponent}
          {rightComponent || <RightComponent img={img} animation={animation} />}
        </Grid>
      </CenteredGrid>
    </Grid>
  );
};

export default MainSection;
