import { Box, Button, Grid, Typography } from '@mui/material';
import getStyles from './styles/highQualitySwag';

const HighQualitySwag = ({ title, subtitle, backgroundImage, centerButton }) => {
  const styles = getStyles({ backgroundImage, centerButton });

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.homeContainer}>
        <Grid container alignItems="center" sx={styles.home}>
          <Grid container justifyContent="flex-start" item sm={8} xs={12} direction="column">
            <Typography align="left" variant="inherit" component="h4" sx={styles.title}>
              {title || 'We are the Swag Management \nPlatform for the Modern Workplace'}
            </Typography>
            <Typography align="left" variant="inherit" sx={styles.subtitle}>
              {subtitle || 'Create, Automate & Distribute swag without the monthly platform fees.'}
            </Typography>
          </Grid>
          <Grid item sx={{ flex: 1 }}>
            <Grid container sx={styles.container}>
              <Button variant="outlined" color="primary" href="/dashboard/product-onboarding" sx={styles.button}>
                Get Started
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HighQualitySwag;
