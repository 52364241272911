const styles = {
  container: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-Bold',
    padding: '100px 0 145px 0',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      px: 6
    }
  }),
  externalContainer: { backgroundColor: '#e9f0fa' },
  title: theme => ({
    fontSize: '3.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.75rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.75rem'
    }
  }),
  subText: {
    opacity: 0.54,
    fontSize: '1.25rem',
    lineHeight: 1.5
  },
  button: {
    borderRadius: 8,
    padding: '26px 84px',
    marginTop: 11
  }
};

export default styles;
