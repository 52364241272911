const styles = {
  container: theme => ({
    fontFamily: 'Gilroy-Bold',
    color: '#0f2440',
    padding: '140px 0 90px 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      px: 6
    }
  }),
  questions: theme => ({
    padding: ' 46px 75px',
    paddingBottom: 0,
    boxShadow: '0 40px 140px 0 rgba(26, 58, 98, 0.05)',
    borderRadius: '25px 25px 125px 25px',
    [theme.breakpoints.down('md')]: {
      padding: '46px 24px'
    }
  }),
  title: theme => ({
    fontSize: '3.5rem',
    lineHeight: 'normal',
    paddingBottom: 7,
    [theme.breakpoints.down('md')]: {
      fontSize: '2.75rem'
    }
  }),
  questionText: {
    fontSize: '1.5rem',
    lineHeight: 'normal',
    paddingBottom: 4
  },
  answerText: {
    fontSize: '1.25rem',
    lineHeight: 1.6,
    opacity: 0.54
  },
  line: {
    height: 1,
    opacity: 0.54,
    border: 'solid 1px #d4d9e2',
    marginTop: 12,
    borderBottomColor: 'transparent'
  }
};

export default styles;
