import { Box, Button, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { imageSrcSet } from '../../utils';
import CenteredGrid from '../shared/CenteredGrid';
import Img from './Img';
import styles from './styles/aboutUs';

const AboutUs = () => (
  <Grid container justifyContent="center" sx={styles.containerBuildYB}>
    <CenteredGrid sx={styles.center}>
      <Box sx={styles.sectionContainerRippling}>
        <Typography component="h2" variant="inherit" sx={styles.promotionTitleAU}>
          About Us
        </Typography>
        <Grid container justifyContent="center">
          <Grid container sx={styles.containerAllAURippling} justifyContent="center">
            <Grid item xs={12} sm={5}>
              <Grid container>
                <Grid item xs={12}>
                  <Box sx={styles.wrapperAUImg}>
                    <Img
                      src="/imgs/landingPagesSSR/aboutus.png"
                      srcSet={imageSrcSet('/imgs/landingPagesSSR/aboutus.png')}
                      alt="group"
                      sx={styles.AUImg}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={7} sx={styles.paragraphAboutUsRippling}>
              <p>
                {' '}
                When we started up back in 2017 out of our garage, we saw companies struggling with unreliable
                suppliers, low quality items, environmental waste, endless manual processes, and tons of frustration.
              </p>
              <p>
                Today we work with over 1000 growing companies helping them create, automate, and distribute high
                quality swag more easily than they ever imagined...and we’re just getting started!
              </p>
              <Grid item container justifyContent="flex-start">
                <Button component={Link} to="/company" variant="contained" sx={styles.buttonAUR}>
                  Learn more
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CenteredGrid>
  </Grid>
);

export default AboutUs;
