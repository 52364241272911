import { useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { imageSrcSet } from '../../utils';
import MainSection, { LeftComponent } from '../shared/MainSection';
import ContactDialog from '../shared/ContactDialog';
import LimitingContainer from '../shared/LimitingContainer';

const styles = {
  mainContainer: theme => ({
    paddingBottom: 18,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 14
    }
  }),
  imgContainer: theme => ({
    height: '100%',
    display: 'flex',
    flexGrow: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    '& > img': {
      objectFit: 'cover',
      position: 'absolute',
      top: '1.25%',
      left: '43%',
      [theme.breakpoints.down('lg')]: {
        position: 'inherit',
        width: '100%'
      }
    },
    [theme.breakpoints.down('xl')]: { width: '80%' }
  })
};

const HeroImage = () => {
  const imagePath = 'imgs/services/hero-page.png';

  return (
    <Grid container item sm={6} xs={12} justifyContent="center" sx={styles.imgContainer}>
      <img src={imagePath} srcSet={imageSrcSet(imagePath)} alt="Img" />
    </Grid>
  );
};

const LandingSection = () => {
  const [open, setOpen] = useState(false);

  const isDownLG = useMediaQuery(theme => theme.breakpoints.down('lg'));

  return (
    <LimitingContainer sx={styles.mainContainer} maxWidth={1440}>
      <MainSection
        justifyContent={isDownLG ? 'space-evenly' : 'space-around'}
        backgroundImage="imgs/landingPagesSSR/form-bg-lined.svg"
        leftComponent={
          <LeftComponent
            title="One Streamlined Platform for All Your Swag Needs"
            subtitle="Create, automate, and distribute swag without a hassle or high platform fees."
            buttonLabel="Book a Tour!"
            buttonWidth={136}
            onClick={() => setOpen(true)}
            alignContent="flex-start"
            lg={4}
          />
        }
        rightComponent={<HeroImage />}
      />
      {open && <ContactDialog defaultSubject="I want to book a demo" open={open} onClose={() => setOpen(false)} />}
    </LimitingContainer>
  );
};

export default LandingSection;
