import { useState, useEffect } from 'react';
import { Grid, Typography, Link, useMediaQuery, Box } from '@mui/material';
import RightArrowIcon from '@mui/icons-material/ChevronRight';
import { Tabs, Tab } from '@swagup-com/react-ds-components';
import getStyles from './styles/fulfillmentSection';
import { moneyStr } from '../../helpers/utils';
import Loader from '../shared/Loader';
import platformApiClient from '../../api/platformApiClient';
import Img from '../shared/Img';

// TODO - scope const styles getStyles to each component for readability

const SectionItem = ({ head, col1, col2, headerRow }) => {
  const styles = getStyles(headerRow);
  return (
    <Grid item container wrap="nowrap" justifyContent="space-between" alignItems="center" sx={styles.sectionContainer}>
      <Grid item container wrap="nowrap" xs={4}>
        <Typography
          variant={headerRow ? 'h5' : 'h6'}
          sx={[styles.item, { textAlign: 'left', fontWeight: headerRow ? 'bold' : 400 }]}
        >
          {head}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant={headerRow ? 'subtitle1' : 'h6'} sx={styles.item}>
          {col1}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant={headerRow ? 'subtitle1' : 'h6'} sx={styles.item}>
          {col2}
        </Typography>
      </Grid>
    </Grid>
  );
};

const RenderSimpleSection = ({ data, headers, name }) =>
  data?.map((info, index) => {
    const styles = getStyles();
    return (
      <Box key={`${name}_${headers[index]}`}>
        <SectionItem
          head={headers[index]}
          col1={Number.isFinite(+info.col1) ? moneyStr(info.col1) : info.col1}
          col2={info.col2 && moneyStr(info.col2)}
        />
        {index < data.length - 1 && <div sx={styles.horizontalLine} />}
      </Box>
    );
  }) || null;

const FooterSection = () => {
  const styles = getStyles();
  return (
    <Grid sx={styles.infoCard}>
      <Grid container alignItems="center" sx={styles.footerSection}>
        <Grid item xs={12} sm={4} md={2}>
          <Img src="/imgs/pricing/plus-tshirt.svg" alt="tee shirt icon" sx={styles.footerImage} />
        </Grid>
        <Grid item xs={12} sm={8} md={10}>
          <Typography component="h3" sx={styles.footerTitle}>
            3rd Party Items Explained
          </Typography>
          <Typography sx={styles.footerText}>
            We pride ourselves on making swag simple. With that in mind, you can always send in your existing swag to be
            included in your platform and shipments
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const storageHeaders = ['Pack Storage', 'Small Item Fee', 'Medium Item Fee', 'Large Item Fee'];
const shippingUSHeaders = ['Pack (1 - 5 lbs)', 'Pack (Under 1 lb)', 'Bulk Shipments'];
const shippingInternationalHeaders = ['Pack (1 - 5 lbs)', 'Pack (Under 1 lb)', 'Bulk Shipments'];
const packingHeaders = ['Repacking (per pack)'];

const FulfillmentTable = ({ data, isPending }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const isXs = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const styles = getStyles();

  return (
    <Grid item sx={styles.container} id="fulfillment-prices-breakdown">
      <Grid item container justifyContent="center" sx={{ textAlign: 'center' }}>
        <Typography variant="h3" style={{ lineHeight: 1.17 }}>
          Flat Rate Storage and Fulfillment
        </Typography>
      </Grid>
      <Grid sx={styles.tableContainer}>
        {isPending && <Loader absolute />}
        <SectionItem head="Storage" col1="SwagUp Provided" col2="3rd Party Provider" headerRow />
        <RenderSimpleSection data={data?.storage} headers={storageHeaders} name="storage" />
        <SectionItem head="Shipping" col1="Standard" col2={`${isXs ? '' : '🚀 '}Express`} headerRow />
        <Tabs value={selectedTab} sx={styles.tabs}>
          <Tab label="United States" onClick={() => setSelectedTab(0)} />
          <Tab label="International" onClick={() => setSelectedTab(1)} />
        </Tabs>
        {selectedTab === 0 ? (
          <RenderSimpleSection data={data?.shippingUS} headers={shippingUSHeaders} name="shippingUS" />
        ) : (
          <RenderSimpleSection
            data={data?.shippingInternational}
            headers={shippingInternationalHeaders}
            name="shippingInternational"
          />
        )}
        <Grid sx={styles.linkContainer}>
          <Link href="#shipping-prices" sx={styles.link}>
            <Img src="/imgs/pricing/icon_calculator.svg" alt="Calculator Icon" style={{ marginRight: 2 }} />
            Calculate Shipment Price
            <RightArrowIcon fontSize="small" style={{ marginTop: 1 }} />
          </Link>
        </Grid>
        <SectionItem head="Packing" col1="SwagUp Provided" col2="3rd Party Provider" headerRow />
        <RenderSimpleSection data={data?.packing} headers={packingHeaders} name="packing" />
      </Grid>
      <Typography sx={styles.text}>Disclaimer: Prices subject to change.</Typography>
      <FooterSection />
    </Grid>
  );
};

const thirdParty = {
  pack: 8,
  small: 0.5,
  regular: 2,
  large: 4
};
const mailerBag = {
  us: {
    standard: 11,
    express: 23
  },
  international: {
    standard: 25,
    express: 40
  }
};
const packing = 4;

const FulfillmentSection = () => {
  const [response, setResponse] = useState();
  const [isPending, setIsPending] = useState(true);
  const [data, setData] = useState();

  const fetchPrices = async () => {
    const individualPackPrices = await platformApiClient.getIndividualPacks();
    const storageCategories = await platformApiClient.getStorageCategories();
    const storagePrices = await platformApiClient.getStoragePrices();
    setIsPending(false);
    setResponse({
      individualPackPrices: individualPackPrices,
      storageCategories: storageCategories,
      storagePrices: storagePrices
    });
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  useEffect(() => {
    if (response) {
      const storageCategories = {};
      response.storageCategories.forEach(c => {
        storageCategories[c.id] = c.name;
      });
      const storagePrices = {};
      response.storagePrices.forEach(p => {
        storagePrices[storageCategories[p.storage_category]] = p.price;
      });
      const onePackPrices = response.individualPackPrices[0];

      setData({
        storage: [
          { col1: storagePrices.Pack, col2: thirdParty.pack },
          { col1: storagePrices.Small, col2: thirdParty.small },
          { col1: storagePrices.Regular, col2: thirdParty.regular },
          { col1: storagePrices.Large, col2: thirdParty.large }
        ],
        shippingUS: [
          { col1: onePackPrices.us_price, col2: onePackPrices.express_us_price },
          { col1: mailerBag.us.standard, col2: mailerBag.us.express },
          { col1: 'Varies' }
        ],
        shippingInternational: [
          {
            col1: onePackPrices.international_price,
            col2: onePackPrices.express_international_price
          },
          { col1: mailerBag.international.standard, col2: mailerBag.international.express },
          { col1: 'Varies' }
        ],
        packing: [{ col1: packing, col2: packing }]
      });
    }
  }, [response]);

  return <FulfillmentTable data={data} isPending={isPending} />;
};

export default FulfillmentSection;
