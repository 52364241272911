import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Box, Typography } from '@mui/material';
import styles from './styles/Article';
import ArticleInformation from './ArticleInformation';
import AppConfigContext from '../../contexts/AppConfigContext';
import Img from '../shared/Img';

const Socials = ({ post }) => (
  <Box sx={styles.SocialMedia}>
    <Typography sx={[styles.TitleText, { textTransform: 'uppercase' }]}>Share to the world</Typography>
    <Grid container justifyContent="center" alignItems="center" spacing={4}>
      <Grid item>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=swagup.com/blog/${post.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Img src="/imgs/blog/circle-facebook.svg" alt="Facebook" sx={styles.imgSocials} />
        </a>
      </Grid>
      <Grid item>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.swagup.com/blog/${post.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Img src="/imgs/blog/circle-linkedin.svg" alt="LinkedIn" sx={styles.imgSocials} />
        </a>
      </Grid>
      <Grid item>
        <a
          href={`https://twitter.com/share?text=${post.title}&url=https://www.swagup.com/blog/${post.slug}&hashtags=swagup`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Img src="/imgs/blog/circle-twitter.svg" alt="Twitter" sx={styles.imgSocials} />
        </a>
      </Grid>
    </Grid>
  </Box>
);

const Article = ({ post }) => {
  const appConfigContext = useContext(AppConfigContext);
  return (
    <Box component="section" sx={styles.Article}>
      <Helmet>
        <link
          rel="stylesheet"
          href={`${appConfigContext.REACT_APP_WORDPRESS_API_REST}/wp-includes/css/dist/block-library/style.css?ver=5.2.3`}
        />
        <link
          rel="stylesheet"
          href={`${appConfigContext.REACT_APP_WORDPRESS_API_REST}/wp-content/themes/twentyseventeen/style.css?ver=20190507`}
        />
      </Helmet>
      <Grid container justifyContent="center">
        <Grid item sx={post.twoColumns ? styles.twoColumnCenter : styles.center}>
          <ArticleInformation post={post} />
          <Box component="article" id="the-article" sx={styles.articleContent}>
            <Box dangerouslySetInnerHTML={{ __html: post.rendered }} />
          </Box>
          <Grid container justifyContent="center">
            <Socials post={post} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Article;
