import { Grid, Link, Typography } from '@mui/material';
import styles from './styles/swagUpWorkSection';
import { Box } from '@mui/system';
import Img from '../shared/Img';

const columnInfo = [
  {
    img: '/imgs/pricing/icon-grid.svg',
    title: 'Select your Swag',
    text: `Select the swag items you want. Don't see what you like? Don't worry we can do just about anything. You select, we help design.`
  },
  {
    img: '/imgs/pricing/store-and-pack.svg',
    title: 'Store and Pack',
    text: `Store your swag with us, including 3rd party items. All swag gets preassembled to your liking and inventory is tracked on your dashboard.`
  },
  {
    img: '/imgs/pricing/delivery-truck-with-mask.svg',
    title: 'Ship',
    text: `Select shipments on demand through your SwagUp dashboard.  Even schedule shipments for new hires or conferences in advance!`
  }
];

const InfoSection = () => (
  <Grid container justifyContent="space-between" sx={styles.infoSection}>
    {columnInfo.map(column => (
      <Grid item xs={12} lg={4} key={column.title} sx={styles.infoColumn}>
        <Img src={column.img} alt={column.title} sx={styles.infoImage} />
        <Box sx={styles.infoTextContainer}>
          <Typography component="h3" sx={styles.infoHeading}>
            {column.title}
          </Typography>
          <Typography sx={styles.infoText}>{column.text}</Typography>
        </Box>
      </Grid>
    ))}
    <Grid container alignItems="center" justifyContent="space-between" sx={styles.blueCard}>
      <Grid item xs={8}>
        <Typography component="h2" sx={styles.cardTitle}>
          Let’s get started today!
        </Typography>
        <Typography sx={styles.cardText}>
          It’s that simple, we do all of the work, so you can take the credit!
        </Typography>
      </Grid>
      <Grid container justifyContent="center" item xs={4}>
        <Link href="/dashboard/product-onboarding" sx={styles.button}>
          Let’s do this!
        </Link>
      </Grid>
    </Grid>
  </Grid>
);

const SwagUpWorkSection = () => {
  return (
    <Grid container justifyContent="center" sx={styles.sectionContainer}>
      <Typography variant="h3" sx={{ lineHeight: 1.17 }}>
        How does SwagUp work?
      </Typography>
      <Typography variant="h6" sx={styles.sectionSubTitle}>
        Let us do the heavy lifting
      </Typography>
      <InfoSection />
    </Grid>
  );
};

export default SwagUpWorkSection;
