import { Grid, Button } from '@mui/material';

const loadMoreStyles = theme => ({
  paddingLeft: 0,
  paddingRight: 0,
  width: 145,
  height: 42,
  margin: '56px 0px',
  backgroundColor: '#ebf1fb',
  color: '#3577d4',
  borderRadius: 5,
  fontFamily: 'Gilroy-Medium !important',
  fontSize: 16,
  fontWeight: 600,
  textTransform: 'none',
  [theme.breakpoints.down('md')]: {
    margin: '26px 0px'
  }
});

const LoadMoreSection = ({ onLoadMore, light }) => (
  <Grid container justifyContent="center">
    <Grid item>
      <Button
        variant="contained"
        disableElevation
        sx={[loadMoreStyles, light && { backgroundColor: '#ffffff' }]}
        onClick={onLoadMore}
        startIcon={<img src="/imgs/faq/icon-load-more.svg" alt="Load more" />}
      >
        Load more
      </Button>
    </Grid>
  </Grid>
);

export default LoadMoreSection;
