const cellHeight = 556;
const defaultCell = {
  height: cellHeight,
  verticalAlign: 'top'
};

const defaultContent = {
  borderRadius: 6,
  padding: 2
};

const title = {
  fontSize: 20,
  color: '#0b1829',
  margin: 0
};

const headerImage = {
  width: 212,
  height: 252,
  objectFit: 'contain',
  position: 'absolute',
  zIndex: -1
};

const styles = {
  galleryCenter: theme => ({
    width: 1440,
    [theme.breakpoints.only('lg')]: {
      width: 1300
    },
    [theme.breakpoints.only('md')]: {
      width: 900
    },
    [theme.breakpoints.only('sm')]: {
      width: 600
    },
    [theme.breakpoints.only('xs')]: {
      width: 320
    }
  }),
  galleryContainer: {
    paddingTop: 23
  },
  wideCell: theme => ({
    ...defaultCell,
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      height: cellHeight / 1.4
    },
    [theme.breakpoints.down('md')]: {
      height: cellHeight / 2
    },
    [theme.breakpoints.down('md')]: {
      height: cellHeight / 4
    }
  }),
  cellContent: theme => ({
    ...defaultContent,
    height: cellHeight,
    [theme.breakpoints.down('lg')]: {
      height: cellHeight / 1.4,
      padding: 0
    },
    [theme.breakpoints.down('md')]: {
      height: cellHeight / 2
    },
    [theme.breakpoints.down('sm')]: {
      height: cellHeight / 4
    }
  }),
  cellContentLong: theme => ({
    ...defaultContent,
    height: cellHeight * 2,
    [theme.breakpoints.down('lg')]: {
      height: (cellHeight * 2) / 1.4
    },
    [theme.breakpoints.down('md')]: {
      height: (cellHeight * 2) / 2,
      padding: 1
    },
    [theme.breakpoints.down('sm')]: {
      height: (cellHeight * 2) / 4,
      padding: 1
    }
  }),
  cellImageContainer: theme => ({
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    borderRadius: 3,
    position: 'relative',
    '& .image-info-container': {
      opacity: 1,
      padding: '0px 28px',
      width: '100%',
      overflow: 'hidden',
      bottom: 0,
      height: 'auto',
      maxHeight: 0,
      transition: 'max-height 0.5s ease-out',
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,0.5)'
    },
    '&:hover .image-info-container': {
      maxHeight: '100%',
      [theme.breakpoints.down('md')]: {
        padding: 3
      },
      [theme.breakpoints.down('sm')]: {
        height: '100%'
      }
    }
  }),
  imageTitle: theme => ({
    color: '#fff',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 32,
    margin: 0,
    marginTop: 8,
    marginBottom: 4.5,
    [theme.breakpoints.down('md')]: {
      lineHeight: '24px',
      fontSize: 20,
      marginTop: 0,
      marginBottom: 0,
      wordWrap: 'wrap',
      maxHeight: 64
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '14px',
      fontSize: 12
    }
  }),
  imageCaptionWrapper: theme => ({
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'table'
    }
  }),
  imageCaptionContainer: theme => ({
    [theme.breakpoints.down('sm')]: {
      verticalAlign: 'middle',
      display: 'table-cell'
    }
  }),
  imageCaption: theme => ({
    marginBottom: 7,
    [theme.breakpoints.down('md')]: {
      marginBottom: 4.5
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      paddingBottom: 0
    },
    '& p': {
      color: '#fff',
      fontFamily: 'Gilroy-SemiBold',
      fontSize: 16,
      lineHeight: '28px',
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        lineHeight: '16px',
        wordWrap: 'wrap',
        maxHeight: '30%',
        marginBottom: 4.5
      },
      [theme.breakpoints.down('sm')]: {
        lineHeight: '10px',
        fontSize: 8,
        marginBottom: 3
      }
    },
    '& a': {
      color: '#ffffff',
      height: 48,
      padding: '16px 32px',
      fontSize: 14,
      minWidth: 125,
      background: '#3577d4',
      fontFamily: 'Gilroy',
      fontWeight: 600,
      borderRadius: 6,
      [theme.breakpoints.down('md')]: {
        padding: '6px 12px',
        fontSize: 12,
        borderRadius: 3
      },
      [theme.breakpoints.down('sm')]: {
        padding: '4px 8px',
        fontSize: 8,
        borderRadius: 2
      }
    }
  }),
  cellImage: {
    objectFit: 'cover',
    height: '100%',
    width: '100%'
  },
  cellExcerptContainer: theme => ({
    backgroundColor: '#fafafa',
    height: '100%',
    padding: '72px 56px',
    borderRadius: 3,
    [theme.breakpoints.down('lg')]: {
      padding: '16px 12px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '12px 8px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 4px'
    }
  }),
  excerpt: theme => ({
    ...title,
    fontSize: 24,
    fontStyle: 'italic',
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
      lineHeight: 1.2,
      marginBottom: 0
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: 1.2,
      marginBottom: 0
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
      lineHeight: 1.2,
      marginBottom: 0
    }
  }),
  excerptName: theme => ({
    ...title,
    fontSize: 24,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      lineHeight: 1.2,
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1.2,
      fontSize: 8
    }
  }),
  excerptTitle: theme => ({
    ...title,
    fontSize: 16,
    color: '#787b80',
    marginTop: 2,
    marginBottom: 2,
    lineHeight: 1.1,
    maxWidth: '90%',
    [theme.breakpoints.down('md')]: {
      marginTop: 1,
      marginBottom: 1,
      lineHeight: 1.2,
      maxWidth: '80%',
      fontSize: 8
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 0,
      lineHeight: 1.2,
      maxWidth: '50%',
      fontSize: 4
    }
  }),
  excerptBottom: theme => ({
    paddingTop: 10.5,
    [theme.breakpoints.down('md')]: {
      paddingTop: 6
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0
    }
  }),
  headerSectionContainer: {
    justifyContent: 'center',
    paddingTop: 16
  },
  headerTitleContainer: {
    maxWidth: 648
  },
  headerExceprtContainer: {
    maxWidth: 628
  },
  galleryTitle: theme => ({
    ...title,
    fontSize: 48,
    lineHeight: 1.33,
    textAlign: 'center',
    marginBottom: 6,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      marginBottom: 3
    }
  }),
  galleryExcerpt: theme => ({
    ...title,
    color: '#787b80',
    fontWeight: 'normal',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 12
    }
  }),
  galleryTitleHowSwagsWorks: theme => ({
    ...title,
    fontSize: 48,
    fontWeight: 'bold',
    lineHeight: 1.33,
    textAlign: 'center',
    marginBottom: 6,
    [theme.breakpoints.down('md')]: {
      fontSize: 32,
      marginBottom: 3
    }
  }),
  galleryExcerptHowSwagsWorks: theme => ({
    ...title,
    color: '#787b80',
    fontWeight: 'normal',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      marginBottom: 14
    }
  }),
  headerActionContainer: {
    paddingTop: 8
  },
  headerActionButton: {
    backgroundColor: '#9846dd'
  },
  clariLogo: theme => ({
    width: 56,
    height: 24,
    objectFit: 'contain',
    marginTop: 1,
    [theme.breakpoints.down('md')]: {
      width: 42,
      height: 18,
      marginTop: 3,
      marginBottom: 3
    },
    [theme.breakpoints.down('sm')]: {
      width: 18,
      height: 8,
      marginTop: 1,
      marginBottom: 2
    }
  }),
  caseStudyButton: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: 192,
      padding: '6px 6px'
    },
    [theme.breakpoints.down('md')]: {
      width: 124,
      padding: '6px 12px',
      height: 32,
      fontSize: 8
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      padding: '4px 6px',
      height: 20,
      fontSize: 4
    }
  }),
  simpleBluePackage: theme => ({
    ...headerImage,
    width: 142,
    height: 324,
    left: 0,
    top: 0,
    [theme.breakpoints.down('md')]: {
      width: 64,
      height: 156
    }
  }),
  pinkPackage: theme => ({
    ...headerImage,
    left: '15%',
    bottom: '-30%',
    [theme.breakpoints.down('md')]: {
      width: 102,
      height: 124,
      left: '10%',
      bottom: 0
    },
    [theme.breakpoints.down('sm')]: {
      left: 0,
      bottom: 0
    }
  }),
  lightBlueBox: theme => ({
    ...headerImage,
    right: '15%',
    bottom: 0,
    [theme.breakpoints.down(1320)]: {
      bottom: '-27%'
    },
    [theme.breakpoints.down('lg')]: {
      right: '10%',
      bottom: '-30%'
    },
    [theme.breakpoints.down('md')]: {
      width: 102,
      height: 124,
      right: '10%',
      bottom: 0
    },
    [theme.breakpoints.down('sm')]: {
      right: 0,
      bottom: 0
    }
  }),
  stayHomePackage: theme => ({
    ...headerImage,
    width: 142,
    height: 324,
    right: 0,
    top: 0,
    [theme.breakpoints.down('md')]: {
      width: 64,
      height: 156
    }
  }),
  holidayPackage: theme => ({
    ...headerImage,
    left: '15%',
    top: '-40%',
    [theme.breakpoints.down('md')]: {
      width: 102,
      height: 124,
      left: '15%',
      top: '-25%'
    },
    [theme.breakpoints.down('sm')]: {
      width: 102,
      height: 124,
      left: '15%',
      top: '-20%'
    }
  }),
  holidayPackageRight: theme => ({
    ...headerImage,
    right: '20%',
    top: '-35%',
    [theme.breakpoints.down('md')]: {
      width: 102,
      height: 124,
      right: '15%',
      top: '-25%'
    },
    [theme.breakpoints.down('sm')]: {
      width: 102,
      height: 124,
      right: '15%',
      top: '-20%'
    }
  }),
  tab: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    marginTop: 4.5,
    textAlign: 'center',
    color: '#3577d4 !important',
    border: '1px solid #3577d4',
    height: 42,
    borderRadius: 5,
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 2,
      paddingRight: 2,
      minWidth: 64,
      fontSize: 12
    }
  }),
  flexContainer: theme => ({
    '.MuiTabs-flexContainer': {
      justifyContent: 'strech',
      paddingLeft: '20%',
      paddingRight: '20%',
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '10%',
        paddingRight: '10%'
      },
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        paddingLeft: 0,
        paddingRight: 0
      }
    }
  }),
  tabOff: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    marginTop: 4.5,
    textAlign: 'center',
    color: '#8d9299 !important',
    border: '1px solid #8d9299',
    height: 42,
    borderRadius: 5,
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 2,
      paddingRight: 2,
      minWidth: 48,
      fontSize: 12
    }
  }),
  stepCard: theme => ({
    padding: 1.5,
    width: 312,
    [theme.breakpoints.only('md')]: {
      width: 256
    },
    [theme.breakpoints.only('sm')]: {
      width: 382
    }
  }),
  stepCardItem: theme => ({
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px !important'
    }
  }),
  stepCardImageContainer: theme => ({
    height: 256,
    width: 256,
    [theme.breakpoints.down('md')]: {
      height: 200,
      width: 200
    },
    [theme.breakpoints.down('sm')]: {
      height: 164,
      width: 164
    }
  }),
  stepCardImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  stepCardNumber: theme => ({
    paddingTop: 1,
    height: 34,
    width: 34,
    borderRadius: '50%',
    border: '2px solid #3577d4',
    fontSize: 16,
    color: '#3577d4',
    fontWeight: 'bold',
    marginTop: 3,
    marginBottom: 3,
    [theme.breakpoints.down('sm')]: {
      marginTop: 1,
      marginBottom: 1
    }
  }),
  stepCardTitle: theme => ({
    ...title,
    fontSize: 36,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 4,
    marginTop: 6,
    [theme.breakpoints.down('md')]: {
      marginBottom: 3,
      marginTop: 3,
      fontSize: 32
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 1,
      marginTop: 1,
      fontSize: 24
    }
  }),
  stepCardExcerpt: {
    ...title,
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 1.63,
    color: '#787b80'
  },
  howSwagsWorks: theme => ({
    padding: '124px 0px',
    [theme.breakpoints.down('sm')]: {
      padding: '64px 0px'
    }
  }),
  cardContainer: theme => ({
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  })
};

export default styles;
