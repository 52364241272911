import * as React from 'react';
import { useOktaAuth, Security } from '@okta/okta-react';
import { toRelativeUrl, OktaAuth } from '@okta/okta-auth-js';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useIntercom } from 'react-use-intercom';
import log from '../../common/logger';
import AppConfigContext from '../../contexts/AppConfigContext';

const registerUser = (user, appConfig) => {
  log.debug('registering user:', user, '..in:');

  const logRocketAppId = appConfig.REACT_APP_LOG_ROCKET_PROJECT_ID;

  if (logRocketAppId && window.location.hostname !== 'localhost') {
    // eslint-disable-next-line global-require
    const LogRocket = require('logrocket');
    log.debug('--LogRocket');
    LogRocket.init(logRocketAppId);
    LogRocket.identify(user.sub, {
      name: user.name,
      email: user.email
    });
  }

  log.debug('--GTM');
  const tagManagerArgs = {
    dataLayer: {
      event: 'userLogin',
      user_id: user.sub,
      email: user.email,
      given_name: user.given_name,
      family_name: user.family_name
    }
  };

  TagManager.dataLayer(tagManagerArgs);
};

const launchDarklyIdentify = (user, ldClient) => {
  if (!user) {
    ldClient.identify({
      key: 'AnonymousUser',
      anonymous: true
    });
  } else if (user.email !== ldClient.getUser().key) {
    ldClient.identify({
      key: user.email,
      name: user.name,
      email: user.email,
      zoneinfo: user.zoneinfo,
      anonymous: false
    });
  } else {
    log.debug('user', user, 'already identified in Launch Darkly');
    return;
  }

  log.debug('user', user?.email ?? 'anonymous', 'identified in Launch Darkly');
};

const AuthContext = React.createContext();

const AuthOktaProvider = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [user, setUser] = React.useState();
  const appConfig = React.useContext(AppConfigContext);
  const isGettingUser = React.useRef(false);

  const ldClient = useLDClient();
  const { demoFeature } = useFlags(); // just for demo/testing purposes

  const login = React.useCallback(
    async url => {
      log.debug('signInWithRedirect url:', url);
      if (url) {
        oktaAuth.setOriginalUri(url);
      }
      await oktaAuth.signInWithRedirect();
    },
    [oktaAuth]
  );

  const logout = React.useCallback(async () => {
    await oktaAuth.signOut();
  }, [oktaAuth]);

  React.useEffect(() => {
    const setUserFromOkta = async () => {
      try {
        if (!user && !isGettingUser.current) {
          isGettingUser.current = true;
          log.debug('about to call oktaAuth.getUser()');
          setUser(await oktaAuth.getUser());
          isGettingUser.current = false;
        }
      } catch (e) {
        log.error('exception:', e, 'in AuthOktaProvider getUser(), about to force logout');
        logout();
      }
    };

    if (!authState?.isPending) {
      if (authState?.isAuthenticated) {
        setUserFromOkta();
      } else if (user) {
        setUser();
      }
    }
  }, [user, authState, oktaAuth, logout]);

  const { update, shutdown, boot } = useIntercom();

  React.useEffect(() => {
    const mustWaitToIdentify =
      authState?.isPending || (authState?.isAuthenticated && !user) || (!authState?.isAuthenticated && user);

    if (!mustWaitToIdentify) {
      if (user) {
        log.debug('registering', user.email, 'in Intercom');
        update({
          name: user.name,
          email: user.email
        });
      } else {
        log.debug('rebooting Intercom');
        shutdown();
        boot();
      }
      if (ldClient) {
        launchDarklyIdentify(user, ldClient);
      }
    }
  }, [authState, user, ldClient, update, shutdown, boot]);

  React.useEffect(() => {
    if (user && appConfig) {
      registerUser(user, appConfig);
    }
  }, [user, appConfig]);

  const value = React.useMemo(
    () => ({
      login,
      logout,
      isAuthenticated: authState?.isAuthenticated,
      accessToken: authState?.accessToken,
      isPending: authState?.isPending
    }),
    [login, logout, authState]
  );

  log.debug('user:', user, 'flag demoFeature active?:', demoFeature);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthProvider = ({ children }) => {
  const appConfig = React.useContext(AppConfigContext);
  const oktaAuth = new OktaAuth({
    issuer: appConfig.REACT_APP_OKTA_ISSUER,
    clientId: appConfig.REACT_APP_OKTA_CLIENTID,
    redirectUri: `${window.location.origin}${appConfig.REACT_APP_OKTA_REDIRECTURI}`,
    pkce: false,
    devMode: process.env.NODE_ENV !== 'production'
  });

  if (typeof window !== 'undefined' && oktaAuth?.tokenManager) {
    oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
      log.debug('token', expiredToken, 'with key', key, 'has expired');
    });
  }

  const history = useHistory();

  // for initial context see:
  // https://github.com/okta/okta-react/issues/60
  // https://github.com/okta/okta-react/commit/153474a55192bbccb980e779852456f3ba7f2f1f
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AuthOktaProvider>{children}</AuthOktaProvider>
    </Security>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthContext, useAuth };
export default AuthProvider;
