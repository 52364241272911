import Box from '@mui/material/Box';
import { Header } from '@swagup-com/react-ds-components';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const noHeaderPaths = ['/implicit/callback', '/landing'];

const isAllowed = pathname => noHeaderPaths.every(path => !RegExp(`^${path}`, 'i').test(pathname));

const headerStyles = {
  appBar: theme => ({
    position: 'static',
    display: 'flex',
    paddingRight: '0 !important', // material-ui popups adds padding-right: 17px to headers and body
    backgroundColor: theme.palette.common.white
  }),
  upperBar: {
    margin: 'auto',
    backgroundColor: 'blue'
  },
  mainBar: theme => ({
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    margin: 'auto',
    width: 1440,
    [theme.breakpoints.up('lg')]: {
      width: 1200
    },
    [theme.breakpoints.up('md')]: {
      width: 900
    },
    [theme.breakpoints.up('sm')]: {
      width: 600
    },
    [theme.breakpoints.up('xs')]: {
      width: 320
    }
  }),
  content: {
    width: '66%'
  }
};

const TopBarContent = ({ pathname, getNotificationComponent }) => {
  return (
    isAllowed(pathname) && (
      <Box
        id="header"
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1100
        }}
      >
        <Header
          appBarSx={headerStyles.appBar}
          upperBarSx={headerStyles.upperBar}
          mainBarSx={headerStyles.mainBar}
          contentSx={headerStyles.content}
        >
          <Box sx={theme => ({ [theme.breakpoints.down('sm')]: { display: 'none' } })}>
            <DesktopHeader getNotificationComponent={() => getNotificationComponent('tooltip')} />
          </Box>
          <Box sx={theme => ({ [theme.breakpoints.up('sm')]: { display: 'none' } })}>
            <MobileHeader getNotificationComponent={() => getNotificationComponent('drawer')} />
          </Box>
        </Header>
      </Box>
    )
  );
};

export default TopBarContent;
