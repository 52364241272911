const styles = {
  productCard: theme => ({
    padding: '30px !important',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  }),
  buttonPS: theme => ({
    height: '100% !important',
    width: '100% !important',
    '& > div': {
      height: '100% !important'
    },
    [theme.breakpoints.down('sm')]: {
      '& div': {
        height: 'auto !important'
      }
    }
  }),
  simpleButtonEGFirst: theme => ({
    height: '100%',
    padding: 17,
    paddingBottom: 15,
    textAlign: 'left',
    backgroundColor: '#ffffff',
    boxShadow: '0 25px 50px 0 rgba(212, 217, 226, 0.25)',
    borderRadius: '20px 100px 20px 20px',
    [theme.breakpoints.up('lg')]: {
      boxShadow: '0 25px 50px 0 rgba(212, 217, 226, 0.25)',
      padding: 22
    },
    [theme.breakpoints.down('lg')]: {
      padding: '20px 10px 20px 10px !important'
    },
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      textAlign: 'left',
      padding: '20px 20px 20px 10px !important'
    }
  }),
  simpleButtonEG: theme => ({
    height: '100%',
    padding: 15,
    paddingTop: 10,
    textAlign: 'left',
    backgroundColor: '#ffffff',
    boxShadow: '0 25px 50px 0 rgba(212, 217, 226, 0.25)',
    borderRadius: 5,
    [theme.breakpoints.down('1030')]: {
      padding: 10,
      paddingBottom: 5,
      paddingTop: 7.5
    },
    [theme.breakpoints.down('md')]: {
      padding: '20px 10px 20px 10px !important'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      boxShadow: 'none',
      padding: '20px 20px 20px 10px !important'
    }
  }),
  hoveredButton: theme => ({
    boxShadow: '0px 40px 140px 0px rgba(26, 58, 98, 0.22) !important',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none !important'
    }
  }),
  insidePaper: theme => ({
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      direction: 'colum',
      width: 'calc(100vw - 60px)',
      boxShadow: '0 25px 50px 0 rgba(212, 217, 226, 0.25)',
      marginBottom: 5,
      borderRadius: 5
    }
  }),
  packImg: theme => ({
    maxHeight: '100%',
    maxWidth: '100%',
    display: 'grid',
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 225
    }
  }),
  minimumTextEG: theme => ({
    fontSize: 12,
    paddingTop: 10,
    opacity: 0.54,
    fontFamily: 'Gilroy-Medium',
    lineHeight: 1.89,
    marginBottom: 0,
    marginTop: 0,
    color: '#0f2440',
    [theme.breakpoints.down('md')]: {
      paddingTop: 7.5,
      marginLeft: 2.5,
      fontSize: 10
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 7.5,
      marginLeft: 'calc((100vw - 265px)/2)',
      fontSize: 10
    }
  }),
  packNameTextEG: theme => ({
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    fontWeight: 'bold',
    color: '#0f2440',
    paddingTop: 1.75,
    marginBottom: 0,
    marginTop: 0,
    [theme.breakpoints.down('1030')]: {
      fontSize: 18
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginLeft: 10
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      marginLeft: 'calc((100vw - 265px)/2)'
    }
  }),
  packPriceTextEG: theme => ({
    fontSize: 16,
    marginTop: 4,
    marginBottom: 0,
    fontFamily: 'Gilroy-Bold',
    color: '#0f2440',
    [theme.breakpoints.down('1030')]: {
      fontSize: 12,
      paddingBottom: 5
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginLeft: 2.5,
      paddingBottom: 5
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginLeft: 'calc((100vw - 265px)/2)',
      paddingBottom: 10
    }
  }),
  simpleButtonEGLast: theme => ({
    height: '100%',
    padding: 15,
    paddingTop: 8,
    textAlign: 'left',
    backgroundColor: '#3577D4',
    boxShadow: '0px 25px 50px rgba(212, 217, 226, 0.25)',
    borderRadius: 5,
    [theme.breakpoints.down('lg')]: {
      padding: '20px 10px 20px 10px !important'
    },
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      padding: '20px 20px 20px 10px !important',
      textAlign: 'left',
      backgroundColor: 'transparent',
      '& > div': {
        backgroundColor: '#3577D4'
      }
    }
  }),
  containPackImg: theme => ({
    padding: '0px 20%',
    flexGrow: 0,
    [theme.breakpoints.down('1030')]: {
      padding: '0px 30% 0px 30%'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6% 20% 0px 20%'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '6% 25% 0px 25%'
    }
  }),
  textCustomPack: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    textAlign: 'center',
    color: '#FFFFFF',
    marginBottom: 0,
    [theme.breakpoints.down('lg')]: {
      fontSize: 18
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    }
  }),
  subTextCustomPack: theme => ({
    fontFamily: 'Gilroy',
    fontSize: 16,
    textAlign: 'center',
    color: '#FFFFFF',
    marginTop: 3,
    marginBottom: 0,
    [theme.breakpoints.down('1030')]: {
      fontSize: 12,
      padding: '0px 22px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
      padding: '0px 12px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: '0px 42px'
    }
  }),
  containerButtonEG: theme => ({
    marginTop: 12,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      marginTop: 6
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 12
    }
  }),
  buttonEG: theme => ({
    maxWidth: '226px !important',
    height: '71.222px !important',
    fontSize: '20px !important',
    paddingTop: '17px !important',
    paddingBottom: '17px !important',
    marginTop: '0 !important',
    borderRadius: '36px !important',
    marginBottom: '31.2px !important',
    [theme.breakpoints.down('1030')]: {
      fontSize: '16px !important'
    },
    [theme.breakpoints.down('md')]: {
      height: '45px !important',
      maxWidth: '180px !important',
      fontSize: '12px !important',
      paddingTop: '12px !important',
      paddingBottom: '12px !important'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '32px !important'
    }
  }),
  containerBuildPS: theme => ({
    paddingTop: 5.25,
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      paddingBottom: 2.5
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('1030')]: {
      width: 960
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  sectionContainerPS: theme => ({
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0
    }
  }),
  promotionTitleAU: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-Semibold',
    fontSize: 56,
    textAlign: 'center',
    marginTop: 9.25,
    marginBottom: 6,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      marginTop: 6,
      textAlign: 'center',
      marginBottom: 2.5
    }
  }),
  textAboutUs: theme => ({
    color: '#0f2440',
    marginTop: 0,
    marginBottom: 25,
    '& p': {
      fontFamily: 'Gilroy-SemiBold',
      fontSize: 20,
      textAlign: 'center',
      lineHeight: 1.5,
      display: 'inline-block',
      opacity: 0.54
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
      marginTop: 0,
      marginBottom: 2.5,
      textAlign: 'center',
      '& p': {
        fontSize: 12,
        padding: '0px 60px'
      }
    }
  }),
  containerAllPS: theme => ({
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 11.25,
      paddingRight: 11.25
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  })
};

export default styles;
