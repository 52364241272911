import ContactUs from '../../shared/ContactUs';
import OurServices from '../../shared/OurServices';
import SamplePack from '../../shared/SamplePack';
import LandingSection from '../shared/LandingSection';
import LandingView from '../shared/LandingView';
import BuildYourBrand from '../shared/NewBuildYourBrand';

const services = [
  {
    src: '/imgs/landingPagesSSR/design.svg',
    title: 'Design',
    text: 'Our team can help design the holiday swag packs to your new branding or even work alongside with your team of designers to make sure it’s right!'
  },
  {
    src: '/imgs/landingPagesSSR/warehouse-storage.svg',
    title: 'Storage',
    text: 'Your new swag is safe with us! Store your Swag packs with us and manage inventory through our dashboard.'
  },
  {
    src: '/imgs/landingPagesSSR/assemble.svg',
    title: 'Assemble',
    text: 'We take this part very seriously! We assemble everything so you don’t have to! Holiday Swag Packs are carefully assembled and packaged as gifts. '
  },
  {
    src: '/imgs/landingPagesSSR/ship.svg',
    title: 'Ship',
    text: 'We get it! You might have to send swag around the world. You’re in good hands, we ship worldwide. '
  }
];

const responses = [
  {
    img: { src: '/imgs/landingPagesSSR/culture.svg', alt: 'culture' },
    subTitle: 'We assemble high-quality swag into packs with your custom branding.'
  },
  {
    img: { src: '/imgs/landingPagesSSR/quality-items.svg', alt: 'quality-items' },
    subTitle: 'Select from top brands like Patagonia, Corkcicle and more!'
  },
  {
    img: { src: '/imgs/landingPagesSSR/client-employee-bond.svg', alt: 'client-employee-bond' },
    subTitle: 'Corporate gifts are perfect to cheer up clients, members or employees!'
  },
  {
    img: { src: '/imgs/landingPagesSSR/quality-items.svg', alt: 'quality-items2' },
    subTitle: 'A curated swag pack is the perfect gift that offers personalization and brand awareness.'
  }
];

const landingImages = [
  '/imgs/landingPagesSSR/holiday/socks.png',
  '/imgs/landingPagesSSR/holiday/waterbottle.png',
  '/imgs/landingPagesSSR/holiday/tape.png',
  '/imgs/landingPagesSSR/holiday/tote.png',
  '/imgs/landingPagesSSR/holiday/schedule.png',
  '/imgs/landingPagesSSR/holiday/box.png',
  '/imgs/landingPagesSSR/holiday/bag.png',
  '/imgs/landingPagesSSR/holiday/coffee-cup.png',
  '/imgs/landingPagesSSR/holiday/bitmap.png'
];

const sectionData = {
  title: 'Holiday Swag\nGifts Everyone\nWill Love!',
  h1Style: { whiteSpace: 'pre-wrap', fontSize: 56 },
  subTitle: 'Create, Automate, and Distribute\nHoliday Swag without the headache',
  subTitleClass: 'homePromoHoliday',
  buttonLabel: 'Let’s get started!',
  landingView: <LandingView images={landingImages} />,
  imagesTitleClass: 'servingUpHoliday'
};

const HolidaySwag = () => (
  <>
    <LandingSection sectionData={sectionData} />
    <BuildYourBrand
      responses={responses}
      title="What’s in a Holiday Swag Pack"
      maingImg="/imgs/landingPagesSSR/holiday/byb.png"
    />
    <OurServices services={services} title="Holiday Swag has never been so easy!" />
    <SamplePack
      title="Need a Holiday Swag Pack Sample?"
      subtitle="Well, we have a free sample waiting for you!"
      buttonText="Grab a sample!"
    />
    <ContactUs namePage="Holiday Swag Gifts Everyone Will Love!" mailTo="team@swagup.com" />
  </>
);

export default HolidaySwag;
