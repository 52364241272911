import { Grid, Typography } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import SectionTag from '../shared/SectionTag';
import styles from './styles/company';
import CenteredGrid from '../../../src/components/shared/CenteredGrid';

const JoinOurTeam = () => (
  <CenteredGrid container justifyContent="center" sx={styles.center}>
    <Grid container direction="column" alignItems="center" sx={styles.joinOurTeamBox}>
      <SectionTag color="#ffffff" tag="Join our team" />
      <Typography component="h2" sx={styles.joinOurTeamTitle}>
        Join our fast growing team
      </Typography>
      <Typography component="p" sx={styles.joinOurTeamText}>
        We’re looking for ambitious individuals who strive in chaotic unstructured environments, and love swag, culture,
        branding, and proactively solving problems!
      </Typography>
      <Grid container justifyContent="center">
        <Button
          href="https://swagup.breezy.hr"
          rel="external noopener noreferrer"
          target="_blank"
          sx={styles.buttonMobile}
        >
          See open positions
        </Button>
      </Grid>
    </Grid>
  </CenteredGrid>
);

export default JoinOurTeam;
