const styles = {
  title: theme => ({
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 1.5,
    [theme.breakpoints.down('md')]: {
      marginBottom: 0.5
    }
  }),
  regularText: {
    textAlign: 'left'
  },
  againButton: {
    textAlign: 'left',
    fontSize: 20,
    paddingBottom: 2,
    paddingLeft: 0.5,
    color: '#3577d4',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  subscribeContainer: theme => ({
    padding: 4.5,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    }
  }),
  subscribeTitle: {
    marginBottom: 2.25,
    marginTop: 0
  },
  subscribeButton: theme => ({
    width: 173,
    height: 64,
    borderRadius: 1.6,
    backgroundColor: 'white',
    color: '#0b1829',
    border: '#0b1829',
    fontFamily: 'Gilroy-SemiBold',
    textTransform: 'none',
    boxShadow: 'none !important',
    '&:hover': {
      backgroundColor: '#FFFFFF'
    },
    [theme.breakpoints.down('md')]: {
      width: 132,
      height: 42,
      fontSize: 16
    }
  }),
  subscribeInput: {
    '& > .MuiInputBase-root': {
      height: 56,
      fontSize: 20,
      fontFamily: 'Gilroy-SemiBold',
      fontWeight: 500,
      letterSpacing: 'normal',
      color: '#434c5f'
    }
  },
  subscribeInputWrapper: {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #ffffff'
    }
  },
  sectionContainer: theme => ({
    width: '100%',
    paddingTop: 5.75,
    paddingBottom: 5.75,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 4
    }
  }),
  subscribeActions: theme => ({
    [theme.breakpoints.down('md')]: {
      paddingTop: 2,
      paddingBottom: 2
    }
  }),
  socialMedia: theme => ({
    width: 350,
    float: 'right',
    '& p': {
      fontFamily: 'Gilroy-SemiBold',
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.33,
      paddingBottom: 3.75,
      paddingTop: 1,
      margin: 0
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      float: 'none',
      '& p': {
        fontSize: 20,
        color: '#0f2440'
      }
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      float: 'none',
      '& p': {
        fontSize: 20,
        paddingTop: 1.5
      }
    }
  })
};

export default styles;
