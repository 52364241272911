import { useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import CenteredGrid from '../shared/CenteredGrid';
import Img from '../shared/Img';
import styles from './styles/company';

const TeamItemCard = ({ name, photoSrc, title, excerpt }) => (
  <Box sx={styles.teamItemView}>
    <Grid container alignItems="baseline" sx={styles.teamItemCover}>
      <Box sx={styles.imageWASwagUpContainer}>
        <Img src={photoSrc} alt="team" sx={styles.imageTemSwagUp} />
      </Box>
      <Box className="name-title-white">
        <Box sx={styles.teamCardBottom}>
          <Typography sx={styles.teamMemberName}>{name}</Typography>
          <Typography sx={styles.teamMemberTitle}>{title}</Typography>
        </Box>
      </Box>
      <Box component="section" sx={[styles.teamItemExcerpt, { width: '100%', height: '100%' }]}>
        <Grid container alignItems="flex-end" sx={{ height: '100%' }}>
          <Box sx={[styles.teamCardBottom, { paddingLeft: 6, paddingRight: 6 }]}>
            <Box sx={{ overflowY: 'hidden' }}>
              <Typography sx={[styles.teamMemberName, { color: '#ffffff' }]}>{name}</Typography>
              <Typography sx={[styles.teamMemberTitle, { color: '#ffffff' }]}>{title}</Typography>
              <Box
                sx={[
                  styles.teamMemberTitle,
                  { color: '#ffffff', opacity: 1, marginTop: 4, fontSize: 14, fontFamily: 'Gilroy-SemiBold' }
                ]}
              >
                <Box dangerouslySetInnerHTML={{ __html: excerpt }} />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  </Box>
);

const teamFilter = (team, showMore) => (!showMore && team.length > 8 ? team.slice(0, 8) : team);

const TeamSwagUp = ({ team = [] }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <CenteredGrid container justifyContent="center" sx={styles.center}>
      <Grid item xs={12}>
        <Typography component="h2" sx={styles.ourTeamTitle}>
          The Swag Fam
        </Typography>
        <Typography component="p" sx={styles.ourTeamSubTitle}>
          We’re a close-knit group of 40+ that loves great swag and solving problems
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={7} sx={styles.headImageContainer}>
          {teamFilter(team, showMore).map(teamate => (
            <Grid key={teamate.id} item xs={12} sm={6} md={3}>
              <TeamItemCard
                styles={styles}
                photoSrc={teamate.image}
                photoSrcSet={teamate.photoSrcSet}
                name={`${teamate.first_name} ${teamate.last_name}`}
                title={teamate.job_title}
                excerpt={teamate.rendered}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container justifyContent="center" sx={{ paddingTop: 24 }}>
        <Button onClick={() => setShowMore(x => !x)} sx={[styles.buttonMobile, styles.teamMembersButton]}>
          {showMore ? 'Show less team members' : 'See all team members'}
        </Button>
      </Grid>
    </CenteredGrid>
  );
};

export default TeamSwagUp;
