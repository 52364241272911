const styles = {
  containerBuildYB: theme => ({
    marginTop: 17.5,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      paddingBottom: 2.5
    }
  }),
  center: theme => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 10,
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('1030')]: {
      width: 960
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingBottom: 1.25
    }
  }),
  titleBYB: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    textAlign: 'center',
    marginTop: 6,
    marginBottom: 14,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      marginBottom: 6.5
    }
  }),
  containerAllBYB: theme => ({
    px: 15.5,
    [theme.breakpoints.up('md')]: { width: 1203 },
    [theme.breakpoints.down('lg')]: { width: 'auto', px: 25 },
    [theme.breakpoints.down('md')]: { px: 0 }
  }),
  wrapperBYBImg: theme => ({
    [theme.breakpoints.down('md')]: {
      padding: '0px 45px 0px 45px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 20px 0px 20px'
    }
  }),
  bYBImg: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderBottomRightRadius: 60,
    borderTopLeftRadius: 60
  },
  containerBoxBYB: theme => ({
    marginTop: 25,
    [theme.breakpoints.down('md')]: {
      marginTop: 11.25
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 3.75
    }
  }),
  boxBYB: theme => ({
    [theme.breakpoints.down('md')]: {
      '&:first-child': {
        paddingRight: 16
      },
      '&:not(:first-child):not(:last-child)': {
        px: 8
      },
      '&:last-child': {
        paddingLeft: 16
      }
    },
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  }),
  boxSM3: theme => ({
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  }),
  containerSectionSubTitleBYB: theme => ({
    padding: '36px 60px 20px 0px',
    [theme.breakpoints.down('lg')]: { paddingRight: 6.25 },
    [theme.breakpoints.down('md')]: { padding: '0px 20px 20px 0px' },
    [theme.breakpoints.down('sm')]: { padding: '0px 20px 20px 0px' }
  }),
  sectionSubTitle: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 22,
    marginBottom: 4,
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginBottom: 2
    }
  })
};

export default styles;
