const styles = {
  container: theme => ({
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 42.5,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 5,
      paddingRight: 5
    }
  }),
  center: theme => ({
    backgroundColor: 'rgba(235, 241, 251, 0.5)',
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 6.25,
    maxWidth: '1340px',
    paddingLeft: '0 !important',
    [theme.breakpoints.down('md')]: {
      maxWidth: '1120px'
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    }
  }),
  imageContainer: theme => ({
    paddingLeft: 5,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }),
  image: {
    objectFit: 'contain',
    width: 428,
    marginBottom: 4
  },
  textContainer: theme => ({
    paddingTop: 9,
    paddingRight: 40,
    paddingLeft: 10,
    [theme.breakpoints.down('md')]: {
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 25
    },
    [theme.breakpoints.down('md')]: {
      padding: 10,
      textAlign: 'center'
    }
  }),
  title: theme => ({
    margin: 0,
    fontSize: 48,
    fontWeight: 600,
    color: '#0f2440',
    [theme.breakpoints.down('md')]: {
      fontSize: 28
    }
  }),
  subTitle: theme => ({
    margin: 0,
    marginTop: 4,
    maxWidth: 429,
    fontSize: 16,
    lineHeight: 1.75,
    color: '#434d5c',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset'
    }
  }),
  emailInput: theme => ({
    maxWidth: 332,
    minHeight: 64,
    fontFamily: 'Gilroy-Medium',
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.33,
    color: '#0f2440',
    backgroundColor: 'transparent',
    '& input': {
      backgroundColor: 'transparent !important',
      fontFamily: 'Gilroy-SemiBold',
      fontSize: 16,
      lineHeight: 0.75,
      padding: '22.5px 30px !important',
      '&::placeholder': {
        color: '#8d9299',
        opacity: 1
      }
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      maxWidth: 'unset',
      marginRight: 5
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      '& input': { padding: '17.5px 30px' }
    }
  }),
  buttonContainer: theme => ({
    '& button > span': { lineHeight: 0.75 },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingTop: 5
    }
  }),
  subscribeButton: theme => ({
    width: '100%',
    marginTop: 0,
    marginLeft: 6,
    maxWidth: 156,
    height: 64,
    [theme.breakpoints.down('md')]: {
      padding: '21px 16px',
      maxWidth: 'unset',
      marginLeft: 0
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      marginLeft: 0
    }
  }),
  sectionContainer: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0
  }
};

export default styles;
