import { getTrackingTemplate } from './utilsCookies';

const trackingTemplate = () => {
  const template = getTrackingTemplate();
  return template ? `?${template}` : '';
};

const getDemoLink = () => `https://swagup.chilipiper.com/router/product-demo-router${trackingTemplate()}`;

const getContactList = () => `https://swagup.chilipiper.com/router/product-demo-router${trackingTemplate()}`;

export { getContactList, getDemoLink };
