const getStyles = ({ gallery } = {}) => ({
  imageContainer: theme => ({
    '& img': {
      height: 25,
      width: 'auto',
      position: 'relative',
      top: 5,
      [theme.breakpoints.down('sm')]: {
        height: 20
      }
    }
  }),
  label: theme => ({
    margin: '2px 0 0 10px',
    fontFamily: 'Gilroy-Semibold',
    fontSize: 20,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    textAlign: 'left',
    color: '#868a8f',
    [theme.breakpoints.down('sm')]: {
      fontFamily: 'Gilroy-Medium',
      fontSize: 12,
      marginTop: 6
    }
  }),
  value: theme => ({
    margin: '8px 0 12px',
    fontFamily: 'Gilroy-Semibold',
    fontSize: 24,
    lineHeight: 1.42,
    textAlign: 'left',
    color: '#0b1829',
    marginBottom: 0,
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  }),
  description: theme => ({
    fontFamily: 'Gilroy-Semibold',
    fontSize: 14,
    fontStretch: 'normal',
    textAlign: 'left',
    color: '#9846dd',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10
    }
  }),
  title: theme => ({
    margin: '20px 0 56px',
    fontFamily: 'Gilroy-Bold',
    fontSize: 48,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    textAlign: 'left',
    color: '#0f2440',
    [theme.breakpoints.down('md')]: {
      marginBottom: 5
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      fontSize: 24
    }
  }),
  sectionContainer: theme => ({
    padding: '39px 44px 20px',
    borderRadius: 5,
    backgroundColor: '#ffffff',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
      padding: '30px 24px 20px'
    },
    [theme.breakpoints.down('lg')]: {
      margin: '0 !important',
      padding: '30px 24px 30px'
    }
  }),
  confirmationContainer: theme => ({
    textAlign: 'center',
    paddingBottom: 5,
    '& img': {
      position: 'absolute',
      left: '45%',
      top: -36,
      [theme.breakpoints.down('lg')]: {
        width: 40,
        height: 40,
        top: -21,
        left: '47%'
      }
    }
  }),
  message: {
    fontFamily: 'Gilroy-Semibold',
    fontSize: 20,
    fontStyle: 'normal',
    lineHeight: 1.6,
    textAlign: 'center',
    color: '#0b1829',
    margin: '20px 0'
  },
  responsiveButton: theme => ({
    [theme.breakpoints.down('sm')]: {
      height: 32,
      fontSize: 14
    }
  }),
  contactContainer: theme => ({
    maxWidth: gallery ? 1200 : 1440,
    margin: 'auto',
    marginBottom: 6,
    minHeight: 717,
    borderRadius: 5,
    position: 'relative',
    backgroundImage: "url('/imgs/landingPagesSSR/form-bg-lined.svg')",
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      position: gallery ? 'inherit' : 'absolute',
      left: 0
    }
  }),
  sectionLeft: theme => ({
    [theme.breakpoints.down('sm')]: {
      paddingTop: '38px !important',
      paddingLeft: '48px !important'
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 15,
      paddingLeft: 15
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 20,
      paddingLeft: 15
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: 24,
      paddingLeft: 25
    }
  }),
  sectionRight: theme => ({
    margin: 'auto',
    padding: '69px 100px 60px 0',
    [theme.breakpoints.only('md')]: { padding: 15 },
    [theme.breakpoints.only('sm')]: { padding: '30px 60px 60px' },
    [theme.breakpoints.down('sm')]: { padding: '36px 20px' }
  }),
  contacts: theme => ({
    [theme.breakpoints.only('sm')]: { padding: '10px 60px 60px' },
    [theme.breakpoints.only('xs')]: { margin: '10px 32px 39px' }
  }),
  separator: theme => ({
    [theme.breakpoints.only('xs')]: { marginBottom: gallery ? 16 : 250 }
  })
});

export default getStyles;
