import { Grid, Card, Typography } from '@mui/material';
import styles from './styles/PostCard';
import { imageSrcSet } from '../../utils';
import Img from '../shared/Img';

const GestPostCard = () => (
  <Card
    sx={[
      styles.card,
      {
        padding: 4,
        margin: '0px 18px',
        justifyItems: 'center',
        border: 'solid 1px #ebeef2',
        height: 'auto',
        paddingBottom: 14,
        cursor: 'default'
      }
    ]}
  >
    <Grid
      container
      justifyContent="center"
      sx={{ background: '#ebf1fb', borderRadius: 1, overflowY: 'hidden', height: 142 }}
    >
      <Img
        sx={[styles.mediaSubscribe, { height: 126 }]}
        src="/imgs/blog/ink-pen.png"
        srcSet={imageSrcSet('/imgs/blog/ink-pen.png')}
        title="ink-pen"
        alt="ink-pen"
      />
    </Grid>

    <div>
      <Typography component="h3" sx={[styles.postTitle, { textAlign: 'center', marginBottom: 8 }]}>
        I want to guest write a SwagUp Blog!
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
        <a target="_blank" rel="noopener noreferrer" href="mailto:marketing@swagup.com">
          <Grid container justifyContent="center" alignItems="center" sx={[styles.subscribeButton, { width: 232 }]}>
            Contribute Guest Blog
          </Grid>
        </a>
      </Grid>
    </div>
  </Card>
);

export default GestPostCard;
