import { Grid, Paper, Typography, Box } from '@mui/material';
import styles from './styles/home';
import { Button } from '@swagup-com/react-ds-components';
import Img from '../shared/Img';

const TabPanel = ({ children, value, index }) => (
  <div style={{ display: value !== index ? 'none' : 'inherit' }}>{children}</div>
);

const PackItemCard = ({ pack }) => {
  const href = `/dashboard/preset-packs/${pack.slug || pack.id}`;

  return (
    <Box sx={styles.packItemContainer}>
      <div>
        <a href={href}>
          <Box sx={styles.packImageWrapper} align="center">
            <Img src={pack.img} sx={styles.imgPack} alt={`pack: ${pack.name}`} />
          </Box>
        </a>
      </div>
      <div>
        <Typography variant="h3Bold" sx={[styles.packItemText, styles.packItemTitle]}>
          {pack.name}
        </Typography>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography
              variant="subtitle2SemiBold"
              sx={styles.packItemSubTitle}
            >{`$ ${pack.price_per_pack}/Pack`}</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle3SemiBold"
              sx={[styles.packItemSubTitle, { opacity: 0.54 }]}
            >{`min ${pack.minimum} packs`}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item xs style={{ marginBottom: 24 }}>
            <Button
              href={href}
              fullWidth
              sx={[styles.packItemButton, styles.responsiveButton, { justifyContent: 'center' }]}
            >
              SELECT PACK
            </Button>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

const OtherPacksCard = ({ title, buttonText, href, list, imgSrc, imgSrcSet }) => (
  <Grid container justifyContent="center">
    <Grid item>
      <Paper sx={styles.otherOptionsContainer} elevation={0}>
        <Grid container>
          <Grid item xs sx={styles.onMobile} align="center">
            <Typography variant="h3SemiBold" sx={styles.lgsTabTitle}>
              {title}
            </Typography>
            <Box sx={styles.cardImgContainer}>
              <Img src={imgSrc} srcSet={imgSrcSet} sx={styles.cardImg} alt="Bulk" />
            </Box>
          </Grid>
          <Grid item md={6} xs={12} sx={{ maxWidth: '340px !important' }}>
            <Typography variant="h3SemiBold" sx={[styles.lgsTabTitle, styles.noMobile]}>
              {title}
            </Typography>
            <Box component="ul" sx={styles.otherMenu}>
              {list.map(item => (
                <Typography
                  key={item.key}
                  component="li"
                  variant="subtitle2SemiBold"
                  sx={[styles.textRequestPack, { marginBottom: 6, color: '#0f2440' }]}
                >
                  {item.text}
                </Typography>
              ))}
            </Box>
          </Grid>
          <Grid item xs align="center" sx={[styles.pWUButtonWrapper, { paddingLeft: 30 }]}>
            <Box sx={[styles.cardImgContainer, styles.noMobile]}>
              <Img src={imgSrc} srcSet={imgSrcSet} sx={styles.cardImg} alt="Bulk" />
            </Box>
            <Button href={href} fullWidth sx={[styles.packItemButton, styles.responsiveButton]}>
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </Grid>
);

export { TabPanel, PackItemCard, OtherPacksCard };
