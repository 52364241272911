import { useEffect, useState } from 'react';
import Helmet from '../shared/Helmet';
import tags from '../../utils/seoTags';
import GlobalInfoContext from './GlobalInfoContext';
import PricingHome from './PricingHome';
import platformApiClient from '../../api/platformApiClient';

const Provider = GlobalInfoContext.Provider;

const Pricing = () => {
  const [bulk, setBulk] = useState(undefined);
  const [swag, setSwag] = useState(undefined);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchDefaultProps = async () => {
      const bulk = await platformApiClient.getDefaultBulk();
      const swag = await platformApiClient.getDefaultSwag();
      const countries = await platformApiClient.getCountries();
      setBulk(bulk);
      setSwag(swag);
      setCountries(countries);
    };
    fetchDefaultProps();
  }, []);

  return (
    <>
      <Helmet tags={tags.pricing} />
      <Provider value={{ bulk, swag, countries }}>
        <PricingHome />
      </Provider>
    </>
  );
};

export default Pricing;
