import ClausesDocument from '../shared/ClausesDocument';

const privacyClauses = {
  '': 'This privacy policy (the “Policy”) describes how we collect and use your information. This Policy applies to data you provide us when you access or use the Site and purchase SwagUp products or services for yourself or others. SwagUp may modify this Policy from time to time effective upon its posting. It is important that you review the Policy often. This Policy is incorporated into, and is subject to, the Terms.',
  'RESPONSIBILITY FOR INFORMATION SUBMITTED BY YOU':
    'You understand and agree that we have no control over the data you provide to us with your purchase. Accordingly, You represent that you are authorized to provide us with such information for the purpose of fulfilling your purchase and agree to hold us and our subsidiaries, affiliates, officers, directors, and employees, and each of their respective successors and assigns (collectively, the “Indemnified Persons”), harmless from, and indemnify them for all damages, costs, expenses and other liabilities, including reasonable attorneys’ fees and expenses, relating to any claim from any person or entity arising out of or related to our use of the information provided in accordance with the terms of this Privacy Policy.',
  'Collection of Non-Personal Information ':
    'We may collect non-personally identifiable information regarding your visit to the Site. We may utilize third party software to collect this information and reserve the right to change our data collection methods, providers and/or software, at any time in our sole discretion. If you do not want such non-personally identifiable information collected, it may be possible for you to adjust settings on the electronic equipment you use to access the Site (e.g., a stationary or mobile computer, phone or tablet) (your “Device”). However, we cannot guarantee that such settings may be changed or will be effective and you assume all risks associated with your access to the Site.',
  'Collection of Personal Information': `We may also collect Personal Information about you or others. By “Personal Information”, we mean information that can be used to identify a specific individual, which could be you or others (i.e., the recipient of any of the Swag Packs, Bulk Swag Orders, Preset Packs and/or any other purchase from SwagUp’s Site (collectively, the “Order”). 

Personal Information may include device identifiers, IP addresses, or “cookies” (“Cookie Data”). This type of information, if collected, is collected through our “Tracking Technologies” described below. Other Personal Information is what you provide to us when registering for a service or purchasing a product for yourself or the recipient of the Order. 
  
By using the Site and providing any information to us, you are acknowledging that the Site is not a secure site and that data transmissions via email, uploads through a network or on Site forms are inherently insecure. We will not intentionally disclose Personal Information, but we cannot control and will not be liable for interference by third-parties. You agree not to provide any information through the Site or email that you or others would not want shared with others.`,
  'How We Use Personal Information': `We will use Personal Information to enable us to provide the service or product indicated when you provided the information on behalf of yourself or the recipient of the Order. We may use Personal Information to identify you when you visit our Site, to improve the operation and usefulness of our Site, to enable us to improve our services and products, and comply with applicable laws and law enforcement.

Users have the opportunity at any time to remove themselves from our communications by responding to the opt-out information provided on our Site or in our emails, or by contacting us using the contact information provided below.`,
  'How We Protect Personal Information': `Personal information collected via the Site may be stored and processed in the United States and/or any other country in which we maintain facilities, and by using the Site, you consent to any such storage, processing and transfer of information, about you or the recipient of the Order, into and out of your country, state, and/or territory.

Generally accepted standards are used to protect Personal Information submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security and cannot be liable for security breaches.`,
  'Tracking Technologies': `We use technologies such as cookies, beacons, tags, and scripts, to analyze trends, administer the Site, track users' movements around the Site, and to gather demographic information about our user base as a whole. We may receive reports based on the use of these technologies by these companies on an individual and aggregated basis.

In addition to the other uses of Personal Information described herein, we use cookie data to remember users' settings (e.g., language preference), and for authentication. Users can control the use of cookies at the individual browser level. If you reject cookies, you may still use our Site, but your ability to use some features or areas of our Site may be limited.`,
  'Data Retention': `You may request that we delete Personal Information and after such request, we will retain and use Personal Information only as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.

We may disclose Personal Information: (1) if we determine a violation of the Terms has occurred; (2) if we believe such disclosure is necessary to identify or bring legal action regarding or prevent injury or interference with our rights or the rights of another user of the Site; (3) to respond to legal process or as otherwise required by law; and (4) to assist us in fraud protection or investigation.

You understand that information received from you may be used by us for any or all of the reasons described herein.

WE WILL NOT HAVE ANY LIABILITY WHATSOEVER FOR ANY DATA BREACH OR UNAUTHORIZED ACCESS TO USER CONTENT OR OTHER INFORMATION ON OR SUBMITTED THROUGH THE SITE.

Except as otherwise required by law, any information submitted by you on the Site or in connection with your use of the Site, is not considered and will not be treated as confidential.`,
  'Children’s Privacy':
    'We do not knowingly collect or solicit personal information from minors and you represent and warrant that you are not providing any information about a minor.',
  'Contact For Any Privacy Concerns:': `SwagUp Inc
Attn: Privacy Officer
226 Old New Brunswick Road
Suite B
Piscataway, NJ 08854
<a href="mailTo:legal@swagup.com">legal@swagup.com</a>
646-494-0520`
};

const PrivacyPolicy = () => <ClausesDocument clausesHeader="Privacy Policy" clauses={privacyClauses} />;

export default PrivacyPolicy;
