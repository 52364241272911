import { Grid, Button, Typography, Box } from '@mui/material';
import { imageSrcSet } from '../../utils';
import gtm from '../..//utils/gtm';
import CenteredGrid from '../shared/CenteredGrid';
import Img from '../shared/Img';
import styles from './styles/home';

const SeasonPromotion = () => {
  return (
    <Grid container justifyContent="center" sx={styles.samplePackContainer}>
      <CenteredGrid sx={[styles.center, styles.promotionHomeCenter]}>
        <Grid container justifyContent="center" spacing={6} sx={styles.promotionHomeWrapperSP}>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent="flex-start" direction="column">
              <Typography
                variant="display2SemiBold"
                component="h4"
                sx={[styles.promotionTextHEH, styles.seasonPromotionHeaderText]}
              >
                It&apos;s a new year - time to hit that refresh button!
              </Typography>
              <Box sx={styles.sampleSubText}>
                <Typography sx={[styles.regularText, styles.seasonPromotionSubtext]}>
                  Check out this season&apos;s curated selection of the freshest packs to inspire, current client faves,
                  and some of our SwagUp MVPs.
                </Typography>
              </Box>
            </Grid>

            <Grid container sx={styles.ctaButtonWrapperSeasonPromotion}>
              <Button
                href="https://issuu.com/swagup/docs/swagup-spring-2022-lookbook"
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                sx={styles.seasonPromotionCtaButton}
                id="season-promotion-cta"
                onClick={gtm.onClickSeasonPromotionCTA}
              >
                View Spring 2022 Lookbook
              </Button>
            </Grid>
          </Grid>
          <Grid item align="center" md={6} sm={5} xs={12} sx={styles.imageGridItemSeasonPromotion}>
            <Img
              srcSet={imageSrcSet('/imgs/seasonPromotion/season-promotion.png')}
              sx={styles.imgSeasonPromotion}
              alt="SwagUp Season Catalog"
            />
          </Grid>
        </Grid>
      </CenteredGrid>
    </Grid>
  );
};

export default SeasonPromotion;
