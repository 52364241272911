import ContactUs from '../../shared/ContactUs';
import OurServices from '../../shared/OurServices';
import BackgroundLandingPages from '../shared/BackgroundAdornments';
import LandingView from '../shared/LandingView';
import LandingSection from '../shared/LandingSection';
import BuildYourBrand from '../shared/NewBuildYourBrand';
import SamplePack from '../shared/NewSamplePack';

const services = [
  {
    src: '/imgs/landingPagesSSR/design.svg',
    title: 'Design',
    text: 'We understand you have been assigned to order swag for your next tradeshow. Leave the artsy stuff to us. We will design the swag for you'
  },
  {
    src: '/imgs/landingPagesSSR/automate.svg',
    title: 'Automate',
    text: 'Collecting data on employees or clients can be hard. We can integrate with any CRM/HR system or simply upload a CSV of contacts you want to distribute swag'
  },
  {
    src: '/imgs/landingPagesSSR/assemble.svg',
    title: 'Assemble',
    text: 'We understand assembling swag is not part of your job description! Leave the assembly line to us! We have the packing covered'
  },
  {
    src: '/imgs/landingPagesSSR/ship.svg',
    title: 'Distribute',
    text: 'Manage, track inventory and shipments for your swag on our SwagUp Platform. One centralized platform making swag experiences for remote workers simple'
  }
];

const promotionTitle = 'We do the work - You take the credit!';
const buttonLabel = 'Let’s get started!';

const bottomImagesList = [
  { src: '/imgs/landingPagesSSR/atlassian.svg', alt: 'Atlassian', margin: 0.75, forcedMargin: 5.5 },
  { src: '/imgs/landingPagesSSR/notion.svg', alt: 'Notion', margin: 0, noPadding: true, forcedMargin: 10.5 },
  { src: '/imgs/landingPagesSSR/breeze.svg', alt: 'Breeze', noPadding: true, forcedMargin: 10.5 },
  { src: '/imgs/landingPagesSSR/smartbug.svg', alt: 'SmartBug', margin: 0, forcedMargin: 10.5 },
  { src: '/imgs/landingPagesSSR/nasdaq.svg', alt: 'Nasdaq', margin: 0, forcedMargin: 10.5 },
  { src: '/imgs/landingPagesSSR/boomi.svg', alt: 'Boomi', forcedMargin: 10.5 }
];

const sectionData = {
  title: 'Virtual Conference Swag!',
  h1Style: { fontSize: 64 },
  subTitle: 'Send physical swag packs to build a \nsense of engagement with virtual \nconference attendees!',
  subTitleClass: 'homePromoMiddleVCS',
  buttonLabel: 'Let’s start!',
  buttonClass: 'buttonLandingSectionVC',
  landingView: <LandingView />,
  imagesTitle: 'SwagUp Client’s Sending Swag to Remote Teams',
  containerClass: 'bottomImagesContainerVCS',
  bottomImagesList
};

const answers = [
  { subTitle: 'Provide custom swag or virtual gift cards to users that attend your virtual conference or meeting!' },
  {
    subTitle: 'Custom landing page or integration to automate swag for your audience'
  },
  { subTitle: 'Attendees receive something tangible to bring your virtual event to life!' }
];

const VirtualConferenceSwag = () => (
  <>
    <BackgroundLandingPages />
    <LandingSection sectionData={sectionData} />
    <BuildYourBrand
      title={`What’s Swag Look like for\nRemote Workers?`}
      maingImg="/imgs/landingPagesSSR/remote-work.png"
      responses={answers}
      hideTag
    />
    <SamplePack
      sectionTagProps={{ left: false, right: true }}
      title={`We believe in\nour high-quality\nswag.`}
      subTitle={`Click on the link below to have a\nsample pack shipped directly to you!`}
      image="/imgs/landingPagesSSR/pack0050.png"
    />
    <OurServices services={services} title={promotionTitle} buttonProps={{ text: buttonLabel }} />
    <ContactUs namePage="Virtual Conference Swag!" />
  </>
);

export default VirtualConferenceSwag;
