import { forwardRef } from 'react';
import { Slider } from '@mui/material';

const styles = {
  root: {
    color: '#9846dd',
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '5px solid currentColor',
    boxShadow: '0 7px 15px 0 rgba(152, 70, 221, 0.24)',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  mark: {
    display: 'none'
  },
  markActive: {
    display: 'none'
  },
  markLabel: {
    top: 48,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.17,
    color: '#8d9299'
  },
  markLabelActive: {
    color: '#434d5c'
  },
  track: {
    height: 6,
    borderRadius: 0.75
  },
  rail: {
    height: 6,
    borderRadius: 0.75,
    backgroundColor: '#ebf1fb'
  }
};

const CustomSlider = forwardRef((props, ref) => (
  <Slider
    ref={ref}
    {...props}
    sx={[
      styles.root,
      {
        '& .MuiSlider-thumb': styles.thumb,
        '& .MuiSlider-rail': styles.rail,
        '& .MuiSlider-track': styles.track,
        '& .MuiSlider-mark': styles.mark,
        '& .MuiSlider-markLabel': styles.markLabel,
        '& .MuiSlider-markLabelActive': styles.markLabelActive,
        '& .MuiSlider-markActive': styles.markActive,
        '& .MuiSlider-valueLabel': styles.valueLabel
      }
    ]}
  />
));

export default CustomSlider;
