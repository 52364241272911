import { Box, Button, Grid, Typography } from '@mui/material';
import { imageSrcSet } from '../../utils';
import gtm from '../../utils/gtm';
import Img from '../shared/Img';
import SectionTag from '../shared/SectionTag';
import { cardStyles, bulkStyles, mainStyles } from './styles/buildYourBrand';

const SwagCard = ({ image, text }) => {
  return (
    <Grid container justifyContent="center" sx={cardStyles.swagCard}>
      {image}
      <Grid item sx={cardStyles.swagCardTextContainer}>
        <Typography variant="inherit" sx={cardStyles.swagCardText}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

const BulkSwagCard = () => {
  return (
    <Grid container justifyContent="center" sx={bulkStyles.bulkSwagCardContainer}>
      <Grid container sx={bulkStyles.bulkSwagCard} justifyContent="space-between" alignItems="center">
        <Grid item>
          <SectionTag tag="Bulk Swag" left={false} />
          <Box sx={bulkStyles.bulkPromotionTitleContainer}>
            <Typography component="h3" variant="inherit" sx={bulkStyles.bulkPromotionTitle}>
              Don’t need a swag pack?
            </Typography>
          </Box>

          <Typography variant="inherit" sx={bulkStyles.bulkPromotionSubtitle}>
            You can order individual bulk items as well
          </Typography>
        </Grid>
        <Grid item>
          <Button
            href="/dashboard/bulk/catalog"
            variant="outlined"
            sx={bulkStyles.buttonOutlined}
            onClick={gtm.onClickRequestSampleCTA}
          >
            Order Bulk Swag
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const swagCards = [
  {
    src: '/imgs/landingPagesSSR/t-shirt-with-heart.png',
    text: 'An assortment of high quality branded items bundled together',
    alt: 'T-shirt with a heart icon',
    style: { left: '22%', top: '35%' }
  },
  {
    src: '/imgs/landingPagesSSR/Heart.png',
    text: 'Perfect for clients and employees to build brand awareness & loyalty',
    alt: 'Heart icon',
    style: { left: '55%', top: '48%' }
  },
  {
    src: '/imgs/landingPagesSSR/pick-swag.png',
    text: 'Consists of various items, from notebooks to sweatshirts and beyond',
    alt: 'Pick swag icon',
    style: { left: '58%', top: '7%' }
  }
];

const BuildYourBrand = () => {
  return (
    <Grid container sx={mainStyles.mainAreaContainer}>
      <Box component="main" sx={mainStyles.mainArea}>
        <Box sx={mainStyles.sectionTagContainer}>
          <SectionTag tag="Build Your Brand" left={false} />
        </Box>

        <Box sx={mainStyles.promotionTitleContainer}>
          <Typography component="h3" variant="inherit" sx={mainStyles.promotionTitle}>
            What’s a Swag
            <br /> Pack?
          </Typography>
        </Box>

        <Box sx={mainStyles.swagBoxImgHeroContainer}>
          <Img
            src="/imgs/landingPagesSSR/pack-hero.png"
            srcSet={imageSrcSet('/imgs/landingPagesSSR/pack-hero.png')}
            alt="Hero Be On Deck"
            sx={mainStyles.swagBox}
          />
        </Box>

        {swagCards.map(card => (
          <Box key={card.alt} sx={[mainStyles.swagCardContainer, card.style]}>
            <SwagCard image={<img src={card.src} alt={card.alt} />} text={card.text} />
          </Box>
        ))}
      </Box>
      <Grid container justifyContent="center">
        <Button
          href="/dashboard/onboarding"
          variant="contained"
          sx={mainStyles.button}
          onClick={gtm.onClickRequestSampleCTA}
        >
          Grab a Free Sample Pack
        </Button>
      </Grid>
      <BulkSwagCard />
    </Grid>
  );
};

export default BuildYourBrand;
