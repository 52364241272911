import { Button, Grid, Typography } from '@mui/material';
import { imageSrcSet } from '../../../helpers/utils';
import CenteredGrid from '../../shared/CenteredGrid';
import Img from '../../shared/Img';
import styles from '../shared/styles/landingSection';

const LandingSection = () => {
  return (
    <Grid container justifyContent="center" sx={styles.landingAreHereContainer}>
      <CenteredGrid sx={[styles.center, styles.promotionHomeCenter]}>
        <Grid container justifyContent="center" spacing={10} sx={styles.promotionHomeWrapperHAH}>
          <Grid item sm={6} xs={12} sx={styles.headerSW}>
            <Grid container direction="column">
              <Grid item container direction="row" sx={styles.containerTextRippling}>
                <Img src="/imgs/landingPagesSSR/rippling.svg" alt="Rippling integration" />
              </Grid>
              <Typography component="h1" variant="inherit" sx={styles.promotionTextHEH}>
                Integrate <span style={{ color: '#3577d4' }}>SwagUp</span> with Rippling
              </Typography>
              <Typography variant="inherit" sx={[styles.regularText, styles.homePromoMiddleHAH]}>
                Automatically send swag to new hires
                <br /> around the globe through Rippling
              </Typography>
              <Grid container sx={styles.landingHereButtonContainerFix}>
                <Button
                  href="/dashboard/custom-swag-packs/catalog"
                  variant="contained"
                  sx={[styles.buttonLandingSection, { marginTop: 14 }]}
                >
                  Let’s get started!
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12} sx={[{ padding: 0 }]}>
            <Grid container justifyContent="center">
              <Img
                src="/imgs/landingPagesSSR/group-143.png"
                srcSet={imageSrcSet('/imgs/landingPagesSSR/group-143.png')}
                alt="pack"
                sx={styles.sPImg}
              />
            </Grid>
          </Grid>
        </Grid>
      </CenteredGrid>
    </Grid>
  );
};

export default LandingSection;
