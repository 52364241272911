const styles = {
  center: {
    width: '100% !important'
  },
  blogHeaderContent: theme => ({
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '156px 124px 85px 124px',
    [theme.breakpoints.down('md')]: {
      backgroundPosition: 'center',
      maxWidth: '100%',
      padding: 0,
      paddingBottom: 6
    }
  }),
  BlogHeader: theme => ({
    backgroundColor: '#f3f6fa',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      paddingTop: 14,
      paddingBottom: 0
    }
  }),
  StayInLoopDescription: {
    padding: '0 65px'
  },
  TimeReading: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: '16px',
    fontWeight: 600,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#ffffff',
    textTransform: 'uppercase',
    paddingTop: 7.5,
    '&::before': {
      backgroundColor: '#ffffff',
      content: '""',
      display: 'inline-block',
      height: '1px',
      position: 'relative',
      verticalAlign: 'middle',
      width: '7%',
      right: '3em'
    },
    '&::after': {
      backgroundColor: '#ffffff',
      content: '""',
      display: 'inline-block',
      height: '1px',
      position: 'relative',
      verticalAlign: 'middle',
      width: '7%',
      left: '3em'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14
    }
  }),
  goBackContent: theme => ({
    cursor: 'pointer',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
      paddingBottom: 0,
      margin: 'auto'
    }
  }),
  subscribeSectionContainer: theme => ({
    backgroundColor: '#ebf1fb',
    borderRadius: 6,
    paddingTop: 10.5,
    paddingBottom: 16.5,
    paddingLeft: '78px',
    paddingRight: '78px',
    margin: 'auto',
    width: '1340px',
    [theme.breakpoints.down('xl')]: {
      width: '1280px'
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      paddingLeft: '24px',
      paddingRight: '24px',
      borderRadius: 0
    }
  }),
  backArrow: theme => ({
    height: 24,
    fontSize: 16,
    color: '#434d5c !important',
    [theme.breakpoints.down('md')]: {
      paddingTop: 0.5,
      fontSize: 14,
      marginRight: 4
    }
  }),
  BlogIntroContainer: {
    maxWidth: '100%',
    margin: '0 auto',
    textAlign: 'center',
    position: 'relative'
  },
  BlogTitle: theme => ({
    textAlign: 'center',
    fontSize: 56,
    fontWeight: 600,
    lineHeight: 'normal',
    color: '#0f2440',
    marginBottom: 8,
    marginTop: 14.5,
    [theme.breakpoints.down('md')]: {
      fontSize: 32
    }
  }),
  BlogSummary: theme => ({
    opacity: 0.75,
    fontFamily: 'Gilroy-Medium',
    textShadow: '0 1px 3px rgba(0, 0, 0, 0.5)',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.67,
    color: '#ffffff',
    paddingBottom: 10,
    '& p': {
      color: '#ffffff'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    }
  }),
  backHome: theme => ({
    opacity: 0.75,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.67,
    color: '#8d9299',
    paddingBottom: 0,
    paddingLeft: 2.5,
    textDecoration: 'none',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0
    }
  }),
  PostCategory: {
    height: 38,
    borderRadius: 1.5,
    backgroundColor: '#d7e0eb',
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 2.29,
    color: '#ffffff',
    textTransform: 'uppercase',
    padding: '3px 8px',
    textAlign: 'center',
    margin: '0 auto'
  }
};

export default styles;
