import { Grid, Box, Typography } from '@mui/material';
import SectionTag from './SectionTag';
import styles from './styles/home';
import BottomImages from '../shared/BottomImages';
import CenteredGrid from '../shared/CenteredGrid';
import Img from '../shared/Img';

const bottomImagesList = () => {
  const basePath = `${process.env.PUBLIC_URL}/imgs/homeLight/`;
  return [
    {
      src: `${basePath}patagonia.svg`,
      alt: 'Patagonia',
      noPadding: true
    },
    {
      src: `${basePath}everland.svg`,
      alt: 'Everland',
      noPadding: true
    },
    {
      src: `${basePath}larst.svg`,
      alt: 'Karst',
      noPadding: true
    },
    {
      src: `${basePath}stickermule.svg`,
      alt: 'Stickermule',
      noPadding: true
    },
    {
      src: `${basePath}combined-shape-2.svg`,
      alt: 'Sock Club',
      margin: -2,
      noPadding: true
    },
    {
      src: `${basePath}moo.svg`,
      alt: 'Moo',
      noPadding: true
    }
  ];
};

const BuildYourBrand = () => {
  const bottomImages = bottomImagesList();

  return (
    <Grid container justifyContent="center" sx={[styles.container, { paddingTop: 14, paddingBottom: 16 }]}>
      <CenteredGrid sx={styles.center}>
        <Box sx={styles.sectionContainer}>
          <SectionTag tag="BUILD YOUR BRAND" />

          <Typography variant="display2SemiBold" sx={styles.promotionTitleBYB}>
            What’s a Swag Pack?
          </Typography>
          <Box sx={[styles.hoveredButton, styles.containerBYB, { backgroundColor: '#ffffff' }]} align="right">
            <Grid container justifyContent="center">
              <Grid item xs>
                <Grid container>
                  <Grid item>
                    <Box sx={styles.wrapperBYBImg}>
                      <Img
                        src={`${process.env.PUBLIC_URL}/imgs/homeLight/swagup-pack.png`}
                        srcSet={`${process.env.PUBLIC_URL}/imgs/homeLight/swagup-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/homeLight/swagup-pack@2x.png 2x,
                        ${process.env.PUBLIC_URL}/imgs/homeLight/swagup-pack@3x.png 3x`}
                        alt="group"
                        sx={styles.bYBImg}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs>
                    <Box sx={styles.menuSectionWrapper}>
                      <Box component="ul" sx={[styles.menuSection, { marginTop: 0, marginBottom: 0 }]}>
                        <li>
                          <Typography variant="subtitle1SemiBold">
                            An assortment of high quality branded items bundled together
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="subtitle1SemiBold">
                            Consists of various items, from notebooks to sweatshirts and beyond
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="subtitle1SemiBold">
                            Perfect for clients and employees to build brand awareness & loyalty
                          </Typography>
                        </li>
                      </Box>

                      <Typography variant="subtitle2SemiBold" sx={styles.regularTextBYB}>
                        Don’t need a swag pack?{' '}
                        <a href="/dashboard/bulk/catalog">
                          <span>Order bulk swag instead</span>
                        </a>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Grid container justifyContent="center">
            <Typography variant="subtitle2SemiBold" sx={styles.partnerTextBYB}>
              We partner with the best
            </Typography>
            <Grid container alignItems="flex-end" sx={styles.brandsContainer}>
              <Grid item container justifyContent="space-around" xs={12} sm={6} lg={6}>
                <BottomImages imgSx={styles.imgStandardBYB} list={bottomImages.slice(0, 3)} xs={false} />
              </Grid>
              <Grid item container justifyContent="space-around" xs={12} sm={6} lg={6}>
                <BottomImages imgSx={styles.imgStandardBYB} list={bottomImages.slice(3)} xs={false} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CenteredGrid>
    </Grid>
  );
};

export default BuildYourBrand;
