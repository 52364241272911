import { Box, Grid, Typography } from '@mui/material';
import CenteredGrid from '../shared/CenteredGrid';
import SectionTag from '../shared/SectionTag';
import { mainStyles, cardStyles, chipStyles } from './styles/swagUpExplained';

const IconChip = ({ children }) => <Box sx={chipStyles.iconChip}>{children}</Box>;

const SwagCard = ({ icon, title, children }) => (
  <Box sx={cardStyles.card}>
    <Box sx={cardStyles.cardArea}>
      <IconChip>{icon}</IconChip>
      <Box sx={cardStyles.textContainer}>
        <Typography component="h3" variant="inherit" sx={cardStyles.cardTitle}>
          {title}
        </Typography>
        <Typography variant="inherit" sx={cardStyles.cardText}>
          {children}
        </Typography>
      </Box>
    </Box>
  </Box>
);

const SwagUpExplained = ({ cards = [], title }) => (
  <Grid container justifyContent="center" sx={mainStyles.mainAreaContainer}>
    <CenteredGrid sx={mainStyles.mainAreaCenter}>
      <Box sx={mainStyles.mainArea}>
        <SectionTag centered tag="SwagUp Explained" />

        <Typography variant="inherit" sx={mainStyles.promotionTitle}>
          {title}
        </Typography>
        <Grid container justifyContent="space-around" alignItems="stretch" sx={mainStyles.cardsContainer}>
          {cards.map(card => (
            <SwagCard key={card.title} icon={card.icon} title={card.title}>
              {card.text}
            </SwagCard>
          ))}
        </Grid>
      </Box>
    </CenteredGrid>
  </Grid>
);

export default SwagUpExplained;
