import { useReducer, useCallback, useRef, useLayoutEffect } from 'react';
import _ from 'lodash';
import log from '../common/logger';

const defaultStatus = {
  pending: false,
  error: undefined,
  response: undefined
};

const useSafeDispatch = dispatch => {
  const mounted = useRef(false);
  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return useCallback((...args) => (mounted.current ? dispatch(...args) : undefined), [dispatch]);
};

function reducer(state, action) {
  switch (action.type) {
    case 'start':
      return { ...state, pending: true };
    case 'success':
      return { pending: false, response: action.payload, error: undefined };
    case 'error':
      return { pending: false, error: action.payload, response: undefined };
    default:
      return defaultStatus;
  }
}

const useAsync = (asyncFn, wait = 0) => {
  const [{ response, pending, error }, dispatch] = useReducer(reducer, defaultStatus);

  const safeDispatch = useSafeDispatch(dispatch);

  // eslint-disable-next-line
  const execute = useCallback(
    _.debounce(async (...args) => {
      log.debug('asyncFn call enter');
      safeDispatch({ type: 'start' });

      const resp = await asyncFn(...args);

      safeDispatch({
        payload: resp,
        type: resp.result === 'error' ? 'error' : 'success'
      });

      log.debug('asyncFn response:', resp);
      return resp;
    }, wait),
    [asyncFn, wait, safeDispatch]
  );

  return [execute, response, pending, error];
};

export default useAsync;
