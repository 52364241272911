import * as React from 'react';
import { Grid, Typography, Link as MuiLink } from '@mui/material';
import { Tabs, Tab, Button } from '@swagup-com/react-ds-components';
import { useAuth } from '../Authentication/index';
import { LoggedOut, LoggedIn } from './SessionHandler';
import HeaderMenu from './Menu';
import { Link } from 'react-router-dom';
import { productMenuItemList, companyMenuItemList, packsMenuItemList, platformMenuItemList } from './common';
import ClickableTooltip from './ClickableTooltip';
import { getFieldFromCookie } from '../../utils';

const tabStyle = {
  paddingLeft: 13,
  paddingRight: 13,
  color: '#434D5C',
  cursor: 'pointer',
  opacity: 1,
  maxWidth: '100px'
};

const getHeaderItems = () => {
  const productsMenuItems =
    getFieldFromCookie('affiliate_id') === 'postal.io' ? packsMenuItemList : productMenuItemList;
  return [
    { title: 'Products', menuList: productsMenuItems },
    { title: 'Platform', menuList: platformMenuItemList },
    { title: 'Company', menuList: companyMenuItemList },
    { title: 'Pricing', to: '/pricing', absolute: true }
  ];
};

const HeaderItems = () => {
  const headerItems = getHeaderItems();

  return (
    <Tabs value={false} variant="scrollable" scrollButtons="auto">
      {headerItems.map(({ title, menuList, to, absolute }) =>
        to ? (
          <Tab
            key={title}
            sx={tabStyle}
            label={<Typography variant="subtitle3SemiBold">{title}</Typography>}
            component={Link}
            to={to}
            absolute={absolute}
          />
        ) : (
          <Tab
            key={title}
            sx={tabStyle}
            label={
              <ClickableTooltip title={<HeaderMenu menuItemList={menuList} />}>
                <Typography variant="subtitle3SemiBold">{title}</Typography>
              </ClickableTooltip>
            }
          />
        )
      )}
    </Tabs>
  );
};

const DashboardButton = () => {
  const { isPending, isAuthenticated } = useAuth();

  return !isPending && isAuthenticated ? (
    <Grid container justifyContent="flex-end" item xs={4} md={2}>
      <Button href="/dashboard" component={MuiLink} size="small">
        Dashboard
      </Button>
    </Grid>
  ) : null;
};

const RegularSection = () => (
  <Grid container alignItems="center" justifyContent="space-between">
    <Grid item alignItems="center" container>
      <Grid item xs={8} md={10}>
        <HeaderItems />
      </Grid>
      <DashboardButton />
    </Grid>
  </Grid>
);

const DesktopHeaderContent = () => {
  const { isPending } = useAuth();

  if (isPending) return null;

  return <RegularSection />;
};

const DesktopHeader = ({ getNotificationComponent }) => {
  const { isPending, isAuthenticated } = useAuth();

  const authenticated = !isPending && isAuthenticated;
  return (
    <Grid container alignItems="center">
      <Grid sx={{ paddingRight: '2.5%' }} item xs={authenticated ? 9 : 8} md={9} lg={10}>
        <DesktopHeaderContent />
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" item xs={authenticated ? 3 : 4} sm md={3} lg={2}>
        {authenticated && <LoggedIn getNotificationComponent={getNotificationComponent} />}
        {!isPending && !isAuthenticated && <LoggedOut />}
      </Grid>
    </Grid>
  );
};

export default DesktopHeader;
