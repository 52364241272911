import { Grid, Typography } from '@mui/material';
import { imageSrcSet } from '../../../utils';
import CenteredGrid from '../../shared/CenteredGrid';
import Img from '../../shared/Img';
import styles from './styles/ourMission';

const OurMission = () => (
  <Grid container justifyContent="center" sx={styles.containerBuildYB}>
    <CenteredGrid sx={styles.center}>
      <Typography variant="inherit" component="h2" sx={styles.title}>
        Our Mission
      </Typography>
      <Grid container flexDirection="column" alignItems="center">
        <Typography variant="inherit" sx={styles.textAboutUs}>
          Empower brands to fully utilize swag to build culture
        </Typography>
        <Grid container sx={styles.containerAllBYB} flexDirection="column" alignItems="center">
          <Grid item sx={styles.wrapperBYBImg}>
            <Img
              src="/imgs/landingPagesSSR/our-mission.png"
              srcSet={imageSrcSet('/imgs/landingPagesSSR/our-mission.png')}
              alt="group"
              sx={styles.bYBImg}
            />
          </Grid>
          <Typography variant="inherit" sx={styles.paragraphAboutUs}>
            SwagUp brings simplicity and quality to the world of branded swag. We focus on creating high-quality swag
            packs for employee welcoming, client gifting, event attendees, and more. We pair this with our warehousing
            and fulfillment services to ensure your swag gets where you want it, when you want, without you having to
            break a sweat through our centralized dashboard and API
          </Typography>
        </Grid>
      </Grid>
    </CenteredGrid>
  </Grid>
);

export default OurMission;
