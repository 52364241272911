import { Grid, Typography, Link as MuiLink } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import CategoryLabel from './CategoryLabel';
import styles from './styles/BlogHeader';
import CenteredGrid from '../shared/CenteredGrid';

const BlogHeader = ({ post }) => (
  <CenteredGrid sx={styles.center} component="section">
    <Grid item sx={styles.subscribeSectionContainer}>
      <Link to="/blog">
        <Grid container sx={styles.goBackContent}>
          <Grid item>
            <ArrowBack sx={styles.backArrow} />
          </Grid>
          <Grid item>
            <MuiLink sx={styles.backHome}>Back to Blogs</MuiLink>
          </Grid>
        </Grid>
      </Link>
      <div>
        <Typography variant="display1SemiBold" sx={styles.BlogTitle}>
          {post.title}
        </Typography>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item>
            <CategoryLabel category={post.category} />
          </Grid>
          <Grid item>
            <CategoryLabel category={`${post.readtime} min read`} variant="outlined" />
          </Grid>
        </Grid>
      </div>
    </Grid>
  </CenteredGrid>
);

export default BlogHeader;
