import { Grid, Link, Typography, Box } from '@mui/material';
import styles from './styles/swagPricesSection';

const Image = ({ img }) => <Box sx={styles.imageContainer}>{img}</Box>;

const SubSection = ({ isBulk, product }) =>
  isBulk ? (
    <Typography component="h4" sx={[styles.mt36, styles.mb0, styles.font, styles.mediumFont, styles.mediumFontSize]}>
      {product.name}
    </Typography>
  ) : (
    <Grid container justifyContent="space-between" alignItems="center" sx={[styles.mt36, styles.packSubSection]}>
      <Grid item xs={7} lg={8}>
        <Typography component="h4" sx={[styles.mt0, styles.mb0, styles.font, styles.mediumFont, styles.mediumFontSize]}>
          {product.name}
        </Typography>
      </Grid>
      <Grid container justifyContent="flex-end" item xs={5} lg={4}>
        <Link
          href={`/dashboard/preset-packs/${product.slug || product.id}`}
          sx={[styles.font, styles.linkFont, styles.blueColor, styles.button]}
        >
          Pack details
        </Link>
      </Grid>
    </Grid>
  );

const PriceSection = ({ priceText, isLoading, isBulk, shipping }) => {
  const swagPrices = `Price per ${isBulk ? 'item' : 'pack'}`;
  const sectionText = shipping ? 'Estimated Shipping Price' : swagPrices;
  return (
    <Grid container justifyContent="center" style={{ marginTop: shipping ? 30 : 53 }}>
      <Box sx={[styles.widthFull, styles.priceCard, !shipping && styles.withBorder]}>
        <Typography
          component="h6"
          sx={[styles.mt0, styles.mb0, styles.widthFull, styles.alignCenter, styles.linkFont, styles.priceTitle]}
        >
          {sectionText}
        </Typography>
        <Typography
          sx={[
            styles.mt20,
            styles.mb0,
            styles.widthFull,
            styles.alignCenter,
            styles.font,
            shipping ? styles.numberFontBlue : styles.numberFontPurple,
            priceText.startsWith('$') && !isLoading ? styles.largeFontSize : styles.linkFont
          ]}
        >
          {isLoading ? 'Calculating...' : priceText}
        </Typography>
      </Box>
    </Grid>
  );
};

export { Image, SubSection, PriceSection };
