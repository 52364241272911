const title = {
  fontFamily: 'Gilroy-SemiBold',
  fontSize: 48,
  color: '#ffffff',
  margin: 0
};

const image = {
  height: 40,
  width: 36,
  objectFit: 'contain',
  position: 'absolute'
};

const styles = {
  root: theme => ({
    backgroundImage: 'url(/imgs/public/Patternbg.svg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '64px 0px',
    position: 'relative',
    marginBottom: 31,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
      padding: '24px 0px'
    }
  }),
  title: theme => ({
    ...title,
    fontWeight: 'bold',
    marginBottom: 6,
    [theme.breakpoints.down('md')]: {
      marginBottom: 2,
      fontSize: 22
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 1,
      fontSize: 18
    }
  }),
  subtitle: theme => ({
    ...title,
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 1,
      fontSize: 12,
      maxWidth: 164
    }
  }),
  blueTriangle: {
    ...image,
    width: 64,
    bottom: -24,
    left: '45%'
  },
  purpleTriangle: {
    ...image,
    top: -18,
    right: '35%'
  },
  getStartedButton: theme => ({
    [theme.breakpoints.down('md')]: {
      width: 92,
      padding: '4px 6px',
      height: 32,
      fontSize: 12
    }
  })
};

export default styles;
