const commonTextStyle = {
  fontFamily: 'Gilroy-Medium',
  color: '#434c5f',
  whiteSpace: 'pre-wrap',
  marginBottom: 5
};

const styles = {
  root: theme => ({
    paddingBottom: 75,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 170
    }
  }),
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: 1024,
      padding: '0 24px'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  header: theme => ({
    ...commonTextStyle,
    fontSize: 24,
    paddingTop: 12.5,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  }),
  title: {
    ...commonTextStyle,
    fontSize: 16
  },
  content: {
    ...commonTextStyle,
    fontSize: 16,
    textAlign: 'justify'
  }
};

export default styles;
