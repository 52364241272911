const styles = {
  container: theme => ({
    padding: '180px 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      px: 6
    }
  }),
  textContainer: theme => ({
    color: '#0f2440',
    fontFamily: 'Gilroy-Bold',
    maxWidth: 520,
    paddingTop: '50px !important',
    paddingLeft: '60px !important',
    [theme.breakpoints.down('sm')]: { paddingLeft: '0 !important' }
  }),
  title: {
    fontSize: '3.5rem',
    lineHeight: 1.29,
    maxWidth: 490,
    my: 7
  },
  text: {
    opacity: 0.54,
    fontSize: '1.25rem',
    lineHeight: 1.8,
    maxWidth: 490
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    borderRadius: '75px 5px 75px 5px'
  }
};

export default styles;
