const styles = {
  container: theme => ({
    paddingLeft: 12.5,
    paddingRight: 12.5,
    paddingBottom: 12.5,
    [theme.breakpoints.down('lg')]: {
      px: 5
    }
  }),
  center: theme => ({
    backgroundColor: 'rgba(235, 241, 251, 0.5)',
    paddingTop: 10,
    borderRadius: 6,
    maxWidth: 1340,
    paddingLeft: '0 !important',
    [theme.breakpoints.down('xl')]: {
      maxWidth: 1120
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%'
    }
  }),
  emailInput: theme => ({
    maxWidth: 332,
    minHeight: 64,
    fontFamily: 'Gilroy-Medium',
    fontSize: 24,
    lineHeight: 1.33,
    color: '#0f2440',
    backgroundColor: 'transparent',
    '& input': {
      backgroundColor: 'transparent',
      fontFamily: 'Gilroy',
      fontSize: 16,
      lineHeight: 0.75,
      padding: '22.5px 30px',
      '&::placeholder': {
        color: '#8d9299',
        opacity: 1
      }
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      maxWidth: 'unset',
      marginRight: 5
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      '& input': { padding: '17.5px 30px' }
    }
  }),
  textContainer: theme => ({
    paddingTop: 10,
    paddingLeft: 27,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 8,
      paddingBottom: 10,
      paddingRight: 20
    },
    [theme.breakpoints.down('md')]: {
      padding: 10,
      textAlign: 'center'
    }
  }),
  title: theme => ({
    fontSize: 56,
    paddingRight: 30,
    fontFamily: 'Gilroy-Bold',
    color: '#0f2440',
    [theme.breakpoints.down('lg')]: {
      paddingRight: 10,
      fontSize: 35
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 28,
      paddingRight: 0
    }
  }),
  subTitle: theme => ({
    marginTop: 4,
    fontFamily: 'Gilroy',
    lineHeight: 1.75,
    color: '#868a8f',
    fontSize: 18,
    maxWidth: 488,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'unset'
    }
  }),
  imageContainer: theme => ({
    paddingLeft: 5,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }),
  image: theme => ({
    objectFit: 'contain',
    width: 695,
    position: 'relative',
    left: -40,
    [theme.breakpoints.down('md')]: {
      left: 0
    }
  }),
  buttonContainer: theme => ({
    '& button > span': { lineHeight: 0.75 },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingTop: 5
    }
  }),
  subscribeButton: theme => ({
    width: '100%',
    maxWidth: 156,
    height: 64,
    marginLeft: 6,
    [theme.breakpoints.down('lg')]: {
      padding: '21px 16px',
      maxWidth: 'unset',
      marginLeft: 0
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
      marginLeft: 0
    }
  })
};

export default styles;
