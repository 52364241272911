import { useMediaQuery } from '@mui/material';
import BaseSectionTag from '../shared/SectionTag';

const SectionTag = ({ tag, color }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  return <BaseSectionTag tag={tag} color={color} right={isMobile} centered={isMobile} />;
};

export default SectionTag;
