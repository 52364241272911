import styles from './styles/BlogExpanded';
import Box from '@mui/material/Box';
import BlogHeader from './BlogHeader';
import Article from './Article';
import RelatedPosts from './RelatedPosts';
import StayInLoop from '../shared/StayInLoopSection';

const BlogExpanded = ({ post, relatedPosts }) => {
  if (!post || !relatedPosts) {
    return <div style={{ height: '100vh' }}></div>;
  }
  return (
    <Box component="main" sx={styles.container}>
      <BlogHeader post={post} />
      <Article post={post} />
      {post.relatedPosts && relatedPosts.length > 0 && (
        <RelatedPosts relatedPosts={relatedPosts} category={post.category} />
      )}
      <div style={{ marginTop: 64 }} />
      <StayInLoop />
    </Box>
  );
};

export default BlogExpanded;
