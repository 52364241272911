import { Grid, Box, Typography } from '@mui/material';
import CenteredGrid from '../shared/CenteredGrid';
import Img from '../shared/Img';
import styles from './styles/company';

const TheStory = () => (
  <CenteredGrid container justifyContent="center" sx={styles.center}>
    <Grid item>
      <Typography component="h2" sx={styles.ourMissionTitle}>
        The SwagUp Story
      </Typography>
      <Typography sx={styles.ourMissionSubTitle}>
        We’ve accomplished so much but we’re not stopping anytime soon
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Grid container justifyContent="center" spacing={6} sx={styles.headImageContainer}>
        <Grid item xs={12} md={5}>
          <Box>
            <Img
              src="/imgs/company/michael.png"
              srcSet="/imgs/company/michael.png 1x, /imgs/company/michael@2x.png 2x,
                  /imgs/company/michael@3x.png 3x"
              alt="Michael Martocci"
              sx={styles.imageWASwagUp}
            />
          </Box>
          <Box>
            <Typography sx={styles.founderChief}>Michael Martocci</Typography>
            <Typography sx={styles.founderChiefSubTxt}>
              Founder, Chief Swag Officer{' '}
              <Typography component="span" role="img" aria-label="rocket">
                🚀
              </Typography>
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={12} md={7}>
          <Box sx={styles.storyContent}>
            <Typography sx={styles.storyParagraph}>
              We’re not printers, we’re not promotional products salesmen, and honestly, up until about 3 years ago, we
              couldn’t even tell you what a vector file was. At our core we are marketers, branders, entrepreneurs, and
              technologists who understand growing businesses and the unique challenges they face. We noticed that
              branded swag, while incredibly important for companies, was broken.
            </Typography>
            <Typography sx={styles.storyParagraph}>
              So in May 2017 we set out to create the swag company we would want to work with if we were running a
              growing company. This company would need to be fun, relatable, have a great taste in products and design,
              and understand the (evolving) challenges that companies face on daily basis as it relates to swag.
            </Typography>
            <Typography sx={styles.storyParagraph}>
              Fast forward to today and we have become one of the fastest growing companies in this industry through our
              unconventional approach and focus on solving problems for customers. The SwagUp story has just begun and
              we’re so excited for you to be a part of it.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  </CenteredGrid>
);

export default TheStory;
