import { Grid, Box, Typography } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import styles from './styles/gallery';
import CenteredGrid from '../shared/CenteredGrid';
import { imageSrcSet } from '../../utils';
import Img from '../shared/Img';

const HeaderAdornment = ({ imageUrl, alt, classKey }) => (
  <Img src={imageUrl} srcSet={imageSrcSet(imageUrl)} alt={alt} sx={styles[classKey]} />
);

const adornments = [
  {
    imageUrl: '/imgs/gallery/blue-package.png',
    alt: 'blue-package',
    classKey: 'simpleBluePackage'
  },
  {
    imageUrl: '/imgs/gallery/pink-swagup-package.png',
    alt: 'pink-package',
    classKey: 'pinkPackage'
  },
  {
    imageUrl: '/imgs/gallery/light-blue-swag-box.png',
    alt: 'light-blue-package',
    classKey: 'lightBlueBox'
  },
  {
    imageUrl: '/imgs/gallery/stay-home-package.png',
    alt: 'stay-home-package',
    classKey: 'stayHomePackage'
  },
  {
    imageUrl: '/imgs/gallery/holiday-package.png',
    alt: 'holiday-package',
    classKey: 'holidayPackage'
  },
  {
    imageUrl: '/imgs/gallery/holiday-package.png',
    alt: 'holiday-big-package',
    classKey: 'holidayPackageRight'
  }
];

const GalleryHeader = () => (
  <Grid container justifyContent="center" sx={{ position: 'relative' }}>
    <CenteredGrid item>
      <Grid container justifyContent="center" sx={styles.headerSectionContainer}>
        <Box sx={styles.headerTitleContainer}>
          <Typography variant="h1Bold" sx={styles.galleryTitle}>
            Check out some of our favorite projects!
          </Typography>
        </Box>
        <Grid container justifyContent="center">
          <Box sx={styles.headerExceprtContainer}>
            <Typography sx={styles.galleryExcerpt}>
              Over 1000 teams use the SwagUp platform to create, automate, and distribute swag and swag packs around the
              globe!
            </Typography>
          </Box>
        </Grid>
        <Grid container justifyContent="center" sx={styles.headerActionContainer}>
          <Button href="/dashboard/product-onboarding" variant="primary" sx={styles.headerActionButton}>
            Start a Project
          </Button>
        </Grid>
      </Grid>
    </CenteredGrid>
    {adornments.map(adorn => (
      <HeaderAdornment key={adorn.alt} imageUrl={adorn.imageUrl} alt={adorn.alt} classKey={adorn.classKey} />
    ))}
  </Grid>
);

export default GalleryHeader;
