import { Helmet as ReactHelmet } from 'react-helmet';
import { useLocation } from 'react-router';

const getTags = (property, content) => {
  switch (property) {
    case 'title':
      return [{ property: 'og:title', content }];

    case 'description':
      return [
        { name: 'description', content },
        { property: 'og:description', content }
      ];

    case 'google-site-verification':
    case 'keywords':
    case 'twitter:card':
    case 'twitter:title':
    case 'twitter:description':
    case 'twitter:url':
    case 'twitter:image':
      return [{ name: property, content }];

    default:
      return [{ property, content }];
  }
};

const buildMeta = (tags, nofollow, article) => {
  const entries = Object.entries(tags);
  const tagsMeta = entries.reduce((acc, [property, content]) => acc.concat(getTags(property, content)), []);

  const commonMeta = [
    { property: 'og:type', content: article ? 'article' : 'website' },
    { name: 'robots', content: `index, ${nofollow ? 'nofollow' : 'follow'}` }
  ];

  return [...tagsMeta, ...commonMeta];
};

const pathsWithCanonical = ['/blog', '/landing', '/company'];
const includeCanonical = pathname => {
  const includeIt = pathname && (pathname === '/' || pathsWithCanonical.some(p => pathname.startsWith(p)));
  return includeIt;
};

const hrefCanonical = path => `https://www.swagup.com${path === '/' ? '' : path}`;

const Helmet = ({ tags, nofollow, article, children }) => {
  const { pathname } = useLocation();

  return (
    <ReactHelmet title={tags.title} meta={buildMeta(tags, nofollow, article)}>
      {includeCanonical(pathname) && <link rel="canonical" href={hrefCanonical(pathname)} />}
      {children}
    </ReactHelmet>
  );
};

export default Helmet;
