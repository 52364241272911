const getStyles = ({ backgroundImage, centerButton }) => ({
  wrapper: {
    height: 256
  },
  homeContainer: {
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    left: 0,
    background: backgroundImage || "url('/imgs/services/Patternbg.svg') repeat center"
  },
  home: theme => ({
    position: 'relative',
    margin: 'auto',
    lineHeight: 1.5,
    minHeight: 256,
    maxWidth: 1440,
    [theme.breakpoints.down('lg')]: {
      minHeight: 'auto',
      width: 'auto',
      marginLeft: 0
    }
  }),
  title: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 48,
    lineHeight: 1.39,
    color: '#ffffff',
    whiteSpace: 'pre-wrap',
    marginLeft: 7.5,
    [theme.breakpoints.down('lg')]: { fontSize: 30, marginTop: 7.5 },
    [theme.breakpoints.down('md')]: { fontSize: 24, lineHeight: 1.42 }
  }),
  subtitle: theme => ({
    marginTop: 4,
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
    fontWeight: 500,
    color: '#f0f2f5',
    marginLeft: 7.5,
    [theme.breakpoints.down('lg')]: { marginBottom: 7.5 },
    [theme.breakpoints.down('sm')]: { fontSize: 12, maxWidth: '65%', margin: '10px 10px 24px 30px', lineHeight: 1.67 }
  }),
  button: theme => ({
    alignSelf: 'center',
    border: 'none',
    '&:hover': { border: 'none' },
    [theme.breakpoints.down('lg')]: {
      marginRight: 7.5
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      height: 48,
      fontSize: 14
    }
  }),
  container: theme => ({
    justifyContent: centerButton ? 'center' : 'flex-start',
    [theme.breakpoints.down('lg')]: {
      justifyContent: centerButton ? 'center' : 'flex-end',
      marginRight: centerButton ? 0 : 7.5
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: centerButton ? 'center' : 'flex-start',
      marginBottom: 8,
      marginLeft: centerButton ? 0 : 7.5
    }
  })
});

export default getStyles;
