import { Grid, Typography, Box } from '@mui/material';
import styles from './styles/home';
import CenteredGrid from '../shared/CenteredGrid';
import Img from '../shared/Img';

function CustomWidthButtonInverseAHolydays({ sx, children, width = 'auto', to = '#', id, fontFamily }) {
  return (
    <Box
      component="a"
      id={id}
      href={to}
      sx={sx}
      fontFamily={fontFamily}
      style={{
        display: 'table',
        width,
        backgroundColor: '#ffffff',
        textDecorationLine: 'none',
        textTransform: 'uppercase',
        fontFamily,
        cursor: 'pointer',
        border: '2px solid #3577d4'
      }}
    >
      <div
        style={{
          display: 'table-cell',
          verticalAlign: 'middle',
          fontWeight: 'bold',
          lineHeight: 1.75,
          letterSpacing: 'normal',
          textAlign: 'center'
        }}
      >
        {children}
      </div>
    </Box>
  );
}

const ImageList = ({ images = [] }) =>
  images.map(imgData => (
    <Box key={imgData.url} sx={styles.recentImgContainer}>
      <Img sx={styles.recentImg} src={imgData.url} srcSet={imgData.srcSet} alt={imgData.alt} />
    </Box>
  ));

const firstColumn = [
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-ozy-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-ozy-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-ozy-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-ozy-pack@3x.png 3x`,
    alt: 'OZY Swag Pack'
  },
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-bread-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-bread-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-bread-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-bread-pack@3x.png 3x`,
    alt: 'Bread Swag Pack'
  },
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-partners-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-partners-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-partners-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-partners-pack@3x.png 3x`,
    alt: 'Partners Swag Pack'
  },
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-holidays-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-holidays-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-holidays-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-holidays-pack@3x.png 3x`,
    alt: 'Holidays Swag Pack'
  }
];
const secondColumn = [
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-gemin-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-gemin-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-gemin-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-gemin-pack@3x.png 3x`,
    alt: 'Gemin Swag Pack'
  },
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-bbb-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-bbb-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-bbb-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-bbb-pack@3x.png 3x`,
    alt: 'BBB Swag Pack'
  },
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-breeze-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-breeze-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-breeze-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-breeze-pack@3x.png 3x`,
    alt: 'Breeze Swag Pack'
  }
];
const thirdColumn = [
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-domio-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-domio-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-domio-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-domio-pack@3x.png 3x`,
    alt: 'Domio Swag Pack'
  },
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-letgo-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-letgo-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-letgo-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-letgo-pack@3x.png 3x`,
    alt: 'LetGo Swag Pack'
  }
];

const fourthColumn = [
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-doggy-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-doggy-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-doggy-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-doggy-pack@3x.png 3x`,
    alt: 'Doggy Swag Pack'
  },
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-patreon-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-patreon-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-patreon-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-patreon-pack@3x.png 3x`,
    alt: 'Patreon Swag Pack'
  },
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-burn-rateid-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-burn-rateid-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-burn-rateid-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-burn-rateid-pack@3x.png 3x`,
    alt: 'Burn Rateid Swag Pack'
  }
];

const fifthColumn = [
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-sample-pack.svg`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-sample-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-sample-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-sample-pack@3x.png 3x`,
    alt: 'Sample Swag Pack'
  },
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-outer-asile-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-outer-asile-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-outer-asile-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-outer-asile-pack@3x.png 3x`,
    alt: 'Outer Asile Swag Pack'
  },
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-goal-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-goal-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-goal-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-goal-pack@3x.png 3x`,
    alt: 'Goal Swag Pack'
  },
  {
    url: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-unpluged-pack.png`,
    srcSet: `${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-unpluged-pack.png 1x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-unpluged-pack@2x.png 2x, ${process.env.PUBLIC_URL}/imgs/recentFavorites/swagup-unpluged-pack@3x.png 3x`,
    alt: 'Unpluged Swag Pack'
  }
];

const Recents = () => {
  return (
    <Grid container justifyContent="center" sx={styles.containerRecent}>
      <Grid container justifyContent="center">
        <CenteredGrid item sx={styles.center}>
          <Typography variant="display1SemiBold" sx={styles.recentTitle}>
            Recent Favorites
          </Typography>
          <Typography variant="subtitle2SemiBold" sx={styles.recentSubTitle}>
            Check out some of the latest swag created on the SwagUp platform
          </Typography>
        </CenteredGrid>
      </Grid>
      <Grid item>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item sx={styles.columnSec}>
            <ImageList images={firstColumn} />
          </Grid>
          <Grid item sx={styles.columnSec}>
            <ImageList images={secondColumn} />
          </Grid>
          <Grid item sx={styles.columnSec}>
            <ImageList images={thirdColumn} />
            <Grid container justifyContent="center" sx={styles.noMobile}>
              <CustomWidthButtonInverseAHolydays to="/gallery" fontFamily="Gilroy" sx={styles.viewAllButton}>
                View all
              </CustomWidthButtonInverseAHolydays>
            </Grid>
          </Grid>
          <Grid item sx={styles.columnSec}>
            <ImageList images={fourthColumn} />
          </Grid>
          <Grid item sx={styles.columnSec}>
            <ImageList images={fifthColumn} />
          </Grid>
          <Grid item container justifyContent="center" xs={12} sx={styles.onMobile}>
            <Grid item sm={6} xs={8}>
              <CustomWidthButtonInverseAHolydays to="/gallery" fontFamily="Gilroy" sx={styles.viewAllButton}>
                View all
              </CustomWidthButtonInverseAHolydays>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Recents;
