import { useQuery } from 'react-query';
import { Redirect, useParams } from 'react-router-dom';
import platformApiClient from '../../api/platformApiClient';
import Helmet from '../shared/Helmet';
import BackgroundAdorments from '../shared/BackgroundAdornments';
import Testimonials from '../shared/Testimonials';
import CenteredGrid from '../shared/CenteredGrid';
import ContactUs from '../shared/ContactUs';
import AboutUs from '../shared/AboutUs';
import SamplePack from '../shared/SamplePack';
import ProductSection from '../shared/ProductSection';
import BenefitsSection from './BenefitsSection';
import LandingSection from './LandingSection';
import SwagUpExplained from './SwagUpExplained';

import TShirtSwagsIcon from './svgs/tShirtSwagsIcon';
import RocketIcon from './svgs/rocketIcon';
import ScreenIcon from './svgs/screenIcon';
import BuildYourBrand from './BuildYourBrand';

const cards = [
  { icon: <TShirtSwagsIcon />, title: 'Create', text: 'We curate the best of the best, no more searching' },
  { icon: <RocketIcon />, title: 'Automate', text: 'Integrate into your workflows through our API and integrations' },
  {
    icon: <ScreenIcon />,
    title: 'Distribute',
    text: 'Keep track of your inventory, place reorders, and send swag around the globe'
  }
];

const emptyPage = { benefits_header_text: 'Benefits', benefits_sub_header_text: 'Loading...', discount: 0 };

const Partner = () => {
  const { slug } = useParams();
  const { data, isLoading } = useQuery(['partners', slug], () => platformApiClient.fetchPartern(slug), {
    enabled: Boolean(slug)
  });

  const isNotAPartner = !isLoading && !data;
  if (!slug || isNotAPartner) return <Redirect to="/" />;

  const page = data || emptyPage;

  return (
    <main style={{ paddingTop: '76px' }}>
      <Helmet tags={{ title: page.seo_title, description: page.seo_description }} />
      <BackgroundAdorments />
      <LandingSection page={page} />
      <BenefitsSection page={page} />
      <SwagUpExplained cards={cards} title="No More Swag Headaches" />
      <BuildYourBrand />
      <ProductSection subtitle="Not sure where to start? No worries, our team curated the most popular packs. Check out our SwagUp Preset packs!" />
      <SamplePack subtitle="Get a sense of what your branded swag pack can look like with our SwagUp Sample pack!" />
      <AboutUs />
      <Testimonials title="Our clients love us to the moon and back" />
      <CenteredGrid sx={{ my: 10 }}>
        <ContactUs namePage={`${page.seo_title} Partnership`} />
      </CenteredGrid>
    </main>
  );
};

export default Partner;
