const styles = {
  landingContainer: theme => ({
    paddingLeft: 12.5,
    paddingRight: 12.5,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }),
  landingSection: theme => ({
    maxWidth: 1340,
    width: '100%',
    paddingLeft: 24.5,
    paddingRight: 24.5,
    backgroundColor: 'rgba(235, 241, 251, 0.5)',
    borderRadius: 24.5,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 12.5,
      paddingRight: 12.5
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 5,
      paddingRight: 5
    }
  }),
  landingSectionLeft: theme => ({
    paddingBottom: 24.25,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
      textAlign: 'center'
    }
  }),
  image: theme => ({
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      width: '70%'
    }
  }),
  landingTitle: theme => ({
    fontFamily: 'Gilroy-SemiBold',
    fontWeight: 600,
    marginTop: 25,
    marginBottom: 0,
    maxWidth: 442,
    lineHeight: 1.17,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset'
    }
  }),
  landingText: theme => ({
    maxWidth: 315,
    marginTop: 6,
    marginBottom: 0,
    fontFamily: 'Gilroy-SemiBold',
    lineHeight: 1.67,
    color: '#8d9299',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset'
    }
  }),
  buttonContainer: theme => ({
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }),
  buttonLandingSection: {
    display: 'flex',
    maxWidth: 156,
    marginTop: 11,
    textTransform: 'none',
    borderRadius: 32,
    backgroundColor: '#3577d4',
    color: '#ffffff',
    padding: '26px 32px',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 0.75
  }
};

export default styles;
