const styles = {
  center: theme => ({
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    }
  }),
  landingAreHereContainer: theme => ({
    paddingTop: 0,
    paddingBottom: 14,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      paddingBottom: 0
    }
  }),
  containerTextRippling: theme => ({
    marginTop: 15,
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      marginTop: 1
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }),
  sPImg: theme => ({
    maxWidth: 916,
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      maxWidth: 800
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }),
  promotionHomeCenter: theme => ({
    [theme.breakpoints.down('md')]: {
      padding: '0px 24px !important'
    }
  }),
  promotionHomeWrapperHAH: theme => ({
    width: 'calc(100% + 60px)',
    marginTop: 3,
    mx: -7.5,
    px: 5.5,
    lineHeight: 1.5,
    [theme.breakpoints.down('lg')]: {
      px: 0
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  }),
  headerSW: theme => ({
    padding: '20px 20px 20px 62px !important',
    [theme.breakpoints.down('md')]: {
      padding: '20px 10px 20px 45px!important'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px !important'
    }
  }),
  promotionTextHEH: theme => ({
    fontFamily: 'Gilroy-Bold',
    fontSize: 72,
    textAlign: 'left',
    color: '#0f2440',
    lineHeight: 1.17,
    marginTop: 4.5,
    marginBottom: 2.5,
    [theme.breakpoints.down('1030')]: {
      fontSize: '40px !important',
      marginTop: 2.5,
      width: 'auto !important'
    },
    [theme.breakpoints.down('990')]: { fontSize: '35px !important' },
    [theme.breakpoints.down('md')]: { fontSize: '30px !important' },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px !important',
      textAlign: 'center'
    }
  }),
  regularText: theme => ({
    color: '#0f2440',
    opacity: 0.54,
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    textAlign: 'center',
    [theme.breakpoints.down('1030')]: {
      fontSize: '20px !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px !important'
    }
  }),
  homePromoMiddleHAH: theme => ({
    lineHeight: 1.8,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: 3
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }),
  landingHereButtonContainer: theme => ({
    paddingTop: 10,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      paddingBottom: 0,
      textAlign: 'center'
    }
  }),
  landingHereButtonContainerFix: theme => ({
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }),
  buttonLandingSection: theme => ({
    width: 256,
    fontSize: 20,
    py: 5,
    lineHeight: 1.75,
    height: 'unset',
    borderRadius: 9,
    [theme.breakpoints.down('990')]: {
      width: 210,
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      width: 196,
      fontSize: 12,
      paddingTop: 4,
      paddingBottom: 4
    }
  }),
  hero: theme => ({
    position: 'relative',
    left: 'calc(50vw - 625px)',
    top: -5,
    [theme.breakpoints.up('1920')]: {
      left: 'calc(50vw - 728px)'
    },
    [theme.breakpoints.down('lg')]: {
      left: 'calc(50vw - 565px)'
    },
    [theme.breakpoints.down('md')]: {
      top: 0,
      left: 0
    }
  }),
  landingBottomFrame: theme => ({
    marginTop: 1.25,
    padding: 7.5,
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  }),
  servingUp: theme => ({
    color: '#0f2440',
    fontSize: 20,
    fontFamily: 'Gilroy-SemiBold',
    textAlign: 'center',
    margin: '20px 0',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      display: 'none'
    }
  }),
  noMobile: theme => ({
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }),
  onMobile: theme => ({
    display: 'none',
    zIndex: 999,
    [theme.breakpoints.down('md')]: {
      display: 'inline'
    }
  }),
  bottomImagesContainer: theme => ({
    marginTop: 6,
    [theme.breakpoints.down('md')]: {
      marginTop: 3,
      paddingRight: 4
    }
  }),
  imgStandardServing: theme => ({
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    [theme.breakpoints.down('md')]: {
      maxWidth: 60,
      maxHeight: 15,
      height: 15
    }
  }),

  // section data classes
  homePromoHoliday: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 24,
    textAlign: 'left',
    color: '#868a8f',
    opacity: 1,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: 12
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 12,
      textAlign: 'center'
    }
  }),
  servingUpHoliday: {
    color: '#868a8f'
  },
  homePromoMiddleVCS: theme => ({
    lineHeight: 1.67,
    textAlign: 'left',
    opacity: 1,
    color: ' #8d9299',
    fontFamily: 'Gilroy-Medium',
    [theme.breakpoints.down('1030')]: {
      width: '260px !important',
      lineHeight: 1.3
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
      marginTop: 0,
      marginBottom: 5.5,
      textAlign: 'left',
      width: 'auto !important'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 3,
      textAlign: 'center',
      width: 'auto !important'
    }
  }),
  bottomImagesContainerVCS: theme => ({
    marginTop: 6,
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: 3,
      paddingRight: 4
    },
    '& div': {
      flexBasis: 'auto',
      flexGrow: 0
    }
  }),
  homePromoMiddleRE: theme => ({
    lineHeight: 1.67,
    textAlign: 'left',
    opacity: 1,
    color: ' #8d9299',
    fontFamily: 'Gilroy-Medium',
    [theme.breakpoints.down('1030')]: {
      width: '260px !important',
      lineHeight: 1.3,
      fontSize: '20px !important'
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
      marginTop: 0,
      marginBottom: 5.5,
      textAlign: 'left',
      width: 'auto !important',
      fontSize: '16px !important'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 3,
      textAlign: 'center',
      width: 'auto !important'
    }
  }),
  buttonLandingSectionVC: theme => ({
    width: '204px !important',
    [theme.breakpoints.down('md')]: {
      width: '146px !important'
    }
  }),
  imageContainerVector: theme => ({
    width: 'auto',
    marginLeft: 6,
    [theme.breakpoints.down('md')]: {
      marginLeft: 3,
      width: 30
    }
  }),
  textRightSideVector: theme => ({
    fontFamily: 'Gilroy',
    fontSize: 18,
    color: '#434D5C',
    marginLeft: 1.5,
    [theme.breakpoints.down('md')]: {
      fontSize: 12
    }
  }),
  containerImgHeroEventGeek: theme => ({
    backgroundColor: 'transparent !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingTop: 0,
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      marginTop: 2.5
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginLeft: '0px !important',
      paddingLeft: '20px !important',
      paddingRight: '14px !important',
      paddingTop: '5px !important'
    }
  }),
  buttonLandingSectionEG: theme => ({
    [theme.breakpoints.down('md')]: {
      width: 125,
      padding: '12px 4px'
    }
  }),
  containerTextEventGeek: theme => ({
    marginTop: 15,
    [theme.breakpoints.down('md')]: {
      marginTop: 1
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }),
  imageContainerEventGeek: {
    height: 38
  }
};

export default styles;
