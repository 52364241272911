import * as React from 'react';
import { Grid, Tabs, Tab, Typography, Box, useTheme } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import LoadMoreSection from '../shared/LoadMoreSection';
import style from './styles/RecentlyPublished';
import PostCard from './PostCard';
import SubscribeCard from './SubscribeCard';
import GestPostCard from './GestPostCard';
import CenteredGrid from '../shared/CenteredGrid';
import { getSortedPostByDate } from './blogUtilFunctions';

const TabPanel = ({ children, value, index }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
  >
    <Box p={3}>{children}</Box>
  </Typography>
);

const RecentlyPublished = ({ posts, categories, featuredPost, subscriptionOnLanding }) => {
  const [value, setValue] = React.useState(0);
  const [length, setLength] = React.useState(subscriptionOnLanding ? 2 : 7);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setLength(7);
  };

  const handleChangeIndex = setValue;

  const filteredCategories = [
    'Latest',
    ...categories.filter(category => posts.find(post => post.category === category))
  ];

  const sortedPost = getSortedPostByDate(posts, 'modified');
  const latestPost = sortedPost.filter(post => post.id !== featuredPost.id);
  const mergedPosts = [...sortedPost, ...latestPost.map(post => ({ ...post, isLatest: true }))];
  const filteredPost = mergedPosts.filter(
    post =>
      (post.category === filteredCategories[value] && !post.isLatest) ||
      (post.category !== filteredCategories[value] && post.isLatest && filteredCategories[value] === 'Latest')
  );

  return (
    <Grid container justifyContent="center" sx={style.containerPartnerWU}>
      <CenteredGrid sx={style.center}>
        <Typography variant="display2SemiBold" sx={[style.Title, { [style.Title48]: subscriptionOnLanding }]}>
          {subscriptionOnLanding ? 'Recent blog posts' : `What's New`}
        </Typography>
        {!subscriptionOnLanding && (
          <Tabs
            value={value}
            centered
            TabIndicatorProps={{ sx: style.indicator }}
            sx={[style.indicator, style.flexContainer, { justifyContent: 'center' }]}
            onChange={handleChange}
          >
            {filteredCategories.map((category, index) => (
              <Tab
                key={category}
                sx={[
                  index === value ? style.Tab : style.TabOff,
                  { marginRight: index < filteredCategories.length - 1 ? 5 : '0px !important' }
                ]}
                label={category}
              />
            ))}
          </Tabs>
        )}
      </CenteredGrid>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ width: '100vw' }}
      >
        {filteredCategories.map((category, index) => (
          <TabPanel key={category} value={value} index={index} dir={theme.direction}>
            {value === index && (
              <CenteredGrid container justifyContent="center" sx={style.centerTabs}>
                <Box sx={style.tabsWrapper}>
                  <Grid container spacing={0} alignItems="stretch">
                    {filteredPost.slice(0, length).map((post, idx) => (
                      <React.Fragment key={post.id}>
                        {idx === 3 && !subscriptionOnLanding && (
                          <Grid item sm={6} xs={12} md={4} sx={{ marginBottom: 8 }}>
                            <SubscribeCard />
                          </Grid>
                        )}
                        <Grid key={post.id} item sm={6} xs={12} md={4} sx={{ marginBottom: 8 }}>
                          <PostCard post={post} category={post.category} />
                        </Grid>
                        {((idx === 6 && !subscriptionOnLanding) || (idx === 1 && subscriptionOnLanding)) && (
                          <Grid item sm={6} xs={12} md={4} sx={{ marginBottom: 8 }}>
                            <GestPostCard />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                </Box>
              </CenteredGrid>
            )}
            <div style={style.loadMoreSection}>
              {filteredPost.length > length && <LoadMoreSection onLoadMore={() => setLength(length + 6)} />}
            </div>
          </TabPanel>
        ))}
      </SwipeableViews>
    </Grid>
  );
};

export default RecentlyPublished;
