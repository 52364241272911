export const containerStyles = {
  container: theme => ({
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      marginTop: 20
    }
  })
};

export const headerStyles = {
  mainArea: theme => ({
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }),
  sectionTag: theme => ({
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  }),
  promotionTitleContainer: theme => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: 4.5,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center'
    }
  }),
  exclusiveChip: theme => ({
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    backgroundColor: 'rgba(235, 241, 251, 0.5)',
    borderRadius: 3.75,
    padding: '12px 14px 11px',
    marginLeft: 4.5,
    [theme.breakpoints.down('lg')]: {
      padding: '10px 12px'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  }),
  exclusiveChipFont: theme => ({
    fontFamily: 'Gilroy',
    fontSize: 24,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#3577d4',
    [theme.breakpoints.down('lg')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    }
  }),
  promotionTitle: theme => ({
    display: 'inline-block',
    color: '#0f2440',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 56,
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 36
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 28
    }
  }),
  promotionSubtitle: theme => ({
    fontFamily: 'Gilroy-Medium',
    fontSize: 20,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#8d9299',
    marginTop: 2.5,
    marginBottom: 0,
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      lineHeight: 1.3,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: 1.2
    }
  })
};

export const mainStyles = {
  mainArea: theme => ({
    justifyContent: 'center',
    marginTop: 21,
    borderRadius: 12.5,
    padding: '61px 70px 69px 70px',
    border: 'solid 1px #d3dbe5',
    [theme.breakpoints.down('lg')]: {
      border: 'transparent'
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      width: '100%',
      padding: '30px 20px 30px 20px',
      marginTop: 5
    }
  }),
  vectorImageContainer: {
    width: 'auto'
  },
  iconCardsContainer: theme => ({
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  }),
  iconCard: theme => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0px 11px',
    width: 285,
    [theme.breakpoints.down('lg')]: {
      width: 170,
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 2.5
    }
  }),
  subtitle: theme => ({
    fontFamily: 'Gilroy',
    fontSize: 24,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: '#0f2440',
    textAlign: 'center',
    py: 8,
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
      lineHeight: 1.1
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: 1
    }
  }),
  benefitsImgsContainer: theme => ({
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }),
  benefitsImgContainer: {
    margin: '0px 14px'
  },
  benefitImg: {
    borderRadius: 7.5
  }
};
