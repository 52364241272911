import { Typography, Grid, Link } from '@mui/material';
import styles from './styles/landingSection';
import Img from '../shared/Img';

const LandingSection = () => (
  <Grid container justifyContent="center" sx={styles.landingContainer}>
    <Grid container item justifyContent="space-between" sx={styles.landingSection}>
      <Grid item xs={12} lg={7} xl={5} sx={styles.landingSectionLeft}>
        <Typography variant="h3" sx={styles.landingTitle}>
          Simple pricing, no surprises
        </Typography>
        <Typography variant="h5" sx={styles.landingText}>
          We pride ourselves on making swag simple, and our pricing even simpler.
        </Typography>
        <Grid item container xs={12} justifyContent="flex-start" sx={styles.buttonContainer}>
          <Link href="/dashboard/product-onboarding" sx={styles.buttonLandingSection}>
            Get Started
          </Link>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center" item lg={5} xl={7}>
        <Img src="/imgs/pricing/ozy-pack.png" alt="Houston bottle" sx={styles.image} />
      </Grid>
    </Grid>
  </Grid>
);
export default LandingSection;
