import { useQuery } from 'react-query';
import { Box } from '@mui/material';
import Helmet from '../shared/Helmet';
import tags from '../../utils/seoTags';
import BackgroundAdornments from './BackgroundAdornments';
import wordpressApiClient from '../../api/wordpressApiClient';
import WeAreSwagup from './WeAreSwagup';
import OurMission from './OurMission';
import TheStory from './TheStory';
import TeamSwagUp from './TeamSwagUp';
import JoinOurTeam from './JoinOurTeam';
import CompanyValues from './CompanyValues';
import SwagFamPerks from './SwagFamPerks';
import Subscribe from '../shared/Subscribe';
import styles from './styles/company';

const Company = () => {
  const { data: team } = useQuery('team', wordpressApiClient.fetchAllTeamMembers);

  return (
    <>
      <Helmet tags={tags.company} />
      <BackgroundAdornments />
      <Box component="main" sx={styles.container}>
        <WeAreSwagup />
        <OurMission />
        <CompanyValues />
        <TheStory />
        <TeamSwagUp team={team} />
        <JoinOurTeam />
        <SwagFamPerks />
        <Subscribe />
      </Box>
    </>
  );
};

export default Company;
