import * as React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Button } from '@swagup-com/react-ds-components';
import AnimationVisibilityTracker from '../shared/AnimationVisibilityTracker';
import SectionTag from './SectionTag';
import CenteredGrid from '../shared/CenteredGrid';
import { imageSrcSet } from '../../utils';
import styles from './styles/home';
import Img from '../shared/Img';

const PartnerWithUs = () => {
  const [onlyIn, setOnlyIn] = React.useState(false);

  React.useEffect(() => {
    const getMyScrollPosition = () => {
      const tesNode = document.getElementById(`partner-with-us`);
      return tesNode ? tesNode.offsetTop - 430 : 0;
    };

    const listenToScroll = () => {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      setOnlyIn(height && winScroll > getMyScrollPosition());
    };

    window.addEventListener('scroll', listenToScroll);
    listenToScroll();

    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const badgeSrc = `${process.env.PUBLIC_URL}/imgs/homeLight/badge.svg`;

  return (
    <Grid id="partner-with-us" container justifyContent="center" sx={styles.containerPartnerWU}>
      <CenteredGrid sx={styles.center}>
        <Box sx={styles.sectionContainerPWU}>
          <Box sx={[styles.onMobile, { paddingBottom: 8 }]}>
            <SectionTag tag="PARTNER WITH US" />
          </Box>
          <Grid container sx={{ backgroundColor: 'transparent' }}>
            <Grid item md={5} xs={12}>
              <Grid container justifyContent="center">
                <Grid item xs align="center">
                  <div
                    style={{
                      position: 'relative'
                    }}
                  >
                    <Box
                      sx={[styles.noMobile, { top: -64, right: -64, height: 152, width: 152, position: 'absolute' }]}
                    >
                      <img className="App-logo" style={{ height: '100%', width: '100%' }} src={badgeSrc} alt="Badge" />
                    </Box>
                  </div>
                  <Box sx={styles.toteImgAnimationContainer}>
                    <AnimationVisibilityTracker animationIn="fadeInDown" animationOut="fadeOutUp" onlyIn={onlyIn}>
                      <Img
                        sx={styles.imagePartnerWT}
                        src={`${process.env.PUBLIC_URL}/imgs/homeLight/canvas-handbag.png`}
                        srcSet={imageSrcSet(`${process.env.PUBLIC_URL}/imgs/homeLight/canvas-handbag.png`)}
                        alt="Swag Handbag"
                      />
                    </AnimationVisibilityTracker>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={7} xs={12}>
              <div>
                <Box sx={styles.pWUContainer}>
                  <Box sx={styles.noMobile}>
                    <SectionTag tag="PARTNER WITH US" />
                  </Box>
                  <Typography variant="display1SemiBold" sx={styles.promotionTitlePWU}>
                    Happiness guaranteed
                  </Typography>
                  <Typography variant="subtitle1SemiBold" sx={styles.promotionSubTextPWU}>
                    Getting custom swag right can be tricky. If you&apos;re not in love with the end result, just let us
                    know and we&apos;ll make it right, guaranteed!
                  </Typography>
                  <Grid container sx={styles.pWUButtonWrapper}>
                    <Button
                      href="/dashboard/product-onboarding"
                      variant="primary"
                      sx={[styles.pWUButton, styles.responsiveButton, { width: 264 }]}
                    >
                      Let’s get started
                    </Button>
                  </Grid>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Box>
      </CenteredGrid>
    </Grid>
  );
};

export default PartnerWithUs;
