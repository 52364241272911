import { Button, Grid, Typography } from '@mui/material';
import CenteredGrid from '../../shared/CenteredGrid';
import Img from '../../shared/Img';
import SectionTag from '../../shared/NewSectionTag';
import styles from './styles/howDoesWork';

const SectionImages = ({ src, alt, number, text, subtext }) => (
  <Grid item xs={12} sm={4} sx={styles.boxSectionImageRippling}>
    <Grid sx={styles.numberSectionImage}>{number}</Grid>
    <Grid container direction="column">
      <Grid item xs={12} sx={styles.itemSectionImage}>
        <Img sx={styles.imgSectionImage} src={src} alt={alt} />
      </Grid>
      <Grid item xs sx={styles.containerTextSectionImages}>
        <Typography variant="inherit" sx={styles.textSectionImages}>
          {text}
        </Typography>
      </Grid>
      <Grid item xs sx={styles.containerSubTextSectionImages}>
        <Typography variant="inherit" sx={styles.subTextSectionImages}>
          {subtext}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);

const HowDoesWork = ({ configurationData }) => (
  <>
    <Grid container justifyContent="center" sx={[styles.container, configurationData.containerStyle]}>
      <CenteredGrid sx={styles.sectionContainerOS}>
        <SectionTag
          sectionContainerClass={configurationData.SectionTagStyle}
          tag={configurationData.tag || 'Process'}
        />

        <Typography variant="inherit" component="h2" sx={styles.promotionTitle}>
          How Does it Work
        </Typography>
        <Typography variant="inherit" sx={styles.textHDW}>
          {configurationData.subTitle ||
            'It’s pretty simple! In just a few clicks you can sync your SwagUp products to Rippling.'}
        </Typography>
        <Grid item sx={styles.containerBoxHDW}>
          <Grid container spacing={10}>
            <SectionImages
              src={configurationData.SectionImages[0].src || '/imgs/landingPagesSSR/image1.svg'}
              alt="image1"
              text={configurationData.SectionImages[0].text || 'Add the App'}
              subtext={
                configurationData.SectionImages[0].subText ||
                'Search for SwagUp in the Rippling App Directory. Click on add SwagUp app'
              }
              number="1"
            />
            <SectionImages
              src={configurationData.SectionImages[1].src || '/imgs/landingPagesSSR/image2.svg'}
              alt="image2"
              text={configurationData.SectionImages[1].text || 'Login or Sign up'}
              subtext={
                configurationData.SectionImages[1].subText ||
                'Start building your swag pack! Select from our curated swag catalog!'
              }
              number="2"
            />
            <SectionImages
              src={configurationData.SectionImages[2].src || '/imgs/landingPagesSSR/image3.svg'}
              alt="image3"
              text={configurationData.SectionImages[2].text || 'Sync Products & Ship!'}
              subtext={
                configurationData.SectionImages[2].subText ||
                'View all the products you have in storage with swagup & sync to easily ship swag out to employees'
              }
              number="3"
            />
          </Grid>
        </Grid>
      </CenteredGrid>
      <Grid container justifyContent="center" sx={styles.servicesButtonContainerOS}>
        <Button href="/dashboard/custom-swag-packs/catalog" variant="contained" sx={styles.servicesButton}>
          Let’s get started
        </Button>
      </Grid>
    </Grid>
  </>
);

export default HowDoesWork;
