import { Tooltip } from '@swagup-com/react-ds-components';
import { Grid, Typography, Button } from '@mui/material';
import UserAvatar from './UserAvatar';
import { Link } from 'react-router-dom';
import { joinFields } from '../../utils';

const getStyles = isCurrent => ({
  root: {
    minWidth: 150,
    padding: 0
  },
  item: {
    maxWidth: 202,
    padding: '0px 6px',
    borderRadius: 12,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f7f8fa',
      borderRadius: 12
    },
    margin: '6px 0px 6px 0px'
  },
  currentItem: {
    maxWidth: 202,
    padding: '0px 6px',
    borderRadius: 12,
    margin: '0px 0px 6px 0px'
  },
  accountListContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '250px'
  },
  avatar: {
    alignSelf: 'center',
    marginRight: 3,
    paddingBottom: isCurrent ? 2 : 'auto',
    paddingTop: isCurrent ? 'auto' : 1.5
  },
  accountControlTitle: {
    margin: '6px 0px 0px 6px',
    lineHeight: 1.25,
    overflow: isCurrent ? 'auto' : 'hidden',
    whiteSpace: isCurrent ? 'normal' : 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    fontWeight: 'bold'
  },
  accountControlSubtitle: theme => ({
    margin: '0px 6px 0px 6px',
    lineHeight: 1,
    overflow: 'hidden',
    color: theme.palette.neutral['500']
  }),
  manageAccountLink: {
    margin: '0px 0px 6px 6px',
    lineHeight: 1.5,
    color: '#3577d4'
  },
  signOutButton: {
    color: 'red',
    fontWeight: 'bold',
    margin: '6px 6px 0 6px',
    padding: 0,
    height: 32
  }
});

const AccountControlItem = ({ name, companyName, isCurrent, onSwapAccount, accountId }) => {
  const styles = getStyles(isCurrent);
  const handleAccountSwap = () => {
    if (!isCurrent) {
      onSwapAccount(accountId);
    }
  };

  return (
    <Tooltip
      disableTouchListener
      enterDelay={700}
      enterNextDelay={700}
      arrow
      title={companyName}
      placement="left-start"
    >
      <Grid
        container
        sx={isCurrent ? styles.currentItem : styles.item}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        onClick={handleAccountSwap}
      >
        <Grid item sx={styles.avatar}>
          <UserAvatar name={name} nameForColorGeneration={companyName} size={32} />
        </Grid>
        <Grid container item xs={8}>
          <Typography variant="body2Medium" sx={styles.accountControlTitle}>
            {isCurrent ? name : companyName}
          </Typography>
          <Typography sx={styles.accountControlSubtitle} variant="body3SemiBold">
            {isCurrent ? companyName : name}
          </Typography>
          {isCurrent && (
            <Link key="manage-my-account" to="/account/profile">
              <Typography variant="body3SemiBold" sx={styles.manageAccountLink}>
                Manage My Account
              </Typography>
            </Link>
          )}
        </Grid>
      </Grid>
    </Tooltip>
  );
};

const AccountSignOut = ({ logout }) => {
  const styles = getStyles();
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" sx={styles.item}>
      <Button variant="text" onClick={logout} sx={styles.signOutButton}>
        Sign Out
      </Button>
    </Grid>
  );
};

const AccountControl = ({ profile, onSwapAccount, logout }) => {
  const name = joinFields([profile.first_name, profile.last_name], ' ');
  const { currentAccount } = profile;
  const filteredAccounts = profile.related_accounts?.filter(a => a.id !== profile.current_account);
  const styles = getStyles();
  return (
    <>
      <AccountControlItem name={name} companyName={currentAccount?.name} isCurrent />
      <hr style={{ borderBottom: '1px solid lightgray' }} />
      {filteredAccounts?.length > 0 && (
        <>
          <div style={styles.accountListContainer}>
            {filteredAccounts.map(a => (
              <AccountControlItem
                key={a.id}
                name={name}
                companyName={a.name}
                onSwapAccount={onSwapAccount}
                accountId={a.id}
              />
            ))}
          </div>
          <hr style={{ borderBottom: '1px solid lightgray' }} />
        </>
      )}
      <AccountSignOut logout={logout} />
    </>
  );
};

export default AccountControl;
